import "../../../css/react-date-range/styles.css";
import "../../../css/react-date-range/default.css";
import "bootstrap/dist/css/bootstrap.min.css";
import * as React from "react";
import { useState } from "react";
import { DateRange } from "react-date-range";
import { Button } from "../../common/ui";
import { tr } from "../../../helpers/languages";

type TimeFrameProps = {
    sendNewTimeFrame: (startDate: Date, endDate: Date) => void,
    startDate: Date,
    endDate: Date
};

export default function TimeFrame(props: TimeFrameProps) {
    const curDate = new Date();
    const [state, setState] = useState([
        {
            startDate: props.startDate,
            endDate: props.endDate,
            key: "selection"
        }
    ]);
    var errorText = <></>;
    const setDays = (days: Number) => {
        var endDate = new Date(new Date().setHours(0, 0, 0, 0));
        switch (days) {
            case 0: //Today
                setState([{ startDate: curDate, endDate: curDate, key: "selection" }]);
                break;
            case 1: //Yesterday
                endDate = new Date(endDate.setDate(curDate.getDate() - 1));
                setState([
                    {
                        startDate: new Date(new Date(endDate).setHours(23, 59, 59)),
                        endDate: endDate,
                        key: "selection"
                    }
                ]);
                break;
            case 2: //Last Week
                endDate = new Date(endDate.setDate(new Date().getDate() - 7));
                setState([
                    {
                        startDate: endDate,
                        endDate: curDate,
                        key: "selection"
                    }
                ]);
                break;
            case 3: //Last 14 Days
                endDate = new Date(endDate.setDate(curDate.getDate() - 14));
                setState([
                    {
                        startDate: endDate,
                        endDate: curDate,
                        key: "selection"
                    }
                ]);
                break;
            case 4: //Last Month
                endDate = new Date(endDate.setDate(curDate.getDate() - 30));
                setState([
                    {
                        startDate: endDate,
                        endDate: curDate,
                        key: "selection"
                    }
                ]);
                break;
            case 5: //Last 2 Months
                endDate = new Date(endDate.setDate(curDate.getDate() - 60));
                setState([
                    {
                        startDate: endDate,
                        endDate: curDate,
                        key: "selection"
                    }
                ]);
                break;
            case 6: //Last 3 Months
                endDate = new Date(endDate.setDate(curDate.getDate() - 90));
                setState([
                    {
                        startDate: endDate,
                        endDate: curDate,
                        key: "selection"
                    }
                ]);
                break;
            case 7: //Last 6 Months
                endDate = new Date(endDate.setDate(curDate.getDate() - 180));
                setState([
                    {
                        startDate: endDate,
                        endDate: curDate,
                        key: "selection"
                    }
                ]);
                break;
            case 8: //Last Year
                endDate = new Date(endDate.setDate(curDate.getDate() - 365));
                setState([
                    {
                        startDate: endDate,
                        endDate: curDate,
                        key: "selection"
                    }
                ]);
                break;
            default:
                break;
        }
    };
    const setTimeFrame = () => {
        var exactTime = new Date();
        var startTime,
            endTime = [0, 0, 0, 0];
        var startDate,
            endDate = new Date();
        /// Below code exits if range is greater than 3 months. This has been removed for Service Desk Trends.
        // if (diffInTime / (1000 * 3600 * 24) >= 91) { //If range is greater than 91 days (>3 months)
        //     setError(true);
        //     return;
        // }
        ///
        //Set time from date picker
        // If same day then start => end of day
        // else current time => current time
        //I'm using Grafana's system which is always current time on both start and end date. Except when it's the
        if (
            state[0].startDate.getDate() + " " + state[0].startDate.getDay() + " " + state[0].startDate.getFullYear() ===
            state[0].endDate.getDate() + " " + state[0].endDate.getDay() + " " + state[0].endDate.getFullYear()
        ) {
            //If same day
            //props.sendNewTimeFrame(new Date(state[0].startDate.setHours(23, 59, 59, 59)), new Date(state[0].endDate.setHours(0, 0, 0, 0)));
            startTime = [23, 59, 59, 59];
            endTime = [0, 0, 0, 0];
        }
        // else if ((state[0].startDate.getDate() === exactTime.getDate() && state[0].startDate.getMonth() === exactTime.getMonth() && state[0].startDate.getFullYear() === exactTime.getFullYear()) ||
        //     (state[0].endDate.getDate() === exactTime.getDate() && state[0].endDate.getMonth() === exactTime.getMonth() && state[0].endDate.getFullYear() === exactTime.getFullYear())) { //If start or end date === today's date
        //         // startTime = [exactTime.getHours(), exactTime.getMinutes(), exactTime.getSeconds(), exactTime.getMilliseconds()];
        //         // endTime = [exactTime.getHours(), exactTime.getMinutes(), exactTime.getSeconds(), exactTime.getMilliseconds()];
        //         startTime = [0, 0, 0, 0];
        //         endTime = [exactTime.getHours(), exactTime.getMinutes(), exactTime.getSeconds(), exactTime.getMilliseconds()];
        // }
        else {
            startTime = [exactTime.getHours(), exactTime.getMinutes(), exactTime.getSeconds(), exactTime.getMilliseconds()];
            endTime = [exactTime.getHours(), exactTime.getMinutes(), exactTime.getSeconds(), exactTime.getMilliseconds()];
        }
        if (state[0].startDate >= state[0].endDate) {
            startDate = new Date(state[0].startDate.setHours(startTime[0], startTime[1], startTime[2], startTime[3]));
            endDate = new Date(state[0].endDate.setHours(endTime[0], endTime[1], endTime[2], endTime[3]));
            //props.sendNewTimeFrame(new Date(state[0].startDate.setHours(exactTime.getHours(),exactTime.getMinutes(),exactTime.getSeconds(),exactTime.getMilliseconds())), new Date(state[0].endDate.setHours(exactTime.getHours(),exactTime.getMinutes(),exactTime.getSeconds(),exactTime.getMilliseconds())));
        } else {
            startDate = new Date(state[0].endDate.setHours(endTime[0], endTime[1], endTime[2], endTime[3]));
            endDate = new Date(state[0].startDate.setHours(startTime[0], startTime[1], startTime[2], startTime[3]));
            //props.sendNewTimeFrame(new Date(state[0].endDate.setHours(exactTime.getHours(),exactTime.getMinutes(),exactTime.getSeconds(),exactTime.getMilliseconds())), new Date(state[0].startDate.setHours(exactTime.getHours(),exactTime.getMinutes(),exactTime.getSeconds(),exactTime.getMilliseconds())));
        }
        props.sendNewTimeFrame(startDate, endDate);
    };
    return (
        <div style={{ width: "485px", marginLeft: "auto", marginRight: "auto" }}>
            <p
                style={{
                    textAlign: "center",
                    userSelect: "none",
                    fontSize: "24px"
                }}
            >
                {tr("DASHBOARD_FILTER_TITLE")}
            </p>
            <div style={{ width: "485px", display: "flex" }}>
                <div
                    style={{
                        display: "flex",
                        flexDirection: "column",
                        paddingRight: "10px"
                    }}
                >
                    <Button
                        primary
                        onClick={() => {
                            setDays(0);
                        }}
                        style={{ marginBottom: "5px" }}
                    >
                        {tr("DASHBOARD_FILTER_TODAY")}
                    </Button>
                    <Button
                        primary
                        onClick={() => {
                            setDays(1);
                        }}
                        style={{ marginBottom: "5px" }}
                    >
                        {tr("DASHBOARD_FILTER_YESTERDAY")}
                    </Button>
                    <Button
                        primary
                        onClick={() => {
                            setDays(2);
                        }}
                        style={{ marginBottom: "5px" }}
                    >
                        {tr("DASHBOARD_FILTER_LAST_WEEK")}
                    </Button>
                    <Button
                        primary
                        onClick={() => {
                            setDays(3);
                        }}
                        style={{ marginBottom: "5px" }}
                    >
                        {tr("DASHBOARD_FILTER_LAST_FORTNIGHT")}
                    </Button>
                    <Button
                        primary
                        onClick={() => {
                            setDays(4);
                        }}
                        style={{ marginBottom: "5px" }}
                    >
                        {tr("DASHBOARD_FILTER_LAST_MONTH")}
                    </Button>
                    <Button
                        primary
                        onClick={() => {
                            setDays(5);
                        }}
                        style={{ marginBottom: "5px" }}
                    >
                        {tr("DASHBOARD_FILTER_LAST_TWO_MONTHS")}
                    </Button>
                    <Button
                        primary
                        onClick={() => {
                            setDays(6);
                        }}
                        style={{ marginBottom: "5px" }}
                    >
                        {tr("DASHBOARD_FILTER_LAST_THREE_MONTHS")}
                    </Button>
                    <Button
                        primary
                        onClick={() => {
                            setDays(7);
                        }}
                        style={{ marginBottom: "5px" }}
                    >
                        {tr("DASHBOARD_FILTER_LAST_SIX_MONTHS")}
                    </Button>
                    <Button
                        primary
                        onClick={() => {
                            setDays(8);
                        }}
                        style={{ marginBottom: "5px" }}
                    >
                        {tr("DASHBOARD_FILTER_LAST_YEAR")}
                    </Button>
                </div>
                <DateRange
                    editableDateInputs={true}
                    //onChange from react-date-range which isn't created in TypeScript.
                    //ToDo: Need to reverse and create type
                    onChange={(item: any) => setState([item.selection])}
                    moveRangeOnFirstSelection={false}
                    ranges={state}
                    maxDate={new Date()}
                />
            </div>
            {errorText}
            <Button
                primary
                onClick={setTimeFrame}
                style={{
                    marginTop: "5px",
                    marginLeft: "auto",
                    marginRight: "auto",
                    display: "flex"
                }}
            >
                Submit
            </Button>
        </div>
    );
}
