import * as React from "react";
import { tr } from "../../../../helpers/languages";
import HelpDashIcon from "../../../common/help/HelpDashIcon";
import BlankCard from "../../ui/BlankCard";
import { Row } from "../../../common/ui";

type DashCardErrorProps = {
    cardName: string,
    openHelpPopup: () => void
};

const DashCardError: React.FC<DashCardErrorProps> = ({ cardName, openHelpPopup }) => {
    return (
        <BlankCard activatePopup={() => {}} title={"Card Error"} isCSE={false} openHelpPopup={openHelpPopup}>
            <Row center style={{ height: "166.6px" }}>
                <p style={{ textAlign: "center" }}>
                    {tr("DASHBOARD_ERROR_CARD")}
                    {cardName}
                </p>
            </Row>
        </BlankCard>
    );
};

export default DashCardError;
