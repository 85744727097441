import React, { useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { tr } from "../../../helpers/languages";
import { send } from "../../../helpers/requests";
import userManager from "../../../util/userManager";
import { ApplicationPaths } from "../../common/authorization/AuthorizationConstants";
import { Button, Container, Row, Spinner } from "../../common/ui";
import AllUnknownDevices from "./AllUnknownDevices";
import OfflineServers from "./OfflineServers";
import { AllFeedback } from "./AllFeedback";
import CSEActivityPerUser from "./ServiceDeskActivityPerUser";
import ServiceDeskUserActivity from "./ServiceDeskUserActivity";


const InternalPage = () => {
    const [page, setPage] = useState("Contents");
    const [loading, setLoading] = useState(true);
    const [denyAccess, setDenyAccess] = useState(false);
    const history = useHistory();
    useEffect(() => {
        VerifyAdmin();
    }, [])
    const SwitchPage = (newPage: string) => {
        switch (newPage) {
            case "offlineServers":
                setPage("OfflineServers");
                break;
            case "serviceDeskActivityPerUser":
                setPage("ServiceDeskActivityPerUser");
                break;
            case "allUnknownDevices":
                setPage("AllUnknownDevices");
                break;
            case "serviceDeskUserActivity":
                setPage("ServiceDeskUserActivity");
                break;
            case "allFeedback":
                setPage("AllFeedback");
                break;
            default:
                setPage("Contents");
                break;
        }
    };
    const VerifyAdmin = async () => {
        let token = (await userManager.getUser())!.access_token;
        send({
            method: "GET",
            url: '/api/servicemanager/getmysmaccess',
            token: token,
            data: "",
            params: ""
        }).then((response: any) => {
            setLoading(false);
            if (!response.data.isAdmin && !response.data.isTech) {
                //this.props.dispatch(push("/dashboard"));
                setDenyAccess(true);
            }
        }).catch((error: any) => {
            console.log("Error :" + error);
            history.push("/dashboard");
        });
    }
    const ExitPage = () => {
        setPage("Contents");
    };
    if (loading) {
        return (
            <div
                style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    marginTop: "10rem"
                }}
            >
                <Spinner size={250} />
            </div>
        );
    }
    if (denyAccess) {
        console.log("401 - Access Denied");
        history.push("/dashboard");
        return (
            <><Link to="/dashboard">Access Denied. Returning to Dashboard.</Link></>
        );
    }
    switch (page) {
        case "OfflineServers":
            return <OfflineServers ExitPage={ExitPage} />;
        case "ServiceDeskActivityPerUser":
            return <CSEActivityPerUser ExitPage={ExitPage} />;
        // case "AllUnknownDevices":
        //     return <AllUnknownDevices ExitPage={ExitPage} />;
        // case "ServiceDeskUserActivity":
        //     return <ServiceDeskUserActivity ExitPage={ExitPage} />;
        case "AllFeedback":
            return <AllFeedback ExitPage={ExitPage} />;
        case "Contents":
        default:
            return <Contents switchPage={SwitchPage} />;
    }
};

type ContentsProps = {
    switchPage: (arg0: string) => void
};

const Contents = (props: ContentsProps) => {
    const SwitchPage = (newPage: string) => {
        props.switchPage(newPage);
    };

    return (
        <>
            <Container>
                <h1>{tr("CSE_INTERNAL_TITLE")}</h1>
                <Row style={{ marginTop: "1rem" }} center>
                    <Button
                        primary
                        onClick={() => {
                            SwitchPage("offlineServers");
                        }}
                    >
                        {tr("CSE_INTERNAL_ALL_OFFLINE_SERVERS")}
                    </Button>
                </Row>
                <Row style={{ marginTop: "1rem" }} center>
                    <Button
                        primary
                        onClick={() => {
                            SwitchPage("allUnknownDevices");
                        }}
                    >
                        {tr("CSE_INTERNAL_UNKNOWN_DEVICES_TITLE")}
                    </Button>
                </Row>
                {/* <Row style={{ marginTop: "1rem" }} center>
                <Button
                        primary
                        onClick={() => {
                            SwitchPage("serviceDeskActivityPerUser");
                        }}
                    >
                        {tr("CSE_INTERNAL_CSE_ACTIVITY")}
                    </Button>
                </Row>
                <Row style={{ marginTop: "1rem" }} center>
                <Button
                        primary
                        onClick={() => {
                            SwitchPage("serviceDeskUserActivity");
                        }}
                    >
                        {tr("CSE_INTERNAL_USER_ACTIVITY")}
                    </Button>
                </Row> */}
                <Row style={{ marginTop: "1rem" }} center>
                    <Button
                        primary
                        onClick={() => {
                            SwitchPage("allFeedback");
                        }}
                    >
                        {tr("CSE_INTERNAL_ALL_FEEDBACK")}
                    </Button>
                </Row>
            </Container>
        </>
    );
};

export default InternalPage;
