import React, { useState } from "react";
import HelpDashIcon from "../../common/help/HelpDashIcon";
import CommentIcon from "../../../assets/icons/comment.svg";
import Popup from "../../common/popup";
import { tr } from "../../../helpers/languages";
import Comments from "./Comments/Comments";

type BlankCardProps = {
    activatePopup: () => void,
    title: string, //User presented card title - Server Online Status 
    openHelpPopup: () => void,
    children: JSX.Element | JSX.Element[],
    cardID?: string, //Internal cardID - ServerStatus
    custID?: string,
    customID?: string, //Replace ID with custom ID if used for reports - LastWeekServerStatusCard
    isCSE: boolean,
    startDate?: Date,
    endDate?: Date
};

const BlankCard = (props: BlankCardProps) => {
    const [popupStatus, setPopupStatus] = useState(false);
    return (
        <>
            <div style={{ margin: "2px" }} id={props.customID ? props.customID : props.cardID ? props.cardID : props.title}>
                <div className="dash-card">
                    <header className="dash-header draggable-ind">
                        <h2 onClick={props.activatePopup}>{props.title}</h2>
                        <HelpDashIcon openHelpPopup={props.openHelpPopup} />
                    </header>
                    {props.children}
                    {props.isCSE && <img
                        alt=""
                        aria-label={tr("DASHBOARD_COMMENTS")}
                        className="commentIcon"
                        src={CommentIcon}
                        onClick={() => {
                            setPopupStatus(true);
                        }}
                    />}
                </div>
            </div>
            {popupStatus && (
                <Popup
                    content={<Comments type={props.cardID!} custID={props.custID!} startDate={props.startDate!} endDate={props.endDate!} />}
                    close={() => {
                        setPopupStatus(false);
                    }}
                    PopupName="CardComments"
                />
            )}
        </>
    );
};

export default BlankCard;
