import { tr } from "../../../helpers/languages";
import React, { useRef, useState } from "react";
import { Button, Row } from "../../common/ui";
import { Convert } from "../../common/ValidateDashboardLayout";
import format from "date-fns/format";
import styles from "./Settings.module.css";
import { CustomDashboard, DashboardCard, PreVersionElevenMigration } from "../DefaultDashboardLayouts";
import { send } from "../../../helpers/requests";
import userManager from "../../../util/userManager";
import { LatestVersion } from "../../common/changelog/versions";
import { LogError } from "../../analytics/eventTracker";

type downloadFileProps = { data: string, fileName: string, fileType: string };

const downloadFile = ({ data, fileName, fileType }: downloadFileProps) => {
    // Create a blob with the data we want to download as a file
    const blob = new Blob([data], { type: fileType });
    // Create an anchor element and dispatch a click event on it
    // to trigger a download
    const a = document.createElement("a");
    a.download = fileName;
    a.href = window.URL.createObjectURL(blob);
    const clickEvt = new MouseEvent("click", {
        view: window,
        bubbles: true,
        cancelable: true
    });
    a.dispatchEvent(clickEvt);
    a.remove();
};

const EncodeString = (str: string) => {
    return btoa(str);
}
const DecodeString = (str: string) => {
    return atob(str);
}

type ImportExportLayoutProps = {
    UpdateLayout: (layout: DashboardCard[], type: Boolean) => void;
}

const ImportExportLayout: React.FC<ImportExportLayoutProps> = ({ UpdateLayout }) => {
    //For whatever reason prettier is breaking the line below.
    // prettier-ignore
    const hiddenFileInput = useRef<HTMLInputElement>(null);
    const [errorMessage, setErrorMessage] = useState("");
    const HandleClick = () => {
        hiddenFileInput.current?.click();
    };
    // function to read file as binary and return
    function getFileFromInput(file: File): Promise<string | ArrayBuffer | null> {
        return new Promise(function (resolve, reject) {
            const reader = new FileReader();
            reader.onerror = reject;
            reader.onload = function () {
                resolve(reader.result);
            };
            reader.readAsBinaryString(file); // here the file can be read in different way Text, DataUrl, ArrayBuffer
        });
    }
    const getAccessToken = async () => {
        let token = (await userManager.getUser())?.access_token;
        return token;
    };
    const ImportLayout = () => {
        if (hiddenFileInput.current?.files) {
            getFileFromInput(hiddenFileInput.current.files[0])
                .then(async (binary) => {
                    try {
                        var decodedString = DecodeString(binary as string);
                        var decodedDash = Convert.toDashboardLayout(decodedString);
                        if (decodedDash[0].dashLayout === 2 && PreVersionElevenMigration.includes(decodedDash[0].version!)) {
                            //Version 2022.1.0.11 moved custom from dashLayout === 2 to dashLayout === 99 to allow room for more pre-defined layouts (preview)
                            decodedDash[0].dashLayout = 99;
                        }
                        window.localStorage.setItem("dashboard", decodedString);
                        send({
                            method: "POST",
                            url: `/api/reporting/updatelayout`,
                            data: decodedString,
                            params: "",
                            token: await getAccessToken()
                        });
                        UpdateLayout(JSON.parse(decodedString), true);
                        setErrorMessage(tr('DASHBOARD_SETTINGS_LAYOUT_SET'));
                    } catch (error) {
                        LogError("ImportExportLayout - Error Reading JSON File", { error });
                        setErrorMessage(tr('DASHBOARD_SETTINGS_ERROR_JSON'));
                        console.error(tr('DASHBOARD_SETTINGS_ERROR_JSON'));
                    }
                })
                .catch((e) => {
                    LogError("ImportExportLayout - Error Reading JSON File", { e });
                    setErrorMessage(tr('DASHBOARD_SETTINGS_ERROR_FILE_READ'));
                    console.error(tr('DASHBOARD_SETTINGS_ERROR_FILE_READ'));
                });
        }
    };
    const ResetLayout = async () => {
        window.localStorage.setItem("dashboard", JSON.stringify(CustomDashboard));
        send({
            method: "POST",
            url: `/api/reporting/updatelayout`,
            data: CustomDashboard,
            params: "",
            token: await getAccessToken()
        });
        UpdateLayout(CustomDashboard, true);
        setErrorMessage(tr("DASHBOARD_SETTINGS_LAYOUT_RESET"));
    }
    const ExportLayout = () => {
        var dash;
        try {
            dash = window.localStorage.getItem("dashboard");
        } catch (error) {
            console.log(tr('DASHBOARD_SETTINGS_LOCAL_STORAGE'));
        }
        if (dash) {
            downloadFile({
                data: EncodeString(dash),
                fileName: "dashboard-layout-export " + format(Date.now(), "dd-MM-yyyy HH-mm-ss") + ".json",
                fileType: "text/json"
            });
        } else {
            setErrorMessage(tr("DASHBOARD_SETTINGS_EXPORT_CANCEL"));
            console.log(tr('DASHBOARD_SETTINGS_EXPORT_CANCEL'));
        }
    };
    if (errorMessage === "Layout Set") {
        return (<h3 style={{ textAlign: 'center' }}>{errorMessage}</h3>)
    }
    return (<>
        {errorMessage !== "" && <Row center><p className={styles.errorText}>{errorMessage}</p></Row>}
        <Row center>
            <Button className={styles.buttonSep} primary onClick={HandleClick}>
                {tr("DASHBOARD_SETTINGS_IMPORT_LAYOUT")}
            </Button>
            <input accept=".json" ref={hiddenFileInput} type="file" onChange={ImportLayout} style={{ display: "none" }} />
            <Button className={styles.buttonSep} primary onClick={ResetLayout}>
                {tr("DASHBOARD_SETTINGS_RESET_LAYOUT")}
            </Button>
            <Button primary onClick={ExportLayout}>
                {tr("DASHBOARD_SETTINGS_EXPORT_LAYOUT")}
            </Button>
        </Row>
    </>);
};

export default ImportExportLayout;
