import * as React from "react";
import userManager from "../../../util/userManager";
import { send } from "../../../helpers/requests";
import DataTable from "react-data-table-component";
import { tr } from "../../../helpers/languages";
import { Input, Label } from "reactstrap";
import dayjs from "dayjs";
import plugin from "dayjs/plugin/advancedFormat";
import { CSVLink } from "react-csv";
import { Spinner } from "../../common/ui";

import styles from "./popout.module.css";

dayjs.extend(plugin);

export default class DeviceLoginsPopout extends React.PureComponent {
    _isMounted = false;
    constructor(props) {
        super(props);
        this.state = {
            getDone: false,
            devicesOriginal: [],
            curSite: "All",
            search: ""
        };
    }
    componentDidMount() {
        this._isMounted = true;
        this.getDevices(this.props.customerID, this.props.startDate, this.props.endDate);
    }
    componentWillUnmount() {
        this._isMounted = false;
    }
    //API Calls
    getAccessToken = async () => {
        let token = (await userManager.getUser()).access_token;
        return token;
    };
    getDevices = async (custID, startDate, endDate) => {
        const timeZoneOffset = startDate.getTimezoneOffset() * 60;
        const newStartDate = Math.floor(startDate.getTime() / 1000) - timeZoneOffset;
        const newEndDate = Math.floor(endDate.getTime() / 1000) - timeZoneOffset;
        send({
            method: "GET",
            url: `/api/reporting/getdevicelogins?custid=${custID}&startdate=${newStartDate}&enddate=${newEndDate}&servers=${this.props.serversBool}`,
            token: await this.getAccessToken()
        }).then((response) => {
            if (this._isMounted) {
                this.props.popoutRendered();
                this.setState({
                    getDone: true,
                    devicesOriginal: response.data
                });
            }
        });
    };
    //Other
    columns = [
        {
            name: this.props.serversBool ? "Server" : "Station",
            selector: (row) => row.hostName,
            sortable: true,
            reorder: true
        },
        {
            name: "Username",
            selector: (row) => row.userName,
            sortable: true,
            reorder: true
        },
        {
            name: "Event",
            selector: (row) => row.event,
            sortable: true,
            reorder: true
        },
        {
            name: "Date",
            selector: (row) => dayjs(row.event_date).format("MMMM Do YYYY, h:mm:ss a"),
            sortable: true,
            reorder: true
        }
    ];
    ChangeSite = (newSite) => {
        this.setState({ curSite: newSite.target.value });
    };
    CreateFinalDevicesList = () => {
        var finalDeviceList = [];
        if (this.state.search === "") {
            if (this.state.curSite !== "All") {
                //If site filter used
                this.state.devicesOriginal.forEach((device) => {
                    if (device.site === this.state.curSite) {
                        finalDeviceList.push(device);
                    }
                });
            } else {
                finalDeviceList = JSON.parse(JSON.stringify(this.state.devicesOriginal));
            }
        } else {
            if (this.state.curSite !== "All") {
                this.state.devicesOriginal.forEach((device) => {
                    if (device.site === this.state.curSite) {
                        if (device.hostName.toLowerCase().includes(this.state.search)) {
                            finalDeviceList.push(device);
                        }
                    }
                });
            } else {
                this.state.devicesOriginal.forEach((device) => {
                    if (device.hostName.toLowerCase().includes(this.state.search)) {
                        finalDeviceList.push(device);
                    }
                });
            }
        }

        return finalDeviceList;
    };
    CreateSiteList = () => {
        var uniqueSites = [];
        this.state.devicesOriginal.forEach((device) => {
            if (!uniqueSites.includes(device.site)) {
                uniqueSites.push(device.site);
            }
        });
        return uniqueSites.sort();
    };
    render() {
        var uniqueSites = this.CreateSiteList();
        var finalDevicesList = this.CreateFinalDevicesList();
        if (!this.state.getDone) {
            return (
                <div
                    style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        marginTop: "10rem"
                    }}
                >
                    <Spinner size="250" />
                </div>
            );
        }
        return (
            <div className={styles.popoutContainer}>
                <div>
                    <h2 className={styles.popoutHeader}>
                        {this.props.serversBool ? "Server " : "Station "}
                        {tr("DASHBOARD_LOGINS_DEVICE")}
                    </h2>
                </div>
                <div className={styles.popoutSearch}>
                    <div className="form-floating-labels">
                        <Input
                            id="Search"
                            placeholder=" "
                            value={this.state.search || ""}
                            onChange={(v) => {
                                this.setState({
                                    search: v.target.value.toLowerCase()
                                });
                            }}
                        />
                        <Label for="Search">{tr("DASHBOARD_SEARCH_POPOUT_HOSTNAMES")}</Label>
                    </div>
                </div>
                <div className={styles.popoutExport}>
                    <div className="perm-floating-label">
                        <Input className={styles.popoutInput} type="select" id="deviceLoginsSelect" onChange={this.ChangeSite}>
                            <option value="All">{tr("ALL")}</option>
                            {uniqueSites.map((site, key) => {
                                return (
                                    <option key={key} value={site}>
                                        {site}
                                    </option>
                                );
                            })}
                        </Input>
                        <Label for="deviceLoginsSelect">{tr("DASHBOARD_SITE_SELECT")}</Label>
                    </div>
                    <CSVLink
                        className={styles.exportCSVButton}
                        data={finalDevicesList}
                        filename={"export " + dayjs().format("DD-MM-YYYY h-mm-ss") + ".csv"}
                    >
                        {tr("EXPORT_CSV")}
                    </CSVLink>
                </div>
                <div className={styles.popoutDataTable}>
                    <DataTable columns={this.columns} data={finalDevicesList} theme={this.props.theme} pagination />
                </div>
            </div>
        );
    }
}
