import * as React from "react";
import { useState, useEffect } from "react";
import { Spinner } from "../../../common/ui";
import { send } from "../../../../helpers/requests";
import { tr } from "../../../../helpers/languages";
import userManager from "../../../../util/userManager";
import { GetTheme } from "../SharedFunctions";

import Popup from "../../../common/popup/popout";
import CardClassic from "../../ui/TextCard";
import SamiTokenUsage from "../../popout/SamiTokenUsage";
import SamiConversationCount from "../../popout/SamiConversationCount";
import { LogError } from "../../../analytics/eventTracker";

export type SamiConversationCountAPI = {
    _id: string,
    count: number
}

interface DashCardSamiTokenUsageProps {
    animate?: boolean;
    custID?: string;
    startDate?: Date;
    endDate?: Date;
    disableReRender?: boolean;
    localPreview: boolean;
    cardRendered?: () => void;
    cardType: number;
    cardID: string;
    isCSE?: boolean;
    customID?: string;
    raOnly?: boolean;
    openHelpPopup: (type: string) => void;
};

//API Request
const getAccessToken = async () => {
    let token = (await userManager.getUser())!.access_token;
    return token;
};


const DashCardSamiConversationCount = (props: DashCardSamiTokenUsageProps) => {
    const [getDone, setGetDone] = useState(false);
    const [popupStatus, setPopupStatus] = useState(false);
    const [samiConversationCount, setConversationCount] = useState<SamiConversationCountAPI[]>([]);
    useEffect(() => {
        const getSamiConversationCount = async (custID: string, startDate: Date, endDate: Date) => {
            const timeZoneOffset = startDate.getTimezoneOffset() * 60;
            const newStartDate = Math.floor(startDate.getTime() / 1000) - timeZoneOffset;
            const newEndDate = Math.floor(endDate.getTime() / 1000) - timeZoneOffset;
            const response = await send({
                method: "GET",
                url: `/api/reporting/samiconversationcount?custid=${custID}&startdate=${newStartDate}&enddate=${newEndDate}`,
                token: await getAccessToken(),
                data: "",
                params: ""
            })
            setConversationCount(response.data);
        }

        setGetDone(false);
        //Get Data on component creation
        if (props.localPreview) {
            setConversationCount([{ _id: "Dave", count: 250 }, { _id: "John", count: 126 }, { _id: "Sarah", count: 82 }, { _id: "Eddie", count: 6 }]);
        }
        else {
            getSamiConversationCount(props.custID!, props.startDate!, props.endDate!).catch((err) => {
                LogError("Error samiconversationcount card", { err, custID: props.custID });
                console.error(err);
            })
        }
        setGetDone(true);
    }, [props.startDate, props.endDate, props.custID]);
    if (props.raOnly) return <></>;
    const openPopup = () => {
        setPopupStatus(true);
    }
    var CardSpinner = getDone ? <></> : (
        <div id="loading-spinner" className="spinner-parent">
            <Spinner size={40} className="spinner-position" />
        </div>
    );
    var ReturnCard = <></>;
    const CalClassicCardData = () => {
        var returnArr: string[] = [];
        samiConversationCount.forEach((user) => {
            returnArr.push(user.count.toString());
        })
        return returnArr;
    }
    const CalClassicCardAltText = () => {
        var returnArr: string[] = [];
        samiConversationCount.forEach((user) => {
            returnArr.push(user._id);
        })
        return returnArr;
    }
    switch (props.cardType) {
        case 0:
        default:
            ReturnCard = (
                <CardClassic
                    title={tr("DASHBOARD_SAMI_CONVERSATION_COUNT_TITLE")}
                    cardID={props.cardID!}
                    activatePopup={() => {
                        !props.localPreview ? openPopup() : console.log("Card Details");
                    }}
                    openHelpPopup={() => {
                        props.openHelpPopup("SamiConversationCount");
                    }}
                    data={CalClassicCardData()}
                    type={["dash-neut", "dash-neut", "dash-neut", "dash-neut", "dash-neut", "dash-neut"]}
                    altText={CalClassicCardAltText()}
                    altTextType={["dash-xsmall-text dash-max-w-70", "dash-xsmall-text dash-max-w-70", "dash-xsmall-text dash-max-w-70", "dash-xsmall-text dash-max-w-70"]}
                    getDone={getDone}
                    custID={props.custID!}
                    startDate={props.startDate!}
                    endDate={props.endDate!}
                    isCSE={props.isCSE!}
                    customID={props.customID!}
                >
                    {CardSpinner}
                </CardClassic>
            )
    }
    return (
        <>
            {ReturnCard}
            {popupStatus && (
                <Popup
                    content={<SamiConversationCount theme={GetTheme()} startDate={props.startDate!} endDate={props.endDate!} customerID={props.custID!} popoutRendered={() => { }} />}
                    close={() => setPopupStatus(false)}
                    PopupName="SamiConversationCountCardDetails"
                />
            )}
        </>
    )
}

export { DashCardSamiConversationCount };