import React, { useEffect, useState } from "react";
import DataTable, { TableColumn } from "react-data-table-component";
import { tr } from "../../../helpers/languages";
import { send } from "../../../helpers/requests";
import userManager from "../../../util/userManager";
import dayjs from "dayjs";
import { Button, Row, Spinner } from "../../common/ui";
import { GetTheme } from "../cards/SharedFunctions";
import { CSVLink } from "react-csv";

type CustomerName = {
    customer_id: string,
    organisation_name: string
};
type OfflineServer = {
    serverName: string,
    offlineAt: string,
    customer: string,
    customerID: string,
    site: string,
    service_request_id: number
};

type OfflineServersProps = {
    ExitPage: () => void
};

const columns: TableColumn<OfflineServer>[] = [
    {
        name: "Hostname",
        selector: (row) => row.serverName,
        sortable: true,
        reorder: true
    },
    {
        name: "Offline At",
        selector: (row) => {
            if (row.offlineAt === "Unknown") {
                return "Unknown";
            } else if (row.offlineAt === null) {
                return "---";
            } else {
                return new Date(row.offlineAt).toUTCString();
            }
        },
        sortable: true,
        reorder: true
    },
    {
        name: "Customer",
        selector: (row) => row.customer,
        sortable: true,
        reorder: true
    },
    {
        name: "Site",
        selector: (row) => row.site,
        sortable: true,
        reorder: true
    },
    {
        name: "Service Request ID",
        selector: (row) => {
            if (row.service_request_id.toString() === "" || row.service_request_id === 0) {
                return "---";
            } else {
                return row.service_request_id;
            }
        },
        sortable: true,
        reorder: true
    }
];

const OfflineServers = (props: OfflineServersProps) => {
    const [OfflineServers, setOfflineServers] = useState({});
    const [getDone, setGetDone] = useState(false);
    /**
     * getAccessToken
     * @description Gets the access token of the authenticated user
     */
    const getAccessToken = async () => {
        let token = (await userManager.getUser())?.access_token;
        return token;
    };
    const getOfflineServers = async () => {
        //setOfflineServers([{ "serverName": "TGA-RD-KH", "offlineAt": "2023-01-04T08:52:30.309Z", "customer": "Tudor Grange Academies Trust", "customerID": "TUDORGRA", "site": "Kingshurst" }, { "serverName": "TGA-RD-KH", "offlineAt": "2023-01-04T08:52:30.309Z", "customer": "Tudor Grange Academies Trust", "customerID": "TUDORGRA", "site": "Kingshurst" }]);
        send({
            method: "GET",
            url: `/api/reporting/getallserversoffline`,
            token: await getAccessToken(),
            data: "",
            params: ""
        })
            .then((response: any) => {
                setOfflineServers(response.data);
                setGetDone(true);
            })
            .catch((error) => {
                console.log(error);
                setGetDone(true);
            });
    };
    const refreshOfflineServers = async () => {
        setGetDone(false);
        getOfflineServers();
    };
    const ChangeCustomer = (newCustomer: { target: { value: string | undefined } }) => {
        setGetDone(false);
        //getOfflineServers(newCustomer.target.value!);
    };
    useEffect(() => {
        //getOtherCustomerNames();
        getOfflineServers();
    }, []);
    if (!getDone) {
        return (
            <div
                style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    marginTop: "10rem"
                }}
            >
                <Spinner size={250} />
            </div>
        );
    }
    const finalOfflineServerList = JSON.parse(JSON.stringify(OfflineServers));
    return (
        <div style={{ overflow: "visible", maxHeight: "100%", marginLeft: "1rem", marginRight: "1rem" }}>
            <div style={{ marginTop: "1rem", marginBottom: "1rem", display: "flex" }}>
                <div style={{ flex: 1 }}>
                    <Button onClick={props.ExitPage}>X</Button>
                </div>
                <h2 style={{ textAlign: "center" }}>{tr("CSE_INTERNAL_ALL_OFFLINE_SERVERS")}</h2>
                <span style={{ flex: 1 }}></span>
            </div>
            {/* <Row style={{ marginTop: "0.5rem", marginBottom: "1rem" }} center>
      <Input type="select" id="customerNamesSelect" style={{ maxWidth: "500px" }} onChange={ChangeCustomer}>
        <option value="All">All</option>
        {AllCustomerNames.map((customerName, index) => {
          return (
            <option key={index} id={customerName.customer_id} value={index}>
              {customerName.organisation_name}
            </option>
          );
        })}
      </Input>
    </Row> */}
            <Row style={{ marginTop: "0.5rem", marginBottom: "1rem" }} center>
                <Button primary onClick={refreshOfflineServers}>
                    Refresh
                </Button>
            </Row>
            <Row center>
                <CSVLink data={finalOfflineServerList} filename={"offline servers " + dayjs().format("DD-MM-YYYY h-mm-ss") + ".csv"}>
                    <Button>{tr("EXPORT_CSV")}</Button>
                </CSVLink>
            </Row>
            <Row style={{ marginTop: "0.5rem", marginBottom: "1rem" }} center>
                <a
                    href={`data:text/json;charset=utf-8,${encodeURIComponent(JSON.stringify(finalOfflineServerList))}`}
                    download={"offline servers " + dayjs().format("DD-MM-YYYY h-mm-ss") + ".json"}
                >
                    <Button>{tr("EXPORT_JSON")}</Button>
                </a>
            </Row>
            <div style={{ marginBottom: "2rem" }}>
                {JSON.stringify(finalOfflineServerList) !== "{}" && <DataTable columns={columns} data={finalOfflineServerList} theme={GetTheme()} />}
            </div>

            {/* <table style={{ minHeight: "fit-content", width: "100%", marginTop: "100px", marginBottom: "100px" }}>
      <thead>
        <tr>
          <th>Hostname</th>
          <th>Offline At</th>
          <th>Customer</th>
          <th>Site</th>
        </tr>
      </thead>
      <tbody>
        {finalOfflineServerList.map((server: OfflineServer, index: number) => {
          return(
            <tr key={"resultRow" + index}>
              <td>{server.serverName}</td>
              <td>{server.offlineAt === "Unknown" ? "Unknown" : server.offlineAt === null ? "---" : new Date(server.offlineAt).toUTCString()}</td>
              <td>{server.customer}</td>
              <td>{server.site}</td>
            </tr>
          )
        })}
      </tbody>
    </table> */}
        </div>
    );
};

export default OfflineServers;
