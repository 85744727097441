import { connectRouter, routerMiddleware } from 'connected-react-router'
import { applyMiddleware, compose, createStore, combineReducers } from 'redux';
import { loadUser, reducer as oidcReducer } from "redux-oidc";
import thunk from 'redux-thunk';
import userManager from "../util/userManager";
import { send } from '../helpers/requests';

const configureStore = (state, history) => {
    const env = process.env.NODE_ENV;

    const allMiddlewares = [
        thunk.withExtraArgument(send),
        routerMiddleware(history),
    ];
    const rootReducer = combineReducers({
        router: connectRouter(history),
        oidc: oidcReducer
    });

    const enhancers = [];

    const hasReduxDevtools = typeof window === 'object' && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__;
    enhancers.push((env === 'development' && hasReduxDevtools) ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__() : compose);

    const store = createStore(rootReducer, state, compose(applyMiddleware(...allMiddlewares), ...enhancers));

    loadUser(store, userManager);

    return store;
};

export default configureStore;