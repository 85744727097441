import * as React from "react";
import userManager from "../../../util/userManager";
import { send } from "../../../helpers/requests";
import DataTable, { TableColumn, TableRow } from "react-data-table-component";
import { tr } from "../../../helpers/languages";
import { Input, Label } from "reactstrap";
import dayjs from "dayjs";
import { CSVLink } from "react-csv";
import { Spinner } from "../../common/ui";

import styles from "./popout.module.css";

type AssetChangesAPI = {
    asset_id: number,
    name: string,
    created_date: Date,
    created_by_network_scan: Boolean,
    site_id: string,
    classification: string,
    mac_address: string,
    ip_addresses: string[],
    model: string
};

type Props = {
    customerID: string,
    startDate: Date,
    endDate: Date,
    theme: string,
    popoutRendered: () => void
}
type Site = {
    site_id: string,
    site_name: string
}
type State = {
    getDone: boolean,
    search: string,
    curSite: string,
    sites: Site[]
    assets: AssetChangesAPI[]
}

export default class AssetChanges extends React.PureComponent<Props, State> {
    _isMounted = false;
    state = {
        getDone: false,
        search: "",
        curSite: "All",
        sites: [],
        assets: [],
    };
    componentDidMount() {
        this._isMounted = true;
        this.getSites(this.props.customerID);
        console.log
        this.getAssetChanges(this.props.customerID, this.props.startDate, this.props.endDate);
    }
    componentWillUnmount() {
        this._isMounted = false;
    }
    //API Calls
    /**
     * getAccessToken
     * @description Gets the access token of the authenticated user
     */
    getAccessToken = async () => {
        let token = (await userManager.getUser())?.access_token;
        return token;
    };
    getSites = async (custID: string) => {
        send({
            method: "GET",
            url: `/api/reporting/getsites?custid=${custID}`,
            token: await this.getAccessToken(),
            data: "",
            params: ""
        }).then((response: any) => {
            if (this._isMounted) {
                this.props.popoutRendered();
                this.setState({ sites: response.data });
            }
        });
    };
    getAssetChanges = async (custID: string, startDate: Date, endDate: Date) => {
        const timeZoneOffset = startDate.getTimezoneOffset() * 60;
        const newStartDate = Math.floor(startDate.getTime() / 1000) - timeZoneOffset;
        const newEndDate = Math.floor(endDate.getTime() / 1000) - timeZoneOffset;
        send({
            method: "GET",
            url: `/api/reporting/getassetchanges?custid=${custID}&startdate=${newStartDate}&enddate=${newEndDate}`,
            token: await this.getAccessToken(),
            params: "",
            data: ""
        }).then((response: any) => {
            if (this._isMounted) {
                this.props.popoutRendered();
                this.setState({
                    getDone: true,
                    assets: response.data
                });
            }
        });
    };
    ChangeSite = (newSite: { target: { value: string | undefined } }) => { this.setState({ curSite: newSite.target.value! }) }
    // type AssetChangesAPI = {
    //     asset_id: number,
    //     name: string,
    //     created_date: Date,
    //     created_by_network_scan: Boolean,
    //     site_id: string,
    //     location: string,
    //     mac_address: string,
    //     ip_addresses: string[],
    //     model: string
    // };
    columns: TableColumn<AssetChangesAPI>[] = [
        {
            name: "Asset Name",
            selector: (row) => row.name,
            sortable: true,
            reorder: true
        },
        {
            name: "Type",
            selector: (row) => row.classification,
            sortable: true,
            reorder: true
        },
        {
            name: "Site",
            selector: (row) => {
                var returnVal = row.site_id;
                this.state.sites?.every((site: Site) => {
                    if (site.site_id === row.site_id) {
                        returnVal = site.site_name;
                        return false;
                    }
                    return true;
                })
                return returnVal;
            },
            sortable: true,
            reorder: true
        },
        {
            name: "IP Address",
            selector: (row) => row.ip_addresses ? row.ip_addresses.toString() : "",
            sortable: true,
            reorder: true
        },
        {
            name: "MAC Address",
            selector: (row) => row.mac_address,
            sortable: true,
            reorder: true
        },
        {
            name: "Created by Network Scan",
            selector: (row) => {
                return row.created_by_network_scan === true ? "Yes" : "No"
            },
            sortable: true,
            reorder: true
        },
        {
            name: "Created Date",
            selector: (row) => row.created_date.toLocaleString(),
            sortable: true,
            reorder: true
        }
    ]
    CreateFinalAssetsList = () => {
        var finalAssetsList: AssetChangesAPI[] = [];
        if (this.state.search === "") {
            if (this.state.curSite === "All") {
                finalAssetsList = JSON.parse(JSON.stringify(this.state.assets));
            } else {
                this.state.assets.forEach((asset: AssetChangesAPI) => {
                    if (asset.site_id === this.state.curSite) {
                        finalAssetsList.push(asset);
                    }
                });
            }
        } else {
            if (this.state.curSite === "All") {
                this.state.assets.forEach((asset: AssetChangesAPI) => {
                    if (asset.name.toString().toLowerCase().includes(this.state.search)) {
                        finalAssetsList.push(asset);
                    }
                });
            }
            else {
                this.state.assets.forEach((asset: AssetChangesAPI) => {
                    if (asset.site_id === this.state.curSite) {
                        if (asset.name.toString().toLowerCase().includes(this.state.search)) {
                            finalAssetsList.push(asset);
                        }
                    }
                });
            }
        }
        return finalAssetsList;
    }
    render() {
        var finalAssetsList = this.CreateFinalAssetsList();
        if (!this.state.getDone) {
            return (
                <div
                    style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        marginTop: "10rem"
                    }}
                >
                    <Spinner size={250} />
                </div>
            );
        }
        return (
            <div className={styles.popoutContainer}>
                <div>
                    <h2 className={styles.popoutHeader}>{tr("DASHBOARD_ASSET_CHANGES_TITLE")}</h2>
                </div>
                <div className={styles.popoutSearch}>
                    <div className="form-floating-labels">
                        <Input
                            id="Search"
                            placeholder=" "
                            value={this.state.search || ""}
                            onChange={(v: React.ChangeEvent<HTMLInputElement>) => {
                                this.setState({
                                    search: v.target.value.toLowerCase()
                                });
                            }}
                        />
                        <Label for="Search">{tr("DASHBOARD_SEARCH_POPOUT_ASSETS")}</Label>
                    </div>
                </div>
                <div className={styles.popoutExport}>
                    <div className="perm-floating-label">
                        <Input className={styles.popoutInput} type="select" id="feedbackPerCustomerSiteSelect" onChange={this.ChangeSite}>
                            <option value="All">{tr("ALL")}</option>
                            {this.state.sites && this.state.sites!.map((site: Site, key) => {
                                return (
                                    <option key={key} value={site.site_id}>
                                        {site.site_name}
                                    </option>
                                );
                            })}
                        </Input>
                        <Label for="serverShutdownSiteSelect">{tr("DASHBOARD_SITE_SELECT")}</Label>
                    </div>
                    <CSVLink
                        className={styles.exportCSVButton}
                        data={finalAssetsList}
                        filename={"export " + dayjs().format("DD-MM-YYYY h-mm-ss") + ".csv"}
                    >
                        {tr("EXPORT_CSV")}
                    </CSVLink>
                </div>
                <DataTable columns={this.columns} data={finalAssetsList} theme={this.props.theme} pagination />
            </div>
        );
    }
}