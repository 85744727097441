import * as React from "react";
import userManager from "../../../util/userManager";
import { send } from "../../../helpers/requests";
import DataTable, { TableColumn, TableRow } from "react-data-table-component";
import { tr } from "../../../helpers/languages";
import { formatDistanceStrict } from "date-fns";
import { Input, Label } from "reactstrap";
import dayjs from "dayjs";
import plugin from "dayjs/plugin/advancedFormat";
import { CSVLink } from "react-csv";
import { Spinner } from "../../common/ui";

import styles from "./popout.module.css";

type SLAObj = {
    service_request_id: string, title: string, insert_time: Date, update_time: Date, close_time: Date
}

type SlaAPI = {
    passedSLA: SLAObj[]
    failedSLA: SLAObj[]
    org_id: string
}

type Props = {
    customerID: string,
    startDate: Date,
    endDate: Date,
    theme: string,
    popoutRendered: () => void
}
type State = {
    getDone: boolean,
    search: string,
    sla: SlaAPI
}

function FormatTimeFrameText(dateTime: Date) {
    if (dateTime === undefined) {
        return "n/a";
    } //Catch error, when opening settings values are set to undefined despite card not being updated to show "1 day"
    if (
        dateTime.getDate() === new Date(Date.now()).getDate() &&
        dateTime.getMonth() === new Date(Date.now()).getMonth() &&
        dateTime.getFullYear() === new Date(Date.now()).getFullYear()
    ) {
        return "1 day ago";
    } else {
        return formatDistanceStrict(dateTime, Date.now(), { unit: "day" || "month" || "year" }) + " ago";
    }
};

export default class ServiceDeskSLA extends React.PureComponent<Props, State> {
    _isMounted = false;
    state = {
        getDone: false,
        search: "",
        sla: { passedSLA: [], failedSLA: [], org_id: "" },
    };
    componentDidMount() {
        this._isMounted = true;
        this.getServiceDeskSLA(this.props.customerID, this.props.startDate, this.props.endDate);
    }
    componentWillUnmount() {
        this._isMounted = false;
    }
    //API Calls
    /**
     * getAccessToken
     * @description Gets the access token of the authenticated user
     */
    getAccessToken = async () => {
        let token = (await userManager.getUser())?.access_token;
        return token;
    };
    getServiceDeskSLA = async (custID: string, startDate: Date, endDate: Date) => {
        const timeZoneOffset = startDate.getTimezoneOffset() * 60;
        const newStartDate = Math.floor(startDate.getTime() / 1000) - timeZoneOffset;
        const newEndDate = Math.floor(endDate.getTime() / 1000) - timeZoneOffset;
        send({
            method: "GET",
            url: `/api/reporting/getsdsla?custid=${custID}&startdate=${newStartDate}&enddate=${newEndDate}`,
            token: await this.getAccessToken(),
            params: "",
            data: ""
        }).then((response: any) => {
            if (this._isMounted) {
                this.props.popoutRendered();
                this.setState({
                    getDone: true,
                    sla: response.data
                });
            }
        });
    };
    columns: TableColumn<SLAObj>[] = [
        {
            name: "Service Request",
            selector: (row) => row.service_request_id,
            sortable: true,
            reorder: true
        },
        {
            name: "Subject",
            selector: (row) => row.title,
            sortable: true,
            reorder: true
        },
        {
            name: "Service Request Link",
            cell: (row) => {
                return (<a target="_blank" style={{ color: "var(--cse-text)", textDecoration: "underline" }} href={`https://cloud.cseservicemanager.co.uk/ServiceManager/ViewEditServiceRequestV2?id=${row.service_request_id}&orgId=${this.state.sla.org_id}&isArchived=0`}>Click here</a>)
            },
            sortable: true,
            reorder: true
        },
        {
            name: "Ticket Opened Date",
            cell: (row) => {
                if (row.insert_time === null) {
                    return "n/a";
                }
                return FormatTimeFrameText(new Date(row.insert_time));
            },
            sortable: true,
            reorder: true
        },
        {
            name: "Ticket Closed Date",
            cell: (row) => {
                if (row.close_time === null) {
                    return "n/a";
                }
                return FormatTimeFrameText(new Date(row.close_time));
            },
            sortable: true,
            reorder: true
        }
    ];
    CreateFinalSALList = () => {
        var finalSLAList: SlaAPI = { passedSLA: [], failedSLA: [], org_id: "" };
        if (this.state.search === "") {
            finalSLAList = JSON.parse(JSON.stringify(this.state.sla));
        } else {
            //passedSLA
            this.state.sla.passedSLA.forEach((sla: SLAObj) => {
                if (sla.service_request_id.toString().includes(this.state.search) || sla.title.toLowerCase().includes(this.state.search)) {
                    finalSLAList.passedSLA.push(sla);
                }
            });
            //failedSLA
            this.state.sla.failedSLA.forEach((sla: SLAObj) => {
                if (sla.service_request_id.toString().includes(this.state.search) || sla.title.toLowerCase().includes(this.state.search)) {
                    finalSLAList.failedSLA.push(sla);
                }
            })
            //org_id
            finalSLAList.org_id = this.state.sla.org_id;
        }
        return finalSLAList;
    }
    render() {
        var finalSLAList = this.CreateFinalSALList();
        if (!this.state.getDone) {
            return (
                <div
                    style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        marginTop: "10rem"
                    }}
                >
                    <Spinner size={250} />
                </div>
            );
        }
        return (
            <div className={styles.popoutContainer}>
                <div>
                    <h2 className={styles.popoutHeader}>{tr("DASHBOARD_SERVICEDESK_SLA_TITLE")}</h2>
                </div>
                <div className={styles.popoutSearch}>
                    <div className="form-floating-labels">
                        <Input
                            id="Search"
                            placeholder=" "
                            value={this.state.search || ""}
                            onChange={(v: React.ChangeEvent<HTMLInputElement>) => {
                                this.setState({
                                    search: v.target.value.toLowerCase()
                                });
                            }}
                        />
                        <Label for="Search">{tr("DASHBOARD_SEARCH_POPOUT_CATEGORIES")}</Label>
                    </div>
                </div>
                <div className={styles.popoutExport}>
                    <CSVLink
                        className={styles.exportCSVButton}
                        data={finalSLAList.failedSLA}
                        filename={"export " + dayjs().format("DD-MM-YYYY h-mm-ss") + ".csv"}
                    >
                        {tr("EXPORT_CSV")}
                    </CSVLink>
                </div>
                <div style={{ marginTop: "52px" }}>
                    <h2 style={{ textAlign: "center", marginTop: "8px", marginBottom: "8px" }}>Failed SLA</h2>
                    <DataTable columns={this.columns} data={finalSLAList.failedSLA} theme={this.props.theme} pagination />
                    <h2 style={{ textAlign: "center", marginTop: "8px", marginBottom: "8px" }}>Passed SLA</h2>
                    <DataTable columns={this.columns} data={finalSLAList.passedSLA} theme={this.props.theme} pagination />
                </div>

            </div>
        )
    }
}