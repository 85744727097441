import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import userManager from "../../../util/userManager";
import { ApplicationPaths } from './AuthorizationConstants';
import { connect } from "react-redux";
import CSELogo from '../../../assets/CSE-Logo-REV-NS-thick.svg';
import DashIcon from '../../../assets/icons/dashboard.svg';
import LogoutIcon from '../../../assets/icons/logout.svg';
import UserIcon from '../../../assets/icons/user.svg';
import HelpIcon from '../../../assets/icons/help.svg';
import { tr } from '../../../helpers/languages';
import TakeScreenshot from './TakeScreenshot';
import Help from '../help/help';
import Popup from '../popup';
import SendEmail from './SendEmail/SendEmail';
import Schedule from './SendEmail/Schedule';
import { LogEvent } from '../../analytics/eventTracker';

class LoginMenu extends Component {
  constructor(props) {
    super(props);

    this.state = {
      itemHovered: '',
      userName: null,
      isAuthenticated: false,
      popupStatus: false
    };
  }

  componentDidMount() {
    this.getUserInfo();
  }

  toggleItemHover = (hoverItem) => {
    this.setState({ itemHovered: hoverItem });
  }

  getUserInfo = () => {
    const username = this.state.userName;
    userManager.getUser().then((u) => {

      if (u == null) {
        if (username !== null) {
          this.setState({ userName: null, isAuthenticated: false });
        }
      } else {
        if (username !== u.profile.preferred_username) {
          this.setState({ userName: u.profile.preferred_username, isAuthenticated: true });
        }
      }
    });
  }


  render() {
    this.getUserInfo();
    const userName = this.state.userName;
    if (!this.state.isAuthenticated) {
      const loginPath = `${ApplicationPaths.Login}`;
      return this.anonymousView(loginPath);
    } else {
      const profilePath = `${ApplicationPaths.Profile}`;
      const logoutPath = { pathname: `${ApplicationPaths.LogOut}`, state: { local: true } };
      return this.authenticatedView(userName, profilePath, logoutPath);
    }
  }
  authenticatedView(userName, profilePath, logoutPath) {
    return (<>
      <div className='cse-nav-menu-icon-container-empty' >
        <img alt="" aria-label={tr('APP_TITLE')} className='cse-nav-menu-icon-empty' src={CSELogo} />
      </div>
      <div className='cse-nav-menu-icon-container' >
        <Link to="/dashboard" className='cse-nav-link'>
          <img alt="" onMouseEnter={() => this.toggleItemHover('dashItem')} onMouseLeave={() => this.toggleItemHover('')} aria-label={tr('MENU_DASHBOARD')} className='cse-nav-menu-icon' src={DashIcon} />
        </Link>
        <span className={this.state.itemHovered === 'dashItem' ? 'cse-nav-menu-tooltip-hovered cse-nav-menu-tooltip' : 'cse-nav-menu-tooltip'}>
          {tr('MENU_DASHBOARD')}
        </span>
      </div>
      <TakeScreenshot />
      <SendEmail />
      <Schedule />
      <div className='cse-nav-menu-icon-container' >
        <img alt="" onMouseEnter={() => this.toggleItemHover('helpItem')} onMouseLeave={() => this.toggleItemHover('')} aria-label={tr('DASHBOARD_HELP')} className='cse-nav-menu-icon' src={HelpIcon} onClick={() => { this.setState({ popupStatus: true }); LogEvent(4, {}) }} />
        <span className={this.state.itemHovered === 'helpItem' ? 'cse-nav-menu-tooltip-hovered cse-nav-menu-tooltip' : 'cse-nav-menu-tooltip'}>
          {tr('DASHBOARD_HELP')}
        </span>
      </div>
      <div className='cse-nav-menu-icon-container' >
        <Link to={logoutPath} className='cse-nav-link'>
          <img alt="" onMouseEnter={() => this.toggleItemHover('signOutItem')} onMouseLeave={() => this.toggleItemHover('')} aria-label={tr('MENU_SIGN_OUT')} className='cse-nav-menu-icon' src={LogoutIcon} />
        </Link>
        <span className={this.state.itemHovered === 'signOutItem' ? 'cse-nav-menu-tooltip-hovered cse-nav-menu-tooltip' : 'cse-nav-menu-tooltip'}>
          {tr('MENU_SIGN_OUT')}
        </span>
      </div>
      {this.props.children}
      <div className='cse-nav-menu-icon-container' >
        <Link to={profilePath} target='_blank' className='cse-nav-link'>
          <img alt="" onMouseEnter={() => this.toggleItemHover('userItem')} onMouseLeave={() => this.toggleItemHover('')} aria-label={userName} className='cse-nav-menu-icon' src={UserIcon} />
        </Link>
        <span className={this.state.itemHovered === 'userItem' ? 'cse-nav-menu-tooltip-hovered cse-nav-menu-tooltip' : 'cse-nav-menu-tooltip'}>
          {tr('MENU_PROFILE_MANAGEMENT')}
        </span>
      </div>
      {this.state.popupStatus && <Popup content={<Help />} close={() => { this.setState({ popupStatus: false }) }} PopupName="HelpPage" />}
    </>);

  }

  anonymousView(loginPath) {
    this.props.receiveUserName("", "/");
    return (<>
      <div className='cse-nav-menu-icon-container' >
        <Link to="/" className='cse-nav-link'>
          <img alt="" aria-label={tr('MENU_HOMEPAGE')} className='cse-nav-menu-icon' onMouseEnter={() => this.toggleItemHover('item1')} onMouseLeave={() => this.toggleItemHover('')} src={CSELogo} />
        </Link>
        <span className={this.state.itemHovered === 'item1' ? 'cse-nav-menu-tooltip-hovered cse-nav-menu-tooltip' : 'cse-nav-menu-tooltip'}>
          {tr('MENU_HOMEPAGE')}
        </span>
      </div>
      {this.props.children}
    </>);
  }

}

const mapStateToProps = (state, _props) => {
  return {
    oidc: state.oidc
  };
};

export default connect(mapStateToProps)(LoginMenu);