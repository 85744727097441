import { useEffect, useState } from "react";
import userManager from "../../../util/userManager";
import { send } from "../../../helpers/requests";
import DataTable, { TableColumn, TableRow } from "react-data-table-component";
import { CSVLink } from "react-csv";
import { Spinner } from "../../common/ui";
import { Input, Label } from "reactstrap";
import { format } from "date-fns";
import { tr } from "../../../helpers/languages";

import styles from "./popout.module.css";
import { LogError } from "../../analytics/eventTracker";

type ServiceDeskFeedback = {
    _id: string,
    customer_id: string,
    site_id: string,
    database_link_id: string,
    feedback_user: string,
    feedback_message: string,
    feedback_rating: number | string,
    created: Date,
    updated: Date,
    updated_by: string,
    organisation_id: string
};
type Site = {
    site_id: string,
    site_name: string
}
type FeedbackPerCustomerProps = {
    customerID: string,
    startDate: Date,
    endDate: Date,
    theme: string,
    popoutRendered: () => void;
}

//API Calls
/**
 * getAccessToken
 * @description Gets the access token of the authenticated user
 */
const getAccessToken = async () => {
    let token = (await userManager.getUser())?.access_token;
    return token;
};
const getSites = async (custID: string) => {
    return await send({
        method: "GET",
        url: `/api/reporting/getsites?custid=${custID}`,
        token: await getAccessToken(),
        data: "",
        params: ""
    });
};
const getFeedback = async (custID: string, startDate: Date, endDate: Date) => {
    const timeZoneOffset = startDate.getTimezoneOffset() * 60;
    const newStartDate = Math.floor(startDate.getTime() / 1000) - timeZoneOffset;
    const newEndDate = Math.floor(endDate.getTime() / 1000) - timeZoneOffset;
    return await send({
        method: "GET",
        url: `/api/reporting/getfeedbackpercustomer?custid=${custID}&startdate=${newStartDate}&enddate=${newEndDate}`,
        token: await getAccessToken(),
        data: "",
        params: ""
    })
}

const FeedbackPerCustomerPopout = (props: FeedbackPerCustomerProps) => {
    const [curSite, setCurSite] = useState("All");
    const [sites, setSites] = useState<Site[]>();
    const [feedback, setFeedback] = useState<ServiceDeskFeedback[]>();
    useEffect(() => {
        //Get Data on component creation
        getSites(props.customerID).then((res) => {
            setSites(res.data);
        }).catch((err) => {
            LogError("Error getfeedbackpercustomer card", { err, custID: props.customerID, apiCall: 1, apiCallDetail: "getSites" });
            console.error(err);
        })
        getFeedback(props.customerID, props.startDate, props.endDate).then((res) => {
            setFeedback(res.data);
        }).catch((err) => {
            LogError("Error getfeedbackpercustomer card", { err, custID: props.customerID, apiCall: 1, apiCallDetail: "getFeedback" });
            console.error(err);
        })
        //Return function to inform popout has rendered
        props.popoutRendered();
    }, []);

    const ChangeSite = (newSite: { target: { value: string | undefined } }) => { setCurSite(newSite.target.value!) }
    const CreateFinalFeedbackList = () => {
        var finalFeedbackList: ServiceDeskFeedback[] = [];
        if (curSite === "All") {
            finalFeedbackList = JSON.parse(JSON.stringify(feedback));
            finalFeedbackList.forEach((feedback: ServiceDeskFeedback, index) => {
                switch (feedback.feedback_rating) {
                    case 0:
                        finalFeedbackList[index].feedback_rating = "Happy Feedback";
                        break;
                    case 1:
                        finalFeedbackList[index].feedback_rating = "Fine Feedback";
                        break;
                    default:
                        finalFeedbackList[index].feedback_rating = "Unhappy Feedback";
                        break;
                }
            })
        }
        else {
            feedback!.forEach((feedback: ServiceDeskFeedback) => {
                if (feedback.site_id === curSite) {
                    switch (feedback.feedback_rating) {
                        case 0:
                            feedback.feedback_rating = "Happy Feedback";
                            break;
                        case 1:
                            feedback.feedback_rating = "Fine Feedback";
                            break;
                        default:
                            feedback.feedback_rating = "Unhappy Feedback";
                            break;
                    }
                    finalFeedbackList.push(feedback);
                }
            })
        }
        return finalFeedbackList;
    }
    const columns: TableColumn<ServiceDeskFeedback>[] = [
        {
            name: "Service Request ID",
            selector: (row) => row.database_link_id,
            sortable: true,
            reorder: true
        },
        {
            name: "Service Request Link",
            cell: (row) => {
                return (<a target="_blank" style={{ color: "var(--cse-text)", textDecoration: "underline" }} href={`https://cloud.cseservicemanager.co.uk/ServiceManager/ViewEditServiceRequestV2?id=${row.database_link_id}&orgId=${row.organisation_id}&isArchived=0`}>Click here</a>)
            },
            sortable: true,
            reorder: true
        },
        {
            name: "Site",
            selector: (row) => {
                var returnVal = row.site_id;
                sites?.every((site) => {
                    if (site.site_id === row.site_id) {
                        returnVal = site.site_name;
                        return false;
                    }
                    return true;
                })
                return returnVal;
            },
            sortable: true,
            reorder: true
        },
        {
            name: "Feedback Left By",
            selector: (row) => row.feedback_user,
            sortable: true,
            reorder: true
        },
        {
            name: "Feedback Rating",
            selector: (row) => row.feedback_rating,
            // selector: (row) => {
            //     switch (row.feedback_rating) {
            //         case 0:
            //             return "Happy Feedback"
            //         case 1:
            //             return "Fine Feedback"
            //         default:
            //             return "Unhappy Feedback"
            //     }
            // },
            sortable: true,
            reorder: true
        },
        {
            name: "Feedback Message",
            selector: (row) => row.feedback_message,
            sortable: true,
            reorder: true,
            wrap: true
        },
        {
            name: "Created Date",
            selector: (row) => row.created.toLocaleString(),
            sortable: true,
            reorder: true
        }
    ]
    if (feedback) {
        const finalFeedbackList = CreateFinalFeedbackList();
        return (
            <div className={styles.popoutContainer}>
                <div>
                    <h2 className={styles.popoutHeader}>{tr("DASHBOARD_SERVICEDESK_FEEDBACK_TITLE")}</h2>
                </div>
                <div className={styles.popoutExport}>
                    <div className="perm-floating-label">
                        <Input className={styles.popoutInput} type="select" id="feedbackPerCustomerSiteSelect" onChange={ChangeSite}>
                            <option value="All">{tr("ALL")}</option>
                            {sites && sites.map((site, key) => {
                                return (
                                    <option key={key} value={site.site_id}>
                                        {site.site_name}
                                    </option>
                                );
                            })}
                        </Input>
                        <Label for="serverShutdownSiteSelect">{tr("DASHBOARD_SITE_SELECT")}</Label>
                    </div>
                    <CSVLink
                        className={styles.exportCSVButton}
                        data={finalFeedbackList}
                        filename={`export ${format(Date.now(), "dd-MM-yyyy HH-mm-ss")}.csv`}
                    >
                        {tr("EXPORT_CSV")}
                    </CSVLink>
                </div>
                <div id="feedbackPerCustomerTable">
                    <DataTable
                        columns={columns}
                        data={finalFeedbackList}
                        theme={props.theme}
                        pagination
                    />
                </div>
            </div>
        )
    }
    else { //!getDone
        return (<div
            style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                marginTop: "10rem"
            }}
        >
            <Spinner size={250} />
        </div>);
    }
}

export { FeedbackPerCustomerPopout }