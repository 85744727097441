import * as React from "react";
import { Spinner } from "../../../common/ui";
import { send } from "../../../../helpers/requests";
import { tr } from "../../../../helpers/languages";
import userManager from "../../../../util/userManager";
import { GetTheme } from "../SharedFunctions";

import Popup from "../../../common/popup/popout";
import ServersOnlinePopout from "../../popout/ServerOnline";

import CardClassic from "../../ui/TextCard";
import CardPie from "../../ui/PieCard";
import CardGauge from "../../ui/GaugeCard";

export default class DashCardStatus extends React.PureComponent {
    _isMounted = false; //If true then component is mounted. If false data returned from API GET should be dropped.

    constructor(props) {
        super(props);
        this.state = {
            serverStatus: {
                total: 0,
                online: 0,
                offline: 0,
                offlineList: []
            },
            getDone: false,
            popupStatus: false
        };
    }
    /**
     * componentDidMount
     * @description API calls for rebuilding base dashboard
     */
    componentDidMount() {
        this._isMounted = true;
        if (this.props.localPreview) {
            this.getServerStatusPreview();
        } else {
            if (!this.props.raOnly) {
                this.getServerStatus(this.props.custID);
            }
        }
    }
    componentWillUnmount() {
        this._isMounted = false;
    }
    componentDidUpdate(prevProps) {
        if (!this.props.disableReRender) {
            if (prevProps.custID !== this.props.custID) {
                this.setState({
                    getDone: false,
                    serverStatus: {
                        total: 0,
                        online: 0,
                        offline: 0,
                        offlineList: []
                    }
                });
                this.getServerStatus(this.props.custID);
            }
        }
    }
    /**
     * OpenPopup
     * @description Calls OpenPopup inside Dashboard.js
     */
    OpenPopup = () => {
        this.setState({ popupStatus: true });
    };
    ///
    /// API Calls
    ///
    /**
     * getAccessToken
     * @description Gets the access token of the authenticated user
     */
    getAccessToken = async () => {
        let token = (await userManager.getUser()).access_token;
        return token;
    };
    /**
     * getServerStatus
     * @description Gets ServerStatus and Sets this.state.serverStatus
     * @param {String} custID Customer ID
     */
    getServerStatus = async (custID) => {
        send({
            method: "GET",
            url: `/api/reporting/serverstatus${custID !== "" ? "?custid=" + custID : ""}`,
            token: await this.getAccessToken()
        })
            .then((response) => {
                if (this._isMounted) {
                    this.setState({
                        getDone: true,
                        serverStatus: {
                            total: response.data.totalServers,
                            online: response.data.onlineServers,
                            offline: response.data.offlineServers,
                            offlineList: response.data.serversOffline
                        }
                    });
                    this.props.cardRendered();
                }
            })
            .catch((error) => {
                console.log(error);
            });
    };
    getServerStatusPreview = () => {
        this.setState({
            getDone: true,
            serverStatus: {
                total: 150,
                online: 130,
                offline: 20,
                offlineList: [
                    {
                        hostname: "server-1",
                        offlineAt: "2022-04-05T14:37:23.1648346"
                    },
                    {
                        hostname: "server-3",
                        offlineAt: "2022-04-05T22:15:30.4207223"
                    },
                    {
                        hostname: "server-2",
                        offlineAt: "2022-03-20T19:38:36.069039"
                    }
                ]
            }
        });
    };
    render() {
        var CardSpinner,
            ReturnCard = <></>;
        if (this.props.raOnly) {
            return <></>;
        }
        if (!this.state.getDone) {
            CardSpinner = (
                <div id="loading-spinner" className="spinner-parent">
                    <Spinner size={40} className="spinner-position" />
                </div>
            );
        }
        switch (this.props.cardType) {
            case 0: //Classic
                ReturnCard = (
                    <CardClassic
                        title={tr("DASHBOARD_SERSTATUS_TITLE")}
                        cardID={this.props.cardID}
                        data={[this.state.serverStatus.total, this.state.serverStatus.online, this.state.serverStatus.offline]}
                        type={["dash-neut", "dash-neut", "dash-neg"]}
                        altText={[tr("DASHBOARD_SERSTATUS_TOTAL"), tr("DASHBOARD_SERSTATUS_ONLINE"), tr("DASHBAORD_SERSTATUS_OFFLINE")]}
                        getDone={this.state.getDone}
                        activatePopup={() => {
                            !this.props.localPreview ? this.OpenPopup() : console.log("Card Details");
                        }}
                        openHelpPopup={() => {
                            this.props.openHelpPopup("ServerStatusCard");
                        }}
                        custID={this.props.custID}
                        startDate={this.props.startDate}
                        endDate={this.props.endDate}
                        isCSE={this.props.isCSE}
                        customID={this.props.customID}
                    >
                        {CardSpinner}
                    </CardClassic>
                );
                break;
            case 1:
                ReturnCard = (
                    <CardPie
                        graphType="doughnut"
                        cardID={this.props.cardID}
                        data={[this.state.serverStatus.online, this.state.serverStatus.offline]}
                        labels={[tr("DASHBOARD_SERSTATUS_ONLINE"), tr("DASHBAORD_SERSTATUS_OFFLINE")]}
                        pieColours={["#8AB785", "#f12a2a"]}
                        title={tr("DASHBOARD_SERSTATUS_TITLE")}
                        getDone={this.state.getDone}
                        otherData={[this.state.serverStatus.total]}
                        type={[""]}
                        otherDataTitle={[tr("DASHBOARD_SERSTATUS_TOTAL")]}
                        activatePopup={() => {
                            !this.props.localPreview ? this.OpenPopup() : console.log("Card Details");
                        }}
                        openHelpPopup={() => {
                            this.props.openHelpPopup("ServerStatusCard");
                        }}
                        custID={this.props.custID}
                        startDate={this.props.startDate}
                        endDate={this.props.endDate}
                        isCSE={this.props.isCSE}
                        customID={this.props.customID}
                    >
                        {CardSpinner}
                    </CardPie>
                );
                break;
            case 2:
            default:
                ReturnCard = (
                    <CardGauge
                        title={tr("DASHBOARD_SERSTATUS_TITLE")}
                        cardID={this.props.cardID}
                        textData={[this.state.serverStatus.total, this.state.serverStatus.online, this.state.serverStatus.offline]}
                        gaugeText={tr("DASHBOARD_SERSTATUS_GAUGE")}
                        gaugeColours={["#DC2323", "#35A048"]}
                        percent={Math.round((this.state.serverStatus.online / this.state.serverStatus.total) * 100) / 100}
                        type={["dash-neut", "dash-neut", "dash-neg"]}
                        altText={[tr("DASHBOARD_SERSTATUS_TOTAL"), tr("DASHBOARD_SERSTATUS_ONLINE"), tr("DASHBAORD_SERSTATUS_OFFLINE")]}
                        getDone={this.state.getDone}
                        activatePopup={() => {
                            !this.props.localPreview ? this.OpenPopup() : console.log("Card Details");
                        }}
                        openHelpPopup={() => {
                            this.props.openHelpPopup("ServerStatusCard");
                        }}
                        custID={this.props.custID}
                        startDate={this.props.startDate}
                        endDate={this.props.endDate}
                        isCSE={this.props.isCSE}
                        customID={this.props.customID}
                    >
                        {CardSpinner}
                    </CardGauge>
                );
                break;
        }
        return (
            <>
                {ReturnCard}
                {this.state.popupStatus && (
                    <Popup
                        content={<ServersOnlinePopout theme={GetTheme()} popoutRendered={() => {}} customerID={this.props.custID} />}
                        close={() => this.setState({ popupStatus: false })}
                        PopupName="ServersOnlineCardDetails"
                    />
                )}
            </>
        );
    }
}
