import React from "react";
import styles from "./Spinner.module.css";

type SpinnerProps = {
    size: number,
    className?: string,
    id?: string
};

const Spinner: React.FC<SpinnerProps> = ({ className, size }) => {
    return (
        <div className={`${className} ${styles.popoutSpinnerMain}`} style={{ height: `${size}px`, width: `${size}px` }}>
            <div style={{ height: `${size}px`, width: `${size}px` }}></div>
            <div style={{ height: `${size}px`, width: `${size}px` }}></div>
            <div style={{ height: `${size}px`, width: `${size}px` }}></div>
            <div style={{ height: `${size}px`, width: `${size}px` }}></div>
        </div>
    );
};

export default Spinner;
