import * as React from "react";
import userManager from "../../../util/userManager";
import { send } from "../../../helpers/requests";
import DataTable, { TableColumn, TableRow } from "react-data-table-component";
import { tr } from "../../../helpers/languages";
import { Input, Label } from "reactstrap";
import dayjs from "dayjs";
import plugin from "dayjs/plugin/advancedFormat";
import { CSVLink } from "react-csv";
import { Spinner } from "../../common/ui";

import styles from "./popout.module.css";
import { SamiTokenUsageAPI } from "../cards/SamiTokenUsage/SamiTokenUsage";

dayjs.extend(plugin);

type Props = {
    customerID: string,
    startDate: Date,
    endDate: Date,
    theme: string,
    popoutRendered: () => void
}
type State = {
    getDone: boolean,
    search: string,
    TokenUsage: SamiTokenUsageAPI[],
}

export default class SamiTokenUsage extends React.PureComponent<Props, State> {
    _isMounted = false;
    state = {
        getDone: false,
        search: "",
        TokenUsage: []
    }
    componentDidMount() {
        this._isMounted = true;
        this.getSamiTokenUsage(this.props.customerID, this.props.startDate, this.props.endDate);
    }
    componentWillUnmount() {
        this._isMounted = false;
    }
    //API Calls
    /**
     * getAccessToken
     * @description Gets the access token of the authenticated user
     */
    getAccessToken = async () => {
        let token = (await userManager.getUser())?.access_token;
        return token;
    };
    getSamiTokenUsage = async (custID: string, startDate: Date, endDate: Date) => {
        const timeZoneOffset = startDate.getTimezoneOffset() * 60;
        const newStartDate = Math.floor(startDate.getTime() / 1000) - timeZoneOffset;
        const newEndDate = Math.floor(endDate.getTime() / 1000) - timeZoneOffset;
        send({
            method: "GET",
            url: `/api/reporting/getsamitokenusage?custid=${custID}&startdate=${newStartDate}&enddate=${newEndDate}`,
            token: await this.getAccessToken(),
            params: "",
            data: ""
        }).then((response: any) => {
            if (this._isMounted) {
                this.props.popoutRendered();
                this.setState({
                    getDone: true,
                    TokenUsage: response.data
                });
            }
        });
    };
    columns: TableColumn<SamiTokenUsageAPI>[] = [
        {
            name: "User",
            selector: (row) => row._id,
            style: {
                textTransform: "capitalize"
            },
            sortable: true,
            reorder: true
        },
        {
            name: "Token Usage",
            selector: (row) => row.count,
            sortable: true,
            reorder: true
        },
    ];
    CreateFinalTokenUsageList = () => {
        //Combining all smsystem requests under "System Auditing"
        var TokenUsage: SamiTokenUsageAPI[] = [];
        var smSystem: SamiTokenUsageAPI = { _id: "System Auditing", count: 0 };
        this.state.TokenUsage.forEach((user: SamiTokenUsageAPI) => {
            if (user._id === "SYSTEM") {
                smSystem.count += user.count;
            } else {
                TokenUsage.push(user);
            }
        });
        if (smSystem.count != 0) {
            TokenUsage.push(smSystem);
            TokenUsage.sort((a, b) => b.count - a.count);
        }
        //Normal Search
        var finalTokenList = [];
        if (this.state.search === "") {
            finalTokenList = JSON.parse(JSON.stringify(TokenUsage));
        } else {
            TokenUsage.forEach((user: SamiTokenUsageAPI) => {
                if (user._id.toLowerCase().includes(this.state.search)) {
                    finalTokenList.push(user);
                }
            });
        }
        return finalTokenList;
    }
    render() {
        var finalTokenList = this.CreateFinalTokenUsageList();
        if (!this.state.getDone) {
            return (
                <div
                    style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        marginTop: "10rem"
                    }}
                >
                    <Spinner size={250} />
                </div>
            );
        }
        return (
            <div className={styles.popoutContainer}>
                <div>
                    <h2 className={styles.popoutHeader}>{tr("DASHBOARD_SAMI_TOKEN_USAGE_TITLE")}</h2>
                </div>
                <div className={styles.popoutSearch}>
                    <div className="form-floating-labels">
                        <Input
                            id="Search"
                            placeholder=" "
                            value={this.state.search || ""}
                            onChange={(v: React.ChangeEvent<HTMLInputElement>) => {
                                this.setState({
                                    search: v.target.value.toLowerCase()
                                });
                            }}
                        />
                        <Label for="Search">{tr("DASHBOARD_SEARCH_POPOUT_USER")}</Label>
                    </div>
                </div>
                <div className={styles.popoutExport}>
                    <CSVLink
                        className={styles.exportCSVButton}
                        data={finalTokenList}
                        filename={"export " + dayjs().format("DD-MM-YYYY h-mm-ss") + ".csv"}
                    >
                        {tr("EXPORT_CSV")}
                    </CSVLink>
                </div>
                <DataTable columns={this.columns} data={finalTokenList} theme={this.props.theme} pagination />
            </div>
        );
    }
}