import * as React from "react";
import { useState, useEffect } from "react";
import { Spinner } from "../../../common/ui";
import { send } from "../../../../helpers/requests";
import { tr } from "../../../../helpers/languages";
import userManager from "../../../../util/userManager";
import { GetTheme, RandomGUID } from "../SharedFunctions";

import Popup from "../../../common/popup/popout";
import CardClassic from "../../ui/TextCard";
import ServiceDeskTopKeywords from "../../popout/ServiceDeskTopKeywords";
import { LogError } from "../../../analytics/eventTracker";

export type SamiKnowledgeBaseFeedbackAPI = {
    GoodFeedback: number,
    BadFeedback: number
}

interface DashCardSamiKnowledgeBaseFeedbackProps {
    animate?: boolean;
    custID?: string;
    startDate?: Date;
    endDate?: Date;
    disableReRender?: boolean;
    localPreview: boolean;
    cardRendered?: () => void;
    cardType: number;
    cardID: string;
    isCSE?: boolean;
    customID?: string;
    raOnly?: boolean;
    openHelpPopup: (type: string) => void;
};

//API Request
const getAccessToken = async () => {
    let token = (await userManager.getUser())!.access_token;
    return token;
};


const DashCardSamiKnowledgeBase = (props: DashCardSamiKnowledgeBaseFeedbackProps) => {
    const [getDone, setGetDone] = useState(false);
    const [popupStatus, setPopupStatus] = useState(false);
    const [kbFeedback, setKBFeedback] = useState<SamiKnowledgeBaseFeedbackAPI>();
    useEffect(() => {
        const getSamiKBFeedback = async (custID: string, startDate: Date, endDate: Date) => {
            const timeZoneOffset = startDate.getTimezoneOffset() * 60;
            const newStartDate = Math.floor(startDate.getTime() / 1000) - timeZoneOffset;
            const newEndDate = Math.floor(endDate.getTime() / 1000) - timeZoneOffset;
            const response = await send({
                method: "GET",
                url: `/api/reporting/samikbfeedback?custid=${custID}&startdate=${newStartDate}&enddate=${newEndDate}`,
                token: await getAccessToken(),
                data: "",
                params: ""
            })
            setKBFeedback(response.data);
        }
        setGetDone(false);
        if (props.localPreview) {
            //setKBFeedback();
        }
        else {
            getSamiKBFeedback(props.custID!, props.startDate!, props.endDate!).catch((err) => {
                LogError("Error samikbfeedback card", { err, custID: props.custID });
                console.error(err);
            })
        }
        setGetDone(true);
    }, [props.startDate, props.endDate, props.custID])
    if (props.raOnly) return <></>;
    const openPopup = () => {
        setPopupStatus(true);
    }
    var CardSpinner = getDone ? <></> : (
        <div id="loading-spinner" className="spinner-parent">
            <Spinner size={40} className="spinner-position" />
        </div>
    );
    var ReturnCard = <></>

    switch (props.cardType) {
        case 0:
        default:
            var totalFeedback: number;
            if (kbFeedback?.GoodFeedback == undefined || kbFeedback.BadFeedback == undefined) {
                totalFeedback = 0;
            } else {
                totalFeedback = kbFeedback?.GoodFeedback + kbFeedback?.BadFeedback;
            }
            ReturnCard = (
                <CardClassic
                    title={tr("DASHBOARD_SAMI_KB_TITLE")}
                    cardID={props.cardID!}
                    activatePopup={() => {
                        !props.localPreview ? openPopup() : console.log("Card Details");
                    }}
                    openHelpPopup={() => {
                        props.openHelpPopup("SamiKnowledgeBaseFeedback");
                    }}
                    data={[totalFeedback.toString(), kbFeedback!.GoodFeedback.toString(), kbFeedback!.BadFeedback.toString()]}
                    type={["dash-neut", "dash-neut", "dash-neg"]}
                    altText={[tr("DASHBOARD_SERVICEDESK_FEEDBACK_TOTAL_FEEDBACK"), tr("DASHBOARD_SERVICEDESK_FEEDBACK_HAPPY_FEEDBACK"), tr("DASHBOARD_SERVICEDESK_FEEDBACK_UNHAPPY_FEEDBACK")]}
                    altTextType={[]}
                    getDone={getDone}
                    custID={props.custID!}
                    startDate={props.startDate!}
                    endDate={props.endDate!}
                    isCSE={props.isCSE!}
                    customID={props.customID!}
                >
                    {CardSpinner}
                </CardClassic>
            )
    }
    return (
        <>
            {ReturnCard}
            {popupStatus && (
                <></>
            )}
        </>
    );
}

export { DashCardSamiKnowledgeBase }