import * as React from "react";
import userManager from "../../../util/userManager";
import { send } from "../../../helpers/requests";
import { Line } from "react-chartjs-2";
import { tr } from "../../../helpers/languages";
import { Input, Label } from "reactstrap";
import { CSVLink } from "react-csv";
import { Spinner } from "../../common/ui";
import { DatesToString } from "./SharedPopoutFunctions";

import styles from "./popout.module.css";
import GetCardTheme from "../ui/SharedCardFunctions";

type Site = {
  [siteName: string]: number
};
type ServiceDeskRequestType = {
  date: string,
  sites: Site
}
type ServiceDeskRequestArrayType = ServiceDeskRequestArrayType[];

type ServiceDeskMultiTypePopoutProps = {
  startDate: Date,
  endDate: Date,
  popoutRendered: () => void,
  customerID: string,
  type: "Open" | "Close"
};
type ServiceDeskMultiTypePopoutState = {
  getDone: boolean,
  curSite: string,
  allSites: string[],
  startDate: Date,
  endDate: Date,
  data: ServiceDeskRequestArrayType,
  dataRaw: any,
  dataDates: string[],
  chartFontColour: string
};


export default class ServiceDeskMultiTypePopout extends React.PureComponent<ServiceDeskMultiTypePopoutProps, ServiceDeskMultiTypePopoutState> {
  _isMounted = false;
  state = {
    getDone: false,
    serviceDeskTrendDates: [],
    serviceDeskTrendValues: [],
    curSite: "All",
    allSites: [],
    data: [],
    dataRaw: [],
    dataDates: [],
    startDate: this.props.startDate,
    endDate: this.props.endDate,
    chartFontColour: GetCardTheme(),
    options: JSON.parse(window.localStorage.getItem("options")!)
  };
  componentDidMount() {
    this._isMounted = true;
    if (this.props.type === "Open") {
      this.getServiceDeskOpenTrends(this.props.customerID, this.props.startDate, this.props.endDate);
    }
    else {
      this.getServiceDeskClosedTrends(this.props.customerID, this.props.startDate, this.props.endDate);
    }
  }
  componentWillUnmount() {
    this._isMounted = false;
  }
  UpdateTheme = () => {
    const chartFontColour = GetCardTheme();
    this.setState({ chartFontColour });
  };
  //API Calls
  getAccessToken = async () => {
    let token = (await userManager.getUser())!.access_token;
    return token;
  };
  /**
   * getServiceDeskOpenTrends
   * @description Gets daily tickets in a given time frame
   * @param {string} custID Customer ID
   * @param {Date} startDate Start date as JS Date
   * @param {Date} endDate End date as JS Date
   */
  getServiceDeskOpenTrends = async (custID: string, startDate: Date, endDate: Date) => {
    const timeZoneOffset = startDate.getTimezoneOffset() * 60;
    const newStartDate = Math.floor(startDate.getTime() / 1000) - timeZoneOffset;
    const newEndDate = Math.floor(endDate.getTime() / 1000) - timeZoneOffset;
    send({
      method: "GET",
      url: `/api/reporting/getsdopenrequests?custid=${custID}&startdate=${newStartDate}&enddate=${newEndDate}`,
      token: await this.getAccessToken(),
      data: "",
      params: ""
    })
      .then((response: any) => {
        if (this._isMounted) {
          this.props.popoutRendered();
          let sites = Object.keys(response.data[0].sites);
          this.setState({
            getDone: true,
            allSites: sites,
            dataDates: response.data.map((item: ServiceDeskRequestType) => new Date(item.date.split("T", 1)[0]).toLocaleString().split(",", 1)[0]),
            data: response.data.map((item: ServiceDeskRequestType) => item.sites),
            dataRaw: response.data
          });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  /**
 * getServiceDeskClosedTrends
 * @description Gets daily tickets in a given time frame
 * @param {string} custID Customer ID
 * @param {Date} startDate Start date as JS Date
 * @param {Date} endDate End date as JS Date
 */
  getServiceDeskClosedTrends = async (custID: string, startDate: Date, endDate: Date) => {
    const timeZoneOffset = startDate.getTimezoneOffset() * 60;
    const newStartDate = Math.floor(startDate.getTime() / 1000) - timeZoneOffset;
    const newEndDate = Math.floor(endDate.getTime() / 1000) - timeZoneOffset;
    send({
      method: "GET",
      url: `/api/reporting/getsdclosedrequests?custid=${custID}&startdate=${newStartDate}&enddate=${newEndDate}`,
      token: await this.getAccessToken(),
      data: "",
      params: ""
    })
      .then((response) => {
        if (this._isMounted) {
          this.props.popoutRendered();
          let sites = Object.keys(response.data[0].sites);
          this.setState({
            getDone: true,
            allSites: sites,
            dataDates: response.data.map((item: ServiceDeskRequestType) => new Date(item.date.split("T", 1)[0]).toLocaleString().split(",", 1)[0]),
            data: response.data.map((item: ServiceDeskRequestType) => item.sites),
            dataRaw: response.data
          });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  ChartData = (inputData: Site) => {
    var colourArr = [
      "#118DFF",
      "#E32322",
      "#F3E500",
      "#FDC60A",
      "#F28E1C",
      "#E96220",
      "#C2037B",
      "#cd86c6",
      "#6D398B",
      "#9356b4",
      "#454E99",
      "#2C31EC",
      "#008F59",
      "#8DBB25",
      "#1FDD81",
      "#4DE917",
      "#ffffff",
      "#000000"
    ];
    let dataset: number[][] = [];
    let datasetLabels: string[] = [];
    const data = JSON.parse(JSON.stringify(inputData));
    if (data === undefined || data.length === 0) {
      return {
        labels: [""],
        datasets: []
      }
    }
    Object.keys(data[0]).forEach((key) => {
      let datasetItem: number[] = [];
      datasetLabels.push(key);
      data.forEach((day: Site) => {
        datasetItem.push(day[key]);
      })
      dataset.push(datasetItem);
    });
    var allDatasets: { label: string; data: number[]; backgroundColor: string; borderColor: string; }[] = [];
    dataset.forEach((site, index) => {
      allDatasets.push({
        label: datasetLabels[index],
        data: site,
        backgroundColor: colourArr[index],
        borderColor: colourArr[index]
      })
    })
    return {
      labels: this.state.dataDates,
      datasets: allDatasets
    };
  };
  ChartOptions = () => {
    return {
      responsive: true,
      maintainAspectRatio: false,
      scales: {
        yAxes: {
          ticks: {
            display: true,
            color: this.state.chartFontColour,
            font: {
              size: this.state.options.graphTextSize
            }
          }
        },
        xAxes: {
          ticks: {
            display: true,
            color: this.state.chartFontColour,
            font: {
              size: this.state.options.graphTextSize
            }
          }
        }
      },
      plugins: {
        legend: { display: true, labels: { font: { size: this.state.options.graphTextSize } } },
        title: {
          display: true,
          text: this.props.type === "Open" ? tr("DASHBOARD_SERVICEDESK_OPEN_REQ_LABEL") : tr("DASHBOARD_SERVICEDESK_CLOSED_REQ_LABEL"),
          color: this.state.chartFontColour,
          font: {
            size: this.state.options.graphTextSize
          }
        },
        datalabels: {
          color: this.state.chartFontColour
        }
      }
    };
  };
  ChangeSite = (newSite: { target: { value: string | undefined } }) => {
    if (newSite.target.value && newSite.target.value !== this.state.curSite) {
      this.setState({ curSite: newSite.target.value });
    }
  }
  CreateFinalRequestList = () => {
    var finalRequestList: {[key: string]: number}[] = []; //Array of objects containing key:value pairs where value === number
    if (this.state.curSite === "All") {
      return JSON.parse(JSON.stringify(this.state.data));
    }
    if (this.state.curSite === "All-Combined") {
      this.state.data.forEach((day: Site) => {
        var curValue = 0;
        Object.keys(day).forEach(e => curValue += day[e]);
        finalRequestList.push({"Total": curValue})
      })
    } else {
      this.state.data.forEach((day: Site) => {
        finalRequestList.push({[this.state.curSite]:day[this.state.curSite]});
      })
    }
    return finalRequestList;
  }
  render() {
    var Chart = <></>;
    if (!this.state.getDone) {
      return (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            marginTop: "10rem"
          }}
        >
          <Spinner size={250} />
        </div>
      );
    }
    let finalRequestList = this.CreateFinalRequestList();
    if (this.state.getDone) {
      Chart = <Line key={`lineGraph`} data={this.ChartData(finalRequestList)} options={this.ChartOptions()} />;
    }
    const dates = DatesToString(this.state.startDate.toDateString(), this.state.endDate.toDateString());
    return (
      <div>
        <div>
          <h2 className={styles.popoutHeader}>{this.props.type === "Open" ? tr("DASHBOARD_SERVICEDESK_OPEN_REQ_TITLE") : tr("DASHBOARD_SERVICEDESK_CLOSED_REQ_TITLE")}</h2>
        </div>
        {dates && (
          <h3 style={{ fontSize: "18px", display: "flex", justifyContent: "center", alignItems: "center", width: "100%" }}>
            {tr("DASHBOARD_POPOUT_TIMEFRAME") + dates[0] + " to " + dates[1]}
          </h3>
        )}
        <div style={{ display: "flex", flexDirection: "column" }} className={styles.popoutExport}>
          <div className="perm-floating-label">
            <Input className={styles.popoutInput} type="select" id="serverShutdownSiteSelect" onChange={this.ChangeSite}>
              <option value="All">{tr("ALL")}</option>
              <option value="All-Combined">{tr("ALL_COMBINED")}</option>
              {this.state.allSites.map((site, key) => {
                return (
                  <option key={key} value={site}>
                    {site}
                  </option>
                );
              })}
            </Input>
            <Label for="serverShutdownSiteSelect">{tr("DASHBOARD_SITE_SELECT")}</Label>
          </div>
          {/* <CSVLink
            className={styles.exportCSVButton}
            data={JSON.stringify(this.state.dataRaw)}
            filename={"export " + dayjs().format("DD-MM-YYYY h-mm-ss") + ".csv"}
          >
            {tr("EXPORT_CSV")}
          </CSVLink> */}
        </div>
        <div style={{ float: "left", display: "flex", flexDirection: "column", marginBottom: "2rem" }}>
          <button
            className={styles.exportCSVButton}
            onClick={() => {
              this.setState({
                getDone: false,
                startDate: this.props.startDate,
                endDate: this.props.endDate
              });
              if (this.props.type === "Open") {
                this.getServiceDeskOpenTrends(this.props.customerID, this.state.startDate, this.state.endDate);
              }
              else {
                this.getServiceDeskClosedTrends(this.props.customerID, this.state.startDate, this.state.endDate);
              }
            }}
          >
            {tr("DASHBOARD_SERVICEDESK_TRENDS_CURRENT")}
          </button>
          <button
            className={styles.exportCSVButton}
            onClick={() => {
              const startDate = new Date();
              const endDate = new Date(new Date().setDate(new Date().getDate() - 182));
              this.setState({
                getDone: false,
                startDate: startDate,
                endDate: endDate
              });
              if (this.props.type === "Open") {
                this.getServiceDeskOpenTrends(this.props.customerID, startDate, endDate);
              }
              else {
                this.getServiceDeskClosedTrends(this.props.customerID, startDate, endDate);
              }
            }}
          >
            {tr("DASHBOARD_SERVICEDESK_TRENDS_HISTORICAL")}
          </button>
        </div>
        <div
          style={{
            height: "calc(80vh - 300px)",
            width: "100%",
            maxHeight: "1080px"
          }}
        >
          {Chart}
        </div>
      </div>
    );
  }
}
