import * as React from "react";
import { Spinner } from "../../../common/ui";
import { send } from "../../../../helpers/requests";
import { tr } from "../../../../helpers/languages";
import userManager from "../../../../util/userManager";

import Popup from "../../../common/popup/popout";

import CardBold from "../../ui/BoldCard";
import ServerRebootRequiredPopout from "../../popout/ServerRebootRequired";
import { GetTheme } from "../SharedFunctions";

export default class DashCardServerRebootRequired extends React.PureComponent {
    _isMounted = false; //If true then component is mounted. If false data returned from API GET should be dropped.

    constructor(props) {
        super(props);
        this.state = {
            getDone: false,
            popupStatus: false,
            serverReboot: {
                total: 0,
                servers: [
                    {
                        hostName: "",
                        rebootRequired: "",
                        siteName: ""
                    }
                ]
            }
        };
    }
    /**
     * componentDidMount
     * @description API calls for rebuilding base dashboard
     */
    componentDidMount() {
        this._isMounted = true;
        if (this.props.localPreview) {
            this.getServerRestartRequiredPreview();
        } else {
            if (!this.props.raOnly) {
                this.getServerRestartRequired(this.props.custID);
            }
        }
    }
    componentWillUnmount() {
        this._isMounted = false;
    }
    componentDidUpdate(prevProps) {
        if (!this.props.disableReRender) {
            if (prevProps.custID !== this.props.custID) {
                this.setState({ getDone: false, serverReboot: [] });
                this.getServerRestartRequired(this.props.custID);
            }
        }
    }
    /**
     * OpenPopup
     * @description Calls OpenPopup inside Dashboard.js
     */
    OpenPopup = () => {
        this.setState({ popupStatus: true });
    };
    ///
    /// API Calls
    ///
    /**
     * getAccessToken
     * @description Gets the access token of the authenticated user
     */
    getAccessToken = async () => {
        let token = (await userManager.getUser()).access_token;
        return token;
    };
    /**
     * getServerRestartRequired
     * @description Gets Service Dest Trends as an array.
     * @param {string} custID Customer ID
     */
    getServerRestartRequired = async (custID) => {
        send({
            method: "GET",
            url: `/api/reporting/serverrestartrequired?custid=${custID}`,
            token: await this.getAccessToken()
        })
            .then((response) => {
                if (this._isMounted) {
                    this.setState({
                        getDone: true,
                        serverReboot: response.data
                    });
                    this.props.cardRendered();
                }
            })
            .catch((error) => {
                console.log(error);
            });
    };
    getServerRestartRequiredPreview = () => {
        this.setState({
            getDone: true,
            serverReboot: {
                total: 4,
                servers: [
                    {
                        hostName: "Server-1",
                        rebootRequired: true,
                        siteName: "TestSite"
                    },
                    {
                        hostName: "Server-2",
                        rebootRequired: true,
                        siteName: "TestSite"
                    },
                    {
                        hostName: "Server-3",
                        rebootRequired: true,
                        siteName: "TestSite2"
                    },
                    {
                        hostName: "Server-4",
                        rebootRequired: true,
                        siteName: "TestSite2"
                    }
                ]
            }
        });
    };
    render() {
        var CardSpinner,
            ReturnCard = <></>;
        if (this.props.raOnly) {
            return <></>;
        }
        var additionalDataTitle = tr("DASHBOARD_SERVER_RESTART_TEXT") + ":";
        if (!this.state.getDone) {
            CardSpinner = (
                <div id="loading-spinner" className="spinner-parent">
                    <Spinner size={40} className="spinner-position" />
                </div>
            );
        }
        if (this.state.getDone) {
            if (this.state.serverReboot.servers.length > 3) {
                additionalDataTitle = tr("DASHBOARD_SERVER_RESTART_LIST");
            }
        }
        switch (this.props.cardType) {
            case 0:
            default:
                ReturnCard = (
                    <CardBold
                        title={tr("DASHBOARD_SERVER_RESTART_TITLE")}
                        cardID={this.props.cardID}
                        text={this.state.serverReboot.total}
                        textType="dash-neut"
                        textTitle={tr("DASHBOARD_SERVER_RESTART_TEXT")}
                        data={this.state.serviceDeskTrendValues}
                        additionalData={this.state.serverReboot.servers}
                        additionalDataTitle={additionalDataTitle}
                        activatePopup={() => {
                            !this.props.localPreview ? this.OpenPopup() : console.log("Card Details");
                        }}
                        openHelpPopup={() => {
                            this.props.openHelpPopup("ServerRestartCard");
                        }}
                        custID={this.props.custID}
                        startDate={this.props.startDate}
                        endDate={this.props.endDate}
                        isCSE={this.props.isCSE}
                        customID={this.props.customID}
                    >
                        {CardSpinner}
                    </CardBold>
                );
                break;
        }
        return (
            <>
                {ReturnCard}
                {this.state.popupStatus && (
                    <Popup
                        content={<ServerRebootRequiredPopout customerID={this.props.custID} popoutRendered={() => {}} theme={GetTheme()} />}
                        close={() => this.setState({ popupStatus: false })}
                        PopupName="ServerRebootRequiredCardDetails"
                    />
                )}
            </>
        );
    }
}
