import React from "react";
import { LogEvent } from "../../analytics/eventTracker";

  const refreshCacheAndReload = async () => {
    try {
      if (window?.caches) {
        const { caches } = window;
        const cacheNames = await caches.keys();
        for (const cacheName of cacheNames) {
          caches.delete(cacheName);
        }
        console.log('The cache has been deleted.');
        window.location.reload();
      }
    } catch (error) {
      console.log('An error occurred while deleting the cache.', true);
      console.log(error as string, true);
    }
  };
const ForceCacheCleanup = () => {
  LogEvent(8, { type: "settings-button" });
  refreshCacheAndReload().then(() => { window.location.href=`${process.env.REACT_APP_API_BASE_URL}` });
}

export { ForceCacheCleanup, refreshCacheAndReload };