import * as React from "react";
import { Col, Row } from "../../common/ui";
import GaugeChart from "../../common/graphs/Gauge";
import BlankCard from "./BlankCard";

import styles from "./Gauge.module.css";

type CardGaugeProps = {
    title: string,
    cardID: string,
    textData: number[],
    altText: string[],
    type: string[],
    gaugeText: string,
    percent: number,
    gaugeColours: string[],
    children: JSX.Element,
    getDone: boolean,
    activatePopup: () => void,
    openHelpPopup: () => void,
    custID: string,
    isCSE: boolean,
    startDate: Date,
    endDate: Date,
    customID: string,
    numLevels?: number,
    animate?: boolean,
    id: string
};

export default class Card_Gauge extends React.PureComponent<CardGaugeProps> {
    render() {
        return (
            <BlankCard
                customID={this.props.customID}
                activatePopup={this.props.activatePopup}
                title={this.props.title}
                openHelpPopup={this.props.openHelpPopup}
                cardID={this.props.cardID}
                custID={this.props.custID}
                isCSE={this.props.isCSE}
                startDate={this.props.startDate}
                endDate={this.props.endDate}
            >
                {this.props.children}
                <Row style={{ height: "166.6px", maxWidth: "548px" }}>
                    <Col
                        className="dash-content-col"
                        style={{ display: "flex", flexDirection: "column", justifyContent: "center", maxHeight: "166.6px" }}
                    >
                        {this.props.textData.map((dataItem, index) => {
                            return (
                                <div key={"dataItem" + index} className={this.props.type[index] + " " + styles.mainText}>
                                    {this.props.altText[index]}: {dataItem}
                                </div>
                            );
                        })}
                    </Col>
                    <div className={styles.chart}>
                        <GaugeChart
                            id={this.props.id}
                            nrOfLevels={this.props.numLevels || 20}
                            percent={this.props.percent || 0}
                            animate={this.props.animate || true}
                            colors={this.props.gaugeColours}
                            textColor="var(--cse-text)"
                        />
                        <p>{this.props.gaugeText}</p>
                    </div>
                </Row>
            </BlankCard>
        );
    }
}
