import * as React from "react";
import { Col, Row } from "../../common/ui";
import { Bar } from "react-chartjs-2";
import type { ChartData, ChartOptions } from 'chart.js';
import BlankCard from "./BlankCard";
import GetCardTheme from "./SharedCardFunctions";

type Card_BarGraphProps = {
    title: string,
    cardID: string,
    children: JSX.Element,
    barData: {
        name: string,
        count: number
    }[]
    barDataTitle: string,
    dataReturned: boolean,
    displayLegend: boolean,
    legendTitle: string,
    activatePopup: () => void,
    openHelpPopup: () => void,
    custID: string,
    isCSE: boolean,
    startDate: Date,
    endDate: Date,
    customID: string
}
type Card_BarGraphState = {
    chartKey: number,
    chartFontColour: string
}

export default class Card_BarGraph extends React.PureComponent<Card_BarGraphProps, Card_BarGraphState> {
    state: Card_BarGraphState = {
        chartFontColour: GetCardTheme(),
        chartKey: 0
    };
    componentDidMount() {
        window.addEventListener("themeToggled", this.UpdateTheme);
    }
    componentWillUnmount() {
        window.removeEventListener("themeToggled", this.UpdateTheme);
    }
    componentDidUpdate(prevProps: Card_BarGraphProps) {
        if (prevProps.barData !== this.props.barData) {
            this.setState({ chartKey: this.state.chartKey + 1 });
        }
    }
    UpdateTheme = () => {
        const chartFontColour = GetCardTheme();
        this.setState({ chartFontColour });
    };
    BarData = () => {
        return {
            datasets: [
                {
                    label: this.props.barDataTitle,
                    data: this.props.barData,
                    backgroundColor: "#118dff",
                    borderColor: "#118dff",
                    borderWidth: 0.5,
                    barPercentage: 0.5,
                    barThickness: 15,
                    maxBarThickness: 15,
                    minBarLength: 2
                }
            ]
        };
    };

    BarOptions = () => {
        return {
            indexAxis: 'y',
            elements: { bar: { borderWidth: 1 } },
            parsing: {
                xAxisKey: "count",
                yAxisKey: "name"
            },
            scales: {
                y: {
                    suggestedMin: 0,
                    grid: { display: false },
                    ticks: {
                        display: true,
                        color: this.state.chartFontColour
                    }
                },
                x: {
                    grid: { display: false },
                    ticks: {
                        display: true,
                        callback: function (value: number) {
                            if (value % 1 === 0) {
                                return value;
                            }
                        },
                        color: this.state.chartFontColour
                    }
                }
            },
            responsive: true,
            maintainAspectRatio: false,
            plugins: {
                legend: { display: false },
                title: {
                    display: this.props.displayLegend,
                    text: this.props.legendTitle,
                    color: this.state.chartFontColour
                }
            }
        } as ChartOptions<"bar">;
    };

    render() {
        var BarGraph = <></>;
        if (this.props.dataReturned) {
            BarGraph = <Bar key={this.state.chartKey} style={{ maxHeight: "150px" }} data={this.BarData} options={this.BarOptions()} />;
        }
        return (
            <BlankCard
                customID={this.props.customID}
                activatePopup={this.props.activatePopup}
                title={this.props.title}
                openHelpPopup={this.props.openHelpPopup}
                cardID={this.props.cardID}
                custID={this.props.custID}
                isCSE={this.props.isCSE}
                startDate={this.props.startDate}
                endDate={this.props.endDate}
            >
                {this.props.children}
                <Row className="dash-content-row">
                    <Col className="dash-graph">{BarGraph}</Col>
                </Row>
            </BlankCard>
        );
    }
}
