type HandleURLParamsReturnObj = {
  isServiceDesk?: string
}

const HandleURLParams = () => {
    const urlParams = new URL(window.location.toLocaleString()).searchParams;
    let returnObj: HandleURLParamsReturnObj = {};
    if (urlParams.has("isServiceDesk")) {
      returnObj.isServiceDesk = (urlParams.get("isServiceDesk")!)
    }
    return returnObj;
};

export default HandleURLParams;
