import * as React from "react";
import { useState, useEffect } from "react";
import { Spinner } from "../../../common/ui";
import { send } from "../../../../helpers/requests";
import { tr } from "../../../../helpers/languages";
import userManager from "../../../../util/userManager";
import { GetTheme, RandomGUID } from "../SharedFunctions";

import Popup from "../../../common/popup/popout";
import { FeedbackPerCustomerPopout } from "../../popout/FeedbackPerCustomer";
import CardClassic from "../../ui/TextCard";
import CardPie from "../../ui/PieCard";
import CardGauge from "../../ui/GaugeCard";
import { LogError } from "../../../analytics/eventTracker";

type ServiceDeskFeedback = {
    _id: string,
    customer_id: string,
    site_id: string,
    database_link_id: string,
    feedback_user: string,
    feedback_message: string,
    feedback_rating: number,
    created: Date,
    updated: Date,
    updated_by: string
};

type SDFeedbackCardAPI = {
    totalSRClosed: number,
    totalPositiveFeedback: number,
    totalNeutralFeedback: number,
    totalNegativeFeedback: number
};
interface DashCardServiceDeskFeedbackProps {
    animate?: boolean;
    custID?: string;
    startDate?: Date;
    endDate?: Date;
    disableReRender?: boolean;
    localPreview: boolean;
    cardRendered?: () => void;
    cardType: number;
    cardID: string;
    isCSE?: boolean;
    customID?: string;
    raOnly?: boolean;
    openHelpPopup: (type: string) => void;
};

const CardSpinner = (
    <div id="loading-spinner" className="spinner-parent">
        <Spinner size={40} className="spinner-position" />
    </div>
);

//API Request
// /getsdfeedback
// send customer_id,
// returns total sd feedback
const getAccessToken = async () => {
    let token = (await userManager.getUser())!.access_token;
    return token;
};

const DashCardServiceDeskFeedback = (props: DashCardServiceDeskFeedbackProps) => {
    const [getDone, setGetDone] = useState(false);
    const [popupStatus, setPopupStatus] = useState(false);
    const [sdFeedbackItems, setSDFeedbackItems] = useState<SDFeedbackCardAPI>({ totalSRClosed: 0, totalPositiveFeedback: 0, totalNegativeFeedback: 0, totalNeutralFeedback: 0 });
    useEffect(() => {
        const getSDFeedback = async (custID: string, startDate: Date, endDate: Date) => {
            const timeZoneOffset = startDate.getTimezoneOffset() * 60;
            const newStartDate = Math.floor(startDate.getTime() / 1000) - timeZoneOffset;
            const newEndDate = Math.floor(endDate.getTime() / 1000) - timeZoneOffset;
            const response = await send({
                method: "GET",
                url: `/api/reporting/sdfeedback?custid=${custID}&startdate=${newStartDate}&enddate=${newEndDate}`,
                token: await getAccessToken(),
                data: "",
                params: ""
            })
            setSDFeedbackItems(response.data);
        }
        setGetDone(false);
        //Get Data on component creation
        if (props.localPreview) {
            setSDFeedbackItems({ totalSRClosed: 500, totalPositiveFeedback: 400, totalNeutralFeedback: 5, totalNegativeFeedback: 45 });
        }
        else {
            getSDFeedback(props.custID!, props.startDate!, props.endDate!).catch((err) => {
                LogError("Error sdfeedback card", { err, custID: props.custID });
                console.error(err);
            })
        }
        setGetDone(true);
    }, [props.startDate, props.endDate, props.custID]);
    if (props.raOnly) return <></>;
    const openPopup = () => {
        setPopupStatus(true);
    }
    var CardSpinner = getDone ? <></> : (
        <div id="loading-spinner" className="spinner-parent">
            <Spinner size={40} className="spinner-position" />
        </div>
    );
    var ReturnCard = <></>;
    const totalFeedbackLeft = sdFeedbackItems.totalPositiveFeedback + sdFeedbackItems.totalNeutralFeedback + sdFeedbackItems.totalNegativeFeedback;
    var percentageOfFeedbackLeft = (totalFeedbackLeft / sdFeedbackItems.totalSRClosed) * 100;
    if (percentageOfFeedbackLeft >= 99 && percentageOfFeedbackLeft < 100) {
        percentageOfFeedbackLeft = 0.99;
    }
    if (Number.isNaN(percentageOfFeedbackLeft)) { percentageOfFeedbackLeft = 0; }
    switch (props.cardType) {
        case 0:
            ReturnCard = (
                <CardClassic
                    title={tr("DASHBOARD_SERVICEDESK_FEEDBACK_TITLE")}
                    cardID={props.cardID!}
                    data={[`${percentageOfFeedbackLeft}%`, `${sdFeedbackItems.totalPositiveFeedback}`, `${sdFeedbackItems.totalNeutralFeedback}`, `${sdFeedbackItems.totalNegativeFeedback}`]}
                    type={["dash-neut", "dash-pos", "dash-neut", "dash-neg"]}
                    altText={[tr("DASHBOARD_SERVICEDESK_FEEDBACK_TOTAL_FEEDBACK"), tr("DASHBOARD_SERVICEDESK_FEEDBACK_HAPPY_FEEDBACK"), tr("DASHBOARD_SERVICEDESK_FEEDBACK_FINE_FEEDBACK"), tr("DASHBOARD_SERVICEDESK_FEEDBACK_UNHAPPY_FEEDBACK")]}
                    activatePopup={() => {
                        !props.localPreview ? openPopup() : console.log("Card Details");
                    }}
                    openHelpPopup={() => {
                        props.openHelpPopup("ServiceDeskFeedback");
                    }}
                    getDone={getDone}
                    custID={props.custID!}
                    startDate={props.startDate!}
                    endDate={props.endDate!}
                    isCSE={props.isCSE!}
                    customID={props.customID!}
                >
                    {CardSpinner}
                </CardClassic>
            );
            break;
        // case 1:
        //     ReturnCard = (
        //         <CardPie
        //             graphType="pie"
        //             cardID={props.cardID}
        //             data={[tr("DASHBOARD_SERVICEDESK_FEEDBACK_HAPPY_FEEDBACK"), tr("DASHBOARD_SERVICEDESK_FEEDBACK_FINE_FEEDBACK"), tr("DASHBOARD_SERVICEDESK_FEEDBACK_UNHAPPY_FEEDBACK")]}
        //             labels={[tr("DASHBOARD_SERVICEDESK_FEEDBACK_HAPPY_FEEDBACK"), tr("DASHBOARD_SERVICEDESK_FEEDBACK_FINE_FEEDBACK"), tr("DASHBOARD_SERVICEDESK_FEEDBACK_UNHAPPY_FEEDBACK")]}
        //             pieColours={["#8AB785", "#FDB515", "#f12a2a"]}
        //             title={tr("DASHBOARD_SERVICEDESK_FEEDBACK_TITLE")}
        //             getDone={getDone}
        //             otherData={[`${percentageOfFeedbackLeft}%`]}
        //             type={[""]}
        //             otherDataTitle={[tr("DASHBOARD_SERVICEDESK_FEEDBACK_TOTAL_FEEDBACK")]}
        //             activatePopup={() => {
        //                 !props.localPreview ? openPopup() : console.log("Card Details");
        //             }}
        //             openHelpPopup={() => {
        //                 props.openHelpPopup("ServiceDeskFeedback");
        //             }}
        //             custID={props.custID!}
        //             startDate={props.startDate!}
        //             endDate={props.endDate!}
        //             isCSE={props.isCSE!}
        //             customID={props.customID!}
        //         >
        //             {CardSpinner}
        //         </CardPie>
        //     );
        //     break;
        case 1:
        default:
            ReturnCard = (
                <CardGauge
                    title={tr("DASHBOARD_SERVICEDESK_FEEDBACK_TITLE")}
                    cardID={props.cardID}
                    textData={[sdFeedbackItems.totalPositiveFeedback, sdFeedbackItems.totalNeutralFeedback, sdFeedbackItems.totalNegativeFeedback]}
                    type={["dash-small-text dash-pos", "dash-small-text dash-neut", "dash-small-text dash-neg"]}
                    gaugeText={tr("DASHBOARD_SERVICEDESK_FEEDBACK_TOTAL_FEEDBACK")}
                    gaugeColours={["#DC2323", "#35A048"]}
                    percent={percentageOfFeedbackLeft / 100}
                    altText={[tr("DASHBOARD_SERVICEDESK_FEEDBACK_HAPPY_FEEDBACK"), tr("DASHBOARD_SERVICEDESK_FEEDBACK_FINE_FEEDBACK"), tr("DASHBOARD_SERVICEDESK_FEEDBACK_UNHAPPY_FEEDBACK")]}
                    getDone={getDone}
                    activatePopup={() => {
                        !props.localPreview ? openPopup() : console.log("Card Details");
                    }}
                    openHelpPopup={() => {
                        props.openHelpPopup("ServiceDeskFeedback");
                    }}
                    custID={props.custID!}
                    startDate={props.startDate!}
                    endDate={props.endDate!}
                    isCSE={props.isCSE!}
                    customID={props.customID!}
                    id={RandomGUID()}
                >
                    {CardSpinner}
                </CardGauge>
            );
            break;
    }
    return (
        <>
            {ReturnCard}
            {popupStatus && (
                <Popup
                    content={<FeedbackPerCustomerPopout theme={GetTheme()} startDate={props.startDate!} endDate={props.endDate!} customerID={props.custID!} popoutRendered={() => { }} />}
                    close={() => setPopupStatus(false)}
                    PopupName="FeedbackPerCustomerCardDetails"
                />
            )}
        </>
    );
};

export { DashCardServiceDeskFeedback };
