import React from "react";
import { trackEvent } from "./umami";

//alObject is stored in sessionStorage and is a basic way of limiting the amount of events collected.
//For example, opening settings multiple times will only record a single "event"

//Current alObject list:
//Total: 11
const allEvents = [
    "Marque Mode Toggled",
    "Email Sent",
    "Screenshot Event",
    "Schedule Accessed",
    "Help Opened",
    "Help Opened via Card",
    "Theme Toggled",
    "Card Comments Opened",
    "Refresh & Clear Cache Used",
    "Graph Text Size Changed",
    "Layout Changed",
    "Dashboard loaded"
];

const GetOrCreateSessionStorage = () => {
    let alObject = sessionStorage.getItem("alobject");
    if (alObject === undefined || alObject === null) {
        alObject = JSON.stringify(new Array<boolean>(allEvents.length));
        sessionStorage.setItem("alobject", JSON.stringify(alObject));
    }
    return JSON.parse(alObject);
}

const SaveToSessionStorage = (alObject: string) => {
    sessionStorage.setItem("alobject", alObject);
}

const LogEvent = (eventType: number, extraData: object) => {
    let alObject: Array<boolean> = GetOrCreateSessionStorage();
    if (!alObject[eventType]) {
        trackEvent(allEvents[eventType], extraData);
        alObject[eventType] = true;
        SaveToSessionStorage(JSON.stringify(alObject));
    }
}

const AlwaysLogEvent = (eventName: string, extraData: object) => {
    trackEvent(eventName, extraData);
}

const LogError = (errorName: string | number, extraData: object) => {
    extraData = { ...extraData, eventType: "error" };
    if (typeof errorName === "string") {
        trackEvent(errorName, extraData);
    } else {
        trackEvent(allEvents[errorName], extraData);
    }
}

export { AlwaysLogEvent, LogEvent, LogError }