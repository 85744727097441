import * as React from "react";
import { Spinner } from "../../../common/ui";
import { send } from "../../../../helpers/requests";
import { tr } from "../../../../helpers/languages";
import userManager from "../../../../util/userManager";

import Popup from "../../../common/popup/popout";

import CardLineGraph from "../../ui/LineGraphCard";
import ServiceDeskTrendsPopout from "../../popout/ServiceDeskTrends";

export default class DashCardServiceDeskTrends extends React.PureComponent {
    _isMounted = false; //If true then component is mounted. If false data returned from API GET should be dropped.

    constructor(props) {
        super(props);
        this.state = {
            getDone: false,
            popupStatus: false,
            serviceDeskTrendDates: [],
            serviceDeskTrendValues: []
        };
    }
    /**
     * componentDidMount
     * @description API calls for rebuilding base dashboard
     */
    componentDidMount() {
        this._isMounted = true;
        if (this.props.localPreview) {
            this.getServiceDeskTrendsPreview();
        } else {
            this.getServiceDeskTrends(this.props.custID, this.props.startDate, this.props.endDate);
        }
    }
    componentWillUnmount() {
        this._isMounted = false;
    }
    componentDidUpdate(prevProps) {
        if (!this.props.disableReRender) {
            if (prevProps.custID !== this.props.custID || prevProps.startDate !== this.props.startDate || prevProps.endDate !== this.props.endDate) {
                this.setState({ getDone: false, serviceDeskTrend: [] });
                this.getServiceDeskTrends(this.props.custID, this.props.startDate, this.props.endDate);
            }
        }
    }
    /**
     * OpenPopup
     * @description Calls OpenPopup inside Dashboard.js
     */
    OpenPopup = () => {
        this.setState({ popupStatus: true });
    };
    ///
    /// API Calls
    ///
    /**
     * getAccessToken
     * @description Gets the access token of the authenticated user
     */
    getAccessToken = async () => {
        let token = (await userManager.getUser()).access_token;
        return token;
    };
    /**
     * getServiceDeskTrends
     * @description Gets Service Dest Trends as an array.
     * @param {string} custID Customer ID
     * @param {Date} startDate Start date as JS Date
     * @param {Date} endDate End date as JS Date
     */
    getServiceDeskTrends = async (custID, startDate, endDate) => {
        const timeZoneOffset = startDate.getTimezoneOffset() * 60;
        const newStartDate = Math.floor(startDate.getTime() / 1000) - timeZoneOffset;
        const newEndDate = Math.floor(endDate.getTime() / 1000) - timeZoneOffset;
        send({
            method: "GET",
            url: `/api/reporting/servicedesktrends?custid=${custID}&startdate=${newStartDate}&enddate=${newEndDate}`,
            token: await this.getAccessToken()
        })
            .then((response) => {
                if (this._isMounted) {
                    this.setState({
                        getDone: true,
                        serviceDeskTrendDates: response.data.map(
                            (item) => new Date(item.calendarDate.split("T", 1)[0]).toLocaleString("en-GB").split(",", 1)[0] //Not the nicest way to do but works
                        ),
                        serviceDeskTrendValues: response.data.map((item) => item.sRsDayTotal)
                    });
                    this.props.cardRendered();
                }
            })
            .catch((error) => {
                console.log(error);
            });
    };
    getServiceDeskTrendsPreview = () => {
        this.setState({
            getDone: true,
            serviceDeskTrendDates: ["01/01/2022", "02/01/2022", "03/01/2022", "04/01/2022", "05/01/2022", "06/01/2022", "07/01/2022"],
            serviceDeskTrendValues: [10, 14, 12, 15, 11, 14, 9]
        });
    };
    render() {
        var CardSpinner,
            ReturnCard = <></>;
        if (!this.state.getDone) {
            CardSpinner = (
                <div id="loading-spinner" className="spinner-parent">
                    <Spinner size={40} className="spinner-position" />
                </div>
            );
        }
        switch (this.props.cardType) {
            case 0:
            default:
                ReturnCard = (
                    <CardLineGraph
                        title={tr("DASHBOARD_SERVICEDESK_TRENDS_TITLE")}
                        cardID={this.props.cardID}
                        displayLegend={true}
                        legendTitle={tr("DASHBOARD_SERVICEDESK_TRENDS_LABEL")}
                        data={[this.state.serviceDeskTrendValues]}
                        chartColours={["#8AB785", "#f12a2a"]}
                        labels={this.state.serviceDeskTrendDates}
                        datasetLabel={[tr("DASHBOARD_SERVICEDESK_TRENDS_LABEL")]}
                        getDone={this.state.getDone}
                        activatePopup={() => {
                            !this.props.localPreview ? this.OpenPopup() : console.log("Card Details");
                        }}
                        openHelpPopup={() => {
                            this.props.openHelpPopup("ServiceDeskTrendsCard");
                        }}
                        custID={this.props.custID}
                        startDate={this.props.startDate}
                        endDate={this.props.endDate}
                        isCSE={this.props.isCSE}
                        customID={this.props.customID}
                    >
                        {CardSpinner}
                    </CardLineGraph>
                );
                break;
        }
        return (
            <>
                {ReturnCard}
                {this.state.popupStatus && (
                    <Popup
                        content={
                            <ServiceDeskTrendsPopout
                                startDate={this.props.startDate}
                                endDate={this.props.endDate}
                                popoutRendered={() => {}}
                                customerID={this.props.custID}
                            />
                        }
                        close={() => this.setState({ popupStatus: false })}
                        PopupName="ServiceDeskTrendsCardDetails"
                    />
                )}
            </>
        );
    }
}
