import React, { useState } from "react";

import { tr } from "../../../helpers/languages";
import CameraIcon from "../../../assets/icons/camera.svg";
import { toPng } from "html-to-image";
import { saveAs } from "file-saver";
import { format } from "date-fns";
import { LogError, LogEvent } from "../../analytics/eventTracker";

const getScreenshot = async () => {
    let tempImage = "";
    try {
        tempImage = await toPng(document.getElementById("content")!);
    } catch (e) {
        LogError("Error taking screenshot", { type: "TakeScreenshot", e });
        console.error(e);
    }
    return tempImage;
};

const TakeScreenshot = () => {
    const [hover, setHover] = useState(false);
    var customerName = "";
    try {
        customerName = document.getElementById("custNameHeader")?.innerText || "";
    } catch (error) {
        LogError("Error locating customer name", { error });
        console.error("Unable to locate customer name. Using default screenshot filename");
    }
    var attachmentFileName =
        customerName === ""
            ? "dashboard " + format(Date.now(), "dd-MM-yyyy HH-mm-ss") + ".png"
            : customerName.split(" ").join("").slice(0, 5).toUpperCase() + " " + format(Date.now(), "dd-MM-yyyy HH-mm-ss") + ".png";
    const DownloadScreenshot = () => {
        setHover(false);
        LogEvent(2, {});
        getScreenshot().then((res) => {
            saveAs(res, attachmentFileName);
        });
    };
    return (
        <div className="icon-hide-mobile cse-nav-menu-icon-container">
            <img
                alt=""
                onMouseEnter={() => setHover(true)}
                onMouseLeave={() => setHover(false)}
                aria-label={tr("DASHBOARD_SCREENSHOT")}
                className="cse-nav-menu-icon"
                src={CameraIcon}
                onClick={DownloadScreenshot}
            />
            <span className={hover ? "cse-nav-menu-tooltip-hovered cse-nav-menu-tooltip" : "cse-nav-menu-tooltip"}>{tr("DASHBOARD_SCREENSHOT")}</span>
        </div>
    );
};

export default TakeScreenshot;
