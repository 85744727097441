import * as React from "react";
import { Spinner } from "../../../common/ui";
import { send } from "../../../../helpers/requests";
import { tr } from "../../../../helpers/languages";
import userManager from "../../../../util/userManager";
import { GetTheme } from "../SharedFunctions";

import Popup from "../../../common/popup/popout";
import ApplicationUsagePopout from "../../popout/ApplicationUsage";

import CardBarGraph from "../../ui/BarGraphCard";
import CardClassic from "../../ui/TextCard";
import ActivityPerUser from "../../popout/ActivityPerUser";
import TopServiceDeskRequestUsers from "../../popout/TopServiceDeskRequestUsers";

class DashCardTopServiceDeskRequestUsers extends React.PureComponent {
    _isMounted = false;

    constructor(props) {
        super(props);
        this.state = {
            activity: [
                { username: "", count: 0 },
                { username: "", count: 0 },
                { username: "", count: 0 }
            ],
            getDone: false,
            popupStatus: false
        };
    }
    /**
     * componentDidMount
     * @description API calls for rebuilding base dashboard
     */
    componentDidMount() {
        this._isMounted = true;
        if (this.props.localPreview) {
            this.getActivityPreview();
        } else {
            this.getActivity(this.props.custID, this.props.startDate, this.props.endDate);
        }
    }
    componentWillUnmount() {
        this._isMounted = false;
    }
    componentDidUpdate(prevProps) {
        if (!this.props.disableReRender) {
            if (prevProps.custID !== this.props.custID || prevProps.startDate !== this.props.startDate || prevProps.endDate !== this.props.endDate) {
                this.setState({
                    getDone: false,
                    activity: [
                        { username: "", count: 0 },
                        { username: "", count: 0 },
                        { username: "", count: 0 }
                    ]
                });
                this.getActivity(this.props.custID, this.props.startDate, this.props.endDate);
            }
        }
    }
    /**
     * OpenPopup
     * @description Calls OpenPopup inside Dashboard.js
     */
    OpenPopup = () => {
        this.setState({ popupStatus: true });
    };
    ///
    /// API Calls
    ///
    /**
     * getAccessToken
     * @description Gets the access token of the authenticated user
     */
    getAccessToken = async () => {
        let token = (await userManager.getUser()).access_token;
        return token;
    };
    getActivity = async (custID, startDate, endDate) => {
        const timeZoneOffset = startDate.getTimezoneOffset() * 60;
        const newStartDate = Math.floor(startDate.getTime() / 1000) - timeZoneOffset;
        const newEndDate = Math.floor(endDate.getTime() / 1000) - timeZoneOffset;
        send({
            method: "GET",
            url: `/api/reporting/toprequestusers?custid=${custID}&startdate=${newStartDate}&enddate=${newEndDate}`,
            token: await this.getAccessToken()
        })
            .then((response) => {
                if (this._isMounted) {
                    this.setState({
                        getDone: true,
                        activity: response.data
                    });
                    this.props.cardRendered();
                }
            })
            .catch((error) => {
                console.log(error);
            });
    };
    getActivityPreview = () => {
        this.setState({
            getDone: true,
            activity: [
                { username: "User1", count: 23 },
                { username: "User2", count: 15 },
                { username: "User3", count: 52 }
            ]
        });
    };
    render() {
        var CardSpinner,
            ReturnCard = <></>;
        var usernameRes = [];
        var countRes = [];
        if (!this.state.getDone) {
            CardSpinner = (
                <div id="loading-spinner" className="spinner-parent">
                    <Spinner size={40} className="spinner-position" />
                </div>
            );
        } else if (this.state.activity.length === 0) {
            return <></>;
        }
        if (this.state.activity !== []) {
            this.state.activity.forEach((item, index) => {
                if (index <= 2) {
                    usernameRes.push(item.username);
                    countRes.push(item.count);
                }
            });
        }
        switch (this.props.cardType) {
            case 0:
            default:
                ReturnCard = (
                    <CardClassic
                        title={tr("DASHBOARD_SERVICEDESK_TOP_REQUEST_TITLE")}
                        cardID={this.props.cardID}
                        data={countRes}
                        type={["dash-neut", "dash-neut", "dash-neut"]}
                        altTextType={["dash-xsmall-text dash-max-w-70", "dash-xsmall-text dash-max-w-70", "dash-xsmall-text dash-max-w-70"]}
                        altText={usernameRes}
                        getDone={this.state.getDone}
                        activatePopup={() => {
                            !this.props.localPreview ? this.OpenPopup() : console.log("Card Details");
                        }}
                        openHelpPopup={() => {
                            this.props.openHelpPopup("TopServiceDeskRequestUsers");
                        }}
                        custID={this.props.custID}
                        startDate={this.props.startDate}
                        isCSE={this.props.isCSE}
                        endDate={this.props.endDate}
                        customID={this.props.customID}
                    >
                        {CardSpinner}
                    </CardClassic>
                );
                break;
        }
        return (
            <>
                {ReturnCard}
                {this.state.popupStatus && (
                    <Popup
                        content={
                            <TopServiceDeskRequestUsers
                                theme={GetTheme()}
                                startDate={this.props.startDate}
                                endDate={this.props.endDate}
                                popoutRendered={() => {}}
                                customerID={this.props.custID}
                            />
                        }
                        close={() => this.setState({ popupStatus: false })}
                        PopupName="TopServiceDeskRequestUsersCardDetails"
                    />
                )}
            </>
        );
    }
}

export { DashCardTopServiceDeskRequestUsers }