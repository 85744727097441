import React from "react";
import styles from "./Button.module.css";

type ButtonProps = {
    className?: string,
    style?: React.CSSProperties,
    children: string | string[] | JSX.Element | JSX.Element[],
    primary?: boolean,
    disabled?: boolean,
    type?: "submit" | "reset" | "button" | undefined,
    onClick?: () => void,
    id?: string
};

const Button: React.FC<ButtonProps> = ({ className, children, disabled, id, onClick, primary, style, type }) => {
    var idText = "button";
    var primaryStyles = "";
    if (primary) {
        primaryStyles = styles.buttonPrimary;
    }
    if (id) {
        idText = id;
    }
    return (
        <button
            id={idText}
            className={`${className ? className : ""} ${styles.button} ${primaryStyles}`}
            disabled={disabled}
            style={style}
            onClick={onClick}
            type={type}
        >
            {children}
        </button>
    );
};

export default Button;
