import * as React from "react";
import HelpIcon from "../../../assets/icons/help.svg";
import { tr } from "../../../helpers/languages";

type HelpDashIconProps = {
    openHelpPopup: () => void
};
const HelpDashIcon: React.FC<HelpDashIconProps> = (props) => {
    return (
        <div style={{ margin: "2px 2px 0 0", height: "40px", width: "40px" }}>
            <img
                style={{ height: "40px", filter: "var(--cse-icon-filter)" }}
                alt=""
                aria-label={tr("DASHBOARD_HELP")}
                src={HelpIcon}
                onClick={props.openHelpPopup}
            />
        </div>
    );
};

export default HelpDashIcon;
