import React, { useEffect, useState } from "react";
import DataTable, { TableColumn } from "react-data-table-component";
import { tr } from "../../../helpers/languages";
import { send } from "../../../helpers/requests";
import userManager from "../../../util/userManager";
import dayjs from "dayjs";
import { Button, Row, Spinner } from "../../common/ui";
import { DateRange } from "react-date-range";
import { GetTheme } from "../cards/SharedFunctions";
import { CSVLink } from "react-csv";

type Activity = {
  username: string,
  totalActivity: number,
  newActivity: number,
  modifiedActivity: number,
  emailActivity: number,
  closedActivity: number,
  archivedActivity: number
};
type CSEActivityPerUserProps = {
  ExitPage: () => void
}

const columns: TableColumn<Activity>[] = [
  {
    name: "Name",
    selector: (row) => row.username,
    sortable: true,
    reorder: true
  },
  {
    name: "Total Activity",
    selector: (row) => row.totalActivity,
    sortable: true,
    reorder: true
  },
  {
    name: "New Requests",
    selector: (row) => row.newActivity,
    sortable: true,
    reorder: true
  },
  {
    name: "Modified Requests",
    selector: (row) => row.modifiedActivity,
    sortable: true,
    reorder: true
  },
  {
    name: "Email Activity",
    selector: (row) => row.emailActivity,
    sortable: true,
    reorder: true
  },
  {
    name: "Closed Requests",
    selector: (row) => row.closedActivity,
    sortable: true,
    reorder: true
  },
  {
    name: "Archived Requests",
    selector: (row) => row.archivedActivity,
    sortable: true,
    reorder: true
  }
]

const CSEActivityPerUser = (props: CSEActivityPerUserProps) => {
  const [activity, setActivity] = useState<Activity[]>([]);
  const [getDone, setGetDone] = useState(false);
  const [state, setState] = useState([
    {
      startDate: new Date(new Date()),
      endDate: new Date(new Date(new Date().setDate(new Date().getDate() - 7))),
      key: "selection"
    }
  ]);
  const curDate = new Date();
  /**
 * getAccessToken
 * @description Gets the access token of the authenticated user
 */
  const getAccessToken = async () => {
    let token = (await userManager.getUser())?.access_token;
    return token;
  };
  const getActivity = async (startDate: Date, endDate: Date) => {
    const timeZoneOffset = startDate.getTimezoneOffset() * 60;
    const newStartDate = Math.floor(startDate.getTime() / 1000) - timeZoneOffset;
    const newEndDate = Math.floor(endDate.getTime() / 1000) - timeZoneOffset;
    send({
      method: "GET",
      url: `/api/reporting/getcsenetactivityperuser?startdate=${newStartDate}&enddate=${newEndDate}`,
      token: await getAccessToken(),
      data: "",
      params: ""
    })
      .then((response: any) => {
        setGetDone(true);
        setActivity(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const refreshActivity = async () => {
    setGetDone(false);
    getActivity(state[0].startDate, state[0].endDate)
  }
  const ChangeCustomer = (newCustomer: { target: { value: string | undefined } }) => {
    setGetDone(false);
    //getOfflineServers(newCustomer.target.value!);
  }
  const setDays = (days: Number) => {
    var endDate = new Date(new Date().setHours(0, 0, 0, 0));
    switch (days) {
      case 0: //Today
        setState([{ startDate: curDate, endDate: endDate, key: "selection" }]);
        break;
      case 1: //Yesterday
        endDate = new Date(endDate.setDate(curDate.getDate() - 1));
        setState([
          {
            startDate: new Date(new Date(endDate).setHours(23, 59, 59)),
            endDate: endDate,
            key: "selection"
          }
        ]);
        break;
      case 2: //Last Week
        endDate = new Date(endDate.setDate(new Date().getDate() - 7));
        setState([
          {
            startDate: curDate,
            endDate: endDate,
            key: "selection"
          }
        ]);
        break;
      case 3: //Last 14 Days
        endDate = new Date(endDate.setDate(curDate.getDate() - 14));
        setState([
          {
            startDate: curDate,
            endDate: endDate,
            key: "selection"
          }
        ]);
        break;
      case 4: //Last Month
        endDate = new Date(endDate.setDate(curDate.getDate() - 30));
        setState([
          {
            startDate: curDate,
            endDate: endDate,
            key: "selection"
          }
        ]);
        break;
      case 5: //Last 2 Months
        endDate = new Date(endDate.setDate(curDate.getDate() - 60));
        setState([
          {
            startDate: curDate,
            endDate: endDate,
            key: "selection"
          }
        ]);
        break;
      case 6: //Last 3 Months
        endDate = new Date(endDate.setDate(curDate.getDate() - 90));
        setState([
          {
            startDate: curDate,
            endDate: endDate,
            key: "selection"
          }
        ]);
        break;
      case 7: //Last 6 Months
        endDate = new Date(endDate.setDate(curDate.getDate() - 180));
        setState([
          {
            startDate: curDate,
            endDate: endDate,
            key: "selection"
          }
        ]);
        break;
      case 8: //Last Year
        endDate = new Date(endDate.setDate(curDate.getDate() - 365));
        setState([
          {
            startDate: curDate,
            endDate: endDate,
            key: "selection"
          }
        ]);
        break;
      default:
        break;
    }
    setGetDone(false);
    getActivity(curDate, endDate);
  };
  useEffect(() => {
    //getOtherCustomerNames();
    getActivity(state[0].startDate, state[0].endDate);
  }, [])
  if (!getDone) {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          marginTop: "10rem"
        }}
      >
        <Spinner size={250} />
      </div>
    );
  }
  const finalActivityList = JSON.parse(JSON.stringify(activity));
  return (<div style={{ overflow: "visible", maxHeight: "100%", marginLeft: "1rem", marginRight: "1rem" }}>
    <div style={{ marginTop: "1rem", marginBottom: "1rem", display: "flex" }}>
      <div style={{ flex: 1 }}><Button onClick={props.ExitPage}>X</Button></div>
      <h2 style={{ textAlign: "center" }}>{tr("CSE_INTERNAL_CSE_ACTIVITY")}</h2>
      <span style={{ flex: 1 }}></span>
    </div>
    <Row center>
      <div style={{ display: "flex", flexDirection: "row", marginBottom: "5px" }}>
        <div style={{ display: "flex", flexDirection: "row" }}>
          <Button
            primary
            onClick={() => {
              setDays(0);
            }}
            style={{ marginRight: "5px" }}
          >
            {tr("DASHBOARD_FILTER_TODAY")}
          </Button>
          <Button
            primary
            onClick={() => {
              setDays(1);
            }}
            style={{ marginRight: "5px" }}
          >
            {tr("DASHBOARD_FILTER_YESTERDAY")}
          </Button>
          <Button
            primary
            onClick={() => {
              setDays(2);
            }}
            style={{ marginRight: "5px" }}
          >
            {tr("DASHBOARD_FILTER_LAST_WEEK")}
          </Button>
          <Button
            primary
            onClick={() => {
              setDays(3);
            }}
            style={{ marginRight: "5px" }}
          >
            {tr("DASHBOARD_FILTER_LAST_FORTNIGHT")}
          </Button>
          <Button
            primary
            onClick={() => {
              setDays(4);
            }}
            style={{ marginRight: "5px" }}
          >
            {tr("DASHBOARD_FILTER_LAST_MONTH")}
          </Button>
          <Button
            primary
            onClick={() => {
              setDays(5);
            }}
            style={{ marginRight: "5px" }}
          >
            {tr("DASHBOARD_FILTER_LAST_TWO_MONTHS")}
          </Button>
          <Button
            primary
            onClick={() => {
              setDays(6);
            }}
            style={{ marginRight: "5px" }}
          >
            {tr("DASHBOARD_FILTER_LAST_THREE_MONTHS")}
          </Button>
          <Button
            primary
            onClick={() => {
              setDays(7);
            }}
            style={{ marginRight: "5px" }}
          >
            {tr("DASHBOARD_FILTER_LAST_SIX_MONTHS")}
          </Button>
          <Button
            primary
            onClick={() => {
              setDays(8);
            }}
          >
            {tr("DASHBOARD_FILTER_LAST_YEAR")}
          </Button>
        </div>
        {/* <DateRange
          editableDateInputs={true}
          moveRangeOnFirstSelection={false}
          onChange={(item: any) => {
            setState([item.selection]);
          }}
          ranges={state}
          maxDate={new Date()}
        /> */}
      </div>

    </Row>
    {/* <Row style={{ marginTop: "0.5rem", marginBottom: "1rem" }} center>
      <Input type="select" id="customerNamesSelect" style={{ maxWidth: "500px" }} onChange={ChangeCustomer}>
        <option value="All">All</option>
        {AllCustomerNames.map((customerName, index) => {
          return (
            <option key={index} id={customerName.customer_id} value={index}>
              {customerName.organisation_name}
            </option>
          );
        })}
      </Input>
    </Row> */}
    <Row style={{ marginTop: "0.5rem", marginBottom: "1rem" }} center>
      <Button primary onClick={refreshActivity}>Refresh</Button>
    </Row>
    <Row center>
      <CSVLink
        data={finalActivityList}
        filename={"cse activity " + dayjs().format("DD-MM-YYYY h-mm-ss") + ".csv"}
      >
        <Button>{tr("EXPORT_CSV")}</Button>
      </CSVLink>
    </Row>
    <Row style={{ marginTop: "0.5rem", marginBottom: "1rem" }} center>
      <a href={`data:text/json;charset=utf-8,${encodeURIComponent(
        JSON.stringify(finalActivityList)
      )}`}
        download={"cse activity " + dayjs().format("DD-MM-YYYY h-mm-ss") + ".json"}><Button>{tr("EXPORT_JSON")}</Button></a>
    </Row>
    <div style={{ marginBottom: "2rem" }}>
      {JSON.stringify(finalActivityList) !== "{}" && <DataTable columns={columns} data={finalActivityList} theme={GetTheme()} />}
    </div>
  </div>);
};

export default CSEActivityPerUser;