import * as React from "react";
import userManager from "../../../util/userManager";
import { send } from "../../../helpers/requests";
import DataTable, { TableColumn, TableRow } from "react-data-table-component";
import { tr } from "../../../helpers/languages";
import { Input, Label } from "reactstrap";
import dayjs from "dayjs";
import { CSVLink } from "react-csv";
import { Spinner } from "../../common/ui";

import styles from "./popout.module.css";


type SDRequestUsersAPI = {
    username: string,
    count: number,
    categories: string[],
};
type Site = {
    site_id: string,
    site_name: string
}
type Props = {
    customerID: string,
    startDate: Date,
    endDate: Date,
    theme: string,
    popoutRendered: () => void;
};
type State = {
    getDone: boolean,
    curSite: string,
    sites: Site[]
    search: string,
    SDRequestUsers: SDRequestUsersAPI[],
    SDRequestUsersOriginal: SDRequestUsersAPI[]
};

export default class TopServiceDeskRequestUsers extends React.PureComponent<Props, State> {
    _isMounted = false;
    state = {
        getDone: false,
        curSite: "All",
        search: "",
        sites: [],
        SDRequestUsers: [],
        SDRequestUsersOriginal: []
    };
    componentDidMount() {
        this._isMounted = true;
        this.getTopSDRequestUser(this.props.customerID, this.props.startDate, this.props.endDate);
    }
    componentWillUnmount() {
        this._isMounted = false;
    }
    //API Calls
    /**
     * getAccessToken
     * @description Gets the access token of the authenticated user
     */
    getAccessToken = async () => {
        let token = (await userManager.getUser())?.access_token;
        return token;
    };
    getTopSDRequestUser = async (custID: string, startDate: Date, endDate: Date) => {
        const timeZoneOffset = startDate.getTimezoneOffset() * 60;
        const newStartDate = Math.floor(startDate.getTime() / 1000) - timeZoneOffset;
        const newEndDate = Math.floor(endDate.getTime() / 1000) - timeZoneOffset;
        send({
            method: "GET",
            url: `/api/reporting/gettoprequestusers?custid=${custID}&startdate=${newStartDate}&enddate=${newEndDate}`,
            token: await this.getAccessToken(),
            data: "",
            params: ""
        })
            .then((response: any) => {
                if (this._isMounted) {
                    this.setState({
                        getDone: true,
                        SDRequestUsers: response.data,
                        SDRequestUsersOriginal: response.data
                    });
                }
            })
            .catch((error) => {
                console.log(error);
            });
    };
    columns: TableColumn<SDRequestUsersAPI>[] = [
        {
            name: "Name",
            selector: (row) => row.username,
            sortable: true,
            reorder: true
        },
        {
            name: "Requests Created",
            selector: (row) => row.count,
            sortable: true,
            reorder: true
        },
        {
            name: "Request Categories",
            selector: (row) => {
                var returnStr = "";
                for (let index = 0; row.categories.length >= 10 ? index < 10 : index < row.categories.length; index++) {
                    returnStr += `${row.categories[index]}, `
                }
                returnStr = returnStr.substring(0, returnStr.length - 2);
                if (row.categories.length >= 10) {
                    returnStr += "...";
                }
                return returnStr;
            },
            style: {
                maxWidth: "33%",
                maxHeight: "400px",
                textOverflow: "ellipsis",
                overflow: "hidden",
                whiteSpace: "nowrap"
            },
            wrap: true,
            sortable: true,
            reorder: true
        }
    ];
    CreateFinalActivityList = () => {
        var finalActivityList = [];
        if (this.state.search === "") {
            finalActivityList = JSON.parse(JSON.stringify(this.state.SDRequestUsers));
        } else {
            this.state.SDRequestUsers.forEach((user: SDRequestUsersAPI) => {
                if (user.username.toLowerCase().includes(this.state.search)) {
                    finalActivityList.push(user);
                }
            });
        }
        return finalActivityList;
    };
    render() {
        var finalActivityList = this.CreateFinalActivityList();
        var uniqueSites: string[] = [];
        if (!this.state.getDone) {
            return (
                <div
                    style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        marginTop: "10rem"
                    }}
                >
                    <Spinner size={250} />
                </div>
            );
        }
        if (this.state.sites.length > 1) {
            this.state.sites.forEach((site: Site) => {
                uniqueSites.push(site.site_name)
            })
        }
        return (
            <div className={styles.popoutContainer}>
                <div>
                    <h2 className={styles.popoutHeader}>{tr("DASHBOARD_SERVICEDESK_TOP_REQUEST_TITLE")}</h2>
                </div>
                <div className={styles.popoutSearch}>
                    <div className="form-floating-labels">
                        <Input
                            id="Search"
                            placeholder=" "
                            value={this.state.search || ""}
                            onChange={(v: React.ChangeEvent<HTMLInputElement>) => {
                                this.setState({
                                    search: v.target.value.toLowerCase()
                                });
                            }}
                        />
                        <Label for="Search">{tr("DASHBOARD_SEARCH_POPOUT_USER")}</Label>
                    </div>
                </div>
                <div className={styles.popoutExport}>
                    <CSVLink
                        className={styles.exportCSVButton}
                        data={finalActivityList}
                        filename={"export " + dayjs().format("DD-MM-YYYY h-mm-ss") + ".csv"}
                    >
                        {tr("EXPORT_CSV")}
                    </CSVLink>
                </div>
                <div id="activityPerUserTable">
                    <DataTable
                        columns={this.columns}
                        data={finalActivityList}
                        theme={this.props.theme}
                        pagination
                    />
                </div>
            </div>
        );
    }
}
