import React, { useEffect, useState } from "react";
import { tr } from "../../../../helpers/languages";
import { useForm } from "react-hook-form";
import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import ScheduleIcon from "../../../../assets/icons/schedule.svg";
import { ErrorMessage } from "@hookform/error-message";
import Popup from "../../popup";
import styles from "./SendEmail.module.css";
import { Button, Col, Row, Spinner } from "../../ui";
import ReactQuill from "react-quill";
import "./quill.css";
import { getCustomerName, SitesType } from "./SendEmail";
import { send } from "../../../../helpers/requests";
import userManager from "../../../../util/userManager";
import { LogEvent } from "../../../analytics/eventTracker";

enum DayOfWeek {
    //
    // Summary:
    //     Indicates Sunday.
    Sunday = 0,
    //
    // Summary:
    //     Indicates Monday.
    Monday = 1,
    //
    // Summary:
    //     Indicates Tuesday.
    Tuesday = 2,
    //
    // Summary:
    //     Indicates Wednesday.
    Wednesday = 3,
    //
    // Summary:
    //     Indicates Thursday.
    Thursday = 4,
    //
    // Summary:
    //     Indicates Friday.
    Friday = 5,
    //
    // Summary:
    //     Indicates Saturday.
    Saturday = 6
}

const Schedule = () => {
    const [hover, setHover] = useState(false);
    const [popupStatus, setPopupStatus] = useState(false);
    return (
        <>
            <div className="icon-hide-mobile cse-nav-menu-icon-container">
                <img
                    alt=""
                    onMouseEnter={() => setHover(true)}
                    onMouseLeave={() => setHover(false)}
                    aria-label={tr("DASHBOARD_SCHEDULE")}
                    className="cse-nav-menu-icon"
                    src={ScheduleIcon}
                    onClick={() => {
                        setPopupStatus(true);
                        LogEvent(3, {});
                    }}
                />
                <span className={hover ? "cse-nav-menu-tooltip-hovered cse-nav-menu-tooltip" : "cse-nav-menu-tooltip"}>
                    {tr("DASHBOARD_SCHEDULE")}
                </span>
            </div>
            {popupStatus && (
                <Popup
                    content={<ScheduleList />}
                    close={() => {
                        setPopupStatus(false);
                    }}
                    PopupName="ScheduleList"
                />
            )}
        </>
    );
};

const getAccessToken = async () => {
    let token = (await userManager.getUser())?.access_token;
    return token;
};
const getCustomerID = async () => {
    let customerid = (await userManager.getUser())?.profile["http://schemas.cse-net.co.uk/ws/2014/02/identity/claims/customerid"];
    return customerid;
};
const getSMSchedules = async () => {
    let CustID = await getCustomerID();
    var res: any = await send({
        method: "GET",
        url: `/api/reporting/getschedule/${CustID}`,
        token: await getAccessToken(),
        data: "",
        params: ""
    });
    return res.data;
};
type TestSchedule = {
    type: string,
    starting: string
};
const ScheduleList = () => {
    const [scheduleEditor, setScheduleEditor] = useState(false);
    //Get schedules
    const [schedules, setSchedules] = useState<ScheduleType[]>([
        {
            _id: null,
            customer_id: "CSE-NET",
            subject: "CSE Weekly Report",
            schedule_data: { outgoingemailid: "", to: "test@test.com", cc: "", repeat: "Weekly", repeatDay: DayOfWeek.Monday, repeatTime: "00:00" }
        },
        {
            _id: null,
            customer_id: "CSE-NET",
            subject: "CSE Weekly Report",
            schedule_data: { outgoingemailid: "", to: "test@test.com", cc: "", repeat: "Weekly", repeatDay: DayOfWeek.Thursday, repeatTime: "12:00" }
        }
    ]);
    const [scheduleIndex, setScheduleIndex] = useState(NaN);
    const [editSchedule, setEditSchedule] = useState<ScheduleType>();
    const [customer, setCustomer] = useState<{ Name: string, CustID: string }>();
    useEffect(() => {
        //useEffect to only run once on first render, function because async/await
        getCustomerName().then((res) => {
            setCustomer(res);
        });
        getSMSchedules().then((res) => {
            setSchedules(res);
        });
    }, []);
    const AddSchedule = (newSchedule: ScheduleType, id: number, update?: Boolean) => {
        var newSchedules = JSON.parse(JSON.stringify(schedules));
        if (update) {
            newSchedules[id] = newSchedule;
        } else {
            newSchedules.push(newSchedule);
        }
        setSchedules(newSchedules);
    };
    const DeleteSchedule = async (index: number) => {
        if (window.confirm(tr("SCHEDULE_DELETE_CONFIRMATION"))) {
            let sitem = schedules[index];
            send({
                method: "DELETE",
                url: `/api/reporting/removeschedule/${sitem.customer_id}`,
                data: sitem._id,
                params: "",
                token: await getAccessToken()
            }).then(() => {
                var tempSchedules = JSON.parse(JSON.stringify(schedules));
                tempSchedules.splice(index, 1);
                setSchedules(tempSchedules);
            })
        }
    };
    if (customer === undefined) {
        return (
            <div
                style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    marginTop: "10rem"
                }}
            >
                <Spinner size={250} />
            </div>
        );
    }
    if (scheduleEditor) {
        if (editSchedule) {
            return (
                <ScheduleEditor
                    close={() => {
                        setEditSchedule(undefined);
                        setScheduleEditor(false);
                    }}
                    id={scheduleIndex}
                    pushSchedule={AddSchedule}
                    curSchedule={editSchedule}
                    customer={customer}
                />
            );
        } else {
            return (
                <ScheduleEditor
                    close={() => {
                        setScheduleEditor(false);
                    }}
                    id={schedules.length}
                    pushSchedule={AddSchedule}
                    customer={customer}
                />
            );
        }

    }
    return (
        <>
            <div className={styles.scheduleHeader}>
                <h1>{tr("SCHEDULE_TITLE")}</h1>
                <Button
                    primary
                    onClick={() => {
                        setScheduleEditor(true);
                    }}
                >
                    +
                </Button>
            </div>

            {schedules.map((schedule, index) => {
                return (
                    <div key={`scheduleItem ${index}`} className={styles.scheduleCard}>
                        <h2>{customer?.Name} {schedule.schedule_data.repeat.charAt(0).toUpperCase() + schedule.schedule_data.repeat.slice(1)} Schedule</h2>
                        <h5>{schedule.subject}</h5>
                        <p>{DayOfWeek[schedule.schedule_data.repeatDay]} {schedule.schedule_data.repeatTime}</p>
                        <Button primary onClick={() => {
                            setEditSchedule(schedules[index]);
                            setScheduleIndex(index);
                            setScheduleEditor(true);
                        }} style={{ marginRight: "4px" }}>
                            {tr("SCHEDULE_EDIT")}
                        </Button>
                        <Button
                            primary
                            onClick={() => {
                                DeleteSchedule(index);
                            }}
                        >
                            {tr("SCHEDULE_DELETE")}
                        </Button>
                    </div>
                );
            })}
        </>
    );
};

const times = [
    "00:00", "00:30", "01:00", "01:30", "02:00", "02:30", "03:00", "03:30", "04:00", "04:30", "05:00", "05:30",
    "06:00", "06:30", "07:00", "07:30", "08:00", "08:30", "09:00", "09:30", "10:00", "10:30", "11:00", "11:30",
    "12:00", "12:30", "13:00", "13:30", "14:00", "14:30", "15:00", "15:30", "16:00", "16:30", "17:00", "17:30",
    "18:00", "18:30", "19:00", "19:30", "20:00", "20:30", "21:00", "21:30", "22:00", "22:30", "23:00", "23:30",
]

type Schedule = {
    type: "weekly" | "by weekly" | "monthly",
    starting: Date
};

const ScheduleSchema = z.object({
    _id: z.string().nullable().optional(),
    customer_id: z.string(),
    subject: z.string().min(2),
    schedule_data: z.object({
        outgoingemailid: z.number().or(z.string()),
        to: z.string().min(2),
        cc: z.string().optional(),
        repeat: z.string(), //"weekly" | "by weekly" | "monthly"    
        repeatDay: z.nativeEnum(DayOfWeek), //"0" = "Sunday", "1" = "Monday", "2" = "Tuesday", "3" = "Wednesday", "4" = "Thursday", "5" = "Friday", "6" = "Saturday" 
        repeatTime: z.string(), //"09:30"
    })
});
type ScheduleType = z.infer<typeof ScheduleSchema>;
type ScheduleEditorProps = {
    close: () => void,
    id: number,
    pushSchedule: (newSchedule: ScheduleType, id: number, update?: boolean) => void,
    curSchedule?: ScheduleType,
    customer: { Name: string, CustID: string }
};
const ScheduleEditor: React.FC<ScheduleEditorProps> = (props) => {
    const {
        register,
        watch,
        formState: { errors },
        handleSubmit
    } = useForm({ resolver: zodResolver(ScheduleSchema) });
    const [body, setBody] = useState(
        `<p>Hello,</p><p><br></p><p>Please find attached your report summary page.</p><p></p><p>If you would like to view the full report please use this link.</p><p></p><p><a href=\"https://reporting.cseservicemanager.co.uk \" rel=\"noopener noreferrer\" target=\"_blank\">https://reporting.cseservicemanager.co.uk </a></p><p></p><p>You will be asked to log into this system, please use your schools email address and password to login and view this report.</p><p><br></p><p>Kind Regards,</p><p>CSE Education Systems</p>` //eslint-disable-line no-useless-escape
    );
    const [sites, setSites] = useState<SitesType[]>();
    const [curSiteID, setCurSiteID] = useState<number>(0);
    const [curSite, setCurSite] = useState<number>(0);
    useEffect(() => {
        //useEffect to only run once on first render, function because async/await
        const getSites = async () => {
            send({
                method: "GET",
                url: `/api/reporting/getcustomeroutemails/${props.customer.CustID}`,
                data: "",
                params: "",
                token: await getAccessToken()
            }).then((res: any) => {
                setSites(res.data as SitesType[]);
            });
        };
        if (props.customer.CustID !== undefined) {
            getSites();
        }
    }, [props.customer]);
    if (sites === undefined) {
        return (
            <div
                style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    marginTop: "10rem"
                }}
            >
                <Spinner size={250} />
            </div>
        );
    }
    //curSite == Index of current site
    //curSiteID == ID of current site (not the same as curSite)
    if (parseInt(watch("schedule_data.outgoingemailid", sites?.[0].id)) !== curSiteID) {
        sites?.forEach((site, index) => {
            if (site.id === parseInt(watch("schedule_data.outgoingemailid"))) {
                setCurSite(index);
                setCurSiteID(site.id);
            }
        });
    }
    const postSchedule = async (jsonData: ScheduleType) => {
        send({
            method: props.curSchedule ? "PUT" : "POST",
            url: `/api/reporting/createschedule`,
            data: jsonData,
            params: "",
            token: await getAccessToken()
        }).then((res: any) => {
            if (props.curSchedule) {
                props.pushSchedule(res.data as ScheduleType, props.id, true);
            } else {
                props.pushSchedule(res.data as ScheduleType, props.id);
            }
            props.close();
        })
    }
    return (
        <>
            <Button
                primary
                onClick={() => {
                    props.close();
                }}
            >
                Close
            </Button>
            <form
                onSubmit={handleSubmit((data) => {
                    postSchedule(data as ScheduleType);
                })}
            >
                <div className={styles.flexCol}>
                    <h1>{props.curSchedule ? tr("SCHEDULE_EDIT_TITLE") : tr("SCHEDULE_CREATE")}</h1>
                    <div className={`${styles.flexRow} ${styles.topItems}`}>
                        <div style={{ maxWidth: "100%" }}>
                            <Row className={styles.headerRow}>
                                <Col>
                                    <span className={styles.spanHeading}>{tr("EMAIL_FROM")}: </span>
                                </Col>
                                <Col>
                                    <select
                                        {...register("schedule_data.outgoingemailid", {
                                            required: {
                                                value: true,
                                                message: "From field required"
                                            }
                                        })}
                                        placeholder="From *"
                                        className={styles.formTextInput}
                                        defaultValue={props.curSchedule ? props.curSchedule.schedule_data.outgoingemailid.toString() : sites?.[0].id}
                                    >
                                        {sites &&
                                            sites?.map((site) => {
                                                return (
                                                    <option value={site.id} key={site.id}>
                                                        {site.name}
                                                    </option>
                                                );
                                            })}
                                    </select>
                                    <input className={styles.formTextInput} value={sites?.[curSite].from || ""} disabled />
                                    <ErrorMessage errors={errors} name="schedule_data.outgoingemailid" as="p" />
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <p className={styles.emailAddrHeading}>{tr("EMAIL_ADDITIONAL_WARNING")}</p>
                                </Col>
                            </Row>
                            <Row className={styles.headerRow}>
                                <Col>
                                    <span className={styles.spanHeading}>{tr("EMAIL_TO")}: </span>
                                </Col>
                                <Col>
                                    <input
                                        {...register("_id", {
                                        })}
                                        autoComplete="off" hidden
                                        defaultValue={props.curSchedule ? props.curSchedule._id as string : ""}
                                    />
                                    <input
                                        {...register("schedule_data.to", {
                                            required: {
                                                value: true,
                                                message: "To field required"
                                            }
                                        })}
                                        placeholder="To *"
                                        autoComplete="off"
                                        className={styles.formTextInput}
                                        defaultValue={props.curSchedule ? props.curSchedule.schedule_data.to.toString() : ""}
                                    />
                                    <ErrorMessage errors={errors} name="schedule_data.to" as="p" />
                                </Col>
                            </Row>
                            <Row className={styles.headerRow}>
                                <Col>
                                    <span className={styles.spanHeading}>{tr("EMAIL_CC")}: </span>
                                </Col>
                                <Col>
                                    <input {...register("schedule_data.cc")} placeholder="Cc" autoComplete="off" className={styles.formTextInput} defaultValue={props.curSchedule ? props.curSchedule.schedule_data.cc : ""} />
                                    <ErrorMessage errors={errors} name="schedule_data.cc" as="p" />
                                </Col>
                            </Row>
                            <Row className={styles.headerRow}>
                                <Col>
                                    <span className={styles.spanHeading}>{tr("EMAIL_SUBJECT")}: </span>
                                </Col>
                                <Col>
                                    <input
                                        {...register("subject", {
                                            required: {
                                                value: true,
                                                message: "Subject field required"
                                            }
                                        })}
                                        placeholder="Subject *"
                                        className={styles.formTextInput}
                                        defaultValue={props.curSchedule ? props.curSchedule.subject : `${props.customer.Name} Weekly Report`}
                                        autoComplete="off"
                                    />
                                    <ErrorMessage errors={errors} name="subject" as="p" />
                                </Col>
                            </Row>
                        </div>
                    </div>
                    <div id="quillDiv"><ReactQuill theme={"snow"} value={body} onChange={setBody} /></div>
                    <input {...register("customer_id")} defaultValue={props.curSchedule ? props.curSchedule.customer_id : props.customer.CustID} style={{ display: "none" }} />
                </div>
                <div className={styles.scheduleExtra}>
                    <Row className={`${styles.headerRow} ${styles.scheduleExtraRow}`}>
                        <span className={`${styles.spanHeading}`} style={{ minWidth: "max-content", marginRight: "5px" }}>{tr("SCHEDULE_SEND_REPORTS_TYPE")}: </span>
                        <select
                            {...register("schedule_data.repeat", {
                                required: {
                                    value: true,
                                    message: "Repeat field required"
                                }
                            })}
                            className={`${styles.scheduleExtraTextInput} ${styles.scheduleExtraWidthFix}`}
                            style={{ minWidth: "250px", marginTop: "5px", marginBottom: "5px" }}
                            defaultValue={props.curSchedule ? props.curSchedule.schedule_data.repeat : ""}
                        >
                            <option value="Weekly">Weekly</option>
                            <option value="Bi Weekly">Bi Weekly</option>
                            <option value="Monthly">Monthly</option>
                        </select>
                        <ErrorMessage errors={errors} name="schedule_data.repeat" as="p" />
                    </Row>
                    <Row center style={{ marginTop: "5px", marginBottom: "5px" }}>
                        <span className={`${styles.spanHeading}`} style={{ minWidth: "max-content", marginRight: "5px" }}>{tr('SCHEDULE_STARTING_AT')}:</span>
                        <select
                            {...register("schedule_data.repeatDay", {
                                valueAsNumber: true,
                                required: {
                                    value: true,
                                    message: "Repeat Day required"
                                }
                            })}
                            className={`${styles.scheduleExtraTextInput} ${styles.scheduleExtraWidthFix}`}
                            defaultValue={props.curSchedule ? props.curSchedule.schedule_data.repeatDay.toString() : ""}
                        >
                            <option value={DayOfWeek.Monday}>Monday</option>
                            <option value={DayOfWeek.Tuesday}>Tuesday</option>
                            <option value={DayOfWeek.Wednesday}>Wednesday</option>
                            <option value={DayOfWeek.Thursday}>Thursday</option>
                            <option value={DayOfWeek.Friday}>Friday</option>
                            <option value={DayOfWeek.Saturday}>Saturday</option>
                            <option value={DayOfWeek.Sunday}>Sunday</option>
                        </select>
                        <ErrorMessage errors={errors} name="schedule_data.repeatDay" as="p" />
                        <select
                            {...register("schedule_data.repeatTime", {
                                required: {
                                    value: true,
                                    message: "Repeat Time required"
                                }
                            })}
                            className={`${styles.scheduleExtraTextInput} ${styles.scheduleExtraWidthFix}`}
                            defaultValue={props.curSchedule ? props.curSchedule.schedule_data.repeatTime.toString() : ""}
                        >
                            {times.map((time, index) => {
                                return <option value={time} key={`dateOption ${index}`}>{time}</option>
                            })}
                        </select>
                        <ErrorMessage errors={errors} name="schedule_data.repeatTime" as="p" />
                    </Row>
                    <Row center>
                        <Button type="submit" primary>
                            {props.curSchedule ? "Update" : "Submit"}
                        </Button>
                    </Row>
                </div>
            </form>
        </>
    );
};

export default Schedule;
