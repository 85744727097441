import React from "react";
import styles from "./changelog.module.css";
import { version } from "../../../../package.json";

//Version number system
//2023.1.0.00
// split(".")[0] - Current Year
// split(".")[1] - Year quarter - q1, q2, q3 or q4
// split(".")[2] - Major version
// split(".")[3] - Revision

const LatestVersion = () => {
    return version;
};

// const updateTemplate = () => {
//     return (<div id="update-template">
//         <h2 className={styles['update-version-num']}>xx<sup>th</sup> January 20XX - 2023.1.0.1</h2>
//         <ul>
//             <li>Item 1</li>
//             <li>Item 2</li>
//             <li>Item 3</li>
//         </ul>
//         <h3 className={styles['update-title']}>New Features</h3>
//         <ul>
//             <li className={styles['update-text']}><span className={styles['update-text-bold']}>Feature. </span>Details about the new feature.</li>
//         </ul>
//         <h3 className={styles['update-title']}>Improvements</h3>
//         <ul>
//             <li className={styles['update-text']}><span className={styles['update-text-bold']}>Improvement. </span>Details about the improvement.</li>
//         </ul>
//         <h3 className={styles['update-title']}>Fixes</h3>
//         <ul>
//             <li className={styles['update-text']}><span className={styles['update-text-bold']}>Fix. </span>Details about the bug.</li>
//         </ul>
//         <h3 className={styles['update-title']}>Coming Soon</h3>
//         <ul>
//             <li className={styles['update-text']}><span className={styles['update-text-bold']}>Feature. </span>Details about the future feature.</li>
//         </ul>
//     </div>);
// }
const v23_7 = () => {
    return (
        <div id="update-template">
            <h2 className={styles["update-version-num"]}>
                9<sup>th</sup> June 2023 - 2023.1.0.7
            </h2>
            <h3 className={styles["update-title"]}>New Features</h3>
            <ul>
                <li className={styles["update-text"]}>
                    Moved Service Desk Created Requests card to the default layout. Added a cards details page with per-site sorting.
                </li>
                <li className={styles["update-text"]}>
                    Moved Service Desk Closed Requests card to the default layout. Added a cards details page with per-site sorting.
                </li>
                <li className={styles["update-text"]}>Moved Server Installed Windows Updates card to the default layout.</li>
            </ul>
            <h3 className={styles["update-title"]}>Improvements</h3>
            <ul>
                <li className={styles["update-text"]}>
                    Windows 11 Readiness and Notebook Battery Health cards have been optimised and should load faster.
                </li>
            </ul>
            <h3 className={styles["update-title"]}>Fixes</h3>
            <ul>
                <li className={styles["update-text"]}>Other minor bug fixes.</li>
            </ul>
        </div>
    );
};
const v23_6 = () => {
    return (
        <div id="update-template">
            <h2 className={styles["update-version-num"]}>
                12<sup>th</sup> May 2023 - 2023.1.0.6
            </h2>
            <h3 className={styles["update-title"]}>New Features</h3>
            <ul>
                <li className={styles["update-text"]}>Added Service Desk Created Requests Cards. Currently accessible in the Preview layout.</li>
                <li className={styles["update-text"]}>Added Service Desk Closed Requests Cards. Currently accessible in the Preview layout.</li>
            </ul>
            <h3 className={styles["update-title"]}>Fixes</h3>
            <ul>
                <li className={styles["update-text"]}>Fixed Service Desk Stats Site drop-down.</li>
                <li className={styles["update-text"]}>Other minor changes.</li>
            </ul>
        </div>
    );
};
const v23_5 = () => {
    return (
        <div id="update-template">
            <h2 className={styles["update-version-num"]}>
                18<sup>th</sup> April 2023 - 2023.1.0.5
            </h2>
            <h3 className={styles["update-title"]}>New Features</h3>
            <ul>
                <li className={styles["update-text"]}>Added Comments, this includes card specific comments and overall comments.</li>
            </ul>
            <h3 className={styles["update-title"]}>Improvements</h3>
            <ul>
                <li className={styles["update-text"]}>
                    Fixed Server Windows Updates (now Server Pending Windows Updates) card and details results to show more up-to-date data.
                </li>
            </ul>
            <h3 className={styles["update-title"]}>Fixes</h3>
            <ul>
                <li className={styles["update-text"]}>Minor Changes.</li>
            </ul>
        </div>
    );
};
const v23_4 = () => {
    return (
        <div id="update-template">
            <h2 className={styles["update-version-num"]}>
                20<sup>th</sup> February 2023 - 2023.1.0.4
            </h2>
            <h3 className={styles["update-title"]}>Fixes</h3>
            <ul>
                <li className={styles["update-text"]}>Fixed no customer name displayed on the top left of the report.</li>
                <li className={styles["update-text"]}>Fixed Notebook Battery Health card details returning zero results.</li>
                <li className={styles["update-text"]}>Added text wrapping for several card details pages.</li>
                <li className={styles["update-text"]}>Fixed hidden dashboard cards when loading a dashboard from different customers.</li>
                <li className={styles["update-text"]}>Removed duplicated Service Desk user activity from Service Desk Stats.</li>
            </ul>
        </div>
    );
};
const v23_3 = () => {
    return (
        <div id="update-template">
            <h2 className={styles["update-version-num"]}>
                14<sup>th</sup> February 2023 - 2023.1.0.3
            </h2>
            <h3 className={styles["update-title"]}>Improvements</h3>
            <ul>
                <li className={styles["update-text"]}>Added Network Switches Status card.</li>
                <li className={styles["update-text"]}>Added Wireless Access Points Status.</li>
                <li className={styles["update-text"]}>Added device model to existing Windows 11 Readiness card details results.</li>
                <li className={styles["update-text"]}>Added device model table to Windows 11 Readiness card details.</li>
                <li className={styles["update-text"]}>Added filter by site and server name search to Server Windows Updates card details.</li>
            </ul>
            <h3 className={styles["update-title"]}>Fixes</h3>
            <ul>
                <li className={styles["update-text"]}>Removed devices waiting to be onboarded from Reporting data.</li>
                <li className={styles["update-text"]}>Manufacturer details are now correctly displayed inside the Station List.</li>
                <li className={styles["update-text"]}>Fixed incorrect date on Service Desk Stats card.</li>
                <li className={styles["update-text"]}>Station List client version now correctly highlights the latest version.</li>
                <li className={styles["update-text"]}>Server Online Status card and details page now correctly state devices status.</li>
            </ul>
        </div>
    );
};
const v23_2 = () => {
    return (
        <div id="update-template">
            <h2 className={styles["update-version-num"]}>
                11<sup>th</sup> January 2023 - 2023.1.0.2
            </h2>
            <h3 className={styles["update-title"]}>Improvements</h3>
            <ul>
                <li className={styles["update-text"]}>Minor bug fixes.</li>
            </ul>
        </div>
    );
};
const v23_1 = () => {
    return (
        <div id="update-template">
            <h2 className={styles["update-version-num"]}>
                9<sup>th</sup> January 2023 - 2023.1.0.1
            </h2>
            <h3 className={styles["update-title"]}>Improvements</h3>
            <ul>
                <li className={styles["update-text"]}>Network Device Status card moved to Default Layout.</li>
            </ul>
            <h3 className={styles["update-title"]}>Fixes</h3>
            <ul>
                <li className={styles["update-text"]}>Various bug fixes.</li>
            </ul>
        </div>
    );
};
const v22_13 = () => {
    return (
        <div id="update-template">
            <h2 className={styles["update-version-num"]}>
                11<sup>th</sup> November 2022 - 2022.1.0.13
            </h2>
            <h3 className={styles["update-title"]}>New Features</h3>
            <ul>
                <li className={styles["update-text"]}>
                    Marquee Mode added to the settings. Marquee Mode disables the left navigation menu and automatically refreshes the dashboard.
                </li>
            </ul>
            <h3 className={styles["update-title"]}>Improvements</h3>
            <ul>
                <li className={styles["update-text"]}>Various Network Device Status card improvements.</li>
            </ul>
            <h3 className={styles["update-title"]}>Fixes</h3>
            <ul>
                <li className={styles["update-text"]}>Various bug fixes.</li>
            </ul>
        </div>
    );
};
const v22_12 = () => {
    return (
        <div id="update-template">
            <h2 className={styles["update-version-num"]}>
                26<sup>th</sup> October 2022 - 2022.1.0.12
            </h2>
            <h3 className={styles["update-title"]}>Improvements</h3>
            <ul>
                <li className={styles["update-text"]}>Added report scheduling.</li>
                <li className={styles["update-text"]}>Added Activity Per User card to preview layout.</li>
                <li className={styles["update-text"]}>Added settings button to force clear browser cache and load latest Reporting site.</li>
                <li className={styles["update-text"]}>Added "/forcerefresh" URL to force clear browser cache and load latest Reporting site.</li>
            </ul>
            <h3 className={styles["update-title"]}>Fixes</h3>
            <ul>
                <li className={styles["update-text"]}>Network Device Status card details now correctly display servers as online.</li>
                <li className={styles["update-text"]}>Fixed incorrect Reporting version being loaded from browser cache.</li>
            </ul>
        </div>
    );
};
const v22_11 = () => {
    return (
        <div id="update-template">
            <h2 className={styles["update-version-num"]}>
                24<sup>th</sup> October 2022 - 2022.1.0.11
            </h2>
            <h3 className={styles["update-title"]}>Improvements</h3>
            <ul>
                <li className={styles["update-text"]}>
                    Added Preview dashboard layout. This layout currently adds the new Network Device Status card.
                </li>
            </ul>
            <h3 className={styles["update-title"]}>Fixes</h3>
            <ul>
                <li className={styles["update-text"]}>Fixed settings menu error.</li>
                <li className={styles["update-text"]}>Re-added update pop-up.</li>
            </ul>
        </div>
    );
};
const v22_10 = () => {
    return (
        <div id="update-template">
            <h2 className={styles["update-version-num"]}>
                10<sup>th</sup> October 2022 - 2022.1.0.10
            </h2>
            <h3 className={styles["update-title"]}>New Features</h3>
            <ul>
                <li className={styles["update-text"]}>Custom dashboard layouts are now saved externally.</li>
            </ul>
            <h3 className={styles["update-title"]}>Improvements</h3>
            <ul>
                <li className={styles["update-text"]}>Service Desk Stats now displays requests reopened in the given time frame.</li>
            </ul>
            <h3 className={styles["update-title"]}>Fixes</h3>
            <ul>
                <li className={styles["update-text"]}>Fixed Notebook Battery Health card details sorting.</li>
                <li className={styles["update-text"]}>Fixed incorrect initial value for Notebook Battery Health card.</li>
                <li className={styles["update-text"]}>Reporting site should now correctly load new versions on Chromium based browsers.</li>
            </ul>
        </div>
    );
};
const v22_9 = () => {
    return (
        <div id="update-template">
            <h2 className={styles["update-version-num"]}>
                04<sup>th</sup> October 2022 - 2022.1.0.9
            </h2>
            <h3 className={styles["update-title"]}>Improvements</h3>
            <ul>
                <li className={styles["update-text"]}>Screenshots are now renamed to include part of the customer name.</li>
                <li className={styles["update-text"]}>Notebook Battery Health now displays scanned machines with no batteries.</li>
            </ul>
            <h3 className={styles["update-title"]}>Fixes</h3>
            <ul>
                <li className={styles["update-text"]}>Minor Fixes.</li>
            </ul>
        </div>
    );
};
const v22_8 = () => {
    return (
        <div id="update-template">
            <h2 className={styles["update-version-num"]}>
                27<sup>th</sup> September 2022 - 2022.1.0.8
            </h2>
            <h3 className={styles["update-title"]}>Fixes</h3>
            <ul>
                <li className={styles["update-text"]}>Windows 11 Readiness & Notebook Battery Health Card Details pages are now correctly sorted.</li>
                <li className={styles["update-text"]}>Fixed CSE Remote Agent Alerts card details page returned data.</li>
                <li className={styles["update-text"]}>Volume Storage data is accurate and consistent across the card and card details page.</li>
            </ul>
        </div>
    );
};
const v22_7 = () => {
    return (
        <div id="update-template">
            <h2 className={styles["update-version-num"]}>
                20<sup>th</sup> September 2022 - 2022.1.0.7
            </h2>
            <h3 className={styles["update-title"]}>Fixes</h3>
            <ul>
                <li className={styles["update-text"]}>Fixed Windows 11 Readiness & Notebook Battery Health help pages.</li>
                <li className={styles["update-text"]}>Fixed Service Desk Site Performance overflow related problems.</li>
            </ul>
        </div>
    );
};
const v22_6 = () => {
    return (
        <div id="update-template">
            <h2 className={styles["update-version-num"]}>
                15<sup>th</sup> September 2022 - 2022.1.0.6
            </h2>
            <h3 className={styles["update-title"]}>Improvements</h3>
            <ul>
                <li className={styles["update-text"]}>Added help pages for the new cards.</li>
            </ul>
            <h3 className={styles["update-title"]}>Fixes</h3>
            <ul>
                <li className={styles["update-text"]}>Fixed custom settings panel.</li>
                <li className={styles["update-text"]}>Changed Service Desk Trends card details title.</li>
            </ul>
        </div>
    );
};
const v22_5 = () => {
    return (
        <div id="update-template">
            <h2 className={styles["update-version-num"]}>
                13<sup>th</sup> September 2022 - 2022.1.0.5
            </h2>
            <h3 className={styles["update-title"]}>New Features</h3>
            <ul>
                <li className={styles["update-text"]}>Added Windows 11 Readiness Card.</li>
                <li className={styles["update-text"]}>Added Notebook Battery Health Card.</li>
                <li className={styles["update-text"]}>Added Server Restart Required Card.</li>
                <li className={styles["update-text"]}>Added Service Desk Trends Card.</li>
                <li className={styles["update-text"]}>
                    Added Service Desk Site Performance Card. This card is only displayed if multiple sites are used.
                </li>
            </ul>
            <h3 className={styles["update-title"]}>Improvements</h3>
            <ul>
                <li className={styles["update-text"]}>
                    Replaced the time frame selector with a date picker. Change the time frame with the button located on the top right of the
                    dashboard.
                </li>
                <li className={styles["update-text"]}>
                    The "Stations Last Booted" Device List now shows if the CSE Client Version installed is up-to-date.
                </li>
                <li className={styles["update-text"]}>Added a host name search for pop-up pages.</li>
                <li className={styles["update-text"]}>Added dashboard layout import and export. Requires a "Custom" layout.</li>
                <li className={styles["update-text"]}>Added a device filter for the following pop-up pages:</li>
                <ul>
                    <li>Server Volume Storage</li>
                    <li>Server Login Information</li>
                    <li>Backups</li>
                    <li>Unexpected Server Shutdowns</li>
                    <li>CSE Remote Agent Alerts</li>
                    <li>Station Volume Storage</li>
                    <li>Station Login Information</li>
                </ul>
            </ul>
            <h3 className={styles["update-title"]}>Fixes</h3>
            <ul>
                <li className={styles["update-text"]}>Fixed "black screen of death" when switching between customers.</li>
                <li className={styles["update-text"]}>Moved loading spinner to top right of dashboard cards for visibility with the dark theme.</li>
                <li className={styles["update-text"]}>Custom dashboard layouts will now load correctly.</li>
                <li className={styles["update-text"]}>Increased the size of the cards pop-up loading spinner.</li>
            </ul>
        </div>
    );
};

const v22_4 = () => {
    return (
        <div id="update-template">
            <h2 className={styles["update-version-num"]}>
                6<sup>th</sup> June 2022 - 2022.1.0.4
            </h2>
            <h3 className={styles["update-title"]}>Improvements</h3>
            <ul>
                <li className={styles["update-text"]}>
                    The last customer accessed is now saved locally, refreshing the page now loads the last customer accessed.
                </li>
                <li className={styles["update-text"]}>Added an unread badge for the changelog.</li>
            </ul>
            <h3 className={styles["update-title"]}>Fixes</h3>
            <ul>
                <li className={styles["update-text"]}>Minor changes to the mobile layout.</li>
            </ul>
        </div>
    );
};

const v22_3 = () => {
    return (
        <div id="update-template">
            <h2 className={styles["update-version-num"]}>
                19<sup>th</sup> May 2022 - 2022.1.0.3
            </h2>
            <h3 className={styles["update-title"]}>Improvements</h3>
            <ul>
                <li className={styles["update-text"]}>Added "Toggle Theme" and "Open Release Notes" buttons to Settings menu.</li>
            </ul>
            <h3 className={styles["update-title"]}>Fixes</h3>
            <ul>
                <li className={styles["update-text"]}>Removed forced refresh that would occur after the dashboard finished loading all cards.</li>
                <li className={styles["update-text"]}>Changed Station Threat Protection's text colours to match event severity.</li>
                <li className={styles["update-text"]}>Fixed cards displaying incorrect data after changing requested time frame.</li>
                <li className={styles["update-text"]}>Cards containing graphs now update colours based on the current theme.</li>
            </ul>
        </div>
    );
};

const v22_2 = () => {
    return (
        <div id="update-template">
            <h2 className={styles["update-version-num"]}>
                07<sup>th</sup> April 2022 - 2022.1.0.2
            </h2>
            <h3 className={styles["update-title"]}>New Features</h3>
            <ul>
                <li className={styles["update-text"]}>
                    Added new Dashboard Cards.
                    <ul>
                        <li>&quot;Network Device Status&quot; shows the ping status of network work devices.</li>
                        <li>&quot;Laptop Battery Health&quot; displays the battery health of laptops.</li>
                        <li>&quot;Station Windows 11 Readiness&quot; details which stations are ready for the Windows 11 Upgrade.</li>
                    </ul>
                </li>
                <li className={styles["update-text"]}>Added "Take Screenshot" button. Downloads result as .png.</li>
                <li className={styles["update-text"]}>Added Layout Editor in Dashboard Settings.</li>
                <li className={styles["update-text"]}>
                    Added Card Editor in Dashboard Settings. Changes made to the dashboard are currently changed locally.
                </li>
            </ul>
            <h3 className={styles["update-title"]}>Improvements</h3>
            <ul>
                <li className={styles["update-text"]}>The changelog can now display multiple pages of changes.</li>
                <li className={styles["update-text"]}>Pop-up menus now display a loading spinner.</li>
                <li className={styles["update-text"]}>Pie charts now display a chart legend.</li>
            </ul>
            <h3 className={styles["update-title"]}>Fixes</h3>
            <ul>
                <li className={styles["update-text"]}>Fixed "jumping" UI components.</li>
                <li className={styles["update-text"]}>Changed changelog icon.</li>
                <li className={styles["update-text"]}>Pop-up menus can be correctly sorted when true or false values are displayed.</li>
                <li className={styles["update-text"]}>Fixed hidden text when using the Classic dashboard layout.</li>
            </ul>
            <h3 className={styles["update-title"]}>Coming Soon</h3>
            <ul>
                <li className={styles["update-text"]}>Remote saving and loading of dashboard layouts and card types.</li>
                <li className={styles["update-text"]}>More applications tracked and displayed under Application Usage.</li>
            </ul>
        </div>
    );
};

const v22_1 = () => {
    return (
        <div id="update-template">
            <h2 className={styles["update-version-num"]}>
                30<sup>th</sup> March 2022 - 2022.1.0.1
            </h2>
            <h3 className={styles["update-title"]}>New Features</h3>
            <ul>
                <li className={styles["update-text"]}>Initial release.</li>
            </ul>
            <h3 className={styles["update-title"]}>Coming Soon</h3>
            <ul>
                <li className={styles["update-text"]}>Help page.</li>
            </ul>
        </div>
    );
};

export {
    v22_1,
    v22_2,
    v22_3,
    v22_4,
    v22_5,
    v22_6,
    v22_7,
    v22_8,
    v22_9,
    v22_10,
    v22_11,
    v22_12,
    v22_13,
    v23_1,
    v23_2,
    v23_3,
    v23_4,
    v23_5,
    v23_6,
    v23_7,
    LatestVersion
};
