import * as React from "react";
import userManager from "../../../util/userManager";
import { send } from "../../../helpers/requests";
import DataTable from "react-data-table-component";
import { tr } from "../../../helpers/languages";
import { Input, Label } from "reactstrap";
import dayjs from "dayjs";
import { CSVLink } from "react-csv";
import { Spinner } from "../../common/ui";

import styles from "./popout.module.css";

export default class VolumePopout extends React.PureComponent {
    _isMounted = false;
    constructor(props) {
        super(props);
        this.state = {
            getDone: false,
            volumesOriginal: [],
            curSite: "All",
            search: ""
        };
    }
    componentDidMount() {
        this._isMounted = true;
        this.getVolumes(this.props.customerID);
    }
    componentWillUnmount() {
        this._isMounted = false;
    }
    //API Calls
    getAccessToken = async () => {
        let token = (await userManager.getUser()).access_token;
        return token;
    };
    getVolumes = async (custID) => {
        send({
            method: "GET",
            url: `/api/reporting/getvolumes?custid=${custID}&servers=${this.props.serversBool}`,
            token: await this.getAccessToken()
        }).then((response) => {
            if (this._isMounted) {
                this.props.popoutRendered();
                this.setState({
                    getDone: true,
                    volumesOriginal: response.data
                });
            }
        });
    };
    //Other
    columns = [
        {
            name: this.props.serversBool ? "Server" : "Station",
            selector: (row) => row.server,
            sortable: true,
            reorder: true
        },
        {
            name: "Volume",
            selector: (row) => row.volume,
            sortable: true,
            reorder: true
        },
        {
            name: "Disk Size",
            selector: (row) => row.diskSize,
            sortable: true,
            reorder: true
        },
        {
            name: "Used Size",
            selector: (row) => row.usedSize,
            sortable: true,
            reorder: true
        },
        {
            name: "Free Size",
            selector: (row) => row.freeSize,
            sortable: true,
            reorder: true
        },
        {
            name: "Percentage Used",
            selector: (row) => {
                if (row.percentage === "%") {
                    return "0%";
                }
                if (parseFloat(row.percentage) >= 100) {
                    return "100%";
                }
                return row.percentage;
            },
            sortable: true,
            reorder: true
        }
    ];
    ChangeSite = (newSite) => {
        this.setState({ curSite: newSite.target.value });
    };
    CreateFinalStorageList = () => {
        var finalStorageList = [];
        if (this.state.search === "") {
            if (this.state.curSite !== "All") {
                //If site filter used
                this.state.volumesOriginal.forEach((volume) => {
                    if (volume.site_name === this.state.curSite) {
                        finalStorageList.push(volume);
                    }
                });
            } else {
                finalStorageList = JSON.parse(JSON.stringify(this.state.volumesOriginal));
            }
        } else {
            if (this.state.curSite !== "All") {
                this.state.volumesOriginal.forEach((volume) => {
                    if (volume.site_name === this.state.curSite) {
                        if (volume.server.toLowerCase().includes(this.state.search)) {
                            finalStorageList.push(volume);
                        }
                    }
                });
            } else {
                this.state.volumesOriginal.forEach((volume) => {
                    if (volume.server.toLowerCase().includes(this.state.search)) {
                        finalStorageList.push(volume);
                    }
                });
            }
        }
        return finalStorageList;
    };
    CreateSiteList = () => {
        var uniqueSites = [];
        this.state.volumesOriginal.forEach((volume) => {
            if (!uniqueSites.includes(volume.site_name)) {
                uniqueSites.push(volume.site_name);
            }
        });
        return uniqueSites.sort();
    };
    render() {
        var uniqueSites = this.CreateSiteList();
        var finalStorageList = this.CreateFinalStorageList();
        if (!this.state.getDone) {
            return (
                <div
                    style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        marginTop: "10rem"
                    }}
                >
                    <Spinner size="250" />
                </div>
            );
        }
        return (
            <div className={styles.popoutContainer}>
                <div>
                    <h2 className={styles.popoutHeader}>{tr("DASHBOARD_VOL_STOR_POPOUT_TITLE")}</h2>
                </div>
                <div className={styles.popoutSearch}>
                    <div className="form-floating-labels">
                        <Input
                            id="Search"
                            placeholder=" "
                            value={this.state.search || ""}
                            onChange={(v) => {
                                this.setState({
                                    search: v.target.value.toLowerCase()
                                });
                            }}
                        />
                        <Label for="Search">{tr("DASHBOARD_SEARCH_POPOUT_HOSTNAMES")}</Label>
                    </div>
                </div>
                <div className={styles.popoutExport}>
                    <div className="perm-floating-label">
                        <Input className={styles.popoutInput} type="select" id="volumeSiteSelect" onChange={this.ChangeSite}>
                            <option value="All">{tr("ALL")}</option>
                            {uniqueSites.map((site, key) => {
                                return (
                                    <option key={key} value={site}>
                                        {site}
                                    </option>
                                );
                            })}
                        </Input>
                        <Label for="volumeSiteSelect">{tr("DASHBOARD_SITE_SELECT")}</Label>
                    </div>
                    <CSVLink
                        className={styles.exportCSVButton}
                        data={finalStorageList}
                        filename={"export " + dayjs().format("DD-MM-YYYY h-mm-ss") + ".csv"}
                    >
                        {tr("EXPORT_CSV")}
                    </CSVLink>
                </div>
                <div className={styles.popoutDataTable}>
                    <DataTable columns={this.columns} data={finalStorageList} theme={this.props.theme} pagination />
                </div>
            </div>
        );
    }
}
