import * as React from "react";
import userManager from "../../../util/userManager";
import { send } from "../../../helpers/requests";
import DataTable from "react-data-table-component";
import { tr } from "../../../helpers/languages";
import { Input, Label } from "reactstrap";
import dayjs from "dayjs";
import plugin from "dayjs/plugin/advancedFormat";
import { CSVLink } from "react-csv";
import { Spinner } from "../../common/ui";

import styles from "./popout.module.css";

dayjs.extend(plugin);

export default class AlertServiceRequestsPopout extends React.PureComponent {
    _isMounted = false;
    constructor(props) {
        super(props);
        this.state = {
            getDone: false,
            requestsOriginal: [],
            curSite: "All",
            search: ""
        };
    }
    componentDidMount() {
        this._isMounted = true;
        this.getCSERemoteAgentAlerts(this.props.customerID, this.props.startDate, this.props.endDate);
    }
    componentWillUnmount() {
        this._isMounted = false;
    }
    //API Calls
    /**
     * getAccessToken
     * @description Gets the access token of the authenticated user
     */
    getAccessToken = async () => {
        let token = (await userManager.getUser()).access_token;
        return token;
    };
    getCSERemoteAgentAlerts = async (custID, startDate, endDate) => {
        const timeZoneOffset = startDate.getTimezoneOffset() * 60;
        const newStartDate = Math.floor(startDate.getTime() / 1000) - timeZoneOffset;
        const newEndDate = Math.floor(endDate.getTime() / 1000) - timeZoneOffset;
        send({
            method: "GET",
            url: `/api/reporting/getcseremoteagentalerts?custid=${custID}&startdate=${newStartDate}&enddate=${newEndDate}`,
            token: await this.getAccessToken()
        }).then((response) => {
            if (this._isMounted) {
                this.props.popoutRendered();
                this.setState({
                    getDone: true,
                    requestsOriginal: response.data
                });
            }
        });
    };
    //Other
    columns = [
        {
            name: "Service Request ID",
            selector: (row) => row.service_request_id,
            sortable: true,
            reorder: true
        },
        {
            name: "Server",
            selector: (row) => row.server,
            sortable: true,
            reorder: true
        },
        {
            name: "Category",
            selector: (row) => row.category,
            sortable: true,
            reorder: true
        },
        {
            name: "Message",
            selector: (row) => row.message,
            sortable: true,
            wrap: true,
            reorder: true,
            grow: 3
        },
        {
            name: "Date",
            selector: (row) => dayjs(row.alert_recieved).format("MMMM Do YYYY, h:mm:ss a"),
            sortable: true,
            wrap: true,
            reorder: true
        }
    ];
    ChangeSite = (newSite) => {
        this.setState({ curSite: newSite.target.value });
    };
    CreateFinalServiceRequestList = () => {
        var finalRequestList = [];
        if (this.state.search === "") {
            if (this.state.curSite !== "All") {
                //If site filter used
                this.state.requestsOriginal.forEach((request) => {
                    if (request.site === this.state.curSite) {
                        finalRequestList.push(request);
                    }
                });
            } else {
                finalRequestList = JSON.parse(JSON.stringify(this.state.requestsOriginal));
            }
        } else {
            if (this.state.curSite !== "All") {
                this.state.requestsOriginal.forEach((request) => {
                    if (request.site === this.state.curSite) {
                        if (request.server.toLowerCase().includes(this.state.search)) {
                            finalRequestList.push(request);
                        }
                    }
                });
            } else {
                this.state.requestsOriginal.forEach((request) => {
                    if (request.server.toLowerCase().includes(this.state.search)) {
                        finalRequestList.push(request);
                    }
                });
            }
        }
        return finalRequestList;
    };
    CreateSiteList = () => {
        var uniqueSites = [];
        this.state.requestsOriginal.forEach((request) => {
            if (!uniqueSites.includes(request.site)) {
                uniqueSites.push(request.site);
            }
        });
        return uniqueSites.sort();
    };
    render() {
        var uniqueSites = this.CreateSiteList();
        var finalRequestList = this.CreateFinalServiceRequestList();
        var requestsResolved = [];
        var requestsNotResolved = [];
        if (finalRequestList.length > 0) {
            finalRequestList.forEach((request) => {
                if (request.resolved) {
                    requestsResolved.push(request);
                } else {
                    requestsNotResolved.push(request);
                }
            });
        }
        if (!this.state.getDone) {
            return (
                <div
                    style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        marginTop: "10rem"
                    }}
                >
                    <Spinner size="250" />
                </div>
            );
        }
        return (
            <div className={styles.popoutContainer}>
                <div>
                    <h2 className={styles.popoutHeader}>{tr("DASHBOARD_CSE_REMAGENT_TITLE")}</h2>
                </div>
                <div className={styles.popoutSearch}>
                    <div className="form-floating-labels">
                        <Input
                            id="Search"
                            placeholder=" "
                            value={this.state.search || ""}
                            onChange={(v) => {
                                this.setState({
                                    search: v.target.value.toLowerCase()
                                });
                            }}
                        />
                        <Label for="Search">{tr("DASHBOARD_SEARCH_POPOUT_HOSTNAMES")}</Label>
                    </div>
                </div>
                <div className={styles.popoutExport}>
                    <div className="perm-floating-label">
                        <Input className={styles.popoutInput} type="select" id="alertServiceSiteSelect" onChange={this.ChangeSite}>
                            <option value="All">{tr("ALL")}</option>
                            {uniqueSites.map((site, key) => {
                                return (
                                    <option key={key} value={site}>
                                        {site}
                                    </option>
                                );
                            })}
                        </Input>
                        <Label for="alertServiceSiteSelect">{tr("DASHBOARD_SITE_SELECT")}</Label>
                    </div>
                    <CSVLink
                        className={styles.exportCSVButton}
                        data={requestsNotResolved}
                        filename={"export " + dayjs().format("DD-MM-YYYY h-mm-ss") + ".csv"}
                    >
                        {tr("EXPORT_CSV")}
                    </CSVLink>
                </div>
                <DataTable
                    title={tr("DASHBOARD_CSE_REMAGENT_UNRESOLVED")}
                    columns={this.columns}
                    data={requestsNotResolved}
                    theme={this.props.theme}
                    pagination
                />
                <div className={styles.popoutDataTable}>
                    <div className={styles.popoutExport}>
                        <CSVLink
                            className={styles.exportCSVButton}
                            data={requestsResolved}
                            filename={"export " + dayjs().format("DD-MM-YYYY h-mm-ss") + ".csv"}
                        >
                            {tr("EXPORT_CSV")}
                        </CSVLink>
                    </div>
                    <div id="alertServiceRequestTable">
                        <DataTable
                            title={tr("DASHBOARD_CSE_REMAGENT_RESOLVED")}
                            columns={this.columns}
                            data={requestsResolved}
                            theme={this.props.theme}
                            pagination
                        />
                    </div>
                </div>
            </div>
        );
    }
}
