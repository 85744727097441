import * as React from "react";
import userManager from "../../../util/userManager";
import { send } from "../../../helpers/requests";
import { Bar } from "react-chartjs-2";
import DataTable from "react-data-table-component";
import { tr } from "../../../helpers/languages";
import { Input, Label } from "reactstrap";
import { Col, Row } from "../../common/ui";
import dayjs from "dayjs";
import { CSVLink } from "react-csv";
import { Spinner } from "../../common/ui";
import { formatDistanceStrict } from "date-fns";

import styles from "./popout.module.css";

const siteColumns = [
    {
        name: "Site",
        selector: (row) => row.site,
        sortable: true,
        reorder: true
    },
    {
        name: "Number of Service Requests",
        selector: (row) => row.count,
        sortable: true,
        reorder: true
    }
];

export default class ServiceRequestsPopout extends React.PureComponent {
    _isMounted = false;

    constructor(props) {
        super(props);
        this.state = {
            getDone: false,
            serviceDeskStats: {
                getDone: false
            },
            curSite: "All",
            techPerSite: [],
            techPerSiteRaw: [],
            options: JSON.parse(window.localStorage.getItem("options"))
        };
    }
    componentDidMount() {
        this._isMounted = true;
        this.getTechPerSite(this.props.customerID, this.props.startDate, this.props.endDate);
        this.getServiceDeskStats(this.props.customerID, this.props.startDate, this.props.endDate, this.state.curSite);
    }
    componentWillUnmount() {
        this._isMounted = false;
    }
    //API Calls
    getAccessToken = async () => {
        let token = (await userManager.getUser()).access_token;
        return token;
    };
    /**
     * getTechPerSite
     * @description Gets technician service requests per site
     * @param {*} custID Customer ID
     * @param {*} startDate Start date as JS Date
     * @param {*} endDate End date as JS Date
     */
    getTechPerSite = async (custID, startDate, endDate) => {
        const timeZoneOffset = startDate.getTimezoneOffset() * 60;
        const newStartDate = Math.floor(startDate.getTime() / 1000) - timeZoneOffset;
        const newEndDate = Math.floor(endDate.getTime() / 1000) - timeZoneOffset;
        send({
            method: "GET",
            url: `/api/reporting/getuserhistory?custid=${custID}&startdate=${newStartDate}&enddate=${newEndDate}`,
            token: await this.getAccessToken()
        }).then((response) => {
            if (this._isMounted) {
                //var correctedData = this.RemoveLowUserActivity();
                var techPerSite = this.CalcTechPerSite(response.data);
                this.setState({
                    getDone: true,
                    techPerSiteRaw: response.data,
                    techPerSite
                });
            }
        });
    };
    /**
     * getServiceDeskStats
     * @description Gets Service Desk Stats and sets this.state.serviceDeskStats
     * @param {String} custID Customer ID
     * @param {Date} startDate Start date as JS Date
     * @param {Date} endDate End date as JS Date
     * @param {String} siteName Site name
     */
    getServiceDeskStats = async (custID, startDate, endDate, siteName = "All") => {
        const timeZoneOffset = startDate.getTimezoneOffset() * 60;
        const newStartDate = Math.floor(startDate.getTime() / 1000) - timeZoneOffset;
        const newEndDate = Math.floor(endDate.getTime() / 1000) - timeZoneOffset;
        send({
            method: "GET",
            url: `/api/reporting/getservicerequests?custid=${custID}&startdate=${newStartDate}&enddate=${newEndDate}&sitename=${siteName}`,
            token: await this.getAccessToken()
        })
            .then((response) => {
                if (this._isMounted) {
                    this.setState({
                        serviceDeskStats: { ...response.data, getDone: true }
                    });
                }
            })
            .catch((error) => {
                console.log(error);
            });
    };
    FormatTimeFrameText = () => {
        if (this.props.startDate === undefined || this.props.endDate === undefined) {
            return "1 day";
        } //Catch error, when opening settings values are set to undefined despite card not being updated to show "1 day"
        if (
            this.props.startDate.getDate() === this.props.endDate.getDate() &&
            this.props.startDate.getMonth() === this.props.endDate.getMonth()  &&
            this.props.startDate.getFullYear() === this.props.endDate.getFullYear()
        ) {
            return "1 day";
        } else {
            return formatDistanceStrict(this.props.startDate, this.props.endDate, { unit: "day" || "month" || "year" });
        }
    };
    //Bar Graph
    BarData = () => {
        var colourArr = [
            "#118DFF",
            "#E32322",
            "#F3E500",
            "#FDC60A",
            "#F28E1C",
            "#E96220",
            "#C2037B",
            "#cd86c6",
            "#6D398B",
            "#9356b4",
            "#454E99",
            "#2C31EC",
            "#008F59",
            "#8DBB25",
            "#1FDD81",
            "#4DE917",
            "#ffffff",
            "#000000"
        ];
        var allData = [];
        JSON.parse(JSON.stringify(this.state.techPerSite)).forEach((tech) => {
            delete tech.site_name;
            allData.push(tech);
        });
        var allDataSets = [];
        this.state.techPerSite.forEach((site, index) => {
            allDataSets.push({
                label: site.site_name,
                data: allData[index],
                backgroundColor: colourArr[index],
                borderColor: "#118dff"
            });
        });
        return {
            datasets: allDataSets
        };
    };
    BarOptions = () => {
        return {
            scales: {
                x: {
                    suggestedMin: 0,
                    grid: {
                        display: false
                    },
                    ticks: {
                        font: {
                            size: this.state.options.graphTextSize
                        }
                    },
                    stacked: true
                },
                y: {
                    grid: {
                        display: false
                    },
                    ticks: {
                        display: true,
                        callback: function (/** @type {number} */ value) {
                            if (value % 1 === 0) {
                                return value;
                            }
                        },
                        font: {
                            size: this.state.options.graphTextSize
                        }
                    },
                    stacked: true
                }
            },
            responsive: true,
            maintainAspectRatio: false,
            plugins: {
                title: {
                    display: true,
                    text: tr("DASHBOARD_SERVICEDESK_TECH"),
                    font: {
                        size: this.state.options.graphTextSize
                    }
                },
                legend: { display: true, labels: { font: { size: this.state.options.graphTextSize } } }
            }
        };
    };
    //Other
    /**
     * CalcTechPerSite
     * @description Data returned from API contains array of objects ordered by username.
     *              These values are returned as { username: "user@cse-net.co.uk", site_name: "SiteName" } and need to be counted
     *              This function first counts those values and creates an array [{ site_name: "SiteName", user@cse-net.co.uk: 5, user2@cse-net.co.uk: 2 }]
     *              And that's all good except the array isn't ordered... Enter the jank:
     *              The total number of entries for each tech username is recorded into techTotals,
     *              Example: techTotals = { site_name: "TechNamesTotalOrdered", user@cse-net.co.uk: 25, user3@cse-net.co.uk: 23, user2@cse-net.co.uk: 5 }
     *              This value is then pushed to the start of the array.
     *              WHEN BAR GRAPH IS RENDERED => onLoadedData is triggered which calls this.CleanupTechPerSite which removes the first entry from the visible graph
     *              If you're reading this in the future, I apologise.
     * @param {*} data API Data
     * @returns Array of objects ready for bar graph
     */
    CalcTechPerSite = (data) => {
        //Counts
        var finalArr = [];
        var currentItem = {};
        data.forEach((item, index) => {
            if (item.site_name !== currentItem.site_name) {
                if (index !== 0) {
                    finalArr.push(currentItem);
                }
                currentItem = { site_name: item.site_name };
            }
            if (isNaN(currentItem[item.username])) {
                currentItem[item.username] = 0;
            }
            currentItem[item.username] = currentItem[item.username] + 1;
        });
        if (currentItem.site_name !== undefined) {
            finalArr.push(currentItem); //Last item needs to be pushed
        } else {
            //If no data returned display 'No Data Returned'
            finalArr.push({ site_name: "No Data Returned" });
        }
        //Enter the jank:
        //create sortedArr with empty element containing all names in numbered order
        var techTotals = { site_name: "TechNamesTotalOrdered" };
        data.forEach((item) => {
            if (isNaN(techTotals[item.username])) {
                techTotals[item.username] = 0;
            }
            techTotals[item.username] = techTotals[item.username] + 1;
        });
        //Sort object properties into numerical order (I know this breaks JSON rules but data is kept inside this one file)
        var techTotalsSorted = Object.entries(techTotals)
            .sort(([, a], [, b]) => b - a)
            .reduce(
                (previousValue, [key, value]) => ({
                    ...previousValue,
                    [key]: value
                }),
                {}
            );
        //add techTotalsSorted to start of array
        finalArr.unshift(techTotalsSorted);
        //ENDOF Jank :)
        return finalArr;
    };
    RemoveLowUserActivity = (data) => {
        const dataArr = {};
        data.forEach((item) => {
            if (isNaN(dataArr[item.username])) {
                dataArr[item.username] = 0;
            }
            dataArr[item.username] = dataArr[item.username] + 1;
        });
        var deletedUsernames = [];
        Object.keys(dataArr).forEach(function (key, index) {
            // key: the name of the object key
            // index: the ordinal position of the key within the object
            if (dataArr[key] <= 10) {
                deletedUsernames.push(key);
            }
        });
        var finalArr = [];
        data.forEach((item) => {
            if (!deletedUsernames.includes(item.username)) {
                finalArr.push(item);
            }
        });
        return finalArr;
    };
    CleanupTechPerSite = () => {
        if (this.state.techPerSite[0] !== undefined) {
            if (this.state.techPerSite[0].site_name === "TechNamesTotalOrdered") {
                var techPerSite = JSON.parse(JSON.stringify(this.state.techPerSite));
                techPerSite.splice(0, 1); //removes first item
                this.setState({ techPerSite });
            }
        }
    };
    ChangeSite = (newSite) => {
        var techPerSite = [];
        if (newSite.target.value !== "All") {
            this.state.techPerSiteRaw.forEach((element) => {
                if (element.site_name === newSite.target.value) {
                    techPerSite.push(element);
                }
            });
        } else {
            techPerSite = JSON.parse(JSON.stringify(this.state.techPerSiteRaw));
        }
        var result = this.CalcTechPerSite(techPerSite);
        this.setState({ techPerSite: result, curSite: newSite.target.value });
        this.getServiceDeskStats(this.props.customerID, this.props.startDate, this.props.endDate, newSite.target.value);
    };
    render() {
        var uniqueSites = [];
        this.state.techPerSiteRaw.forEach((device) => {
            if (!uniqueSites.includes(device.site_name)) {
                uniqueSites.push(device.site_name);
            }
        });
        var BarGraph = <></>;
        if (this.state.techPerSite !== []) {
            BarGraph = <Bar data={this.BarData()} options={this.BarOptions()} onLoadedData={this.CleanupTechPerSite()} />;
        }
        if (!this.state.getDone && !this.state.serviceDeskStats.getDone) {
            return (
                <div
                    style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        marginTop: "10rem"
                    }}
                >
                    <Spinner size="250" />
                </div>
            );
        } else {
            this.props.popoutRendered();
        }
        return (
            <div>
                <div>
                    <h2 className={styles.popoutHeader}>{tr("DASHBOARD_SERVICEDESK_TITLE")}</h2>
                </div>
                <div className={styles.serviceDeskHeaderItem}>
                    <div>
                        <div className="perm-floating-label">
                            <Input className={styles.popoutInput} type="select" id="serviceRequestsSelect" onChange={this.ChangeSite}>
                                <option value="All">{tr("ALL")}</option>
                                {uniqueSites.map((site, key) => {
                                    return (
                                        <option key={key} value={site}>
                                            {site}
                                        </option>
                                    );
                                })}
                            </Input>
                            <Label for="serviceRequestsSelect">{tr("DASHBOARD_SITE_SELECT")}</Label>
                        </div>
                        <p className={styles.serviceDeskExclusion}>{tr("DASHBOARD_SERVICEDESK_EXCLUSION")}</p>
                        <CSVLink
                            className={styles.exportCSVButton}
                            data={this.state.techPerSite}
                            filename={"export " + dayjs().format("DD-MM-YYYY h-mm-ss") + ".csv"}
                        >
                            {tr("EXPORT_CSV")}
                        </CSVLink>
                    </div>
                </div>
                <div>
                    <Row>
                        <Col center>
                            <p className={styles.serviceDeskCard}>
                                {tr("DASHBOARD_SERVICEDESK_TOTAL")}:{" "}
                                {this.state.serviceDeskStats.getDone && this.state.serviceDeskStats.totalRequests}
                            </p>
                        </Col>
                        <Col center>
                            <p className={styles.serviceDeskCard}>
                                SLA Percentage: {this.state.serviceDeskStats.getDone && this.state.serviceDeskStats.sla}
                            </p>
                        </Col>
                        <Col center>
                            <p className={styles.serviceDeskCard}>
                                {tr("DASHBOARD_SERVICEDESK_OPENED").replace("$#days#", this.FormatTimeFrameText)}: {this.state.serviceDeskStats.getDone && this.state.serviceDeskStats.opened}
                            </p>
                        </Col>
                        <Col center>
                            <p className={styles.serviceDeskCard}>
                                {tr("DASHBOARD_SERVICEDESK_CLOSED").replace("$#days#", this.FormatTimeFrameText)}: {this.state.serviceDeskStats.getDone && this.state.serviceDeskStats.closed}
                            </p>
                        </Col>
                    </Row>
                    <div className={styles.serviceDeskContainer}>
                        <div id="serviceDeskStatsTable" className={styles.serviceDeskItem}>
                            {this.state.serviceDeskStats.getDone && (
                                <DataTable
                                    columns={siteColumns}
                                    data={this.state.serviceDeskStats.requestsPerSite}
                                    theme={this.props.theme}
                                    pagination
                                />
                            )}
                        </div>
                    </div>
                </div>
                <div className={styles.popoutSRGraph}>{BarGraph}</div>
            </div>
        );
    }
}
