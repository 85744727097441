import * as React from "react";
import { useState, useEffect } from "react";
import { Spinner } from "../../../common/ui";
import { send } from "../../../../helpers/requests";
import { tr } from "../../../../helpers/languages";
import userManager from "../../../../util/userManager";
import { GetTheme } from "../SharedFunctions";

import Popup from "../../../common/popup/popout";
import CardClassic from "../../ui/TextCard";
import ServiceDeskSLA from "../../popout/ServiceDeskSLA";
import { LogError } from "../../../analytics/eventTracker";
import BlankCard from "../../ui/BlankCard";

type ServiceDeskSlaAPI = {
    passedSLA: number,
    failedSLA: number,
    slaPercentage: string
}

interface DashCardServiceDeskSLAProps {
    animate?: boolean;
    custID?: string;
    startDate?: Date;
    endDate?: Date;
    disableReRender?: boolean;
    localPreview: boolean;
    cardRendered?: () => void;
    cardType: number;
    cardID: string;
    isCSE?: boolean;
    customID?: string;
    raOnly?: boolean;
    openHelpPopup: (type: string) => void;
}

//API Request
const getAccessToken = async () => {
    let token = (await userManager.getUser())!.access_token;
    return token;
};


const DashCardServiceDeskSLA = (props: DashCardServiceDeskSLAProps) => {
    const [getDone, setGetDone] = useState(false);
    const [popupStatus, setPopupStatus] = useState(false);
    const [sdSLA, setSDSLA] = useState<ServiceDeskSlaAPI>();
    useEffect(() => {
        const getServiceDeskSLA = async (custID: string, startDate: Date, endDate: Date) => {
            const timeZoneOffset = startDate.getTimezoneOffset() * 60;
            const newStartDate = Math.floor(startDate.getTime() / 1000) - timeZoneOffset;
            const newEndDate = Math.floor(endDate.getTime() / 1000) - timeZoneOffset;
            const response = await send({
                method: "GET",
                url: `/api/reporting/sdsla?custid=${custID}&startdate=${newStartDate}&enddate=${newEndDate}`,
                token: await getAccessToken(),
                data: "",
                params: ""
            })
            setSDSLA(response.data);
        }
        setGetDone(false);
        //Get Data on component creation
        if (props.localPreview) {
            setSDSLA({ passedSLA: 50, failedSLA: 12, slaPercentage: "85%" });
        }
        else {
            getServiceDeskSLA(props.custID!, props.startDate!, props.endDate!).catch((err) => {
                LogError("Error servicedesksla card", { err, custID: props.custID });
                console.error(err);
            })
        }
        setGetDone(true);
    }, [props.startDate, props.endDate, props.custID])
    if (props.raOnly) return <></>;
    const openPopup = () => {
        setPopupStatus(true);
    }
    var ReturnCard = <></>;
    var CardSpinner = <></>;
    if (getDone && sdSLA !== undefined) {
        switch (props.cardType) {
            case 0:
            default:
                var slaType = "dash-pos";
                if (sdSLA?.slaPercentage !== undefined) {
                    const sla = JSON.stringify(sdSLA?.slaPercentage).replace("%", "").replaceAll('"', "");
                    const slaInt = parseInt(sla);
                    if (100 >= slaInt && slaInt >= 90) {
                        slaType = "dash-pos";
                    } else if (89 >= slaInt && slaInt >= 50) {
                        slaType = "dash-warn";
                    } else {
                        slaType = "dash-neg";
                    }
                }
                ReturnCard = (
                    <CardClassic
                        title={tr("DASHBOARD_SERVICEDESK_SLA_TITLE")}
                        cardID={props.cardID!}
                        activatePopup={() => {
                            !props.localPreview ? openPopup() : console.log("Card Details");
                        }}
                        openHelpPopup={() => {
                            props.openHelpPopup("ServiceDeskSLA");
                        }}
                        data={[sdSLA!.passedSLA.toString(), sdSLA!.failedSLA.toString(), sdSLA!.slaPercentage]}
                        type={["dash-neut", "dash-neg", `${slaType} dash-neut`]}
                        altText={[tr("DASHBOARD_SERVICEDESK_SLA_PASSED"), tr("DASHBOARD_SERVICEDESK_SLA_FAILED"), tr("DASHBOARD_SERVICEDESK_SLA")]}
                        altTextType={["dash-xsmall-text dash-no-overflow", "dash-xsmall-text dash-no-overflow", "dash-xsmall-text dash-no-overflow", "dash-xsmall-text dash-no-overflow"]}
                        getDone={getDone}
                        custID={props.custID!}
                        startDate={props.startDate!}
                        endDate={props.endDate!}
                        isCSE={props.isCSE!}
                        customID={props.customID!}
                    >
                        {CardSpinner}
                    </CardClassic>
                )
        }
    } else {
        CardSpinner = <div id="loading-spinner" className="spinner-parent">
            <Spinner size={40} className="spinner-position" />
        </div>;
        ReturnCard = (
            <BlankCard activatePopup={() => { }} title={tr("DASHBOARD_SERVICEDESK_SLA_TITLE")} isCSE={false} openHelpPopup={() => { }}>
                <div id="loading-spinner" className="spinner-parent">
                    <Spinner size={40} className="spinner-position" />
                </div>
            </BlankCard>
        )
    }
    return (
        <>
            {ReturnCard}
            {popupStatus && (
                <Popup
                    content={<ServiceDeskSLA theme={GetTheme()} startDate={props.startDate!} endDate={props.endDate!} customerID={props.custID!} popoutRendered={() => { }} />}
                    close={() => setPopupStatus(false)}
                    PopupName="ServiceDeskSLACardDetails"
                />
            )}
        </>
    )
}

export { DashCardServiceDeskSLA }