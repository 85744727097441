import * as React from "react";
import userManager from "../../../util/userManager";
import { send } from "../../../helpers/requests";
import DataTable from "react-data-table-component";
import { tr } from "../../../helpers/languages";
import { Input, Label } from "reactstrap";
import dayjs from "dayjs";
import { CSVLink } from "react-csv";
import { Spinner } from "../../common/ui";

import styles from "./popout.module.css";

const columns = [
    {
        name: "Server",
        selector: (row) => row.hostName,
        sortable: true,
        reorder: true
    },
    {
        name: tr("DASHBOARD_AV_ANTI_THREAT"),
        selector: (row) => row.avProduct,
        sortable: true,
        reorder: true
    },
    {
        name: tr("DASHBOARD_AV_UPTODATE"),
        selector: (row) => {
            if (row.avUpToDate) {
                return "True";
            } else {
                return "False";
            }
        },
        conditionalCellStyles: [
            {
                when: (row) => row.avUpToDate,
                style: {
                    background: "linear-gradient(to right, green 50%, transparent 0)",
                    fontSize: "0px",
                    margin: "5px"
                }
            },
            {
                when: (row) => !row.avUpToDate,
                style: {
                    background: "linear-gradient(to right, red 50%, transparent 0)",
                    fontSize: "0px",
                    margin: "5px"
                }
            }
        ],
        sortable: true,
        reorder: true
    },
    {
        name: "Running",
        selector: (row) => {
            if (row.avRunning) {
                return "True";
            } else {
                return "False";
            }
        },
        conditionalCellStyles: [
            {
                when: (row) => row.avRunning,
                style: {
                    background: "linear-gradient(to right, green 50%, transparent 0)",
                    fontSize: "0px",
                    margin: "5px"
                }
            },
            {
                when: (row) => !row.avRunning,
                style: {
                    background: "linear-gradient(to right, red 50%, transparent 0)",
                    fontSize: "0px",
                    margin: "5px"
                }
            }
        ],
        sortable: true,
        reorder: true
    }
];

export default class AntiThreatPopout extends React.PureComponent {
    _isMounted = false;

    constructor(props) {
        super(props);
        this.state = {
            getDone: false,
            antiThreat: [],
            curSite: "All",
            search: ""
        };
    }
    componentDidMount() {
        this._isMounted = true;
        this.GetAllAntiThreatStats(this.props.customerID);
    }
    componentWillUnmount() {
        this._isMounted = false;
    }
    //API Calls
    /**
     * getAccessToken
     * @description Gets the access token of the authenticated user
     */
    getAccessToken = async () => {
        let token = (await userManager.getUser()).access_token;
        return token;
    };
    /**
     * GetAllAntiThreatStats
     * @description Get all Anti Threat stats
     * @param {String} custID Customer ID
     */
    GetAllAntiThreatStats = async (custID, servers = this.props.serversBool) => {
        send({
            method: "GET",
            url: `/api/reporting/getantivirusstatus?custid=${custID}&servers=${servers}`,
            token: await this.getAccessToken()
        }).then((response) => {
            if (this._isMounted) {
                this.props.popoutRendered();
                var antiThreatSorted = this.DefaultSort(response.data);
                this.setState({ getDone: true, antiThreat: antiThreatSorted });
            }
        });
    };
    //Other
    /**
     * ChangeSite
     * @description Changes the sorted list to only view from one site
     * @param {String} newSite New site string
     */
    ChangeSite = (newSite) => {
        this.setState({ curSite: newSite.target.value });
    };
    /**
     * DefaultSort
     * @description Sorts array of objects, pushes devices not running AV or not up-to-date to the top of the list
     * @param {Object Array} objectArr Data returned from serversonline API request
     * @returns Sorted array
     */
    DefaultSort = (objectArr) => {
        objectArr.sort((a, b) => {
            //Sort hostName alphabetical
            if (a.hostName < b.hostName) {
                return -1;
            }
            if (a.hostName > b.hostName) {
                return 1;
            }
            return 0;
        });
        objectArr.sort((a, b) => {
            //Push out of date servers to the top
            if (a.avUpToDate < b.avUpToDate) {
                return -1;
            }
            if (a.avUpToDate > b.avUpToDate) {
                return 1;
            }
            return 0;
        });
        objectArr.sort((a, b) => {
            //Push servers not running AV to top
            if (a.avRunning < b.avRunning) {
                return -1;
            }
            if (a.avRunning > b.avRunning) {
                return 1;
            }
            return 0;
        });
        return objectArr;
    };
    CreateFinalDeviceList = () => {
        var finalDeviceList = [];
        if (this.state.search === "") {
            if (this.state.curSite !== "All") {
                this.state.antiThreat.forEach((device) => {
                    if (device.site === this.state.curSite) {
                        finalDeviceList.push(device);
                    }
                });
            } else {
                finalDeviceList = JSON.parse(JSON.stringify(this.state.antiThreat));
            }
        } else {
            if (this.state.curSite !== "All") {
                this.state.antiThreat.forEach((device) => {
                    if (device.site === this.state.curSite) {
                        if (device.hostName.toLowerCase().includes(this.state.search)) {
                            finalDeviceList.push(device);
                        }
                    }
                });
            } else {
                this.state.antiThreat.forEach((device) => {
                    if (device.hostName.toLowerCase().includes(this.state.search)) {
                        finalDeviceList.push(device);
                    }
                });
            }
        }
        return finalDeviceList;
    };
    CreateSiteList = () => {
        var uniqueServers = [];
        this.state.antiThreat.forEach((server) => {
            if (!uniqueServers.includes(server.site)) {
                uniqueServers.push(server.site);
            }
        });
        return uniqueServers.sort();
    };
    render() {
        var uniqueSites = this.CreateSiteList();
        var finalDeviceList = this.CreateFinalDeviceList();
        if (!this.state.getDone) {
            return (
                <div
                    style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        marginTop: "10rem"
                    }}
                >
                    <Spinner size="250" />
                </div>
            );
        }
        return (
            <div className={styles.popoutContainer}>
                <h2 className={styles.popoutHeader}>{tr("DASHBOARD_AV_BY_DEVICE")}</h2>
                <div className={styles.popoutSearch}>
                    <div className="form-floating-labels">
                        <Input
                            id="Search"
                            placeholder=" "
                            value={this.state.search || ""}
                            onChange={(v) => {
                                this.setState({
                                    search: v.target.value.toLowerCase()
                                });
                            }}
                        />
                        <Label for="Search">{tr("DASHBOARD_SEARCH_POPOUT_HOSTNAMES")}</Label>
                    </div>
                </div>
                <div className={styles.popoutExport}>
                    <div className="perm-floating-label">
                        <Input className={styles.popoutInput} type="select" id="serStatusSiteSelect" onChange={this.ChangeSite}>
                            <option value="All">{tr("ALL")}</option>
                            {uniqueSites.map((site, key) => {
                                return (
                                    <option key={key} value={site}>
                                        {site}
                                    </option>
                                );
                            })}
                        </Input>
                        <Label for="serStatusSiteSelect">{tr("DASHBOARD_SITE_SELECT")}</Label>
                    </div>
                    <CSVLink
                        className={styles.exportCSVButton}
                        data={finalDeviceList}
                        filename={"export " + dayjs().format("DD-MM-YYYY h-mm-ss") + ".csv"}
                    >
                        {tr("EXPORT_CSV")}
                    </CSVLink>
                </div>
                <div id="antiThreatTable">
                    <DataTable columns={columns} data={finalDeviceList} theme={this.props.theme} pagination />
                </div>
            </div>
        );
    }
}
