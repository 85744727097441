import * as React from "react";
import userManager from "../../../util/userManager";
import { send } from "../../../helpers/requests";
import DataTable from "react-data-table-component";
import { tr } from "../../../helpers/languages";
import { Input, Label } from "reactstrap";
import dayjs from "dayjs";
import { CSVLink } from "react-csv";
import { Spinner } from "../../common/ui";

import styles from "./popout.module.css";

const columns = [
    {
        name: "Server",
        selector: (row) => row.host_name,
        sortable: true,
        reorder: true
    },
    {
        name: "Alert Triggered",
        selector: (row) => {
            if (row.alert_triggered === null) {
                return "---";
            } else {
                return new Date(row.alert_triggered).toUTCString();
            }
        },
        sortable: true,
        reorder: true
    },
    {
        name: "Message",
        selector: (row) => row.message,
        sortable: true,
        reorder: true,
        wrap: true
    }
];

export default class ServerShutdownPopout extends React.PureComponent {
    _isMounted = false;

    constructor(props) {
        super(props);
        this.state = {
            getDone: false,
            serverShutdownOriginal: [],
            curSite: "All",
            search: ""
        };
    }
    componentDidMount() {
        this._isMounted = true;
        this.getServerShutdowns(this.props.customerID, this.props.startDate, this.props.endDate);
    }
    componentWillUnmount() {
        this._isMounted = false;
    }
    getAccessToken = async () => {
        let token = (await userManager.getUser()).access_token;
        return token;
    };
    getServerShutdowns = async (custID, startDate, endDate) => {
        const timeZoneOffset = startDate.getTimezoneOffset() * 60;
        const newStartDate = Math.floor(startDate.getTime() / 1000) - timeZoneOffset;
        const newEndDate = Math.floor(endDate.getTime() / 1000) - timeZoneOffset;
        send({
            method: "GET",
            url: `/api/reporting/getservershutdowns?custid=${custID}&startdate=${newStartDate}&enddate=${newEndDate}`,
            token: await this.getAccessToken()
        }).then((response) => {
            if (this._isMounted) {
                this.props.popoutRendered();
                this.setState({
                    getDone: true,
                    serverShutdownOriginal: response.data
                });
            }
        });
    };
    //Other
    ChangeSite = (newSite) => {
        this.setState({ curSite: newSite.target.value });
    };
    CreateFinalServerList = () => {
        var finalServerList = [];
        if (this.state.search === "") {
            if (this.state.curSite !== "All") {
                this.state.serverShutdownOriginal.forEach((server) => {
                    if (server.site_name === this.state.curSite) {
                        finalServerList.push(server);
                    }
                });
            } else {
                finalServerList = JSON.parse(JSON.stringify(this.state.serverShutdownOriginal));
            }
        } else {
            if (this.state.curSite !== "All") {
                this.state.serverShutdownOriginal.forEach((server) => {
                    if (server.site_name === this.state.curSite) {
                        if (server.host_name.toLowerCase().includes(this.state.search)) {
                            finalServerList.push(server);
                        }
                    }
                });
            } else {
                this.state.serverShutdownOriginal.forEach((server) => {
                    if (server.host_name.toLowerCase().includes(this.state.search)) {
                        finalServerList.push(server);
                    }
                });
            }
        }
        return finalServerList;
    };
    CreateSiteList = () => {
        var uniqueSites = [];
        this.state.serverShutdownOriginal.forEach((server) => {
            if (!uniqueSites.includes(server.site_name)) {
                uniqueSites.push(server.site_name);
            }
        });
        return uniqueSites.sort();
    };
    render() {
        var uniqueSites = this.CreateSiteList();
        var finalServerList = this.CreateFinalServerList();
        if (!this.state.getDone) {
            return (
                <div
                    style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        marginTop: "10rem"
                    }}
                >
                    <Spinner size="250" />
                </div>
            );
        }
        return (
            <div className={styles.popoutContainer}>
                <div>
                    <h2 className={styles.popoutHeader}>{tr("DASHBOARD_UNEXP_SHUTDOWN_POPOUT")}</h2>
                </div>
                <div className={styles.popoutSearch}>
                    <div className="form-floating-labels">
                        <Input
                            id="Search"
                            placeholder=" "
                            value={this.state.search || ""}
                            onChange={(v) => {
                                this.setState({
                                    search: v.target.value.toLowerCase()
                                });
                            }}
                        />
                        <Label for="Search">{tr("DASHBOARD_SEARCH_POPOUT_SERVERNAMES")}</Label>
                    </div>
                </div>
                <div className={styles.popoutExport}>
                    <div className="perm-floating-label">
                        <Input className={styles.popoutInput} type="select" id="serverShutdownSiteSelect" onChange={this.ChangeSite}>
                            <option value="All">{tr("ALL")}</option>
                            {uniqueSites.map((site, key) => {
                                return (
                                    <option key={key} value={site}>
                                        {site}
                                    </option>
                                );
                            })}
                        </Input>
                        <Label for="serverShutdownSiteSelect">{tr("DASHBOARD_SITE_SELECT")}</Label>
                    </div>
                    <CSVLink
                        className={styles.exportCSVButton}
                        data={finalServerList}
                        filename={"export " + dayjs().format("DD-MM-YYYY h-mm-ss") + ".csv"}
                    >
                        {tr("EXPORT_CSV")}
                    </CSVLink>
                </div>
                <div className={styles.popoutDataTable}>
                    <DataTable columns={columns} data={finalServerList} theme={this.props.theme} pagination />
                </div>
            </div>
        );
    }
}
