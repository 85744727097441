import * as React from "react";
import { Spinner } from "../../../common/ui";
import { send } from "../../../../helpers/requests";
import { tr } from "../../../../helpers/languages";
import userManager from "../../../../util/userManager";
import { GetTheme } from "../SharedFunctions";

import Popup from "../../../common/popup/popout";
import ServerUpdatePopout from "../../popout/ServerUpdate";

import CardClassic from "../../ui/TextCard";
import CardGauge from "../../ui/GaugeCard";

export default class DashCardServerWinUpdates extends React.PureComponent {
    _isMounted = false; //If true then component is mounted. If false data returned from API GET should be dropped.

    constructor(props) {
        super(props);
        this.state = {
            serverWinUpdates: {
                upToDate: 0,
                outOfDate: 0,
                outOfDateDetails: []
            },
            getDone: false,
            popupStatus: false
        };
    }
    /**
     * componentDidMount
     * @description API calls for rebuilding base dashboard
     */
    componentDidMount() {
        this._isMounted = true;
        if (this.props.localPreview) {
            this.getWinUpdatesPreview();
        } else {
            if (!this.props.raOnly) {
                this.getWinUpdates(this.props.custID);
            }
        }
    }
    componentWillUnmount() {
        this._isMounted = false;
    }
    componentDidUpdate(prevProps) {
        if (!this.props.disableReRender) {
            if (prevProps.custID !== this.props.custID) {
                this.setState({
                    getDone: false,
                    serverWinUpdates: {
                        upToDate: 0,
                        outOfDate: 0,
                        outOfDateDetails: []
                    }
                });
                this.getWinUpdates(this.props.custID);
            }
        }
    }
    /**
     * OpenPopup
     * @description Calls OpenPopup inside Dashboard.js
     */
    OpenPopup = () => {
        this.setState({ popupStatus: true });
    };
    ///
    /// API Calls
    ///
    /**
     * getAccessToken
     * @description Gets the access token of the authenticated user
     */
    getAccessToken = async () => {
        let token = (await userManager.getUser()).access_token;
        return token;
    };
    /**
     * getWinUpdates
     * @description Gets Windows Updates Stats and sets this.state.serverWinUpdates
     * @param {String} custID Customer ID
     */
    getWinUpdates = async (custID) => {
        send({
            method: "GET",
            url: `/api/reporting/windowsupdatestats${custID !== "" ? "?custid=" + custID : ""}`,
            token: await this.getAccessToken()
        })
            .then((response) => {
                if (this._isMounted) {
                    this.setState({
                        getDone: true,
                        serverWinUpdates: {
                            upToDate: response.data.uptoDate,
                            outOfDate: response.data.outOfDate,
                            outOfDateDetails: response.data.outofDateDetails
                        }
                    });
                    this.props.cardRendered();
                }
            })
            .catch((error) => {
                console.log(error);
            });
    };
    getWinUpdatesPreview = () => {
        this.setState({
            getDone: true,
            serverWinUpdates: {
                upToDate: 50,
                outOfDate: 100,
                outOfDateDetails: [
                    { hostname: "Server-4", numberMissing: 1 },
                    { hostname: "Server-2", numberMissing: 2 },
                    { hostname: "Server-1", numberMissing: 2 }
                ]
            }
        });
    };
    render() {
        var CardSpinner,
            ReturnCard = <></>;
        if (this.props.raOnly) {
            return <></>;
        }
        if (!this.state.getDone) {
            CardSpinner = (
                <div id="loading-spinner" className="spinner-parent">
                    <Spinner size={40} className="spinner-position" />
                </div>
            );
        }
        switch (this.props.cardType) {
            case 0:
                ReturnCard = (
                    <CardClassic
                        title={tr("DASHBOARD_WIN_UPDATE_TITLE")}
                        cardID={this.props.cardID}
                        data={[this.state.serverWinUpdates.upToDate, this.state.serverWinUpdates.outOfDate]}
                        type={["dash-neut", "dash-neg"]}
                        altText={[tr("DASHBOARD_WIN_UPDATE_UPTODATE"), tr("DASHBOARD_WIN_UPDATE_PENDING")]}
                        activatePopup={() => {
                            !this.props.localPreview ? this.OpenPopup() : console.log("Card Details");
                        }}
                        openHelpPopup={() => {
                            this.props.openHelpPopup("ServerWinUpdatesCard");
                        }}
                        custID={this.props.custID}
                        startDate={this.props.startDate}
                        endDate={this.props.endDate}
                        isCSE={this.props.isCSE}
                        customID={this.props.customID}
                    >
                        {CardSpinner}
                    </CardClassic>
                );
                break;
            case 1:
            default:
                ReturnCard = (
                    <CardGauge
                        title={tr("DASHBOARD_WIN_UPDATE_TITLE")}
                        cardID={this.props.cardID}
                        textData={[this.state.serverWinUpdates.upToDate, this.state.serverWinUpdates.outOfDate]}
                        type={["dash-neut", "dash-neg"]}
                        gaugeText="Servers Up-to-date"
                        gaugeColours={["#DC2323", "#35A048"]}
                        percent={
                            Math.round(
                                (this.state.serverWinUpdates.upToDate /
                                    (this.state.serverWinUpdates.upToDate + this.state.serverWinUpdates.outOfDate)) *
                                    100
                            ) / 100
                        }
                        altText={[tr("DASHBOARD_WIN_UPDATE_UPTODATE"), tr("DASHBOARD_WIN_UPDATE_PENDING")]}
                        getDone={this.state.getDone}
                        activatePopup={() => {
                            !this.props.localPreview ? this.OpenPopup() : console.log("Card Details");
                        }}
                        openHelpPopup={() => {
                            this.props.openHelpPopup("ServerWinUpdatesCard");
                        }}
                        custID={this.props.custID}
                        startDate={this.props.startDate}
                        endDate={this.props.endDate}
                        isCSE={this.props.isCSE}
                        customID={this.props.customID}
                    >
                        {CardSpinner}
                    </CardGauge>
                );
                break;
        }
        return (
            <>
                {ReturnCard}
                {this.state.popupStatus && (
                    <Popup
                        content={
                            <ServerUpdatePopout
                                getDone={this.state.getDone}
                                theme={GetTheme()}
                                renderBar={() => {
                                    if (this.state.serverWinUpdates.outOfDate === 0) {
                                        return false;
                                    } else {
                                        return true;
                                    }
                                }}
                                popoutRendered={() => {}}
                                customerID={this.props.custID}
                                PopupName="ServerUpdateCardDetails"
                            />
                        }
                        close={() => this.setState({ popupStatus: false })}
                    />
                )}
            </>
        );
    }
}
