import React from "react";

declare global {
    interface Window { umami: any; }
}

const trackPage = (url: string, title: string) => {
    try {
        const umami = window.umami;
        umami.track((props: any) => ({ ...props, url: url, title: title }));
    } catch (error: any) {
        console.warn(error.message);
    }
}

const trackEvent = (eventValue: string, eventData: object) => {
    try {
        const umami = window.umami;
        umami.track((props: any) => ({ ...props, name: eventValue, data: eventData }));
    } catch (error: any) {
        console.warn(error.message);
    }
}

export { trackPage, trackEvent }