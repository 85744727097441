import React from "react";
import Layout from "./components/common/Layout";
import Routes from "./components/routes";

import "./App.css";
import "./css/bootstrap-custom.css";
import ConsoleDebug from "./components/common/ConsoleDebug";
import HandleURLParams from "./components/common/URLParams";

export default function App() {
    const marquee = window.localStorage.getItem("marquee");
    const urlParams = HandleURLParams();
    return (
        <>
            <Layout>
                <div id="content-container" className="content-container">
                    {!marquee && urlParams.isServiceDesk !== "true" && <div className="content-margin" />}
                    <div id="content" className="content">
                        <Routes />
                    </div>
                </div>
            </Layout>
            <ConsoleDebug />
        </>
    );
}
