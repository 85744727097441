import * as React from "react";
import userManager from "../../../util/userManager";
import { send } from "../../../helpers/requests";
import { Line } from "react-chartjs-2";
import { tr } from "../../../helpers/languages";
import dayjs from "dayjs";
import { CSVLink } from "react-csv";
import { Spinner } from "../../common/ui";
import { DatesToString } from "./SharedPopoutFunctions";

import styles from "./popout.module.css";
import GetCardTheme from "../ui/SharedCardFunctions";

export default class ServiceDeskTrendsPopout extends React.PureComponent {
    _isMounted = false;
    constructor(props) {
        super(props);
        this.state = {
            getDone: false,
            serviceDeskTrendDates: [],
            serviceDeskTrendValues: [],
            curSite: "All",
            startDate: this.props.startDate,
            endDate: this.props.endDate,
            chartFontColour: GetCardTheme(),
            options: JSON.parse(window.localStorage.getItem("options"))
        };
    }
    componentDidMount() {
        this._isMounted = true;
        this.getServiceDeskTrends(this.props.customerID, this.props.startDate, this.props.endDate);
    }
    componentWillUnmount() {
        this._isMounted = false;
    }
    UpdateTheme = () => {
        const chartFontColour = GetCardTheme();
        this.setState({ chartFontColour });
    };
    //API Calls
    getAccessToken = async () => {
        let token = (await userManager.getUser()).access_token;
        return token;
    };
    /**
     * getServiceDeskTrends
     * @description Gets daily tickets in a given time frame
     * @param {string} custID Customer ID
     * @param {date} startDate Start date as JS Date
     * @param {date} endDate End date as JS Date
     */
    getServiceDeskTrends = async (custID, startDate, endDate) => {
        const timeZoneOffset = startDate.getTimezoneOffset() * 60;
        const newStartDate = Math.floor(startDate.getTime() / 1000) - timeZoneOffset;
        const newEndDate = Math.floor(endDate.getTime() / 1000) - timeZoneOffset;
        send({
            method: "GET",
            url: `/api/reporting/servicedesktrends?custid=${custID}&startdate=${newStartDate}&enddate=${newEndDate}`,
            token: await this.getAccessToken()
        })
            .then((response) => {
                if (this._isMounted) {
                    this.props.popoutRendered();
                    this.setState({
                        getDone: true,
                        serviceDeskTrendDates: response.data.map(
                            (item) => new Date(item.calendarDate.split("T", 1)[0]).toLocaleString("en-GB").split(",", 1)[0] //Not the nicest way to do but works
                        ),
                        serviceDeskTrendValues: response.data.map((item) => item.sRsDayTotal)
                    });
                }
            })
            .catch((error) => {
                console.log(error);
            });
    };
    ChartData = () => {
        return {
            labels: this.state.serviceDeskTrendDates,
            datasets: [
                {
                    label: tr("DASHBOARD_SERVICEDESK_TRENDS_LABEL"),
                    data: this.state.serviceDeskTrendValues,
                    backgroundColor: "#118dff",
                    borderColor: "#118dff"
                }
            ]
        };
    };
    ChartOptions = () => {
        return {
            responsive: true,
            maintainAspectRatio: false,
            scales: {
                yAxes: {
                    ticks: {
                        display: true,
                        color: this.state.chartFontColour,
                        font: {
                            size: this.state.options.graphTextSize
                        }
                    }
                },
                xAxes: {
                    ticks: {
                        display: true,
                        color: this.state.chartFontColour,
                        font: {
                            size: this.state.options.graphTextSize
                        }
                    }
                }
            },
            plugins: {
                legend: { display: false },
                title: {
                    display: true,
                    text: tr("DASHBOARD_SERVICEDESK_TRENDS_LABEL"),
                    color: this.state.chartFontColour,
                    font: {
                        size: this.state.options.graphTextSize
                    }
                },
                datalabels: {
                    color: this.state.chartFontColour
                }
            }
        };
    };
    render() {
        var Chart = <></>;
        if (!this.state.getDone) {
            return (
                <div
                    style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        marginTop: "10rem"
                    }}
                >
                    <Spinner size="250" />
                </div>
            );
        }
        if (this.state.getDone) {
            Chart = <Line key={`line ${this.state.chartKey}`} data={this.ChartData} options={this.ChartOptions()} />;
        }
        const dates = DatesToString(this.state.startDate.toDateString(), this.state.endDate.toDateString());
        return (
            <div>
                <div>
                    <h2 className={styles.popoutHeader}>{tr("DASHBOARD_SERVICEDESK_TRENDS_TITLE")}</h2>
                </div>
                {dates && (
                    <h3 style={{ fontSize: "18px", display: "flex", justifyContent: "center", alignItems: "center", width: "100%" }}>
                        {tr("DASHBOARD_POPOUT_TIMEFRAME") + dates[0] + " to " + dates[1]}
                    </h3>
                )}
                <div style={{ display: "flex", flexDirection: "column" }} className={styles.popoutExport}>
                    <CSVLink
                        className={styles.exportCSVButton}
                        data={this.state.serviceDeskTrendDates.map((val, ind) => {
                            return {
                                date: val,
                                numberOfOpenTickets: this.state.serviceDeskTrendValues[ind]
                            };
                        })}
                        filename={"export " + dayjs().format("DD-MM-YYYY h-mm-ss") + ".csv"}
                    >
                        {tr("EXPORT_CSV")}
                    </CSVLink>
                </div>
                <div style={{ float: "left", display: "flex", flexDirection: "column", marginBottom: "2rem" }}>
                    <button
                        className={styles.exportCSVButton}
                        onClick={() => {
                            this.setState({
                                getDone: false,
                                startDate: this.props.startDate,
                                endDate: this.props.endDate
                            });
                            this.getServiceDeskTrends(this.props.customerID, this.props.startDate, this.props.endDate);
                        }}
                    >
                        {tr("DASHBOARD_SERVICEDESK_TRENDS_CURRENT")}
                    </button>
                    <button
                        className={styles.exportCSVButton}
                        onClick={() => {
                            const startDate = new Date();
                            const endDate = new Date(new Date().setDate(new Date().getDate() - 365));
                            this.setState({
                                getDone: false,
                                startDate: startDate,
                                endDate: endDate
                            });
                            this.getServiceDeskTrends(this.props.customerID, startDate, endDate);
                        }}
                    >
                        {tr("DASHBOARD_SERVICEDESK_TRENDS_HISTORICAL")}
                    </button>
                </div>
                <div
                    style={{
                        height: "calc(80vh - 300px)",
                        width: "100%",
                        maxHeight: "1080px"
                    }}
                >
                    {Chart}
                </div>
            </div>
        );
    }
}
