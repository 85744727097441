import * as React from "react";
import userManager from "../../../util/userManager";
import { send } from "../../../helpers/requests";
import { Bar } from "react-chartjs-2";
import DataTable from "react-data-table-component";
import { Input, Label } from "reactstrap";
import { tr } from "../../../helpers/languages";
import dayjs from "dayjs";
import { CSVLink } from "react-csv";
import { Spinner } from "../../common/ui";

import styles from "./popout.module.css";

const columns = [
    {
        name: "Server Name",
        selector: (row) => row.hostname,
        sortable: true,
        reorder: true
    },
    {
        name: "Number of Updates Required",
        selector: (row) => row.numberMissing,
        sortable: true,
        reorder: true
    }
];

export default class ServerUpdatePopout extends React.PureComponent {
    _isMounted = false;
    constructor(props) {
        super(props);
        this.state = {
            getDone: false,
            curSite: "All",
            search: "",
            updates: [],
            options: JSON.parse(window.localStorage.getItem("options"))
        };
    }
    componentDidMount() {
        this._isMounted = true;
        this.getWindowsUpdateStats(this.props.customerID);
    }
    componentWillUnmount() {
        this._isMounted = false;
    }
    //API Calls
    getAccessToken = async () => {
        let token = (await userManager.getUser()).access_token;
        return token;
    };
    getWindowsUpdateStats = async (custID) => {
        send({
            method: "GET",
            url: `/api/reporting/getserverupdatestats?custid=${custID}`,
            token: await this.getAccessToken()
        }).then((response) => {
            if (this._isMounted) {
                this.props.popoutRendered();
                const sorted = this.DefaultSort(response.data);
                this.setState({ getDone: true, updates: sorted });
            }
        });
    };
    //BarGraph
    BarData = () => {
        return {
            datasets: [
                {
                    label: tr("DASHBOARD_WIN_UPDATE_AVAILABLE"),
                    data: this.CreateFinalServerList(),
                    backgroundColor: "#118dff",
                    borderColor: "#118dff"
                }
            ]
        };
    };
    BarOptions = () => {
        return {
            parsing: {
                xAxisKey: "hostname",
                yAxisKey: "numberMissing"
            },
            scales: {
                x: {
                    suggestedMin: 0,
                    grid: {
                        display: false
                    },
                    ticks: {
                        font: {
                            size: this.state.options.graphTextSize
                        }
                    }
                },
                y: {
                    grid: {
                        display: false
                    },
                    ticks: {
                        display: true,
                        callback: function (value) {
                            if (value % 1 === 0) {
                                return value;
                            }
                        },
                        font: {
                            size: this.state.options.graphTextSize
                        }
                    }
                }
            },
            responsive: true,
            maintainAspectRatio: false,
            plugins: {
                legend: { display: false },
                title: {
                    display: true,
                    text: tr("DASHBOARD_WIN_UPDATE_POPUP_BAR_TITLE"),
                    font: {
                        size: this.state.options.graphTextSize
                    }
                }
            }
        };
    };
    //Extra
    DefaultSort = (objectArr) => {
        objectArr.sort((a, b) => {
            return parseInt(b.numberMissing) - parseInt(a.numberMissing);
        });
        return objectArr;
    };
    ChangeSite = (newSite) => {
        this.setState({ curSite: newSite.target.value });
    };
    CreateSiteList = () => {
        var uniqueSites = [];
        this.state.updates.forEach((station) => {
            if (!uniqueSites.includes(station.site)) {
                uniqueSites.push(station.site);
            }
        });
        return uniqueSites.sort();
    };
    CreateFinalServerList = () => {
        var finalServerList = [];
        if (this.state.search === "") {
            if (this.state.curSite !== "All") {
                this.state.updates.forEach((server) => {
                    if (server.site === this.state.curSite) {
                        finalServerList.push(server);
                    }
                });
            } else {
                finalServerList = JSON.parse(JSON.stringify(this.state.updates));
            }
        } else {
            if (this.state.curSite !== "All") {
                this.state.updates.forEach((server) => {
                    if (server.site === this.state.curSite) {
                        if (server.hostname.toLowerCase().includes(this.state.search)) {
                            finalServerList.push(server);
                        }
                    }
                });
            } else {
                this.state.updates.forEach((server) => {
                    if (server.hostname !== undefined && server.hostname !== null) {
                        if (server.hostname.toLowerCase().includes(this.state.search)) {
                            finalServerList.push(server);
                        }
                    }
                });
            }
        }
        return finalServerList;
    };
    render() {
        var uniqueSites = this.CreateSiteList();
        var finalServerList = this.CreateFinalServerList();
        var BarGraph = <></>;
        if (this.props.getDone && this.props.renderBar()) {
            BarGraph = <Bar data={this.BarData()} options={this.BarOptions()} />;
        }
        if (!this.state.getDone) {
            return (
                <div
                    style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        marginTop: "10rem"
                    }}
                >
                    <Spinner size="250" />
                </div>
            );
        }
        return (
            <div className={styles.popoutContainer}>
                <div>
                    <h2 className={styles.popoutHeader}>{tr("DASHBOARD_WIN_UPDATE_STATUS")}</h2>
                </div>
                <div className={styles.popoutSearch}>
                    <div className="form-floating-labels">
                        <Input
                            id="Search"
                            placeholder=" "
                            value={this.state.search || ""}
                            onChange={(v) => {
                                this.setState({
                                    search: v.target.value.toLowerCase()
                                });
                            }}
                        />
                        <Label for="Search">{tr("DASHBOARD_SEARCH_POPOUT_SERVERNAMES")}</Label>
                    </div>
                </div>
                <div className={styles.popoutExport}>
                    <div className="perm-floating-label">
                        <Input className={styles.popoutInput} type="select" id="winUpdateSiteSelect" onChange={this.ChangeSite}>
                            <option value="All">{tr("ALL")}</option>
                            {uniqueSites.map((site, key) => {
                                return (
                                    <option key={key} value={site}>
                                        {site}
                                    </option>
                                );
                            })}
                        </Input>
                        <Label for="winUpdateSiteSelect">{tr("DASHBOARD_SITE_SELECT")}</Label>
                    </div>
                    <CSVLink
                        className={styles.exportCSVButton}
                        data={finalServerList}
                        filename={"export " + dayjs().format("DD-MM-YYYY h-mm-ss") + ".csv"}
                    >
                        {tr("EXPORT_CSV")}
                    </CSVLink>
                </div>
                <div className={styles.popoutSUGraph}>{BarGraph}</div>
                <div id="serverUpdateTable">
                    <DataTable columns={columns} data={finalServerList} theme={this.props.theme} pagination />
                </div>
            </div>
        );
    }
}
