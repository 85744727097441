import * as React from "react";
import userManager from "../../../util/userManager";
import { send } from "../../../helpers/requests";
import DataTable, { TableColumn, TableRow } from "react-data-table-component";
import { tr } from "../../../helpers/languages";
import { Input, Label } from "reactstrap";
import dayjs from "dayjs";
import plugin from "dayjs/plugin/advancedFormat";
import { CSVLink } from "react-csv";
import { Spinner } from "../../common/ui";

import styles from "./popout.module.css";

dayjs.extend(plugin);

type NetworkDevice = {
    hostName: string,
    ip: string,
    status: "Offline" | "Online" | "Unknown",
    description: string,
    lastPinged: Date,
    uptime: string,
    siteName: string
};
type Props = {
    customerID: string,
    startDate: Date,
    endDate: Date,
    theme: string,
    popoutRendered: () => void
};
type State = {
    getDone: boolean,
    curSite: string,
    search: string,
    NetworkDevices: NetworkDevice[]
};

export default class NetworkSwitchDeviceStatus extends React.PureComponent<Props, State> {
    _isMounted = false;
    state = {
        getDone: false,
        curSite: "All",
        search: "",
        NetworkDevices: []
    };
    componentDidMount() {
        this._isMounted = true;
        this.getNetworkDevices(this.props.customerID, this.props.startDate, this.props.endDate);
    }
    componentWillUnmount() {
        this._isMounted = false;
    }
    //API Calls
    /**
     * getAccessToken
     * @description Gets the access token of the authenticated user
     */
    getAccessToken = async () => {
        let token = (await userManager.getUser())?.access_token;
        return token;
    };
    getNetworkDevices = async (custID: string, startDate: Date, endDate: Date) => {
        const timeZoneOffset = startDate.getTimezoneOffset() * 60;
        const newStartDate = Math.floor(startDate.getTime() / 1000) - timeZoneOffset;
        const newEndDate = Math.floor(endDate.getTime() / 1000) - timeZoneOffset;
        send({
            method: "GET",
            url: `/api/reporting/getswitchdevicestatus?custid=${custID}&startdate=${newStartDate}&enddate=${newEndDate}`,
            token: await this.getAccessToken(),
            params: "",
            data: ""
        }).then((response: any) => {
            if (this._isMounted) {
                this.props.popoutRendered();
                this.setState({
                    getDone: true,
                    NetworkDevices: response.data
                });
            }
        });
    };
    columns: TableColumn<NetworkDevice>[] = [
        {
            name: "Hostname",
            selector: (row) => row.hostName,
            sortable: true,
            reorder: true
        },
        {
            name: "IP Address",
            selector: (row) => row.ip,
            sortable: true,
            reorder: true
        },
        {
            name: "Status",
            selector: (row) => row.status,
            sortable: true,
            reorder: true
        },
        {
            name: "Description",
            selector: (row) => row.description,
            sortable: true,
            wrap: true,
            reorder: true
        },
        {
            name: "Last Pinged",
            selector: (row) =>
                dayjs(row.lastPinged).format("MMMM Do YYYY, h:mm:ss a") === "January 1st 0001, 12:00:00 am"
                    ? "n/a"
                    : dayjs(row.lastPinged).format("MMMM Do YYYY, h:mm:ss a"),
            sortable: true,
            wrap: true,
            reorder: true
        },
        {
            name: "Uptime",
            selector: (row) => {
                try {
                    if (row.uptime) {
                        if (row.uptime.split(".").length === 1) {
                            //case 4, 6 & 8
                            switch (row.uptime.split(":").length) {
                                case 3:
                                    return `${row.uptime.split(":")[0]} hours ${row.uptime.split(":")[1]} minutes ${
                                        row.uptime.split(":")[2]
                                    } seconds`;
                                case 2:
                                    return `${row.uptime.split(":")[0]} minutes ${row.uptime.split(":")[1]} seconds`;
                                case 1:
                                default:
                                    return `${row.uptime.split(":")[0]} seconds`;
                            }
                        }
                        if (row.uptime.split(".")[1].split(":").length === 3) {
                            //case 1 or 2
                            return `${row.uptime.split(".")[0]} days ${row.uptime.split(".")[1].split(":")[0]} hours ${
                                row.uptime.split(".")[1].split(":")[1]
                            } minutes ${row.uptime.split(".")[1].split(":")[2]} seconds`;
                        }
                        if (row.uptime.split(".").length === 2) {
                            //case 3, 5 and 7
                            switch (row.uptime.split(":").length) {
                                case 3:
                                    return `${row.uptime.split(":")[0]} hours ${row.uptime.split(":")[1]} minutes ${
                                        row.uptime.split(":")[2].split(".")[0]
                                    } seconds`;
                                case 2:
                                    return `${row.uptime.split(":")[0]} minutes ${row.uptime.split(":")[1].split(".")[0]} seconds`;
                                case 1:
                                default:
                                    return `${row.uptime.split(".")[0]} seconds`;
                            }
                        }
                    }
                } catch (_error) {
                    return "n/a";
                }
                return "n/a";
            },
            sortable: true,
            wrap: true,
            reorder: true
        },
        {
            name: "Site",
            selector: (row) => row.siteName,
            sortable: true,
            reorder: true
        }
    ];
    ChangeSite = (newSite: { target: { value: string | undefined } }) => {
        if (newSite.target.value) {
            this.setState({ curSite: newSite.target.value });
        }
    };
    CreateFinalDeviceList = () => {
        var finalDeviceList = [];
        if (this.state.search === "") {
            if (this.state.curSite !== "All") {
                this.state.NetworkDevices.forEach((device: NetworkDevice) => {
                    if (device.siteName === this.state.curSite) {
                        finalDeviceList.push(device);
                    }
                });
            } else {
                finalDeviceList = JSON.parse(JSON.stringify(this.state.NetworkDevices));
            }
        } else {
            if (this.state.curSite !== "All") {
                this.state.NetworkDevices.forEach((device: NetworkDevice) => {
                    if (device.siteName === this.state.curSite) {
                        if (device.hostName.toLowerCase().includes(this.state.search)) {
                            finalDeviceList.push(device);
                        }
                    }
                });
            } else {
                this.state.NetworkDevices.forEach((device: NetworkDevice) => {
                    if (device.hostName.toLowerCase().includes(this.state.search)) {
                        finalDeviceList.push(device);
                    }
                });
            }
        }
        return finalDeviceList;
    };
    CreateSiteList = () => {
        var uniqueSites: string[] = [];
        this.state.NetworkDevices.forEach((device: NetworkDevice) => {
            if (!uniqueSites.includes(device.siteName)) {
                uniqueSites.push(device.siteName);
            }
        });
        return uniqueSites.sort();
    };
    render() {
        var uniqueSites = this.CreateSiteList();
        var finalDeviceList = this.CreateFinalDeviceList();
        if (!this.state.getDone) {
            return (
                <div
                    style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        marginTop: "10rem"
                    }}
                >
                    <Spinner size={250} />
                </div>
            );
        }
        return (
            <div className={styles.popoutContainer}>
                <div>
                    <h2 className={styles.popoutHeader}>{tr("DASHBOARD_NETWORK_DEVICE_STATUS_SWITCH_TITLE")}</h2>
                </div>
                <div className={styles.popoutSearch}>
                    <div className="form-floating-labels">
                        <Input
                            id="Search"
                            placeholder=" "
                            value={this.state.search || ""}
                            onChange={(v: React.ChangeEvent<HTMLInputElement>) => {
                                this.setState({
                                    search: v.target.value.toLowerCase()
                                });
                            }}
                        />
                        <Label for="Search">{tr("DASHBOARD_SEARCH_POPOUT_HOSTNAMES")}</Label>
                    </div>
                </div>
                <div className={styles.popoutExport}>
                    <div className="perm-floating-label">
                        <Input className={styles.popoutInput} type="select" id="serverShutdownSiteSelect" onChange={this.ChangeSite}>
                            <option value="All">{tr("ALL")}</option>
                            {uniqueSites.map((site, key) => {
                                return (
                                    <option key={key} value={site}>
                                        {site}
                                    </option>
                                );
                            })}
                        </Input>
                        <Label for="serverShutdownSiteSelect">{tr("DASHBOARD_SITE_SELECT")}</Label>
                    </div>
                    <CSVLink
                        className={styles.exportCSVButton}
                        data={finalDeviceList}
                        filename={"export " + dayjs().format("DD-MM-YYYY h-mm-ss") + ".csv"}
                    >
                        {tr("EXPORT_CSV")}
                    </CSVLink>
                </div>
                <DataTable columns={this.columns} data={finalDeviceList} theme={this.props.theme} pagination />
            </div>
        );
    }
}
