import * as React from "react";
import userManager from "../../../util/userManager";
import { send } from "../../../helpers/requests";
import DataTable from "react-data-table-component";
import { tr } from "../../../helpers/languages";
import { Input, Label } from "reactstrap";
import dayjs from "dayjs";
import plugin from "dayjs/plugin/advancedFormat";
import { CSVLink } from "react-csv";
import { Spinner } from "../../common/ui";

import styles from "./popout.module.css";

dayjs.extend(plugin);

//https://github.com/litejs/natural-compare-lite/blob/main/index.js
const naturalCompare = (a, b) => {
    var i,
        codeA,
        codeB = 1,
        posA = 0,
        posB = 0,
        alphabet = String.alphabet;

    function getCode(str, pos, code) {
        if (code) {
            for (i = pos; (code = getCode(str, i)), code < 76 && code > 65; ) ++i;
            return +str.slice(pos - 1, i);
        }
        code = alphabet && alphabet.indexOf(str.charAt(pos));
        return code > -1
            ? code + 76
            : ((code = str.charCodeAt(pos) || 0), code < 45 || code > 127)
            ? code
            : code < 46
            ? 65 // -
            : code < 48
            ? code - 1
            : code < 58
            ? code + 18 // 0-9
            : code < 65
            ? code - 11
            : code < 91
            ? code + 11 // A-Z
            : code < 97
            ? code - 37
            : code < 123
            ? code + 5 // a-z
            : code - 63;
    }

    if ((a += "") != (b += ""))
        for (; codeB; ) {
            codeA = getCode(a, posA++);
            codeB = getCode(b, posB++);

            if (codeA < 76 && codeB < 76 && codeA > 66 && codeB > 66) {
                codeA = getCode(a, posA, posA);
                codeB = getCode(b, posB, (posA = i));
                posB = i;
            }

            if (codeA != codeB) return codeA < codeB ? -1 : 1;
        }
    return 0;
};

export default class DeviceList extends React.PureComponent {
    _isMounted = false;

    constructor(props) {
        super(props);
        this.state = {
            getDone: false,
            devicesOriginal: [],
            curSite: "All",
            search: "",
            latestClientVersion: ""
        };
    }
    componentDidMount() {
        this._isMounted = true;
        this.getDevices(this.props.customerID);
    }
    componentWillUnmount() {
        this._isMounted = false;
    }
    //API Calls
    getAccessToken = async () => {
        let token = (await userManager.getUser()).access_token;
        return token;
    };
    getDevices = async (custID) => {
        send({
            method: "GET",
            url: `/api/reporting/getknowndevices/?custid=${custID}`,
            token: await this.getAccessToken()
        }).then((response) => {
            if (this._isMounted) {
                this.props.popoutRendered();
                this.setState({
                    getDone: true,
                    devicesOriginal: response.data
                });
            }
        });
    };
    FindTopClientVersion = () => {
        if (this.state.latestClientVersion !== "") {
            return this.state.latestClientVersion;
        }
        if (this.state.devicesOriginal.length !== 0) {
            var devicesSortedByClientVersion = JSON.parse(JSON.stringify(this.state.devicesOriginal));
            devicesSortedByClientVersion.sort(function (a, b) {
                return naturalCompare(b.clientVersion.toLowerCase(), a.clientVersion.toLowerCase());
            });
            // devicesSortedByClientVersion = devicesSortedByClientVersion.sort(
            //     this.SortDevices("clientVersion")
            // );
            this.setState({
                latestClientVersion: devicesSortedByClientVersion[0].clientVersion
            });
            return devicesSortedByClientVersion[0].clientVersion;
        }
        return "";
    };
    SortDevices = (prop) => {
        return function (a, b) {
            if (a[prop] > b[prop]) {
                return 1;
            } else if (a[prop] < b[prop]) {
                return -1;
            }
            return 0;
        };
    };
    //Other
    ChangeSite = (newSite) => {
        this.setState({ curSite: newSite.target.value });
    };
    MemorySortFunc = (rowA, rowB) => {
        var aMem = rowA.memory.replace("B", "").replace("K", "").replace("M", "").replace("G", "").replace("T", "").replace(" ", "");
        var bMem = rowB.memory.replace("B", "").replace("K", "").replace("M", "").replace("G", "").replace("T", "").replace(" ", "");
        if (+(aMem) > +(bMem)) {
            return 1;
        }
        if (+(bMem) > +(aMem)) {
            return -1;
        }
        return 0;
    }
    columns = [
        {
            name: "Name",
            selector: (row) => row.name,
            sortable: true,
            reorder: true
        },
        {
            name: "Manufacturer",
            selector: (row) => row.manufacturer,
            sortable: true,
            reorder: true
        },
        {
            name: "Model",
            selector: (row) => row.model,
            sortable: true,
            wrap: true,
            reorder: true
        },
        {
            name: "Serial Number",
            selector: (row) => row.serialNumber,
            sortable: true,
            reorder: true
        },
        {
            name: "Client Version",
            selector: (row) => row.clientVersion,
            conditionalCellStyles: [
                {
                    when: (row) => row.clientVersion === this.FindTopClientVersion(),
                    style: {
                        color: "#11ff00af"
                    }
                },
                {
                    when: (row) => row.clientVersion !== this.FindTopClientVersion(),
                    style: {
                        color: "#ff0000e7"
                    }
                }
            ],
            sortable: true,
            reorder: true
        },
        {
            name: "Processor",
            selector: (row) => row.processor,
            sortable: true,
            wrap: true,
            reorder: true
        },
        {
            name: "Memory",
            selector: (row) => row.memory,
            sortable: true,
            sortFunction: this.MemorySortFunc,
            reorder: true
        },
        {
            name: "Last Logged In User",
            selector: (row) => row.lastLoggedInUser,
            sortable: true,
            wrap: true,
            reorder: true
        }
    ];
    CreateSiteList = () => {
        var uniqueSites = [];
        this.state.devicesOriginal.forEach((backup) => {
            if (!uniqueSites.includes(backup.site)) {
                uniqueSites.push(backup.site);
            }
        });
        return uniqueSites.sort();
    };
    CreateFinalDeviceList = () => {
        var finalDeviceList = [];
        if (this.state.search === "") {
            if (this.state.curSite !== "All") {
                this.state.devicesOriginal.forEach((device) => {
                    if (device.site === this.state.curSite) {
                        finalDeviceList.push(device);
                    }
                });
            } else {
                finalDeviceList = JSON.parse(JSON.stringify(this.state.devicesOriginal));
            }
        } else {
            if (this.state.curSite !== "All") {
                this.state.devicesOriginal.forEach((device) => {
                    if (device.site === this.state.curSite) {
                        if (device.name.toLowerCase().includes(this.state.search)) {
                            finalDeviceList.push(device);
                        }
                    }
                });
            } else {
                this.state.devicesOriginal.forEach((device) => {
                    if (device.name.toLowerCase().includes(this.state.search)) {
                        finalDeviceList.push(device);
                    }
                });
            }
        }
        return finalDeviceList;
    };
    render() {
        var uniqueSites = this.CreateSiteList();
        var finalDeviceList = this.CreateFinalDeviceList();
        if (this.state.latestClientVersion === "" && this.state.devicesOriginal !== []) {
            this.FindTopClientVersion();
        }
        if (!this.state.getDone) {
            return (
                <div
                    style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        marginTop: "10rem"
                    }}
                >
                    <Spinner size="250" />
                </div>
            );
        }
        return (
            <div className={styles.popoutContainer}>
                <div>
                    <h2 className={styles.popoutHeader}>{tr("DASHBOARD_STATIONLASTSEEN_POPOUT_TITLE")}</h2>
                </div>
                <div className={styles.popoutSearch}>
                    <div className="form-floating-labels">
                        <Input
                            id="Search"
                            placeholder=" "
                            value={this.state.search || ""}
                            onChange={(v) => {
                                this.setState({
                                    search: v.target.value.toLowerCase()
                                });
                            }}
                        />
                        <Label for="Search">{tr("DASHBOARD_SEARCH_POPOUT_HOSTNAMES")}</Label>
                    </div>
                </div>
                <div className={styles.popoutExport}>
                    <div className="perm-floating-label">
                        <Input className={styles.popoutInput} type="select" id="deviceListSiteSelect" onChange={this.ChangeSite}>
                            <option value="All">{tr("ALL")}</option>
                            {uniqueSites.map((site, key) => {
                                return (
                                    <option key={key} value={site}>
                                        {site}
                                    </option>
                                );
                            })}
                        </Input>
                        <Label for="deviceListSiteSelect">{tr("DASHBOARD_SITE_SELECT")}</Label>
                    </div>
                    <CSVLink
                        className={styles.exportCSVButton}
                        data={finalDeviceList}
                        filename={"export " + dayjs().format("DD-MM-YYYY h-mm-ss") + ".csv"}
                    >
                        {tr("EXPORT_CSV")}
                    </CSVLink>
                </div>
                <div className={styles.popoutDataTable}>
                    <DataTable columns={this.columns} data={finalDeviceList} theme={this.props.theme} pagination />
                </div>
            </div>
        );
    }
}
