import React, { Component } from "react";
import { connect } from "react-redux";
import { processSilentRenew } from 'redux-oidc';
import PropTypes from "prop-types";

class SilentRenewPage extends Component {

    componentDidMount() {
        processSilentRenew();
    }
    render() {
       
        return (<div>Silent Renew</div>);
    }
}

SilentRenewPage.propTypes = {
    dispatch: PropTypes.func.isRequired
};

export default connect()(SilentRenewPage);