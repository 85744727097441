import * as React from "react";
import userManager from "../../../util/userManager";
import { send } from "../../../helpers/requests";
import DataTable from "react-data-table-component";
import { tr } from "../../../helpers/languages";
import { Input, Label } from "reactstrap";
import dayjs from "dayjs";
import plugin from "dayjs/plugin/advancedFormat";
import { CSVLink } from "react-csv";
import { Spinner } from "../../common/ui";

import styles from "./popout.module.css";

dayjs.extend(plugin);

export default class WindowsElevenReadinessPopout extends React.PureComponent {
    _isMounted = false;
    constructor(props) {
        super(props);
        this.state = {
            getDone: false,
            WindowsElevenReadiness: [],
            curSite: "All",
            search: ""
        };
    }
    componentDidMount() {
        this._isMounted = true;
        this.getWindowsElevenReadiness(this.props.customerID);
    }
    componentWillUnmount() {
        this._isMounted = false;
    }
    //API Calls
    getAccessToken = async () => {
        let token = (await userManager.getUser()).access_token;
        return token;
    };
    getWindowsElevenReadiness = async (custID) => {
        send({
            method: "GET",
            url: `/api/reporting/getwindowselevenreadiness?custid=${custID}`,
            token: await this.getAccessToken()
        }).then((response) => {
            if (this._isMounted) {
                this.props.popoutRendered();
                this.setState({
                    getDone: true,
                    WindowsElevenReadiness: response.data.sort((a, b) => {
                        if (a.host_name === null) {
                            return 1;
                        }
                        if (b.host_name === null) {
                            return 1;
                        }
                        if (a.host_name < b.host_name) {
                            return -1;
                        }
                        if (a.host_name > b.host_name) {
                            return 1;
                        }
                        return 0;
                    })
                });
            }
        });
    };
    //Other
    columns = [
        {
            name: "Host Name",
            selector: (row) => row.host_name,
            sortable: true,
            reorder: true
        },
        {
            name: "Model",
            selector: (row) => row.model,
            sortable: true,
            reorder: true
        },
        {
            name: "Result Status",
            selector: (row) => row.result_status,
            conditionalCellStyles: [
                {
                    when: (row) => row.result_status === "PASS",
                    style: {
                        background: "linear-gradient(to right, green 50%, transparent 0)",
                        fontSize: "0px",
                        margin: "5px"
                    }
                },
                {
                    when: (row) => row.result_status !== "PASS",
                    style: {
                        background: "linear-gradient(to right, red 50%, transparent 0)",
                        fontSize: "0px",
                        margin: "5px"
                    }
                }
            ],
            sortable: true,
            reorder: true
        },
        // {
        //     name: "Device Type",
        //     selector: row => row.device_type,
        //     sortable: true,
        //     reorder: true
        // },
        {
            name: "Time Stamp",
            selector: (row) => dayjs(row.time_stamp).format("MMMM Do YYYY, h:mm:ss a"),
            sortable: true,
            reorder: true
        },
        {
            name: "Site Name",
            selector: (row) => row.site_name,
            sortable: true,
            reorder: true
        }
    ];
    DeviceModelColumns = [
        {
            name: "Model",
            selector: (row) => row[0],
            sortable: true,
            reorder: true
        },
        {
            name: "Number of Devices",
            selector: (row) => row[1]
        }
    ];
    ChangeSite = (newSite) => {
        this.setState({ curSite: newSite.target.value });
    };
    CreateSiteList = () => {
        var uniqueSites = [];
        this.state.WindowsElevenReadiness.forEach((station) => {
            if (!uniqueSites.includes(station.site_name)) {
                uniqueSites.push(station.site_name);
            }
        });
        return uniqueSites.sort();
    };
    CreateFinalWinList = () => {
        var finalWinList = [];
        if (this.state.search === "") {
            if (this.state.curSite !== "All") {
                //If site filter used
                this.state.WindowsElevenReadiness.forEach((station) => {
                    if (station.site_name === this.state.curSite) {
                        finalWinList.push(station);
                    }
                });
            } else {
                finalWinList = JSON.parse(JSON.stringify(this.state.WindowsElevenReadiness));
            }
        } else {
            if (this.state.curSite !== "All") {
                this.state.WindowsElevenReadiness.forEach((device) => {
                    if (device.site_name === this.state.curSite) {
                        if (device.host_name.toLowerCase().includes(this.state.search)) {
                            finalWinList.push(device);
                        }
                    }
                });
            } else {
                this.state.WindowsElevenReadiness.forEach((device) => {
                    if (device.host_name !== undefined && device.host_name !== null) {
                        if (device.host_name.toLowerCase().includes(this.state.search)) {
                            finalWinList.push(device);
                        }
                    }
                });
            }
        }
        return finalWinList.sort((a, b) => {
            if (a.result_status < b.result_status) {
                return -1;
            }
            if (a.result_status > b.result_status) {
                return 1;
            }
            return 0;
        });
    };
    CreateModelList = (finalWinList) => {
        var unsortedObj = { "Unknown Device": 0 };
        finalWinList.forEach((winItem) => {
            if (winItem.result_status !== "PASS") {
                if (
                    winItem.model === "" ||
                    winItem.model === undefined ||
                    winItem.model === null ||
                    winItem.model === "null" ||
                    winItem.model === "System Product Name"
                ) {
                    unsortedObj["Unknown Device"] = parseInt(unsortedObj["Unknown Device"]) + 1;
                } else if (winItem.model.trim() === "") {
                    unsortedObj["Unknown Device"] = parseInt(unsortedObj["Unknown Device"]) + 1;
                } else if (unsortedObj[winItem.model] === undefined) {
                    unsortedObj[winItem.model] = 1;
                } else {
                    unsortedObj[winItem.model] = parseInt(unsortedObj[winItem.model]) + 1;
                }
            }
        });
        let sortArr = [];
        for (var obj in unsortedObj) {
            sortArr.push([obj, unsortedObj[obj]]);
        }
        sortArr.sort((a, b) => {
            return b[1] - a[1];
        });
        return sortArr;
    };
    render() {
        var uniqueSites = this.CreateSiteList();
        var finalWinList = this.CreateFinalWinList();
        var finalModelList = this.CreateModelList(finalWinList);
        if (!this.state.getDone) {
            return (
                <div
                    style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        marginTop: "10rem"
                    }}
                >
                    <Spinner size="250" />
                </div>
            );
        }
        return (
            <div className={styles.popoutContainer}>
                <div>
                    <h2 className={styles.popoutHeader}>{tr("DASHBOARD_WINDOWS_ELEVEN_READY_TITLE")}</h2>
                </div>
                <div className={styles.popoutSearch}>
                    <div className="form-floating-labels">
                        <Input
                            id="Search"
                            placeholder=" "
                            value={this.state.search || ""}
                            onChange={(v) => {
                                this.setState({
                                    search: v.target.value.toLowerCase()
                                });
                            }}
                        />
                        <Label for="Search">{tr("DASHBOARD_SEARCH_POPOUT_HOSTNAMES")}</Label>
                    </div>
                </div>
                <div className={styles.popoutExport}>
                    <div className="perm-floating-label">
                        <Input className={styles.popoutInput} type="select" id="volumeSiteSelect" onChange={this.ChangeSite}>
                            <option value="All">{tr("ALL")}</option>
                            {uniqueSites.map((site, key) => {
                                return (
                                    <option key={key} value={site}>
                                        {site}
                                    </option>
                                );
                            })}
                        </Input>
                        <Label for="winSiteSelect">{tr("DASHBOARD_SITE_SELECT")}</Label>
                    </div>
                    <CSVLink
                        className={styles.exportCSVButton}
                        data={finalWinList}
                        filename={"export " + dayjs().format("DD-MM-YYYY h-mm-ss") + ".csv"}
                    >
                        {tr("EXPORT_CSV")}
                    </CSVLink>
                </div>
                <div className={styles.popoutDataTable}>
                    <DataTable columns={this.columns} data={finalWinList} theme={this.props.theme} pagination />
                </div>
                <div style={{ marginTop: "1rem" }}>
                    <h3 className={styles.popoutHeader}>{tr("DASHBOARD_WINDOWS_ELEVEN_READY_MODEL_TITLE")}</h3>
                </div>
                <div className={styles.popoutDataTable}>
                    <DataTable columns={this.DeviceModelColumns} data={finalModelList} theme={this.props.theme} pagination />
                </div>
                {/* <div style={{ textAlign: "center", marginLeft: "auto", marginRight: "auto" }}>
                    <h3>Device Models that are unable to be upgraded</h3>
                    <table style={{ marginLeft: "auto", marginRight: "auto" }}>
                        <tbody>
                            <tr>
                                <th>Device Model</th>
                                <th>Devices</th>
                            </tr>
                            {finalModelList.map((model, index) => {
                                return (
                                    <tr key={`modelTable ${index}`}>
                                        <th>{model[0]}</th>
                                        <th>{model[1]}</th>
                                    </tr>
                                );
                            })}
                        </tbody>
                    </table>
                </div> */}
            </div>
        );
    }
}
