import React from "react";
import { Col, Row } from "../../common/ui";
import { Line } from "react-chartjs-2";
import BlankCard from "./BlankCard";
import GetCardTheme from "./SharedCardFunctions";

// type CardLineGraphProps = {
//     title: string,
//     children: JSX.Element,
//     cardID: string,
//     displayLegend: boolean,
//     legendTitle: string,
//     data: string[],
//     chartColours: string[],
//     labels: string,
//     datasetLabel: string[],
//     getDone: boolean,
//     activatePopup: () => void,
//     openHelpPopup: () => void,
//     custID: string,
//     startDate: Date,
//     endDate: Date,
//     isCSE: boolean,
//     customID: string
// };
// type CardLineGraphState = {
//     chartFontColour: string,
//     chartKey: number
// };
// type LineChartDataset = {
//     label: string,
//     data: string,
//     backgroundColor: string,
//     borderColor: string
// }[];

//export default class CardLineGraph extends React.PureComponent<CardLineGraphProps, CardLineGraphState> {
export default class CardLineGraph extends React.PureComponent {
    state = {
        chartFontColour: GetCardTheme(),
        chartKey: 0
    };
    componentDidMount() {
        window.addEventListener("themeToggled", this.UpdateTheme);
    }
    componentWillUnmount() {
        window.removeEventListener("themeToggled", this.UpdateTheme);
    }
    componentDidUpdate(prevProps) {
        if (prevProps.data !== this.props.data) {
            this.setState({ chartKey: this.state.chartKey + 1 });
        }
    }
    UpdateTheme = () => {
        const chartFontColour = GetCardTheme();
        this.setState({ chartFontColour });
    };
    LineChartData = () => {
        var colourArr = [
            "#118dff",
            "#0696BB",
            "#008F59",
            "#8DBB25",
            "#1FDD81",
            "#4DE917",
            "#F3E500",
            "#FDC60A",
            "#F28E1C",
            "#E96220",
            "#E32322",
            "#C2037B",
            "#cd86c6",
            "#6D398B",
            "#9356b4",
            "#454E99",
            "#2C31EC",
            "#2A71AF"
        ];
        var datasetsArr = [];
        this.props.data.map((dataset, index) => {
            datasetsArr.push({
                label: this.props.datasetLabel[index],
                data: dataset,
                backgroundColor: colourArr[index],
                borderColor: colourArr[index]
            });
        });
        return {
            labels: this.props.labels,
            datasets: datasetsArr
        };
    };
    LineChartOptions = () => {
        return {
            responsive: true,
            maintainAspectRatio: false,
            scales: {
                yAxes: {
                    ticks: {
                        display: true,
                        color: this.state.chartFontColour
                    }
                },
                xAxes: {
                    ticks: {
                        display: true,
                        color: this.state.chartFontColour
                    }
                }
            },
            plugins: {
                legend: { display: false },
                title: {
                    display: this.props.displayLegend,
                    text: this.props.legendTitle,
                    color: this.state.chartFontColour
                },
                datalabels: {
                    color: this.state.chartFontColour
                }
            }
        };
    };
    render() {
        var Chart = <></>;
        if (this.props.getDone) {
            Chart = <Line key={`line ${this.state.chartKey}`} data={this.LineChartData()} options={this.LineChartOptions()} />;
        }
        return (
            <BlankCard
                activatePopup={this.props.activatePopup}
                title={this.props.title}
                openHelpPopup={this.props.openHelpPopup}
                cardID={this.props.cardID}
                custID={this.props.custID}
                customID={this.props.customID}
                isCSE={this.props.isCSE}
                startDate={this.props.startDate}
                endDate={this.props.endDate}
            >
                {this.props.children}
                <Row className="dash-content-row">
                    <Col style={{ maxHeight: "151px" }}>{Chart}</Col>
                </Row>
            </BlankCard>
        );
    }
}
