import * as React from "react";
import userManager from "../../../util/userManager";
import { send } from "../../../helpers/requests";
import DataTable, { TableColumn, TableRow } from "react-data-table-component";
import { tr } from "../../../helpers/languages";
import { Input, Label } from "reactstrap";
import dayjs from "dayjs";
import { CSVLink } from "react-csv";
import { Spinner } from "../../common/ui";

import styles from "./popout.module.css";


type Activity = {
    username: string,
    totalActivity: number,
    newActivity: number,
    modifiedActivity: number,
    emailActivity: number,
    closedActivity: number,
    archivedActivity: number
};
type Site = {
    site_id: string,
    site_name: string
}
type Props = {
    customerID: string,
    startDate: Date,
    endDate: Date,
    theme: string,
    popoutRendered: () => void;
};
type State = {
    getDone: boolean,
    curSite: string,
    sites: Site[]
    search: string,
    Activity: Activity[],
    ActivityOriginal: Activity[]
};

export default class ActivityPerUser extends React.PureComponent<Props, State> {
    _isMounted = false;
    state = {
        getDone: false,
        curSite: "All",
        search: "",
        sites: [],
        Activity: [],
        ActivityOriginal: []
    };
    componentDidMount() {
        this._isMounted = true;
        this.getSites(this.props.customerID);
        this.getActivity(this.props.customerID, this.props.startDate, this.props.endDate);
    }
    componentWillUnmount() {
        this._isMounted = false;
    }
    //API Calls
    /**
     * getAccessToken
     * @description Gets the access token of the authenticated user
     */
    getAccessToken = async () => {
        let token = (await userManager.getUser())?.access_token;
        return token;
    };
    getSites = async (custID: string) => {
        send({
            method: "GET",
            url: `/api/reporting/getsites?custid=${custID}`,
            token: await this.getAccessToken(),
            data: "",
            params: ""
        }).then((response: any) => {
            if (this._isMounted) {
                this.props.popoutRendered();
                this.setState({ sites: response.data });
            }
        });
    };
    getActivity = async (custID: string, startDate: Date, endDate: Date) => {
        const timeZoneOffset = startDate.getTimezoneOffset() * 60;
        const newStartDate = Math.floor(startDate.getTime() / 1000) - timeZoneOffset;
        const newEndDate = Math.floor(endDate.getTime() / 1000) - timeZoneOffset;
        send({
            method: "GET",
            url: `/api/reporting/getactivityperuser?custid=${custID}&startdate=${newStartDate}&enddate=${newEndDate}`,
            token: await this.getAccessToken(),
            data: "",
            params: ""
        })
            .then((response: any) => {
                if (this._isMounted) {
                    this.setState({
                        getDone: true,
                        Activity: response.data,
                        ActivityOriginal: response.data
                    });
                }
            })
            .catch((error) => {
                console.log(error);
            });
    };
    getActivityRefresh = async (custID: string, startDate: Date, endDate: Date, siteName: string = "All") => {
        const timeZoneOffset = startDate.getTimezoneOffset() * 60;
        const newStartDate = Math.floor(startDate.getTime() / 1000) - timeZoneOffset;
        const newEndDate = Math.floor(endDate.getTime() / 1000) - timeZoneOffset;
        send({
            method: "GET",
            url: `/api/reporting/getactivityperuser?custid=${custID}&startdate=${newStartDate}&enddate=${newEndDate}&sitename=${siteName}`,
            token: await this.getAccessToken(),
            data: "",
            params: ""
        })
            .then((response: any) => {
                if (this._isMounted) {
                    this.setState({
                        getDone: true,
                        Activity: response.data,
                    });
                }
            })
            .catch((error) => {
                console.log(error);
            });
    };
    columns: TableColumn<Activity>[] = [
        {
            name: "Name",
            selector: (row) => row.username,
            sortable: true,
            reorder: true
        },
        {
            name: "Total Activity",
            selector: (row) => row.totalActivity,
            sortable: true,
            reorder: true
        },
        {
            name: "New Requests",
            selector: (row) => row.newActivity,
            sortable: true,
            reorder: true
        },
        {
            name: "Modified Requests",
            selector: (row) => row.modifiedActivity,
            sortable: true,
            reorder: true
        },
        {
            name: "Email Activity",
            selector: (row) => row.emailActivity,
            sortable: true,
            reorder: true
        },
        {
            name: "Closed Requests",
            selector: (row) => row.closedActivity,
            sortable: true,
            reorder: true
        },
        {
            name: "Archived Requests",
            selector: (row) => row.archivedActivity,
            sortable: true,
            reorder: true
        }
    ];
    ChangeSite = (newSite: { target: { value: string | undefined } }) => {
        if (newSite.target.value !== "All") {
            this.getActivityRefresh(this.props.customerID, this.props.startDate, this.props.endDate, newSite.target.value);
        } else {
            this.setState({ Activity: JSON.parse(JSON.stringify(this.state.ActivityOriginal)) });
        }
    };
    CreateFinalActivityList = () => {
        var finalActivityList = [];
        if (this.state.search === "") {
            finalActivityList = JSON.parse(JSON.stringify(this.state.Activity));
        } else {
            this.state.Activity.forEach((act: Activity) => {
                if (act.username.toLowerCase().includes(this.state.search)) {
                    finalActivityList.push(act);
                }
            });
        }
        return finalActivityList;
    };
    render() {
        var finalActivityList = this.CreateFinalActivityList();
        var uniqueSites: string[] = [];
        if (!this.state.getDone) {
            return (
                <div
                    style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        marginTop: "10rem"
                    }}
                >
                    <Spinner size={250} />
                </div>
            );
        }
        if (this.state.sites.length > 1) {
            this.state.sites.forEach((site: Site) => {
                uniqueSites.push(site.site_name)
            })
        }
        return (
            <div className={styles.popoutContainer}>
                <div>
                    <h2 className={styles.popoutHeader}>{tr("DASHBOARD_USER_ACTIVITY_TITLE")}</h2>
                </div>
                <div className={styles.popoutSearch}>
                    <div className="form-floating-labels">
                        <Input
                            id="Search"
                            placeholder=" "
                            value={this.state.search || ""}
                            onChange={(v: React.ChangeEvent<HTMLInputElement>) => {
                                this.setState({
                                    search: v.target.value.toLowerCase()
                                });
                            }}
                        />
                        <Label for="Search">{tr("DASHBOARD_SEARCH_POPOUT_USER")}</Label>
                    </div>
                </div>
                <div className={styles.popoutExport}>
                    <div className="perm-floating-label">
                        <Input className={styles.popoutInput} type="select" id="serverShutdownSiteSelect" onChange={this.ChangeSite}>
                            <option value="All">{tr("ALL")}</option>
                            {uniqueSites.map((site, key) => {
                                return (
                                    <option key={key} value={site}>
                                        {site}
                                    </option>
                                );
                            })}
                        </Input>
                        <Label for="serverShutdownSiteSelect">{tr("DASHBOARD_SITE_SELECT")}</Label>
                    </div>
                    <CSVLink
                        className={styles.exportCSVButton}
                        data={finalActivityList}
                        filename={"export " + dayjs().format("DD-MM-YYYY h-mm-ss") + ".csv"}
                    >
                        {tr("EXPORT_CSV")}
                    </CSVLink>
                </div>
                <div id="activityPerUserTable">
                    <DataTable
                        columns={this.columns}
                        data={finalActivityList}
                        theme={this.props.theme}
                        pagination
                    />
                </div>
            </div>
        );
    }
}
