import * as React from "react";
import { Spinner } from "../../../common/ui";
import { send } from "../../../../helpers/requests";
import { tr } from "../../../../helpers/languages";
import userManager from "../../../../util/userManager";
import { formatDistanceStrict } from "date-fns";
import { GetTheme } from "../SharedFunctions";

import Popup from "../../../common/popup/popout";
import ServiceRequestsPopout from "../../popout/ServiceRequests";

import CardClassic from "../../ui/TextCard";

export default class DashCardServiceDeskStats extends React.PureComponent {
    _isMounted = false; //If true then component is mounted. If false data returned from API GET should be dropped.

    constructor(props) {
        super(props);
        this.state = {
            serviceDeskStats: {
                total: 0,
                opened: 0,
                closed: 0,
                reopened: 0,
                sla: 0
            },
            getDone: false,
            popupStatus: false
        };
    }
    /**
     * componentDidMount
     * @description API calls for rebuilding base dashboard
     */
    componentDidMount() {
        this._isMounted = true;
        if (this.props.localPreview) {
            this.getServiceDeskStatsPreview();
        } else {
            this.getServiceDeskStats(this.props.custID, this.props.startDate, this.props.endDate);
        }
    }
    componentWillUnmount() {
        this._isMounted = false;
    }
    componentDidUpdate(prevProps) {
        if (!this.props.disableReRender) {
            if (prevProps.custID !== this.props.custID || prevProps.startDate !== this.props.startDate || prevProps.endDate !== this.props.endDate) {
                this.setState({
                    getDone: false,
                    serviceDeskStats: { total: 0, opened: 0, closed: 0, reopened: 0, sla: 0 }
                });
                this.getServiceDeskStats(this.props.custID, this.props.startDate, this.props.endDate);
            }
        }
    }
    /**
     * OpenPopup
     * @description Calls OpenPopup inside Dashboard.js
     */
    OpenPopup = () => {
        this.setState({ popupStatus: true });
    };
    FormatTimeFrameText = () => {
        if (this.props.startDate === undefined || this.props.endDate === undefined) {
            return "1 day";
        } //Catch error, when opening settings values are set to undefined despite card not being updated to show "1 day"
        if (
            this.props.startDate.getDate() === this.props.endDate.getDate() &&
            this.props.startDate.getMonth() === this.props.endDate.getMonth()  &&
            this.props.startDate.getFullYear() === this.props.endDate.getFullYear()
        ) {
            return "1 day";
        } else {
            return formatDistanceStrict(this.props.startDate, this.props.endDate, { unit: "day" || "month" || "year" });
        }
    };
    ///
    /// API Calls
    ///
    /**
     * getAccessToken
     * @description Gets the access token of the authenticated user
     */
    getAccessToken = async () => {
        let token = (await userManager.getUser()).access_token;
        return token;
    };
    /**
     * getServiceDeskStats
     * @description Gets Service Desk Stats and sets this.state.serviceDeskStats
     * @param {String} custID Customer ID
     * @param {Date} startDate Start date as JS Date
     * @param {Date} endDate End date as JS Date
     */
    getServiceDeskStats = async (custID, startDate, endDate) => {
        const timeZoneOffset = startDate.getTimezoneOffset() * 60;
        const newStartDate = Math.floor(startDate.getTime() / 1000) - timeZoneOffset;
        const newEndDate = Math.floor(endDate.getTime() / 1000) - timeZoneOffset;
        send({
            method: "GET",
            url: `/api/reporting/servicedeskstats?custid=${custID}&startdate=${newStartDate}&enddate=${newEndDate}`,
            token: await this.getAccessToken()
        })
            .then((response) => {
                if (this._isMounted) {
                    this.setState({
                        getDone: true,
                        serviceDeskStats: {
                            total: response.data.totalRequests,
                            opened: response.data.opened,
                            closed: response.data.closed,
                            reopened: response.data.reopened,
                            sla: response.data.sla
                        }
                    });
                    this.props.cardRendered();
                }
            })
            .catch((error) => {
                console.log(error);
            });
    };
    getServiceDeskStatsPreview = () => {
        this.setState({
            getDone: true,
            serviceDeskStats: {
                total: 100,
                opened: 350,
                closed: 330,
                reopened: 15,
                sla: "100%"
            }
        });
    };
    render() {
        var CardSpinner,
            ReturnCard = <></>;
        if (!this.state.getDone) {
            CardSpinner = (
                <div id="loading-spinner" className="spinner-parent">
                    <Spinner size={40} className="spinner-position" />
                </div>
            );
        }
        switch (this.props.cardType) {
            case 0:
            default:
                const sla = JSON.stringify(this.state.serviceDeskStats.sla).replace("%", "").replaceAll('"', "");
                const slaInt = parseInt(sla);
                var slaType = "dash-pos";
                if (100 >= slaInt && slaInt >= 90) {
                    slaType = "dash-pos";
                } else if (89 >= slaInt && slaInt >= 50) {
                    slaType = "dash-warn";
                } else {
                    slaType = "dash-neg";
                }
                ReturnCard = (
                    <CardClassic
                        title={tr("DASHBOARD_SERVICEDESK_TITLE")}
                        cardID={this.props.cardID}
                        data={[
                            this.state.serviceDeskStats.total,
                            this.state.serviceDeskStats.opened,
                            this.state.serviceDeskStats.closed,
                            this.state.serviceDeskStats.reopened,
                            this.state.serviceDeskStats.sla
                        ]}
                        type={[
                            "dash-neut dash-30-text",
                            "dash-neut dash-30-text",
                            "dash-neut dash-30-text",
                            "dash-neut dash-30-text",
                            `${slaType} dash-30-text`
                        ]}
                        altTextType={["dash-xsmall-text", "dash-xsmall-text", "dash-xsmall-text", "dash-xsmall-text"]}
                        altText={[
                            tr("DASHBOARD_SERVICEDESK_TOTAL"),
                            tr("DASHBOARD_SERVICEDESK_OPENED").replace("$#days#", this.FormatTimeFrameText),
                            tr("DASHBOARD_SERVICEDESK_CLOSED").replace("$#days#", this.FormatTimeFrameText),
                            tr("DASHBOARD_SERVICEDESK_REOPENED").replace("$#days#", this.FormatTimeFrameText),
                            tr("DASHBOARD_SERVICEDESK_SLA")
                        ]}
                        activatePopup={() => {
                            !this.props.localPreview ? this.OpenPopup() : console.log("Card Details");
                        }}
                        openHelpPopup={() => {
                            this.props.openHelpPopup("ServiceDeskCard");
                        }}
                        custID={this.props.custID}
                        startDate={this.props.startDate}
                        endDate={this.props.endDate}
                        isCSE={this.props.isCSE}
                        customID={this.props.customID}
                    >
                        {CardSpinner}
                    </CardClassic>
                );
                break;
        }
        return (
            <>
                {ReturnCard}
                {this.state.popupStatus && (
                    <Popup
                        content={
                            <ServiceRequestsPopout
                                theme={GetTheme()}
                                startDate={this.props.startDate}
                                endDate={this.props.endDate}
                                popoutRendered={() => {}}
                                customerID={this.props.custID}
                            />
                        }
                        close={() => this.setState({ popupStatus: false })}
                        PopupName="ServiceDeskStatsCardDetails"
                    />
                )}
            </>
        );
    }
}
