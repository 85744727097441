import React, { useEffect, useRef, useState } from "react";
import { Button, Spinner } from "../../../common/ui";
import EditIcon from "../../../../assets/icons/pencil.svg";
import ArchiveIcon from "../../../../assets/icons/archive.svg";
import RestoreIcon from "../../../../assets/icons/restore.svg";
import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import { ErrorMessage } from "@hookform/error-message";
import styles from "./Comments.module.css";
import { send } from "../../../../helpers/requests";
import userManager from "../../../../util/userManager";
import useAutosizeTextArea from "../../../../hooks/useAutosizeTextArea";
import { LogEvent } from "../../../analytics/eventTracker";

type CommentsProps = {
    type: string,
    custID: string,
    startDate: Date,
    endDate: Date
};
export type Comment = {
    _id: any,
    customer_id?: string,
    card_type: string,
    created: any,
    created_by: string,
    updated: any,
    updated_by: string,
    comment: string,
    is_archived?: boolean,
    is_Archived?: boolean
};
type CommentPages = "add" | "edit" | "view";
type ViewCommentsProps = {
    type: string,
    onArchiveComment: (index: number) => void,
    onEditComment: (index: number) => void,
    comments: Comment[]
};
type AddEditCommentsProps = {
    addOrEdit: "Add" | "Edit",
    cardType: string,
    custID: string,
    onAddComment: (newComment: Comment) => void,
    onEditComment?: (index: number, newComment: Comment) => void,
    exit: () => void,
    comment?: Comment,
    commentIndex?: number
};
const NewCommentSchema = z.object({
    comment: z.string().min(2).trim()
});
type NewCommentType = z.infer<typeof NewCommentSchema>;

//API Requests
const getAccessToken = async () => {
    let token = (await userManager.getUser())?.access_token;
    return token;
};
const GetRemoteComments = async (custID: string, startDate: Date, endDate: Date, type: string) => {
    const timeZoneOffset = startDate.getTimezoneOffset() * 60;
    const newStartDate = Math.floor(startDate.getTime() / 1000) - timeZoneOffset;
    const newEndDate = Math.floor(endDate.getTime() / 1000) - timeZoneOffset;
    var res = await send({
        method: "GET",
        url: `/api/reporting/getcomments?custid=${custID}&startdate=${newStartDate}&enddate=${newEndDate}&card_type=${type}`,
        data: "",
        params: "",
        token: await getAccessToken()
    });
    return res.data as Comment[];
}
const AddRemoteComment = async (newComment: Comment) => {
    var res = await send({
        method: "POST",
        url: `/api/reporting/createcomment`,
        data: newComment,
        params: "",
        token: await getAccessToken()
    })
    return res.data as Comment;
}
const EditRemoteComment = async (newComment: Comment) => {
    var res = await send({
        method: "POST",
        url: `/api/reporting/editcomment`,
        data: newComment,
        params: "",
        token: await getAccessToken()
    })
    return res.data as Comment;
}
const ArchiveRemoteComment = async (newComment: Comment) => {
    var res = await send({
        method: "POST",
        url: `/api/reporting/archivecomment`,
        data: newComment,
        params: "",
        token: await getAccessToken()
    })
    return res.data as Comment;
}

const Comments = (props: CommentsProps) => {
    const [page, setPage] = useState<CommentPages>("view");
    const [isLoading, setIsLoading] = useState(true);
    const [editComment, setEditComment] = useState<Comment>();
    const [allComments, setAllComments] = useState<Comment[]>([]);
    useEffect(() => {
        GetRemoteComments(props.custID, props.startDate, props.endDate, props.type).then((res) => {
            setAllComments(res);
            setIsLoading(false);
        }).catch((error) => {
            console.log(error);
        })
    }, []);
    const AddComment = async (newComment: Comment) => {
        setIsLoading(true);
        var allCommentsCopy = JSON.parse(JSON.stringify(allComments));
        var fullNewComment = await AddRemoteComment(newComment);
        allCommentsCopy.push(fullNewComment);
        setAllComments(allCommentsCopy);
        setPage("view");
        setIsLoading(false);
    }
    const ArchiveComment = async (index: number) => {
        setIsLoading(true);
        var allCommentsCopy: Comment[] = JSON.parse(JSON.stringify(allComments));
        var fullArchivedComment = await ArchiveRemoteComment(allCommentsCopy[index]);
        allCommentsCopy[index] = fullArchivedComment;
        setAllComments(allCommentsCopy);
        setIsLoading(false);
    }
    const OpenEditComment = (index: number) => {
        setEditComment(allComments[index]);
        setPage("edit");
    }
    const EditComment = async (index: number, newComment: Comment) => {
        setIsLoading(true);
        var allCommentsCopy: Comment[] = JSON.parse(JSON.stringify(allComments));
        allCommentsCopy[index] = newComment;
        var fullEditedComment = await EditRemoteComment(allCommentsCopy[index]);
        allCommentsCopy[index] = fullEditedComment;
        setAllComments(allCommentsCopy);
        setPage("view");
        setIsLoading(false);
    }
    const ExitAddEditPage = () => {
        setPage("view");
    }
    var pageComponent = <></>;
    switch (page) {
        case "add":
            pageComponent = <AddEditComment cardType={props.type} custID={props.custID} exit={ExitAddEditPage} addOrEdit={"Add"} onAddComment={AddComment} />;
            break;
        case "edit":
            pageComponent = <AddEditComment cardType={props.type} custID={props.custID} exit={ExitAddEditPage} addOrEdit={"Edit"} comment={editComment} commentIndex={allComments.indexOf(editComment!)} onAddComment={AddComment} onEditComment={EditComment} />;
            break;
        case "view":
        default:
            pageComponent = <ViewComments comments={allComments} type={props.type} onEditComment={OpenEditComment} onArchiveComment={ArchiveComment} />;
            break;
    }
    if (isLoading) {
        return (
            <div
                style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    marginTop: "10rem"
                }}
            >
                <Spinner size={250} />
            </div>
        );
    } else {
        LogEvent(7, { page: props.type });
    }
    return (
        <>
            <div style={{ display: "flex", justifyContent: "center", placeItems: "center", flexDirection: "row" }}>
                <Button
                    primary={page === "view"}
                    onClick={() => {
                        if (page !== "view") {
                            setPage("view");
                        }
                    }}
                    style={{ marginRight: "1rem" }}
                >
                    Comments
                </Button>
                <Button
                    primary={page === "add"}
                    onClick={() => {
                        if (page !== "add") {
                            setPage("add");
                        }
                    }}
                >
                    Add Comment
                </Button>
            </div>
            {pageComponent}
        </>
    );
};

const ViewComments = (props: ViewCommentsProps) => {
    return (
        <div>
            <section style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }}>
                <h2 style={{ marginTop: "1rem", marginBottom: "1rem" }}>{props.type !== "All" ? "Card Comments" : "Dashboard Comments"}</h2>
                {props.comments.length === 0 && <h2 style={{ fontSize: "22px", marginTop: "1rem", marginBottom: "1rem" }}>No Comments </h2>}
                <div style={{ width: "90%" }}>
                    {props.comments.map((comment, index) => {
                        if (!comment.is_archived && !comment.is_Archived) {
                            return (
                                <div key={`commentCard ${index}`} style={{ border: "solid var(--cse-bg-alt", borderRadius: "25px", marginBottom: "1rem", padding: "1rem" }}>
                                    <p style={{ fontSize: "24px", whiteSpace: "pre-wrap", overflow: "hidden", textOverflow: "ellipsis" }}>{comment.comment}</p>
                                    <div style={{ display: "flex" }}>
                                        <div style={{ marginTop: "1rem" }}>
                                            <p style={{ fontSize: "18px", margin: 0 }}>
                                                {comment.created_by} - {new Date(comment.created).toUTCString()}
                                            </p>
                                            {comment.updated_by !== null && <p style={{ marginBottom: 0, marginLeft: "0.5rem" }}>{`Edited by ${comment.updated_by} - ${new Date(comment.updated).toUTCString()}`}</p>}
                                        </div>
                                        <img style={{ cursor: "pointer", marginLeft: "auto" }} src={EditIcon} onClick={() => { props.onEditComment(index) }} />
                                        <img style={{ cursor: "pointer" }} src={ArchiveIcon} onClick={() => { props.onArchiveComment(index) }} />
                                    </div>
                                </div>
                            );
                        }
                    })}
                </div>
                {props.comments.length !== 0 && <h2 style={{ marginTop: "1rem", marginBottom: "1rem" }}>{props.type !== "All" ? "Archived Card Comments" : "Archived Dashboard Comments"}</h2>}
                <div style={{ width: "90%" }}>
                    {props.comments.map((comment, index) => {
                        if (comment.is_archived || comment.is_Archived) {
                            return (
                                <div key={`commentCard ${index}`} style={{ border: "solid var(--cse-bg-alt", borderRadius: "25px", marginBottom: "1rem", padding: "1rem" }}>
                                    <p style={{ fontSize: "24px" }}>{comment.comment}</p>
                                    <div style={{ display: "flex" }}>
                                        <div style={{ marginTop: "1rem" }}>
                                            <p style={{ fontSize: "18px", margin: 0 }}>
                                                {comment.created_by} - {new Date(comment.created).toUTCString()}
                                            </p>
                                            {comment.updated_by !== null && <p style={{ marginBottom: 0, marginLeft: "0.5rem" }}>{`Edited by ${comment.updated_by} - ${new Date(comment.updated).toUTCString()}`}</p>}
                                        </div>
                                        <img style={{ cursor: "pointer", marginLeft: "auto" }} src={RestoreIcon} onClick={() => { props.onArchiveComment(index) }} />
                                    </div>
                                </div>
                            );
                        }
                    })}
                </div>
            </section>
        </div>
    );
};

const AddEditComment = (props: AddEditCommentsProps) => {
    const {
        register,
        watch,
        formState: { errors },
        handleSubmit,
    } = useForm({ resolver: zodResolver(NewCommentSchema) });
    const textAreaRef = useRef<HTMLTextAreaElement | null>(null);
    const commentValue = watch("comment", "");
    const { ref, ...rest } = register("comment");
    useAutosizeTextArea(textAreaRef.current, commentValue);
    return (
        <form
            className={styles.addCommentForm}
            onSubmit={handleSubmit((data) => {
                if (props.addOrEdit === "Add") {
                    const restOfComment = {
                        customer_id: props.custID,
                        card_type: props.cardType
                    }
                    let finalComment = { ...data, ...restOfComment }
                    props.onAddComment(finalComment as Comment);
                } else {
                    const restOfComment = {
                        _id: props.comment?._id,
                        customer_id: props.custID,
                        card_type: props.comment?.card_type,
                        created: props.comment?.created,
                        created_by: props.comment?.created_by,
                        is_archived: props.comment?.is_archived,
                    }
                    let finalComment = { ...data, ...restOfComment }
                    props.onEditComment!(props.commentIndex!, finalComment as Comment);
                }

            })}
        >
            <h2>{props.addOrEdit === "Add" ? "Add Comment" : "Edit Comment"}</h2>
            <textarea
                {...register("comment", {
                    required: {
                        value: true,
                        message: "Comment required"
                    }
                })}
                ref={(e) => {
                    ref(e);
                    textAreaRef.current = e;
                }}
                style={props.addOrEdit === "Edit" ? { height: "20vw" } : {}}
                placeholder="Comment"
                defaultValue={props.comment ? props.comment.comment : ""}
                className={styles.addCommentTextArea}
            ></textarea>
            <ErrorMessage errors={errors} name="comment" as="p" />
            <div>
                <Button primary type="submit" className={styles.addCommentButton}>
                    Submit
                </Button>
                <Button onClick={props.exit} className={styles.cancelCommentButton}>
                    Cancel
                </Button>
            </div>
        </form>
    );
};

export default Comments;
