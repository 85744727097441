import React, { Component } from "react";
import userManager from "../../../util/userManager";

class LogoutPage extends Component {
    componentDidMount(event) {
        userManager.signoutRedirect();
    }

    render() {
        return (
            <div style={{textAlign: 'center'}}>
                <h4>Log out</h4>
            </div>
        );
    }
}

export default LogoutPage;