import { useEffect, useState } from "react";
import useLocalStorage from "./useLocalStorage";

const useDarkMode = () => {
    //const [enabled, setEnabled] = useLocalStorage('dark-theme');
    const [enabled, setEnabled] = useLocalStorage("theme");
    const prefersDarkMode = usePrefersDarkMode();
    const isEnabled =
        typeof enabledState === "undefined" ? enabled : prefersDarkMode;

    useEffect(() => {
        //const className = 'dark';
        const lightTheme = "light-theme";
        const darkTheme = "dark-theme";
        const bodyClass = window.document.body.classList;

        //isEnabled === 'dark-theme' ? bodyClass.add(darkTheme), bodyClass.remove(lightTheme) : bodyClass.remove(className);
        if (isEnabled === "light-theme") {
            bodyClass.add(lightTheme);
            bodyClass.remove(darkTheme);
        } else {
            bodyClass.add(darkTheme);
            bodyClass.remove(lightTheme);
        }
        window.dispatchEvent(new Event("themeToggled"));
    }, [enabled, isEnabled]);

    return [enabled, setEnabled];
};

export default useDarkMode;

function usePrefersDarkMode() {
    return useMedia(["(prefers-color-scheme: dark)"], [true], false);
}

function useMedia(queries, values, defaultValue) {
    // Array containing a media query list for each query
    const mediaQueryLists = queries.map((q) => window.matchMedia(q));

    // Function that gets value based on matching media query
    const getValue = () => {
        // Get index of first media query that matches
        const index = mediaQueryLists.findIndex((mql) => mql.matches);
        // Return related value or defaultValue if none
        return typeof values[index] !== "undefined"
            ? values[index]
            : defaultValue;
    };

    // State and setter for matched value
    const [value, setValue] = useState(getValue);

    useEffect(
        () => {
            // Event listener callback
            // Note: By defining getValue outside of useEffect we ensure that it has ...
            // ... current values of hook args (as this hook callback is created once on mount).
            const handler = () => setValue(getValue);
            // Set a listener for each media query with above handler as callback.
            mediaQueryLists.forEach((mql) => mql.addListener(handler));
            // Remove listeners on cleanup
            return () =>
                mediaQueryLists.forEach((mql) => mql.removeListener(handler));
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [] // Empty array ensures effect is only run on mount and unmount
    );

    return value;
}
