import * as React from "react";
import { Col, Row, Spinner } from "../../common/ui";
import BlankCard from "./BlankCard";
import { tr } from "../../../helpers/languages";

/**
 * CalcSiteColour
 * @description Returns the CSS class dependent on the SLA percentage
 * @param {*} value String containing value as percentage. (63%)
 * @returns CSS class name as string
 */
const CalcSiteColour = (value: string) => {
    var returnValue = "";
    const valueNum = parseInt(value.substring(0, value.length - 1));
    if (100 >= valueNum && valueNum >= 90) {
        returnValue = "dash-pos";
    } else if (89 >= valueNum && valueNum >= 50) {
        returnValue = "dash-warn";
    } else {
        returnValue = "dash-neg";
    }
    return returnValue;
};

//ToDo: Move to DashCardServiceDeskPerformance
type SitePerformance = {
    site: string,
    percentValue: string,
    percentRaw: number
};
type CardPerformanceProps = {
    activatePopup: () => void,
    children: JSX.Element,
    cardID: string,
    data: SitePerformance[],
    title: string,
    openHelpPopup: () => void,
    textTitle: string,
    custID: string,
    isCSE: boolean,
    customID: string,
    startDate: Date,
    endDate: Date,
    textType: string[]
};

/**{
    activatePopup,
    children,
    cardID,
    data,
    openHelpPopup,
    textTitle,
    textType,
    custID,
    isCSE,
    customID,
    startDate,
    endDate
} */

const CardPerformance = (props: CardPerformanceProps) => {
    if (props.data.length === 0) {
        return <></>;
    }
    return (
        <BlankCard
            activatePopup={props.activatePopup}
            title={props.textTitle}
            openHelpPopup={props.openHelpPopup}
            cardID={props.cardID}
            customID={props.customID}
            custID={props.custID}
            isCSE={props.isCSE}
            startDate={props.startDate}
            endDate={props.endDate}
        >
            {props.children}
            <CardPerformanceDataRow data={props.data} textType={props.textType} />
        </BlankCard>
    );
};

const CardPerformanceDataRow = (props: { data: SitePerformance[], textType: string[] }) => {
    var topThree = [];
    var bottomThree: SitePerformance[] = [];
    if (props.data.length >= 6) {
        topThree.push(props.data[0], props.data[1], props.data[2]);
        if (props.data[props.data.length - 1].percentRaw <= 89) {
            bottomThree.push(props.data[props.data.length - 1]);
        } else if (props.data[props.data.length - 2].percentRaw <= 89) {
            bottomThree.push(props.data[props.data.length - 2]);
        } else if (props.data[props.data.length - 3].percentRaw <= 89) {
            bottomThree.push(props.data[props.data.length - 3]);
        }
    }
    var cssClass = "";
    switch (props.data.length) {
        case 6:
        case 5:
            return (
                <Row style={{ flexWrap: "nowrap" }} className="dash-content-row">
                    <Col className={`dash-content-col main ${props.textType[0]}`} center>
                        <ul style={{ paddingLeft: "0px" }}>
                            {props.data.map((siteData, index) => {
                                if (index < 3) {
                                    cssClass = CalcSiteColour(siteData.percentValue);
                                    return (
                                        <li
                                            key={`sdPerfList ${index}`}
                                            style={{
                                                listStyleType: "none",
                                                fontSize: "20px",
                                                paddingBottom: "2px",
                                                justifyContent: "center",
                                                maxWidth: "274px",
                                                display: "flex",
                                                flexDirection: "row"
                                            }}
                                        >
                                            <p style={{ width: "220px", overflow: "hidden", textOverflow: "ellipsis" }}>{`${siteData.site} `}</p>
                                            <span className={cssClass} style={{ marginLeft: "1px" }}>
                                                {siteData.percentValue}
                                            </span>
                                        </li>
                                    );
                                } else {
                                    return <React.Fragment key={`sdPerfList ${index}`}></React.Fragment>;
                                }
                            })}
                        </ul>
                    </Col>
                    <Col className={`dash-content-col main ${props.textType[1]}`} center>
                        <ul style={{ paddingLeft: "0px", maxWidth: "274px" }}>
                            {props.data.map((siteData, index) => {
                                if (index >= 3) {
                                    cssClass = CalcSiteColour(siteData.percentValue);
                                    return (
                                        <li
                                            key={`sdPerfList ${index}`}
                                            style={{
                                                listStyleType: "none",
                                                fontSize: "20px",
                                                paddingBottom: "2px",
                                                overflow: "hidden",
                                                textOverflow: "ellipsis",
                                                maxWidth: "274px",
                                                display: "flex",
                                                flexDirection: "row"
                                            }}
                                        >
                                            <p style={{ width: "220px", overflow: "hidden", textOverflow: "ellipsis" }}>{`${siteData.site} `}</p>
                                            <span className={cssClass} style={{ marginLeft: "1px" }}>
                                                {siteData.percentValue}
                                            </span>
                                        </li>
                                    );
                                } else {
                                    return <React.Fragment key={`sdPerfList ${index}`}></React.Fragment>;
                                }
                            })}
                        </ul>
                    </Col>
                </Row>
            );
        case 4:
        case 3:
        case 2:
        case 1:
            return (
                <Row style={{ flexWrap: "nowrap" }} className="dash-content-row">
                    {props.data.map((siteData, index) => {
                        if (index < 3) {
                            cssClass = CalcSiteColour(siteData.percentValue);
                            return (
                                <Col center style={{ fontSize: "20px", userSelect: "none" }} key={`sdPerfList ${index}`}>
                                    <p style={{ maxHeight: "120px", overflow: "hidden", textOverflow: "ellipsis" }}>{`${siteData.site}`}</p>
                                    <span className={cssClass} style={{ fontSize: "36px" }}>
                                        {siteData.percentValue}
                                    </span>
                                </Col>
                            );
                        } else {
                            return <React.Fragment key={`sdPerfList ${index}`}></React.Fragment>;
                        }
                    })}
                </Row>
            );
        case 0:
            return <Row style={{ height: "166.6px" }}>{}</Row>;
        default:
            return (
                <Row style={{ flexWrap: "nowrap" }} className="dash-content-row">
                    <Col className={bottomThree.length !== 0 ? "dash-content-col dash-content-col-overflow" : ""} center>
                        <h3 style={{ fontSize: "22px", userSelect: "none", marginBottom: "0px" }}>{tr("DASHBOARD_SERVICEDESK_PERF_TOP")}</h3>
                        <div className={`main ${props.textType[0]}`}>
                            <ul style={{ paddingLeft: "0px" }}>
                                {topThree.map((siteData, index) => {
                                    cssClass = CalcSiteColour(siteData.percentValue);
                                    return (
                                        <li
                                            key={`sdPerfListBottom ${index}`}
                                            style={{
                                                maxWidth: "270px",
                                                listStyleType: "none",
                                                fontSize: "16px",
                                                display: "flex",
                                                flexDirection: "row",
                                                justifyContent: "center"
                                            }}
                                        >
                                            <p
                                                className={bottomThree.length !== 0 ? "dash-content-col dash-content-col-overflow" : ""}
                                                style={{ fontSize: "16px" }}
                                            >
                                                {`${siteData.site} `}
                                            </p>
                                            <span style={{ fontSize: "16px", paddingBottom: "2px", marginLeft: "1px" }} className={cssClass}>
                                                {siteData.percentValue}
                                            </span>
                                        </li>
                                    );
                                })}
                            </ul>
                        </div>
                    </Col>
                    {bottomThree.length > 0 ? (
                        <Col className={"dash-content-col dash-content-col-overflow"} center>
                            <h3 style={{ fontSize: "22px", userSelect: "none", marginBottom: "0px" }}>{tr("DASHBOARD_SERVICEDESK_PERF_BOTTOM")}</h3>
                            <div className={`main ${props.textType[1]}`}>
                                <ul style={{ paddingLeft: "0px" }}>
                                    {bottomThree.map((siteData, index) => {
                                        cssClass = CalcSiteColour(siteData.percentValue);
                                        return (
                                            <li
                                                key={`sdPerfListBottom ${index}`}
                                                style={{
                                                    maxWidth: "274px",
                                                    listStyleType: "none",
                                                    fontSize: "16px",
                                                    display: "flex",
                                                    flexDirection: "row",
                                                    justifyContent: "center"
                                                }}
                                            >
                                                <p className="dash-content-col dash-content-col-overflow" style={{ fontSize: "16px" }}>
                                                    {`${siteData.site} `}
                                                </p>
                                                <span className={cssClass} style={{ marginLeft: "1px" }}>
                                                    {siteData.percentValue}
                                                </span>
                                            </li>
                                        );
                                    })}
                                </ul>
                            </div>
                        </Col>
                    ) : (
                        <></>
                    )}
                </Row>
            );
    }
};

export default CardPerformance;
