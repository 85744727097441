const shared = {
    UPTODATE: "Up-to-date",
    OUTOFDATE: "Out-of-date",
    PENDING: "Pending",
    AUTORESOLVED: "Auto Resolved",
    EVENT_COUNT: "Event Count",
    TOTAL: "Total",
    BETA_TAG: "(BETA)"
};

const en = {
    HOME: "Home",
    ERROR: "Error!",
    INFO: "Info",
    SUCCESS: "Success!",
    OOPS: "Something went wrong. Please try again later.",
    SIGN_IN: "Sign In",
    SIGNOUT: "Sign out",
    APP_TITLE: "CSE Magellan API",
    UNKNOWN_ACCESSOR: "Failed to authenticate.",
    USER_AREA: "User area!",
    WARNING: "Warning",
    AUTHENTICATION_ERROR: "Invalid login credentials! Please try again!",
    EMAIL_CHANGE_SUCCESS: "Your email address has been changed!",
    EMAIL_ATTACHMENT: "Attachment",
    EMAIL_PREVIEW: "Preview",
    EMAIL_FROM: "From",
    EMAIL_TO: "To",
    EMAIL_CC: "Cc",
    EMAIL_SUBJECT: "Subject",
    EMAIL_SEND: "Send",
    EMAIL_ADDITIONAL_WARNING: "Additional email addresses need to be separated with semicolons!",
    EMAIL_SENT: "Email Sent Successfully",
    SCHEDULE_TITLE: "Scheduled Reports",
    SCHEDULE_CREATE: "Create Schedule",
    SCHEDULE_EDIT: "Edit",
    SCHEDULE_EDIT_TITLE: "Edit Schedule",
    SCHEDULE_DELETE: "Delete",
    SCHEDULE_DELETE_CONFIRMATION: "Are you sure you want to delete this schedule?",
    SCHEDULE_SEND_REPORTS_TYPE: "Schedule Reports",
    SCHEDULE_STARTING_AT: "From Date",
    MENU_HOMEPAGE: "Homepage",
    MENU_DASHBOARD: "Dashboard",
    MENU_CHANGELOG: "Changelog",
    MENU_RELEASENOTES: "Release Notes",
    MENU_TOGGLE_THEME: "Toggle Theme",
    MENU_SETTINGS: "Settings",
    MENU_SIGN_OUT: "Sign out",
    MENU_SCREENSHOT: "Screenshot",
    MENU_PROFILE_MANAGEMENT: "Profile Management",
    PASSWORD_POLICY_ERROR: "Your password must have a minimum of 8 characters!",
    ENABLE: "Enable",
    DISABLE: "Disable",
    SKIP_NAVIGATION: "Skip Navigation",
    CHANGELOG: "Changelog",
    WHATS_NEW: "What's New",
    ALL: "All",
    ALL_COMBINED: "All (Combined)",
    EXPORT_CSV: "Export To CSV",
    EXPORT_JSON: "Export To JSON",
    FILE_UPLOAD: "Click in this area to select a file.",
    //Login
    LOGIN_HEADER: "Login",
    LOGIN_USERNAME_LABEL: "Username",
    //Dashboard
    // General / Shared
    DASHBOARD_COUNT: shared.EVENT_COUNT,
    DASHBOARD_DASH_LAYOUT: "Dashboard Layout",
    DASHBOARD_SITE_SELECT: "Site",
    DASHBOARD_SERVER_SELECT: "Server",
    DASHBOARD_STATION_SELECT: "Station",
    DASHBOARD_DEVICE_TYPE: "Device Type",
    DASHBOARD_SCREENSHOT: "Take Screenshot",
    DASHBOARD_PHISHING: `Phishing Report ${shared.BETA_TAG}`,
    DASHBOARD_SEND_EMAIL: "Email Report",
    DASHBOARD_SCHEDULE: "Schedule",
    DASHBOARD_REPORT: `Generate Report ${shared.BETA_TAG}`,
    DASHBOARD_HELP: "Help",
    //Popout
    DASHBOARD_POPOUT_TIMEFRAME: "Timeframe selected: ",
    DASHBOARD_SEARCH_POPOUT_HOSTNAMES: "Search Host Names",
    DASHBOARD_SEARCH_POPOUT_IP: "Search IP Addresses",
    DASHBOARD_SEARCH_POPOUT_SERVERNAMES: "Search Server Names",
    DASHBOARD_SEARCH_POPOUT_USER: "Search Users",
    DASHBOARD_SEARCH_POPOUT_KEYWORDS: "Search Keywords",
    DASHBOARD_SEARCH_POPOUT_CATEGORIES: "Search Categories",
    DASHBOARD_SEARCH_POPOUT_ASSETS: "Search Assets",
    //ERRORS
    DASHBOARD_ERROR_GENERIC: "ERROR: ",
    DASHBOARD_ERROR_COMPONENT: "An error has occurred in this component.",
    DASHBOARD_ERROR_CARD: "ERROR: An error has occurred in this card. CardName = ",
    DASHBOARD_ERROR_DATE_RANGE: "ERROR: Maximum date range is 3 months.",
    DASHBOARD_SETTINGS_ERROR_FILE_READ: "ERROR: Cannot read file.",
    DASHBOARD_SETTINGS_ERROR_JSON: "ERROR: Invalid JSON.",
    DASHBOARD_SETTINGS_EXPORT_CANCEL: "ERROR: Cancelled Export.",
    DASHBOARD_SETTINGS_LOCAL_STORAGE: "ERROR: Cannot access local storage.",
    // Menu Items
    DASHBOARD_HIDDEN: "Hidden",
    DASHBOARD_CLASSIC: "Classic",
    DASHBOARD_DOUGHNUT: "Doughnut Chart",
    DASHBOARD_PIECHART: "Pie Chart",
    DASHBOARD_COMPACTGAUGE: "Compact Gauge",
    DASHBOARD_GAUGE: "Gauge",
    // Filter By
    DASHBOARD_FILTER_BY: "Filter By",
    DASHBOARD_FILTER_TODAY: "Today",
    DASHBOARD_FILTER_YESTERDAY: "Yesterday",
    DASHBOARD_FILTER_LAST_WEEK: "Last Week",
    DASHBOARD_FILTER_LAST_FORTNIGHT: "Last 14 Days",
    DASHBOARD_FILTER_LAST_MONTH: "Last Month",
    DASHBOARD_FILTER_LAST_TWO_MONTHS: "Last 2 Months",
    DASHBOARD_FILTER_LAST_THREE_MONTHS: "Last 3 Months",
    DASHBOARD_FILTER_LAST_SIX_MONTHS: "Last 6 Months",
    DASHBOARD_FILTER_LAST_YEAR: "Last Year",
    DASHBOARD_FILTER_ALL_TIME: "All Time",
    DASHBOARD_FILTER_TITLE: "Change Dashboard Time Frame",
    //SETTINGS
    DASHBOARD_SETTINGS: "Settings",
    DASHBOARD_SETTINGS_MAIN: "Dashboard Settings",
    DASHBOARD_SETTINGS_GENERAL: "General",
    DASHBOARD_SETTINGS_GENERAL_LAYOUT: "Dashboard Layout",
    DASHBOARD_SETTINGS_GENERAL_TOGGLE: "Toggle Dashboard Cards",
    DASHBOARD_SETTINGS_CSE: "Private",
    DASHBOARD_SETTINGS_CSE_SITE: "Change Customer",
    DASHBOARD_SETTINGS_CSE_INTERNAL: "Open CSE Internal",
    DASHBOARD_SETTINGS_RESET_LAYOUT: "Reset Layout",
    DASHBOARD_SETTINGS_SAVE_LAYOUT: "Save Layout",
    DASHBOARD_SETTINGS_SAVE_CARDS: "Save Cards",
    DASHBOARD_SETTINGS_CARDS_PREVIEW: "Card Preview",
    DASHBOARD_SETTINGS_IMPORT_LAYOUT: "Import Layout",
    DASHBOARD_SETTINGS_EXPORT_LAYOUT: "Export Layout",
    DASHBOARD_SETTINGS_LAYOUT_SET: "Layout Set",
    DASHBOARD_SETTINGS_LAYOUT_RESET: "Layout Set",
    DASHBOARD_SETTINGS_DEBUG: "Debug",
    DASHBOARD_SETTINGS_DEBUG_REFRESH: "Refresh & Clear Cache",
    DASHBOARD_SETTINGS_MARQUEE: "Marquee Mode",
    DASHBOARD_SETTINGS_MARQUEE_ENABLE: "Enable Marquee Mode",
    DASHBOARD_SETTINGS_MARQUEE_DISABLE: "Disable Marquee Mode",
    DASHBOARD_SETTINGS_AUTO_REFRESH: "Refresh Every",
    DASHBOARD_SETTINGS_MARQUEE_WARNING: "Refresh browser to toggle Marquee mode!",
    DASHBOARD_SETTINGS_CURRENT_VERSION: "Current Version",
    DASHBOARD_SETTINGS_GRAPH_TEXT_SIZE: "Card Details Graph Text Size",
    //COMMENTS
    DASHBOARD_COMMENTS: "Comments",
    DASHBOARD_COMMENTS_NEW: "New Comment",
    DASHBOARD_COMMENTS_ARCHIVE: "Archive Comment",
    // Server Status
    DASHBOARD_SERSTATUS_TITLE: "Server Online Status",
    DASHBOARD_SERSTATUS_TOTAL: "Total Servers",
    DASHBOARD_SERSTATUS_ONLINE: "Online Servers",
    DASHBAORD_SERSTATUS_OFFLINE: "Offline Servers",
    DASHBOARD_SERSTATUS_GAUGE: "Servers Online",
    // Anti-Virus
    DASHBOARD_AV_SERVER_TITLE: "Server Anti-Threat Status",
    DASHBOARD_AV_STATION_TITLE: "Station Threat Protection",
    DASHBOARD_AV_ANTI_THREAT: "Anti-Threat",
    DASHBOARD_AV_UPTODATE: shared.UPTODATE,
    DASHBOARD_AV_OUTOFDATE: shared.OUTOFDATE,
    DASHBOARD_AV_NOTRUN: "Not Running",
    DASHBOARD_AV_NOAV: "No Anti-Virus",
    DASHBOARD_AV_AUTORES: shared.AUTORESOLVED,
    DASHBOARD_AV_BY_DEVICE: "Anti-Threat by Device",
    DASHBOARD_AV_STATION_GAUGE: "Stations Protected",
    // Volume Storage
    DASHBOARD_VOL_STOR_SERVER_TITLE: "Server Volume Storage",
    DASHBOARD_VOL_STOR_STATION_TITLE: "Station Volume Storage",
    DASHBOARD_VOL_STOR_TOTAL: "Total Volumes",
    DASHBOARD_VOL_STOR_UNDER90: "Volumes Under 90%",
    DASHBOARD_VOL_STOR_GAUGE: "Station Volumes Under 90%",
    DASHBOARD_VOL_STOR_OVER90: "Volumes over 90%",
    DASHBOARD_VOL_STOR_AUTORES: "Auto Resolved",
    DASHBOARD_VOL_STOR_POPOUT_TITLE: "Storage by Device",
    // Login Information
    DASHBOARD_LOGIN_SERVER_TITLE: "Server Login Information",
    DASHBOARD_LOGIN_STATION_TITLE: "Station Login Information",
    DASHBOARD_LOGIN_SERVER_LOGINS: "Server Logins",
    DASHBOARD_LOGIN_STATION_LOGINS: "Station Logins",
    DASHBOARD_LOGINS_TOP_SERVERS: "Top logged in Servers",
    DASHBOARD_LOGINS_TOP_STATIONS: "Top logged in Stations",
    DASHBOARD_LOGINS_DEVICE: "Logons",
    // Windows Updates
    DASHBOARD_WIN_UPDATE_TITLE: "Server Pending Windows Updates",
    DASHBOARD_WIN_UPDATE_STATUS: "Server Pending Windows Updates",
    DASHBOARD_WIN_UPDATE_UPTODATE: shared.UPTODATE,
    DASHBOARD_WIN_UPDATE_PENDING: shared.PENDING,
    DASHBOARD_WIN_UPDATE_AVAILABLE: "Updates Available",
    DASHBOARD_WIN_UPDATE_POPUP_BAR_TITLE: "Total Pending Updates Per Server",
    // Windows Updates Installed
    DASHBOARD_WIN_UPDATE_INSTALLED_TITLE: "Server Installed Windows Updates",
    DASHBOARD_WIN_UPDATE_INSTALLED_TOTAL: "Total Updates Installed",
    DASHBOARD_WIN_UPDATE_INSTALLED_PATCH: "Patches",
    DASHBOARD_WIN_UPDATE_INSTALLED_HOTFIX: "Hotfixes",
    // Backups
    DASHBOARD_BACKUPS_TITLE: "Backups",
    DASHBOARD_BACKUPS_TOTAL: "Total Backups",
    DASHBOARD_BACKUPS_SUCC: "Successful",
    DASHBOARD_BACKUPS_WARN: "Warnings",
    DASHBOARD_BACKUPS_FAIL: "Failed",
    // CSE Remote Agent Alerts
    DASHBOARD_CSE_REMAGENT_TITLE: "CSE Remote Agent Alerts",
    DASHBOARD_CSE_REMAGENT_TOTAL: "Total",
    DASHBOARD_CSE_REMAGENT_RESOLVED: "Resolved",
    DASHBOARD_CSE_REMAGENT_UNRESOLVED: "Unresolved",
    DASHBOARD_CSE_REMAGENT_UNRESOLVED_TITLE: "Unresolved (Ignores Day Range)",
    // Application Usage
    DASHBOARD_APP_USAGE_TITLE: "Application Usage Top 3",
    DASHBOARD_APP_USAGE_TOP: "Top Applications",
    DASHBOARD_APP_USAGE_POPOUT_TITLE: "Application Usage",
    // Time Saved
    DASHBOARD_TIME_SAVED_TITLE: "Time Saved",
    DASHBOARD_TIME_SAVED_AUTORES: shared.AUTORESOLVED,
    // Stations Last Seen (Stations Last Booted)
    DASHBOARD_STATIONLASTSEEN_TITLE: "Stations Last Booted",
    DASHBOARD_STATIONLASTSEEN_TOTAL: "Total Stations",
    DASHBOARD_STATIONLASTSEEN_90: "90 Days",
    DASHBOARD_STATIONLASTSEEN_30: "30 Days",
    DASHBOARD_STATIONLASTSEEN_14: "14 Days",
    DASHBOARD_STATIONLASTSEEN_7: "7 Days",
    DASHBOARD_STATIONLASTSEEN_NUM: "Number of stations",
    DASHBOARD_STATIONLASTSEEN_POPOUT_TITLE: "Station List",
    // Service Desk Stats
    DASHBOARD_SERVICEDESK_TITLE: "Service Desk Stats",
    DASHBOARD_SERVICEDESK_TOTAL: "Current Open Requests",
    DASHBOARD_SERVICEDESK_OPENED: "Opened Last $#days#",
    DASHBOARD_SERVICEDESK_CLOSED: "Closed Last $#days#",
    DASHBOARD_SERVICEDESK_REOPENED: "Reopened Last $#days#",
    DASHBOARD_SERVICEDESK_SLA: "SLA",
    DASHBOARD_SERVICEDESK_TECH: "SR Tech Per Site",
    DASHBOARD_SERVICEDESK_EXCLUSION: "*Excluding CSE Client Alert Tickets",
    // Unexpected Server Shutdowns
    DASHBOARD_UNEXP_SHUTDOWN_TITLE: "Unexpected Server Shutdowns",
    DASHBOARD_UNEXP_SHUTDOWN_NUMBER: "Number of Unexpected Shutdowns",
    DASHBOARD_UNEXP_SHUTDOWN_POPOUT: "Unexpected Shutdowns",
    DASHBOARD_UNEXP_SHUTDOWN_SHUTDOWNS: "Shutdowns",
    // Network Device Status
    DASHBOARD_NETWORK_DEVICE_STATUS_TITLE: "Network Devices Status",
    DASHBOARD_NETWORK_DEVICE_STATUS_TOTAL: "Total Devices",
    DASHBOARD_NETWORK_DEVICE_STATUS_ONLINE: "Online Devices",
    DASHBOARD_NETWORK_DEVICE_STATUS_OFFLINE: "Offline Devices",
    DASHBOARD_NETWORK_DEVICE_STATUS_UNKNOWN: "Unmonitored Devices",
    DASHBOARD_NETWORK_DEVICE_STATUS_GAUGE: "Devices Online",
    DASHBOARD_NETWORK_DEVICE_STATUS_SWITCH_TITLE: "Network Switches Status",
    DASHBOARD_NETWORK_DEVICE_STATUS_SWITCH_TOTAL: "Total Switches",
    DASHBOARD_NETWORK_DEVICE_STATUS_SWITCH_ONLINE: "Online Switches",
    DASHBOARD_NETWORK_DEVICE_STATUS_SWITCH_OFFLINE: "Offline Switches",
    DASHBOARD_NETWORK_DEVICE_STATUS_SWITCH_UNKNOWN: "Unmonitored Switches",
    DASHBOARD_NETWORK_DEVICE_STATUS_SWITCH_GAUGE: "Switches Online",
    DASHBOARD_NETWORK_DEVICE_STATUS_WAP_TITLE: "Wireless Access Points Status",
    DASHBOARD_NETWORK_DEVICE_STATUS_WAP_TOTAL: "Total APs",
    DASHBOARD_NETWORK_DEVICE_STATUS_WAP_ONLINE: "Online APs",
    DASHBOARD_NETWORK_DEVICE_STATUS_WAP_OFFLINE: "Offline APs",
    DASHBOARD_NETWORK_DEVICE_STATUS_WAP_UNKNOWN: "Unmonitored APs",
    DASHBOARD_NETWORK_DEVICE_STATUS_WAP_GAUGE: "APs Online",
    // Laptop Battery Health
    DASHBOARD_LAPTOP_BATTERY_HEALTH_TITLE: "Notebook Battery Health",
    DASHBOARD_LAPTOP_BATTERY_HEALTH_GAUGE: "Notebooks Battery Good",
    DASHBOARD_LAPTOP_BATTERY_HEALTH_TOTAL: "Total Devices Scanned",
    DASHBOARD_LAPTOP_BATTERY_HEALTH_GOOD: "Good Battery Health",
    DASHBOARD_LAPTOP_BATTERY_HEALTH_POOR: "Poor Battery Health",
    DASHBOARD_LAPTOP_BATTERY_HEALTH_NO: "No Battery Detected",
    DASHBOARD_LAPTOP_BATTERY_HEALTH_POPOUT: "Notebooks Battery Report",
    // Station Windows 11 Readiness
    DASHBOARD_WINDOWS_ELEVEN_READY_TITLE: "Windows 11 Readiness",
    DASHBOARD_WINDOWS_ELEVEN_READY_GAUGE: "Stations Ready To Upgrade",
    DASHBOARD_WINDOWS_ELEVEN_READY_TOTAL: "Total Scanned",
    DASHBOARD_WINDOWS_ELEVEN_READY_READY: "Ready To Upgrade",
    DASHBOARD_WINDOWS_ELEVEN_READY_NOTREADY: "Action Required",
    DASHBOARD_WINDOWS_ELEVEN_READY_MODEL_TITLE: "Device Models Unable to Upgrade",
    // Service Desk Trends
    DASHBOARD_SERVICEDESK_TRENDS_TITLE: "Service Desk Trends",
    DASHBOARD_SERVICEDESK_TRENDS_LABEL: "Number Of Open Requests",
    DASHBOARD_SERVICEDESK_TRENDS_HISTORICAL: "View Historical Data",
    DASHBOARD_SERVICEDESK_TRENDS_CURRENT: "View Current Data",
    //Service Desk Open Requests
    DASHBOARD_SERVICEDESK_OPEN_REQ_TITLE: "Service Desk Created Requests",
    DASHBOARD_SERVICEDESK_OPEN_REQ_LABEL: "Number Of Created Requests",
    //Service Desk Closed Requests
    DASHBOARD_SERVICEDESK_CLOSED_REQ_TITLE: "Service Desk Closed Requests",
    DASHBOARD_SERVICEDESK_CLOSED_REQ_LABEL: "Number Of Closed Requests",
    // Server Restart Required
    DASHBOARD_SERVER_RESTART_TITLE: "Server Restart Required",
    DASHBOARD_SERVER_RESTART_TEXT: "Servers",
    DASHBOARD_SERVER_RESTART_LIST: "Servers Include:",
    // Service Desk Performance
    DASHBOARD_SERVICEDESK_PERF_TITLE: "Service Desk Site Performance",
    DASHBOARD_SERVICEDESK_PERF_TOP: "Top Performing Sites",
    DASHBOARD_SERVICEDESK_PERF_BOTTOM: "Bottom Performing Sites",
    DASHBOARD_SERVICEDESK_PERF_FULL_YEAR: "View Full Academic Year",
    DASHBOARD_SERVICEDESK_PERF_AUTUMN: "View Autumn Term",
    DASHBOARD_SERVICEDESK_PERF_SPRING: "View Spring Term",
    DASHBOARD_SERVICEDESK_PERF_SUMMER: "View Summer Term",
    // Activity Per User
    DASHBOARD_USER_ACTIVITY_TITLE: "Activity Per User",
    // Top Service Desk Users
    DASHBOARD_TOP_SERVICE_DESK_USERS_TITLE: "Top Service Desk User Activity",
    DASHBOARD_TOP_SERVICE_DESK_USERS_SUB_TITLE: "User Activity:",
    // Service Desk Feedback
    DASHBOARD_SERVICEDESK_FEEDBACK_TITLE: "Service Desk Feedback",
    DASHBOARD_SERVICEDESK_FEEDBACK_TOTAL_FEEDBACK: "Feedback Left",
    DASHBOARD_SERVICEDESK_FEEDBACK_HAPPY_FEEDBACK: "Happy Feedback",
    DASHBOARD_SERVICEDESK_FEEDBACK_FINE_FEEDBACK: "Fine Feedback",
    DASHBOARD_SERVICEDESK_FEEDBACK_UNHAPPY_FEEDBACK: "Unhappy Feedback",
    // Service Desk Top keywords
    DASHBOARD_SERVICEDESK_KEYWORDS_TITLE: "Service Desk Top Keywords",
    // Service Desk Top Categories
    DASHBOARD_SERVICEDESK_CATEGORIES_TITLE: "Service Desk Top Categories",
    // Sami Knowledge Base Feedback
    DASHBOARD_SAMI_KB_TITLE: "Sami Knowledge Base Feedback",
    // Sami Token Usage
    DASHBOARD_SAMI_TOKEN_USAGE_TITLE: "Sami Token Usage",
    // Sami Conversation Count
    DASHBOARD_SAMI_CONVERSATION_COUNT_TITLE: "Sami Conversation Count",
    // Service Desk SLA
    DASHBOARD_SERVICEDESK_SLA_TITLE: 'Service Desk SLA',
    DASHBOARD_SERVICEDESK_SLA_PASSED: 'Passed SLA',
    DASHBOARD_SERVICEDESK_SLA_FAILED: 'Failed SLA',
    // Asset Changes
    DASHBOARD_ASSET_CHANGES_TITLE: "New Assets",
    DASHBOARD_ASSET_CHANGES_ALT: "Assets Added",
    DASHBOARD_ASSET_CHANGES_NO_ADDED: "No New Assets Added",
    //Top Service Desk Request Users
    DASHBOARD_SERVICEDESK_TOP_REQUEST_TITLE: "Top Service Desk Request Users",
    //Help
    // Help General
    HELP_RETURN_TO_CONTENTS: "Return To Contents",
    //Phishing Reports
    // Phishing General
    PHISHING_REPORT_TITLE: "Automated Phishing Report Generator",
    PHISHING_REPORT_BETA: shared.BETA_TAG,
    PHISHING_REPORT_FILE_LOADED: "File Selected:",
    //CSE Internal
    CSE_INTERNAL_TITLE: "CSE Internal Use Only",
    CSE_INTERNAL_ALL_OFFLINE_SERVERS: "All Offline Servers",
    CSE_INTERNAL_CSE_ACTIVITY: "CSE Activity Per User",
    CSE_INTERNAL_USER_ACTIVITY: "User Activity",
    CSE_INTERNAL_UNKNOWN_DEVICES_TITLE: "All Unknown Devices",
    CSE_INTERNAL_ALL_FEEDBACK: "All Feedback"
};

export default en;
