import * as React from "react";
import { Col, Row } from "../../common/ui";
import BlankCard from "./BlankCard";

type CardClassicProps = {
    title: string,
    children: JSX.Element,
    cardID: string,
    data: (string | number)[],
    type: string[],
    altTextType?: string[],
    altText: string[],
    getDone: boolean,
    activatePopup: () => void,
    openHelpPopup: () => void,
    custID: string,
    startDate: Date,
    endDate: Date,
    isCSE: boolean,
    customID: string
};

const CardClassic = (props: CardClassicProps) => {
    let colMaxWidthLengthClassName = "";
    switch (props.data.length) {
        case 1:
            colMaxWidthLengthClassName = "dash-content-col-1"
            break;
        case 2:
            colMaxWidthLengthClassName = "dash-content-col-2"
            break;
        case 3:
            colMaxWidthLengthClassName = "dash-content-col-3"
            break;
        case 4:
            colMaxWidthLengthClassName = "dash-content-col-4"
            break;
        case 0:
        default:
            break;
    }
    return (
        <BlankCard
            customID={props.customID}
            activatePopup={props.activatePopup}
            title={props.title}
            openHelpPopup={props.openHelpPopup}
            cardID={props.cardID}
            custID={props.custID}
            isCSE={props.isCSE}
            startDate={props.startDate}
            endDate={props.endDate}
        >
            {props.children}
            <Row style={{ flexWrap: "nowrap" }} className="dash-content-row">
                {props.data.map((dataItem, index) => {
                    return (
                        <Col key={"col" + index} className={`dash-content-col ${colMaxWidthLengthClassName}`} center>
                            <div className={"main " + props.type[index]}>
                                {dataItem}
                                <br />
                            </div>
                            <p className={props.altTextType ? props.altTextType[index] : ""} style={{ whiteSpace: "normal" }}>
                                {props.altText[index]}
                            </p>
                        </Col>
                    );
                })}
            </Row>
        </BlankCard>
    );
}

export default CardClassic;
