import * as React from "react";
import userManager from "../../../util/userManager";
import { send } from "../../../helpers/requests";
import DataTable from "react-data-table-component";
import { tr } from "../../../helpers/languages";
import dayjs from "dayjs";
import { CSVLink } from "react-csv";
import { Spinner } from "../../common/ui";
import { isPast } from "date-fns";
import { DatesToString } from "./SharedPopoutFunctions";

import styles from "./popout.module.css";

export default class ServiceDeskPerformancePopout extends React.PureComponent {
    _isMounted = false;
    constructor(props) {
        super(props);
        this.state = {
            getDone: false,
            startDate: this.props.startDate,
            endDate: this.props.endDate,
            ServiceDeskPerformance: []
        };
    }
    componentDidMount() {
        this._isMounted = true;
        this.getServiceDeskPerformance(this.props.custID, this.props.startDate, this.props.endDate);
    }
    componentWillUnmount() {
        this._isMounted = false;
    }
    ///
    /// API Calls
    ///
    /**
     * getAccessToken
     * @description Gets the access token of the authenticated user
     */
    getAccessToken = async () => {
        let token = (await userManager.getUser()).access_token;
        return token;
    };
    /**
     * getServiceDeskPerformance
     * @description Gets Service Desk Performance as an array.
     * @param {string} custID Customer ID
     * @param {Date} startDate Start date as JS Date
     * @param {Date} endDate End date as JS Date
     */
    getServiceDeskPerformance = async (custID, startDate, endDate) => {
        const timeZoneOffset = startDate.getTimezoneOffset() * 60;
        const newStartDate = Math.floor(startDate.getTime() / 1000) - timeZoneOffset;
        const newEndDate = Math.floor(endDate.getTime() / 1000) - timeZoneOffset;
        send({
            method: "GET",
            url: `/api/reporting/siteslaperformance?custid=${custID}&startdate=${newStartDate}&enddate=${newEndDate}`,
            token: await this.getAccessToken()
        })
            .then((response) => {
                if (this._isMounted) {
                    this.props.popoutRendered();
                    this.setState({
                        getDone: true,
                        ServiceDeskPerformance: response.data
                    });
                }
            })
            .catch((error) => {
                console.log(error);
            });
    };
    //Other
    columns = [
        {
            name: "Site Name",
            selector: (row) => row.site,
            sortable: true,
            reorder: true
        },
        {
            name: "Percent",
            selector: (row) => row.percentValue,
            sortable: true,
            reorder: true,
            conditionalCellStyles: [
                {
                    when: (row) =>
                        100 >= row.percentValue.substring(0, row.percentValue.length - 1) &&
                        row.percentValue.substring(0, row.percentValue.length - 1) >= 90,
                    style: {
                        color: "#31c024"
                        //,opacity: "0.9"
                    }
                },
                {
                    when: (row) =>
                        89 >= row.percentValue.substring(0, row.percentValue.length - 1) &&
                        row.percentValue.substring(0, row.percentValue.length - 1) >= 50,
                    style: {
                        color: "#f2ce2c"
                        //,opacity: "0.9"
                    }
                },
                {
                    when: (row) => row.percentValue.substring(0, row.percentValue.length - 1) <= 49,
                    style: {
                        color: "#f12a2a"
                        //,opacity: "0.9"
                    }
                }
            ]
        }
    ];
    render() {
        if (!this.state.getDone) {
            return (
                <div
                    style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        marginTop: "10rem"
                    }}
                >
                    <Spinner size="250" />
                </div>
            );
        }
        const ServiceDeskPerformance = JSON.parse(JSON.stringify(this.state.ServiceDeskPerformance));
        const dates = DatesToString(this.state.startDate.toDateString(), this.state.endDate.toDateString());
        return (
            <div className={styles.popoutContainer}>
                <div>
                    <h2 className={styles.popoutHeader}>{tr("DASHBOARD_SERVICEDESK_PERF_TITLE")}</h2>
                </div>
                {dates && (
                    <h3 style={{ fontSize: "18px", display: "flex", justifyContent: "center", alignItems: "center", width: "100%" }}>
                        {tr("DASHBOARD_POPOUT_TIMEFRAME") + dates[0] + " to " + dates[1]}
                    </h3>
                )}
                <div style={{ display: "flex", flexDirection: "column" }} className={styles.popoutExport}>
                    <CSVLink
                        className={styles.exportCSVButton}
                        data={ServiceDeskPerformance}
                        filename={"export " + dayjs().format("DD-MM-YYYY h-mm-ss") + ".csv"}
                    >
                        {tr("EXPORT_CSV")}
                    </CSVLink>
                </div>
                <div style={{ float: "left", display: "flex", flexDirection: "column", marginBottom: "2rem" }}>
                    <button
                        className={styles.exportCSVButton}
                        onClick={() => {
                            this.setState({
                                getDone: false,
                                startDate: this.props.startDate,
                                endDate: this.props.endDate
                            });
                            this.getServiceDeskPerformance(this.props.custID, this.props.startDate, this.props.endDate);
                        }}
                    >
                        {tr("DASHBOARD_SERVICEDESK_TRENDS_CURRENT")}
                    </button>
                    <button
                        className={styles.exportCSVButton}
                        onClick={() => {
                            var startDate = new Date();
                            var endDate = new Date();
                            const curYear = new Date().getFullYear();
                            const sep = new Date(`${curYear}-09-01T00:00:00Z`); //1st Sep ${curYear}
                            if (isPast(sep)) {
                                //If this date is in the past then use the current year
                                //This year Sep - end of July
                                startDate = new Date(`${curYear + 1}-07-31T23:59:00`); //31st July ${curYear + 1}
                                endDate = sep; //1st Sep ${curYear}
                            } else {
                                //Last year Sep - end of July
                                startDate = new Date(`${curYear}-07-31T23:59:00`); //31st July ${curYear}
                                endDate = new Date(`${curYear - 1}-09-01T00:00:00Z`); //1st Sep ${curYear - 1}
                            }
                            this.setState({
                                getDone: false,
                                startDate: startDate,
                                endDate: endDate
                            });
                            this.getServiceDeskPerformance(this.props.custID, startDate, endDate);
                        }}
                    >
                        {tr("DASHBOARD_SERVICEDESK_PERF_FULL_YEAR")}
                    </button>
                    <button
                        className={styles.exportCSVButton}
                        onClick={() => {
                            var startDate = new Date();
                            var endDate = new Date();
                            const curYear = new Date().getFullYear();
                            const sep = new Date(`${curYear}-09-01T00:00:00Z`); //1st Sep ${curYear}
                            if (isPast(sep)) {
                                //If this date is in the past then use the current year
                                //This year Sep-Dec
                                startDate = new Date(`${curYear}-12-31T23:59:00Z`); //31st Dec ${curYear}
                                endDate = sep;
                            } else {
                                //Last Year Sep-Dec
                                startDate = new Date(`${curYear - 1}-12-01T23:59:00Z`); //31st Dec ${curYear - 1}
                                endDate = new Date(`${curYear - 1}-09-01T00:00:00Z`); //1st Sep ${curYear - 1}
                            }
                            this.setState({
                                getDone: false,
                                startDate: startDate,
                                endDate: endDate
                            });
                            this.getServiceDeskPerformance(this.props.custID, startDate, endDate);
                        }}
                    >
                        {tr("DASHBOARD_SERVICEDESK_PERF_AUTUMN")}
                    </button>
                    <button
                        className={styles.exportCSVButton}
                        onClick={() => {
                            var startDate = new Date();
                            var endDate = new Date();
                            const curYear = new Date().getFullYear();
                            const jan = new Date(`${curYear}-01-01T00:00:00Z`); //1st Jan ${curYear}
                            if (isPast(jan)) {
                                //If this date is in the past then use the current year
                                //This year Sep-Dec
                                startDate = new Date(`${curYear}-03-31T23:59:00Z`); //31st March ${curYear}
                                endDate = jan;
                            } else {
                                //Last Year Sep-Dec
                                startDate = new Date(`${curYear - 1}-03-31T23:59:00Z`); //31st March ${curYear - 1}
                                endDate = new Date(`${curYear - 1}-01-01T00:00:00Z`); //1st Jan ${curYear - 1}
                            }
                            this.setState({
                                getDone: false,
                                startDate: startDate,
                                endDate: endDate
                            });
                            this.getServiceDeskPerformance(this.props.custID, startDate, endDate);
                        }}
                    >
                        {tr("DASHBOARD_SERVICEDESK_PERF_SPRING")}
                    </button>
                    <button
                        className={styles.exportCSVButton}
                        onClick={() => {
                            var startDate = new Date();
                            var endDate = new Date();
                            const curYear = new Date().getFullYear();
                            const april = new Date(`${curYear}-04-01T00:00:00Z`); //1st April ${curYear}
                            if (isPast(april)) {
                                //If this date is in the past then use the current year
                                //This year Sep-Dec
                                startDate = new Date(`${curYear}-07-31T23:59:00Z`); //31st July ${curYear}
                                endDate = april;
                            } else {
                                //Last Year Sep-Dec
                                startDate = new Date(`${curYear - 1}-07-31T23:59:00Z`); //31st July ${curYear - 1}
                                endDate = new Date(`${curYear - 1}-04-01T00:00:00Z`); //1st April ${curYear - 1}
                            }
                            this.setState({
                                getDone: false,
                                startDate: startDate,
                                endDate: endDate
                            });
                            this.getServiceDeskPerformance(this.props.custID, startDate, endDate);
                        }}
                    >
                        {tr("DASHBOARD_SERVICEDESK_PERF_SUMMER")}
                    </button>
                </div>
                <div id="serviceDeskPerformanceTable" className={styles.popoutDataTable}>
                    <DataTable columns={this.columns} data={ServiceDeskPerformance} theme={this.props.theme} pagination />
                </div>
            </div>
        );
    }
}
