import React from 'react'
import { Component } from 'react'
import { Route, Redirect } from 'react-router-dom'
import { ApplicationPaths, QueryParameterNames } from './AuthorizationConstants'
import userManager from "../../../util/userManager";

export default class AuthorizeRoute extends Component {
  constructor(props) {
    super(props);

    this.state = {
      ready: false,
      authenticated: false
    };
  }

    componentDidMount() {
        userManager.events.addUserSignedIn(() => this.authenticationChanged('IN'))
        userManager.events.addUserSignedOut(() => this.authenticationChanged('OUT'))
        userManager.events.addUserUnloaded(() => this.authenticationChanged('UNLOAD'))
        userManager.events.addUserLoaded(() => this.authenticationChanged('LOAD'))
        userManager.events.addUserSessionChanged(() => this.authenticationChanged('SESS'))
        userManager.events.addSilentRenewError(() => this.SilentRenewError())
        userManager.events.addAccessTokenExpiring(() => this.accessTokenExpiring())
    this.populateAuthenticationState();
  }

  componentWillUnmount() {
      userManager.events.removeUserSignedIn(() => this.authenticationChanged('IN'))
      userManager.events.removeUserSignedOut(() => this.authenticationChanged('OUT'))
      userManager.events.removeUserUnloaded(() => this.authenticationChanged('UNLOAD'))
      userManager.events.removeUserLoaded(() => this.authenticationChanged('LOAD'))
      userManager.events.removeUserSessionChanged(() => this.authenticationChanged('SESS'))
      userManager.events.removeSilentRenewError(() => this.SilentRenewError())
      userManager.events.removeAccessTokenExpiring(() => this.accessTokenExpiring())
  }

    SilentRenewError() {
        console.log('SilentRenewError',this);
    }
    async  accessTokenExpiring() {

        userManager._settings.extraQueryParams.domain_hint =await  userManager.getCustomerIDAsync();
        userManager._settings.extraTokenParams.domain_hint = await userManager.getCustomerIDAsync();
        console.log('AccessTokenExpiring', userManager.settings);
    }
  render() {
    const { ready, authenticated } = this.state;
    var link = document.createElement("a");
    link.href = this.props.path;
    const returnUrl = `${link.protocol}//${link.host}${link.pathname}${link.search}${link.hash}`;
    const redirectUrl = `${ApplicationPaths.Login}?${QueryParameterNames.ReturnUrl}=${encodeURIComponent(returnUrl)}`
    if (!ready) {
      return <div></div>;
    } else {
      const { component: Component, ...rest } = this.props;
      return <Route {...rest}
        render={(props) => {
          if (authenticated) {
            return <Component {...props} />
          } else {
            return <Redirect to={redirectUrl} />
          }
        }} />
    }
  }

  async populateAuthenticationState() {
      let user = await userManager.getUser();
      const authenticated = user !==null;
    this.setState({ ready: true, authenticated });
  }

    async authenticationChanged(status) {
       // console.log('ac:', status);
    this.setState({ ready: false, authenticated: false });
    await this.populateAuthenticationState();
  }
}
