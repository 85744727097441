import React, { useState } from "react";
import { RouteComponentProps } from "react-router";
import { refreshCacheAndReload } from "./ForceCacheCleanup";
import { Container, Spinner } from "../ui";

const CacheLoadingComponent = () => {
    return (
        <Container center>
            <h1 style={{ textAlign: "center" }}>Updating</h1>
            <div style={{ display: "flex", justifyContent: "center", alignItems: "center", margin: "1rem", padding: "1rem" }}>
                <Spinner size={500} />
            </div>
        </Container>
    );
};

const CacheCleanup: React.FC<RouteComponentProps> = ({ history }) => {
    const [loading, setLoading] = useState(true);
    refreshCacheAndReload().then(() => {
        setLoading(false);
    });
    if (loading) {
        return <CacheLoadingComponent />;
    } else {
        history.push("/");
        return <></>;
    }
};

export { CacheCleanup };
