import React, { useState } from 'react';
import { Collapse } from 'reactstrap';
import { Button } from '../../common/ui';
import styles from './SettingsCollapse.module.css';

type SettingsCollapseProps = {
    buttonLabel: string
    children: JSX.Element
}

const SettingsCollapse: React.FC<SettingsCollapseProps> = (props) => {
    const [isOpen, setIsOpen] = useState(false);
    const toggleOpen = () => setIsOpen(!isOpen);
    return(
        <section className={styles.section}>
            <Button primary onClick={toggleOpen} className={styles.layoutButton}>{props.buttonLabel}</Button>
            <Collapse isOpen={isOpen}>
                {props.children}
            </Collapse>
        </section>
    );
}

export default SettingsCollapse;