import * as React from "react";
import userManager from "../../../util/userManager";
import { send } from "../../../helpers/requests";
import DataTable from "react-data-table-component";
import dayjs from "dayjs";
import plugin from "dayjs/plugin/advancedFormat";
import { tr } from "../../../helpers/languages";
import { Input, Label } from "reactstrap";
import { CSVLink } from "react-csv";
import { Spinner } from "../../common/ui";

import styles from "./popout.module.css";

dayjs.extend(plugin);

const columns = [
    {
        name: "Server Name",
        selector: (row) => row.serverName,
        sortable: true,
        reorder: true
    },
    {
        name: "Online?",
        selector: (row) => {
            if (row.onlineAt === null) {
                return "False";
            } else if (row.onlineAt === "Unknown") {
                return "Unknown";
            } else {
                return "True";
            }
        },
        conditionalCellStyles: [
            {
                when: (row) => row.onlineAt === null,
                style: {
                    background: "linear-gradient(to right, red 50%, transparent 0)",
                    fontSize: "0px",
                    margin: "5px 0"
                }
            },
            {
                when: (row) => row.onlineAt !== null,
                style: {
                    background: "linear-gradient(to right, green 50%, transparent 0)",
                    fontSize: "0px",
                    margin: "5px 0"
                }
            },
            {
                when: (row) => row.isOnline,
                style: {
                    background: "linear-gradient(to right, green 50%, transparent 0)",
                    fontSize: "0px",
                    margin: "5px 0"
                }
            },
            {
                when: (row) => row.onlineAt === "Unknown",
                style: {
                    background: "linear-gradient(to right, orange 50%, transparent 0)",
                    fontSize: "0px",
                    margin: "5px 0"
                }
            }
        ],
        sortable: true,
        reorder: true
    },
    {
        name: "Uptime",
        selector: (row) => {
            if (row.onlineAt !== null && parseInt(row.onlineAt)) {
                var rowOnlineAt = dayjs(row.onlineAt);
                var suffix = "";
                if (dayjs().diff(rowOnlineAt, "year") !== 0) {
                    const yearsOnline = dayjs().diff(rowOnlineAt, "year"); //What's the difference in time between onlineTime and Now. Returns number of "year"(s)
                    if (yearsOnline !== 1) {
                        suffix = " years";
                    } else {
                        suffix = " year";
                    }
                    return yearsOnline + suffix;
                } else if (dayjs().diff(rowOnlineAt, "month") !== 0) {
                    const monthsOnline = dayjs().diff(rowOnlineAt, "month");
                    if (monthsOnline !== 1) {
                        suffix = " months";
                    } else {
                        suffix = " month";
                    }
                    return monthsOnline + suffix;
                } else if (dayjs().diff(rowOnlineAt, "day") !== 0) {
                    const daysOnline = dayjs().diff(rowOnlineAt, "day");
                    if (daysOnline !== 1) {
                        suffix = " days";
                    } else {
                        suffix = " day";
                    }
                    return daysOnline + suffix;
                } else if (dayjs().diff(rowOnlineAt, "hour") !== 0) {
                    const hoursOnline = dayjs().diff(rowOnlineAt, "hour");
                    if (hoursOnline !== 1) {
                        suffix = " hours";
                    } else {
                        suffix = " hour";
                    }
                    return hoursOnline + suffix;
                } else {
                    //Uptime less than 1 hour. Resort to minutes.
                    const minutesOnline = dayjs().diff(rowOnlineAt, "minute");
                    if (minutesOnline !== 1) {
                        suffix = " minutes";
                    } else {
                        suffix = " minute";
                    }
                    return minutesOnline + suffix;
                }
            } else {
                return;
            }
        },
        sortable: true,
        reorder: true
    },
    {
        name: "Online At",
        selector: (row) => {
            if (row.onlineAt === "Unknown") {
                return "Unknown";
            } else if (row.onlineAt === null) {
                return "---";
            } else {
                return new Date(row.onlineAt).toUTCString();
            }
        },
        sortable: true,
        reorder: true,
        wrap: true
    },
    {
        name: "Offline At",
        selector: (row) => {
            if (row.offlineAt === "Unknown") {
                return "Unknown";
            } else if (row.offlineAt === null) {
                return "---";
            } else {
                return new Date(row.offlineAt).toUTCString();
            }
        },
        sortable: true,
        reorder: true,
        wrap: true
    },
    {
        name: "Service Manager Request ID",
        selector: (row) => {
            if (row.service_request_id === null || row.service_request_id === "" || row.service_request_id === 0) {
                return "---";
            } else {
                return row.service_request_id;
            }
        },
        sortable: true,
        reorder: true
    }
];

export default class ServersOnlinePopout extends React.PureComponent {
    _isMounted = false;
    constructor(props) {
        super(props);
        this.state = {
            getDone: false,
            search: "",
            curSite: "All",
            allServersStatus: []
        };
    }
    componentDidMount() {
        this._isMounted = true;
        this.getAllServersOnline(this.props.customerID);
    }
    componentWillUnmount() {
        this._isMounted = false;
    }
    //API Calls
    /**
     * getAccessToken
     * @description Gets the access token of the authenticated user
     */
    getAccessToken = async () => {
        let token = (await userManager.getUser()).access_token;
        return token;
    };
    getAllServersOnline = async (custID) => {
        send({
            method: "GET",
            url: `/api/reporting/getserversonline?custid=${custID}`,
            token: await this.getAccessToken()
        }).then((response) => {
            if (this._isMounted) {
                this.props.popoutRendered();
                this.setState({
                    getDone: true,
                    allServersStatus: response.data
                });
            }
        });
    };
    //Extra
    ChangeSite = (newSite) => {
        this.setState({ curSite: newSite.target.value });
    };
    CreateSiteList = () => {
        var uniqueServers = [];
        this.state.allServersStatus.forEach((server) => {
            if (!uniqueServers.includes(server.site)) {
                uniqueServers.push(server.site);
            }
        });
        return uniqueServers.sort();
    };
    CreateFinalServerList = () => {
        var finalServerList = [];
        if (this.state.search === "") {
            if (this.state.curSite !== "All") {
                this.state.allServersStatus.forEach((server) => {
                    if (server.site === this.state.curSite) {
                        finalServerList.push(server);
                    }
                });
            } else {
                finalServerList = JSON.parse(JSON.stringify(this.state.allServersStatus));
            }
        } else {
            if (this.state.curSite !== "All") {
                this.state.allServersStatus.forEach((server) => {
                    if (server.site === this.state.curSite) {
                        if (server.serverName.toLowerCase().includes(this.state.search)) {
                            finalServerList.push(server);
                        }
                    }
                });
            } else {
                this.state.allServersStatus.forEach((server) => {
                    if (server.serverName.toLowerCase().includes(this.state.search)) {
                        finalServerList.push(server);
                    }
                });
            }
        }
        return finalServerList;
    };
    render() {
        var uniqueSites = this.CreateSiteList();
        var finalServerList = this.CreateFinalServerList();
        if (!this.state.getDone) {
            return (
                <div
                    style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        marginTop: "10rem"
                    }}
                >
                    <Spinner size="250" />
                </div>
            );
        }
        return (
            <div className={styles.popoutContainer}>
                <div>
                    <h2 className={styles.popoutHeader}>{tr("DASHBOARD_SERSTATUS_TITLE")}</h2>
                </div>
                <div className={styles.popoutSearch}>
                    <div className="form-floating-labels">
                        <Input
                            id="Search"
                            placeholder=" "
                            value={this.state.search || ""}
                            onChange={(v) => {
                                this.setState({
                                    search: v.target.value.toLowerCase()
                                });
                            }}
                        />
                        <Label for="Search">{tr("DASHBOARD_SEARCH_POPOUT_SERVERNAMES")}</Label>
                    </div>
                </div>
                <div className={styles.popoutExport}>
                    <div className="perm-floating-label">
                        <Input className={styles.popoutInput} type="select" id="serStatusSiteSelect" onChange={this.ChangeSite}>
                            <option value="All">{tr("ALL")}</option>
                            {uniqueSites.map((site, key) => {
                                return (
                                    <option key={key} value={site}>
                                        {site}
                                    </option>
                                );
                            })}
                        </Input>
                        <Label for="serStatusSiteSelect">{tr("DASHBOARD_SITE_SELECT")}</Label>
                    </div>
                    <CSVLink
                        className={styles.exportCSVButton}
                        data={finalServerList}
                        filename={"export " + dayjs().format("DD-MM-YYYY h-mm-ss") + ".csv"}
                    >
                        {tr("EXPORT_CSV")}
                    </CSVLink>
                </div>
                <DataTable columns={columns} data={finalServerList} theme={this.props.theme} pagination />
            </div>
        );
    }
}
