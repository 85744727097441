import * as React from "react";
import NavMenu from "./NavMenu";
import SkipNav from "./accessibility/skip-nav";
import Footer from "./Nav/Footer";

const Layout: React.FC<{ children: JSX.Element }> = (props) => {
    return (
        <React.Fragment>
            <SkipNav />
            <NavMenu />
            {props.children}
            <Footer />
        </React.Fragment>
    );
};

export default Layout;
