import React, { Component } from "react";
import { Input, Label } from 'reactstrap';
import { Button } from "../ui";
import { Col, Container, Row } from "../ui";
import { tr } from '../../../helpers/languages'
import { send } from '../../../helpers/requests';
import userManager from "../../../util/userManager";
import './hrd.css';

class LoginPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            username: "" //Username autofilled for development 
        }
    }
    async componentDidMount() {
        const user = await userManager.getUser();
        if (user) {
            this.props.history.push('/dashboard');
        }
        else {
            const name = new URLSearchParams(this.props.location.search).get('cid');
            if (name) {
                this.performHomeRealmDiscovery(name);
            }
        }

    }
    updateValues = (newValue, type) => {
        this.setState({ [type]: newValue });
    }
    performHomeRealmDiscovery = (username) => {
        let url = process.env.REACT_APP_MAG_IDP_HOST_URL;
        send({ method: 'get', url: `${url}/api/trinidad/?id=${username}` }).then((response) => {
            //  const loginPath = `${ApplicationPaths.Login}?cid=${response.data}&un=${this.state.username}`;
            //document.location.href = loginPath;
            // console.log(userManager.settings);
            if (username.indexOf('@') > 0) {
                userManager._settings.extraQueryParams.login_hint = username;
            }
            userManager._settings.extraQueryParams.domain_hint = response.data;
            userManager._settings.extraQueryParams.acr_values = 'tenant:' + response.data;
            userManager._settings.extraTokenParams.domain_hint = response.data;
            userManager._settings.extraTokenParams.acr_values = 'tenant:' + response.data;
            userManager.signinRedirect();
        }).catch((e) => {
            console.log(e);
        });
    }
    onLoginButtonClick = () => {
        this.performHomeRealmDiscovery(this.state.username);
    }
    render() {
        return (
            <Container>
                <Row center>
                    <h1>{tr('LOGIN_HEADER')}</h1>
                </Row>
                <Row center>
                    <Col center style={{ maxWidth: "400px", marginTop: "1rem", marginBottom: "1rem" }}>
                        <div className="form-floating-labels" style={{ textAlign: "start" }}>
                            <Input style={{ height: '45px' }} id="usernameLoginInput" placeholder=" " onKeyDown={k => { if (k.key === "Enter") { this.onLoginButtonClick(); } }} value={this.state.username || ""} onChange={v => { this.updateValues(v.target.value, "username") }} />
                            <Label for="usernameLoginInput">{tr('LOGIN_USERNAME_LABEL')}</Label>
                        </div>
                    </Col>
                </Row>
                <Row center>
                    <Col center style={{ marginBottom: "2rem" }}>
                        <Button id="usernameLoginButton" primary onClick={this.onLoginButtonClick}>{tr('LOGIN_HEADER')}</Button>
                    </Col>
                </Row>
            </Container>
        );
    }
}

export default LoginPage;