import * as React from "react";
import { Col, Row } from "../../common/ui";
import { Bar } from "react-chartjs-2";
import BlankCard from "./BlankCard";
import GetCardTheme from "./SharedCardFunctions";
import { ChartOptions } from "chart.js";

type CardHalfBarGraphProps = {
    title: string,
    children: JSX.Element,
    cardID: string,
    barData: any,
    barTitle?: string,
    barDataTitle: string,
    otherData: string | number,
    otherDataTitle: string,
    type: string[],
    dataReturned: boolean,
    displayLegend: boolean,
    legendTitle: string,
    activatePopup: () => void,
    openHelpPopup: () => void,
    custID: string,
    startDate: Date,
    endDate: Date,
    isCSE: boolean,
    customID: string,
    fontSize?: number
}
type CardHalfBarGraphState = {
    chartFontColour: string
}

export default class CardHalfBarGraph extends React.PureComponent<CardHalfBarGraphProps, CardHalfBarGraphState> {
    state = {
        chartFontColour: GetCardTheme()
    }
    componentDidMount() {
        window.addEventListener("themeToggled", this.UpdateTheme);
    }
    componentWillUnmount() {
        window.removeEventListener("themeToggled", this.UpdateTheme);
    }
    UpdateTheme = () => {
        const chartFontColour = GetCardTheme();
        this.setState({ chartFontColour });
    };
    BarData = () => {
        return {
            datasets: [
                {
                    label: this.props.barDataTitle,
                    data: this.props.barData,
                    backgroundColor: "#118dff",
                    borderColor: "#118dff",
                    borderWidth: 0.5,
                    barPercentage: 0.5,
                    barThickness: 15,
                    maxBarThickness: 15,
                    minBarLength: 2
                }
            ]
        };
    };
    BarOptions = () => {
        return {
            indexAxis: "y",
            elements: {
                bar: {
                    borderWidth: 1
                }
            },
            parsing: {
                xAxisKey: "count",
                yAxisKey: "name"
            },
            scales: {
                y: {
                    suggestedMin: 0,
                    grid: {
                        display: false
                    },
                    ticks: {
                        display: true,
                        color: this.state.chartFontColour
                    }
                },
                x: {
                    beginAtZero: true,
                    grid: {
                        display: false
                    },
                    ticks: {
                        display: true,
                        callback: function (value: number) {
                            if (value % 1 === 0) {
                                return value;
                            }
                        },
                        color: this.state.chartFontColour
                    }
                }
            },
            responsive: true,
            maintainAspectRatio: false,
            plugins: {
                legend: {
                    display: false
                },
                title: {
                    display: this.props.displayLegend,
                    text: this.props.legendTitle,
                    color: this.state.chartFontColour
                }
            }
        } as ChartOptions<"bar">;
    };
    render() {
        var BarGraph = <></>;
        if (this.props.dataReturned) {
            BarGraph = <Bar data={this.BarData} options={this.BarOptions()} />;
        }
        return (
            <BlankCard
                customID={this.props.customID}
                activatePopup={this.props.activatePopup}
                title={this.props.title}
                openHelpPopup={this.props.openHelpPopup}
                cardID={this.props.cardID}
                custID={this.props.custID}
                isCSE={this.props.isCSE}
                startDate={this.props.startDate}
                endDate={this.props.endDate}
            >
                {this.props.children}
                <Row className="dash-content-row">
                    <Col center className="ml-[5px]">
                        <div className={"main " + this.props.type}>
                            {this.props.otherData}
                            <br />
                        </div>
                        <p style={{ fontSize: this.props.fontSize }}>{this.props.otherDataTitle}</p>
                    </Col>
                    <Col className="dash-graph dash-login-graph">{BarGraph}</Col>
                </Row>
            </BlankCard>
        );
    }
}
