import React, { useState } from "react";
import userManager from "../../../util/userManager";
import "../../../css/error-page.css";

export default class AccessDeniedPage extends React.PureComponent {
    constructor() {
        super();
        this.state = {
            username: "",
            popupStatus: false,
            themeKey: 0
        };
    }
    componentDidMount() {
        document.getElementsByClassName("cse-nav")[0].style.display = 'none';
        document.getElementsByClassName("content")[0].style.backgroundColor = 'var(--cse-primary-alt)';
 
        setTimeout(() => { userManager.signoutRedirect(); }, 2000);
    }
 
    render() {
        return (
            <div id="error-container" className="error-container">

                <h1>Access Denied</h1>

                <h3>You do not have the correct permissions to access this resource</h3>

            </div>
        );
    }
}
