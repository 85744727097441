import React, { useState } from "react";
import { Button } from "../../common/ui";
import { CustomDashboard, DefaultDashboard } from "../DefaultDashboardLayouts";
import { DragDropContext, Droppable, Draggable, DropResult } from "react-beautiful-dnd";
import { Cards } from "../Cards";
import { tr } from "../../../helpers/languages";
import { LatestVersion } from "../../common/changelog/versions";
import type { DashboardCard } from "../DefaultDashboardLayouts";
import { send } from "../../../helpers/requests";
import userManager from "../../../util/userManager";
import { Convert } from "../../common/ValidateDashboardLayout";
import { LogError } from "../../analytics/eventTracker";

type dndCardType = {
    id: string,
    content: string,
    cardType: number,
    name?: string
};

const getDashboard = (layout: DashboardCard[]) => {
    try {
        if (layout !== null) {
            Convert.toDashboardLayout(JSON.stringify(layout)); //validate JSON
        }
    } catch {
        LogError("Error Invalid Dashboard", { type: "LayoutDnD", dashLayout: JSON.stringify(layout) });
        console.warn("Invalid dashboard detected. Using default");
        layout = DefaultDashboard;
    }
    return layout;
};
type genListsReturnType = ReturnType<typeof genLists>;

const genLists = (layout: DashboardCard[]) => {
    var curDashboard = getDashboard(layout);
    let activeList: dndCardType[] = Array.from(
        curDashboard.map((item, index) => {
            const custom = {
                id: `id-${index}`,
                content: item.cardItem,
                cardType: item.cardType
            };
            return custom;
        })
    );
    var activeListItemNameArr: string[] = [];
    activeList.forEach((item) => {
        activeListItemNameArr.push(item.content);
    });
    var removedList: dndCardType[] = [];
    Cards.forEach((item, index) => {
        if (activeListItemNameArr.includes(item.cardItem)) {
            const activeListIndex = activeList.findIndex((curItem) => {
                return curItem.content === item.cardItem;
            });
            activeList[activeListIndex].name = item.name;
        } else {
            let curCardType = 0;
            try {
                const curIndex = CustomDashboard.findIndex((curItem) => {
                    return curItem.cardItem === item.cardItem;
                });
                curCardType = CustomDashboard[curIndex].cardType;
            } catch (error) {
                //Unknown item added (will assume cardType index = 0, Dashboard will not build card)
                curCardType = 0;
            }
            if (curCardType < 0) {
                curCardType = 0;
            }
            removedList.push({ id: `id-${index + activeList.length}`, content: item.cardItem, name: item.name, cardType: curCardType });
        }
    });
    return [
        {
            name: "Visible",
            items: activeList
        },
        {
            name: "Hidden",
            items: removedList
        }
    ];
};

const onDragEnd = (
    result: DropResult,
    columns: genListsReturnType,
    setColumns: React.Dispatch<
        React.SetStateAction<
            {
                name: string,
                items: dndCardType[]
            }[]
        >
    >
) => {
    if (!result.destination) return;
    const { source, destination } = result;

    if (source.droppableId !== destination.droppableId) {
        const sourceColumn = columns[parseInt(source.droppableId)];
        const destColumn = columns[parseInt(destination.droppableId)];
        const sourceItems = [...sourceColumn.items];
        const destItems = [...destColumn.items];
        const [removed] = sourceItems.splice(source.index, 1);
        destItems.splice(destination.index, 0, removed);
        setColumns({
            ...columns,
            [source.droppableId]: {
                ...sourceColumn,
                items: sourceItems
            },
            [destination.droppableId]: {
                ...destColumn,
                items: destItems
            }
        });
    } else {
        const column = columns[parseInt(source.droppableId)];
        const copiedItems = [...column.items];
        const [removed] = copiedItems.splice(source.index, 1);
        copiedItems.splice(destination.index, 0, removed);
        setColumns({
            ...columns,
            [source.droppableId]: {
                ...column,
                items: copiedItems
            }
        });
    }
};

type LayoutDnDProps = {
    layout: DashboardCard[],
    UpdateLayout: (layout: DashboardCard[], type: Boolean) => void
};

const LayoutDnD: React.FC<LayoutDnDProps> = (props) => {
    const [columns, setColumns] = useState(() => {
        return genLists(props.layout);
    });
    const [saveButtonText, setSaveButtonText] = useState("");
    const getAccessToken = async () => {
        let token = (await userManager.getUser())?.access_token;
        return token;
    };
    const saveLayout = async () => {
        var returnJSON: DashboardCard[] = [];
        columns[0]["items"].forEach((colItem, index) => {
            if (index === 0) {
                returnJSON.push({ cardItem: colItem.content, cardType: colItem.cardType, dashLayout: 99, version: LatestVersion() });
            } else {
                returnJSON.push({ cardItem: colItem.content, cardType: colItem.cardType });
            }
        });
        setSaveButtonText("Saved");
        if (returnJSON.length !== 0) {
            send({
                method: "POST",
                url: `/api/reporting/updatelayout`,
                data: returnJSON,
                params: "",
                token: await getAccessToken()
            }).catch((error) => {
                LogError("LayoutDND - Error updating layout", { error, newLayout: returnJSON });
                console.error(error);
            });
            props.UpdateLayout(returnJSON, true);
        } else {
            send({
                method: "POST",
                url: `/api/reporting/updatelayout`,
                data: [{ cardItem: "", cardType: 0, dashLayout: 99 }],
                params: "",
                token: await getAccessToken()
            })
                .then((res) => {
                    console.log(res.data);
                })
                .catch((error) => {
                    LogError("LayoutDND - Error updating default layout", { error });
                    console.error(error);
                });
            props.UpdateLayout([{ cardItem: "", cardType: NaN, dashLayout: 99 }], true);
        }
    };
    const ResetLayout = () => {
        props.UpdateLayout(CustomDashboard, true);
        setColumns(genLists(DefaultDashboard));
    };
    return (
        <>
            <div style={{ display: "flex", justifyContent: "center" }}>
                <DragDropContext onDragEnd={(result) => onDragEnd(result, columns, setColumns)}>
                    {Object.entries(columns).map(([columnId, column]) => {
                        return (
                            <div
                                style={{
                                    display: "flex",
                                    flexDirection: "column",
                                    alignItems: "center"
                                }}
                                key={columnId}
                            >
                                <h2>{column.name}</h2>
                                <div style={{ margin: 8 }}>
                                    <Droppable droppableId={columnId} key={columnId}>
                                        {(provided, snapshot) => {
                                            return (
                                                <div
                                                    {...provided.droppableProps}
                                                    ref={provided.innerRef}
                                                    style={{
                                                        background: snapshot.isDraggingOver ? "var(--cse-bg-alt)" : "var(--cse-bg)",
                                                        padding: 4,
                                                        width: 250,
                                                        minHeight: 500
                                                    }}
                                                >
                                                    {column.items.map((item, index) => {
                                                        return (
                                                            <Draggable key={item.id} draggableId={item.id} index={index}>
                                                                {(provided, snapshot) => {
                                                                    return (
                                                                        <div
                                                                            ref={provided.innerRef}
                                                                            {...provided.draggableProps}
                                                                            {...provided.dragHandleProps}
                                                                            style={{
                                                                                userSelect: "none",
                                                                                padding: 8,
                                                                                border: "1px solid transparent",
                                                                                borderRadius: "4px",
                                                                                margin: "0 0 8px 0",
                                                                                backgroundColor: snapshot.isDragging
                                                                                    ? "var(--cse-primary)"
                                                                                    : "var(--cse-primary-alt)",
                                                                                color: "white",
                                                                                ...provided.draggableProps.style
                                                                            }}
                                                                        >
                                                                            {item.name}
                                                                        </div>
                                                                    );
                                                                }}
                                                            </Draggable>
                                                        );
                                                    })}
                                                    {provided.placeholder}
                                                </div>
                                            );
                                        }}
                                    </Droppable>
                                </div>
                            </div>
                        );
                    })}
                </DragDropContext>
            </div>
            <Button style={{ marginRight: "5px", marginBottom: "5px" }} primary onClick={ResetLayout}>
                {tr("DASHBOARD_SETTINGS_RESET_LAYOUT")}
            </Button>
            <Button style={{ marginRight: "5px", marginBottom: "5px" }} primary onClick={saveLayout}>
                {saveButtonText === "" ? tr("DASHBOARD_SETTINGS_SAVE_LAYOUT") : saveButtonText}
            </Button>
        </>
    );
};

export default LayoutDnD;
