function GetCardTheme() {
    var curTheme = localStorage.getItem("theme");
    if (curTheme === '"light-theme"') {
        return '#4D4D4D'; //Modified to return hex colour codes, colours are WCAG 2 AAA compliant
    }
    else {
        return '#DFDFDF';
    }
}

export default GetCardTheme;