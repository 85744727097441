import React, { useEffect, useRef, useState } from "react";
import { tr } from "../../../helpers/languages";
import ReportIcon from "../../../assets/icons/report.svg";
import { toPng } from "html-to-image";
import Popup from "../../common/popup";
import { PDFDocument, StandardFonts, rgb } from "pdf-lib";
import { frontPage, blankPage, endPage } from "./ReportConsts";
import { Button, Spinner } from "../../common/ui";
import { Cards } from "../Cards";
import userManager from "../../../util/userManager";
import { send } from "../../../helpers/requests";
import type { Comment } from "../ui/Comments/Comments";
import RenderCard from "../RenderCards";
import styles from "./Reports.module.css";
import ServiceDeskPerformancePopout from "../popout/ServiceDeskPerformance";
import { GetTheme } from "../cards/SharedFunctions";
import ServiceRequestsPopout from "../popout/ServiceRequests";
import ActivityPerUser from "../popout/ActivityPerUser";
import AntiThreatPopout from "../popout/AntiThreat";
import ServerUpdatePopout from "../popout/ServerUpdate";
import { LogError } from "../../analytics/eventTracker";

const month = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
const getCard = (cardItem: string) => {
    for (var i = 0; i < Cards.length; i++) {
        if (Cards[i].cardItem === cardItem) return Cards[i];
    }
}
const getAccessToken = async () => {
    let token = (await userManager.getUser())?.access_token;
    return token;
};
const getScreenshot = async (elementID: string) => {
    let image = "";
    try {
        const el = document.getElementById(elementID);
        if (el == null) {
            throw new Error();
        }
        image = await toPng(el);
    } catch (e) {
        LogError("Error taking screenshot", { type: "Report", e });
        console.error(e);
    }
    return image;
};

type ReportsProps = {
    type?: string
}

const Reports = (props: ReportsProps) => {
    const [hover, setHover] = useState(false);
    const [popupStatus, setPopupStatus] = useState(false);
    let icon = <></>
    switch (props.type) {
        case "nav":
            icon = <div className="icon-hide-mobile cse-nav-menu-icon-container">
                <img
                    alt=""
                    onMouseEnter={() => setHover(true)}
                    onMouseLeave={() => setHover(false)}
                    aria-label={tr("DASHBOARD_REPORT")}
                    className="cse-nav-menu-icon"
                    src={ReportIcon}
                    onClick={() => {
                        setPopupStatus(true);
                    }}
                />
                <span className={hover ? "cse-nav-menu-tooltip-hovered cse-nav-menu-tooltip" : "cse-nav-menu-tooltip"}>{tr("DASHBOARD_REPORT")}</span>
            </div>
            break;
        case "link":
        default:
            icon = <Button primary onClick={() => {
                setPopupStatus(true);
            }}>{tr("DASHBOARD_REPORT")}</Button>
            break;
    }
    return (
        <>
            {icon}
            {popupStatus && (
                <Popup
                    content={<PopoutDownload />}
                    close={() => {
                        setPopupStatus(false);
                    }}
                    disableOverflow={true}
                    PopupName="PopoutDownload"
                />
            )}
        </>
    );
};

//
const PopoutDownload = () => {
    // const [cardsLoading, setCardsLoading] = useState(true);
    // const [cardsLoaded, setCardsLoaded] = useState(0);
    // const [commentsLoading, setCommentsLoading] = useState(true);
    const cardsLoading = useRef(true);
    const cardsLoaded = useRef(0);
    const popoutLoading = useRef(true);
    const popoutLoaded = useRef(0);
    const commentsLoading = useRef(true);
    const [isLoading, setIsLoading] = useState(true);
    //Hidden startDate and endDate passthrough from hidden div
    //Note from Jack: There's probably a nicer way of passing this data through (even localStorage / session storage) but...
    var custID = "";
    var startDate: Date, endDate: Date;
    // try {
    //     custID = document.getElementById("hidden-customerID")!.innerText;
    //     startDate = new Date(JSON.parse(document.getElementById("hidden-startDate")!.innerText));
    //     endDate = new Date(JSON.parse(document.getElementById("hidden-endDate")!.innerText));
    //     lastMonthStartDate = new Date(new Date().setMonth(startDate.getMonth() - 1));
    //     lastMonthEndDate = new Date(new Date().setMonth(endDate.getMonth() - 1));
    // } catch (error) {
    //     //Assume page wasn't loaded correctly
    //     return <p style={{ fontSize: "24px" }}>ERROR: Please try again</p>
    // }
    try {
        custID = sessionStorage.getItem("custID")!;
        startDate = new Date(sessionStorage.getItem("startDate")!);
        endDate = new Date(sessionStorage.getItem("endDate")!);
    } catch (error) {
        //Assume page wasn't loaded correctly
        LogError("Report - Cannot read session storage", { error, custID: custID });
        console.error("Cannot read session storage");
        return <p style={{ fontSize: "24px" }}>ERROR: Please try again</p>
    }
    if (custID === undefined || startDate === undefined || endDate === undefined ||
        custID === null || startDate === null || endDate === null ||
        custID === "") {
        //Assume page wasn't loaded correctly
        LogError("Report - Invalid session variables", { custID, startDate, endDate });
        console.error("Invalid session variables");
        return <p style={{ fontSize: "24px" }}>ERROR: Please try again</p>
    }
    const CardsLoaded = () => {
        cardsLoaded.current = cardsLoaded.current + 1;
        if (cardsLoaded.current >= 4) {
            cardsLoading.current = false;
        }
        if (isLoading && (!commentsLoading.current && !popoutLoading.current)) {
            setIsLoading(false);
        }
    }
    const CommentsLoaded = () => {
        commentsLoading.current = false;
        if (isLoading && (!cardsLoading.current && !popoutLoading.current)) {
            setIsLoading(false);
        }
    }
    const PopoutLoaded = () => {
        popoutLoaded.current = popoutLoaded.current + 1;
        if (popoutLoaded.current >= 6) {
            popoutLoading.current = false;
        }
        if (isLoading && (!commentsLoading.current && !cardsLoading.current)) {
            setIsLoading(false);
        }
    }
    return (
        <section>
            <LoadingComponent enabled={isLoading} />
            <ReportDownload />
            <LastMonthsCards custID={custID} startDate={startDate} endDate={endDate} onLoaded={CardsLoaded} />
            <Comments custID={custID} startDate={startDate} endDate={endDate} onLoaded={CommentsLoaded} />
            <PopoutViewer custID={custID} startDate={startDate} endDate={endDate} onLoaded={PopoutLoaded} />
        </section>
    )
}

const LoadingComponent = (props: { enabled: boolean }) => {
    if (!props.enabled) { return <></> }
    return (
        <div
            style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                marginTop: "10rem",
                marginBottom: "80vh"
            }}
        >
            <Spinner size={250} />
        </div>
    );
}

const ReportDownload = () => {
    const [isGeneratingPDF, setIsGeneratingPDF] = useState(false);
    const GenAndDownloadStandardPDF = async () => {
        setIsGeneratingPDF(true);
        const pdfDoc = await PDFDocument.create();
        const helveticaFont = await pdfDoc.embedFont(StandardFonts.Helvetica);
        // const frontPage = pdfDoc.addPage([841.89, 595.28]);
        // const { width: frontWidth, height: frontHeight } = frontPage.getSize();
        // const customerName = document.getElementById("custNameHeader")?.innerText;
        // console.log(customerName);
        // frontPage.drawText(`${customerName}`, {
        //     x: 50,
        //     y: 50,
        //     size: 30,
        //     font: helveticaFont,
        //     color: rgb(0, 0.53, 0.71)
        // });
        //Front Page
        const frontPagePDFDoc = await PDFDocument.load(frontPage);
        const [finishedFrontPage] = await pdfDoc.copyPages(frontPagePDFDoc, [0]);
        const { width: frontPageWidth, height: frontPageHeight } = finishedFrontPage.getSize();
        //Month Year
        const curDate = new Date();
        const curMonth = month[curDate.getMonth()];
        const xPixels = 1200 - ((curMonth.length - 3) * 25);
        //Customer Name
        const customerName = document.getElementById("custNameHeader")?.innerText;
        finishedFrontPage.drawText(`${customerName}`, {
            x: (frontPageWidth / 2) - (35 * (customerName!.length / 2)), //ToDo: Fix
            y: 350,
            size: 62,
            font: helveticaFont,
            color: rgb(1, 1, 1)
        });
        finishedFrontPage.drawText(`${curMonth} ${curDate.getFullYear()}`, {
            x: xPixels,
            y: 90,
            size: 48,
            font: helveticaFont,
            color: rgb(1, 1, 1)
        });
        pdfDoc.addPage(finishedFrontPage);

        const blankPagePDFDoc = await PDFDocument.load(blankPage);
        //ServiceDeskStats
        const [finishedServiceDeskStatsPage] = await pdfDoc.copyPages(blankPagePDFDoc, [0]);
        const serviceDeskStatsImage = await getScreenshot("ServiceDeskStats");
        const pngServiceDeskStatsImage = await pdfDoc.embedPng(serviceDeskStatsImage);
        const pngServiceDeskStatsDims = await pngServiceDeskStatsImage.scale(1);
        finishedServiceDeskStatsPage.drawImage(pngServiceDeskStatsImage, {
            x: 50,
            y: 450,
            width: pngServiceDeskStatsDims.width,
            height: pngServiceDeskStatsDims.height
        });
        finishedServiceDeskStatsPage.drawText(`${getCard("ServiceDeskStats")?.name}`, {
            x: 200,
            y: frontPageHeight - 100,
            size: 62,
            font: helveticaFont,
            color: rgb(1, 1, 1)
        });
        // //ServiceDeskStats Comments
        // var serviceDeskComments = GetPageComments(allComments!, "ServiceDeskStats");
        // numberOfExtraNewLines = 0;
        // serviceDeskComments.forEach((comment, index) => {
        //     finishedServiceDeskStatsPage.drawText(comment.comment, {
        //         x: frontPageWidth - 600,
        //         y: 620 - (index * 50) - (numberOfExtraNewLines * 20),
        //         size: 24,
        //         font: helveticaFont,
        //         color: rgb(1, 1, 1)
        //     });
        //     numberOfExtraNewLines = comment.comment.split(/\r\n|\r|\n/).length - 1;
        // });
        // ServiceDeskStats Comments Image
        const serviceDeskStatsCommentsImage = await getScreenshot("serviceDeskStatsComments");
        const pngServiceDeskStatsCommentsImage = await pdfDoc.embedPng(serviceDeskStatsCommentsImage);
        const pngServiceDeskStatsCommentsDims = await pngServiceDeskStatsCommentsImage.scale(1);
        finishedServiceDeskStatsPage.drawImage(pngServiceDeskStatsCommentsImage, {
            x: 575,
            y: 200,
            width: pngServiceDeskStatsCommentsDims.width,
            height: pngServiceDeskStatsCommentsDims.height
        });
        // ServiceDeskStats Popout
        const serviceDeskStatsPopoutImage = await getScreenshot("serviceDeskPerformanceTable");
        const pngServiceDeskStatsPopoutImage = await pdfDoc.embedPng(serviceDeskStatsPopoutImage);
        const pngServiceDeskStatsPopoutDims = await pngServiceDeskStatsPopoutImage.scale(1);
        finishedServiceDeskStatsPage.drawImage(pngServiceDeskStatsPopoutImage, {
            x: 50,
            y: -290,
            width: pngServiceDeskStatsPopoutDims.width,
            height: pngServiceDeskStatsPopoutDims.height
        });
        pdfDoc.addPage(finishedServiceDeskStatsPage);
        //ServiceDeskStats Last Month page
        const [finishedServiceDeskStatsLMonthPage] = await pdfDoc.copyPages(blankPagePDFDoc, [0]);
        const lMonthServiceDeskStatsImage = await getScreenshot("LastMonthServiceDeskStats");
        const lMonthServiceDeskStatsPNGImage = await pdfDoc.embedPng(lMonthServiceDeskStatsImage);
        const lMonthServiceDeskStatsImageDims = await lMonthServiceDeskStatsPNGImage.scale(1);
        finishedServiceDeskStatsLMonthPage.drawImage(lMonthServiceDeskStatsPNGImage, {
            x: 50,
            y: 450,
            width: lMonthServiceDeskStatsImageDims.width,
            height: lMonthServiceDeskStatsImageDims.height
        })
        finishedServiceDeskStatsLMonthPage.drawText("Compared to Last Month", {
            x: 200,
            y: frontPageHeight - 100,
            size: 62,
            font: helveticaFont,
            color: rgb(1, 1, 1)
        });
        pdfDoc.addPage(finishedServiceDeskStatsLMonthPage);
        //ServiceDeskPerformance
        const [finishedServiceDeskPerfPage] = await pdfDoc.copyPages(blankPagePDFDoc, [0]);
        const serviceDeskPerfImage = await getScreenshot("ServiceDeskPerformance");
        const pngServiceDeskPerfImage = await pdfDoc.embedPng(serviceDeskPerfImage);
        const pngServiceDeskPerfDims = await pngServiceDeskPerfImage.scale(1);
        finishedServiceDeskPerfPage.drawImage(pngServiceDeskPerfImage, {
            x: 50,
            y: 450,
            width: pngServiceDeskPerfDims.width,
            height: pngServiceDeskPerfDims.height
        });
        finishedServiceDeskPerfPage.drawText(`${getCard("ServiceDeskPerformance")?.name}`, {
            x: 200,
            y: frontPageHeight - 100,
            size: 62,
            font: helveticaFont,
            color: rgb(1, 1, 1)
        });
        // //ServiceDeskPerformance Comments
        // var ServiceDeskPerformanceComments = GetPageComments(allComments!, "ServiceDeskPerformance");
        // numberOfExtraNewLines = 0;
        // ServiceDeskPerformanceComments.forEach((comment, index) => {
        //     finishedServiceDeskPerfPage.drawText(comment.comment, {
        //         x: frontPageWidth - 600,
        //         y: 620 - (index * 50) - (numberOfExtraNewLines * 20),
        //         size: 24,
        //         font: helveticaFont,
        //         color: rgb(1, 1, 1)
        //     });
        //     numberOfExtraNewLines = comment.comment.split(/\r\n|\r|\n/).length - 1;
        // });
        // ServiceDeskPerformance Comments Image
        const serviceDeskPerformanceCommentsImage = await getScreenshot("sdSitePerfComments");
        const pngServiceDeskPerformanceCommentsImage = await pdfDoc.embedPng(serviceDeskPerformanceCommentsImage);
        const pngServiceDeskPerformanceCommentsDims = await pngServiceDeskPerformanceCommentsImage.scale(1);
        finishedServiceDeskPerfPage.drawImage(pngServiceDeskPerformanceCommentsImage, {
            x: 575,
            y: 200,
            width: pngServiceDeskPerformanceCommentsDims.width,
            height: pngServiceDeskPerformanceCommentsDims.height
        });
        // ServiceDeskPerformance Popout
        const serviceDeskCommentsPopoutImage = await getScreenshot("serviceDeskStatsTable");
        const pngServiceDeskPerfPopoutImage = await pdfDoc.embedPng(serviceDeskCommentsPopoutImage);
        const pngServiceDeskPerfPopoutDims = await pngServiceDeskPerfPopoutImage.scale(1);
        finishedServiceDeskPerfPage.drawImage(pngServiceDeskPerfPopoutImage, {
            x: 50,
            y: -100,
            width: pngServiceDeskPerfPopoutDims.width,
            height: pngServiceDeskPerfPopoutDims.height
        });
        pdfDoc.addPage(finishedServiceDeskPerfPage);
        //ServiceDeskPerformance Last Month page
        const [finishedServiceDeskPerformanceLMonthPage] = await pdfDoc.copyPages(blankPagePDFDoc, [0]);
        const lMonthServiceDeskPerformanceImage = await getScreenshot("LastMonthServiceDeskSitePerf");
        const lMonthServiceDeskPerformancePNGImage = await pdfDoc.embedPng(lMonthServiceDeskPerformanceImage);
        const lMonthServiceDeskPerformanceImageDims = await lMonthServiceDeskPerformancePNGImage.scale(1);
        finishedServiceDeskPerformanceLMonthPage.drawImage(lMonthServiceDeskPerformancePNGImage, {
            x: 50,
            y: 450,
            width: lMonthServiceDeskPerformanceImageDims.width,
            height: lMonthServiceDeskPerformanceImageDims.height
        })
        finishedServiceDeskPerformanceLMonthPage.drawText("Compared to Last Month", {
            x: 200,
            y: frontPageHeight - 100,
            size: 62,
            font: helveticaFont,
            color: rgb(1, 1, 1)
        });
        pdfDoc.addPage(finishedServiceDeskPerformanceLMonthPage);
        //ActivityPerUser
        const [finishedActivityPerUserPage] = await pdfDoc.copyPages(blankPagePDFDoc, [0]);
        const ActivityPerUserImage = await getScreenshot("ActivityPerUser");
        const pngActivityPerUserImage = await pdfDoc.embedPng(ActivityPerUserImage);
        const pngActivityPerUserDims = await pngActivityPerUserImage.scale(1);
        finishedActivityPerUserPage.drawImage(pngActivityPerUserImage, {
            x: 50,
            y: 450,
            width: pngActivityPerUserDims.width,
            height: pngActivityPerUserDims.height
        });
        finishedActivityPerUserPage.drawText(`${getCard("ActivityPerUser")?.name}`, {
            x: 200,
            y: frontPageHeight - 100,
            size: 62,
            font: helveticaFont,
            color: rgb(1, 1, 1)
        });
        // //ActivityPerUser Comments
        // var ActivityPerUserComments = GetPageComments(allComments!, "ActivityPerUser");
        // numberOfExtraNewLines = 0;
        // ActivityPerUserComments.forEach((comment, index) => {
        //     finishedActivityPerUserPage.drawText(comment.comment, {
        //         x: frontPageWidth - 600,
        //         y: 620 - (index * 50) - (numberOfExtraNewLines * 20),
        //         size: 24,
        //         font: helveticaFont,
        //         color: rgb(1, 1, 1)
        //     });
        //     numberOfExtraNewLines = comment.comment.split(/\r\n|\r|\n/).length - 1;
        // });
        // ActivityPerUser Comments Image
        const activityPerUserCommentsImage = await getScreenshot("userActivityComments");
        const pngActivityPerUserCommentsImage = await pdfDoc.embedPng(activityPerUserCommentsImage);
        const pngActivityPerUserCommentsDims = await pngActivityPerUserCommentsImage.scale(1);
        finishedActivityPerUserPage.drawImage(pngActivityPerUserCommentsImage, {
            x: 575,
            y: 200,
            width: pngActivityPerUserCommentsDims.width,
            height: pngActivityPerUserCommentsDims.height
        });
        // ActivityPerUser Popout
        const activityDeskPopoutImage = await getScreenshot("activityPerUserTable");
        const pngActivityDeskPopoutImage = await pdfDoc.embedPng(activityDeskPopoutImage);
        const pngActivityDeskPopoutDims = await pngActivityDeskPopoutImage.scale(1);
        finishedActivityPerUserPage.drawImage(pngActivityDeskPopoutImage, {
            x: 50,
            y: -310,
            width: pngActivityDeskPopoutDims.width,
            height: pngActivityDeskPopoutDims.height
        });
        pdfDoc.addPage(finishedActivityPerUserPage);
        //ActivityPerUser Last Month page
        const [finishedActivityPerUserLMonthPage] = await pdfDoc.copyPages(blankPagePDFDoc, [0]);
        const lMonthActivityPerUserImage = await getScreenshot("LastMonthActivityPerUser");
        const lMonthActivityPerUserPNGImage = await pdfDoc.embedPng(lMonthActivityPerUserImage);
        const lMonthActivityPerUserImageDims = await lMonthActivityPerUserPNGImage.scale(1);
        finishedActivityPerUserLMonthPage.drawImage(lMonthActivityPerUserPNGImage, {
            x: 50,
            y: 450,
            width: lMonthActivityPerUserImageDims.width,
            height: lMonthActivityPerUserImageDims.height
        })
        finishedActivityPerUserLMonthPage.drawText("Compared to Last Month", {
            x: 200,
            y: frontPageHeight - 100,
            size: 62,
            font: helveticaFont,
            color: rgb(1, 1, 1)
        });
        pdfDoc.addPage(finishedActivityPerUserLMonthPage);
        //ServerThreat
        const [finishedServerThreatPage] = await pdfDoc.copyPages(blankPagePDFDoc, [0]);
        const ServerThreatImage = await getScreenshot("ServerThreat");
        const pngServerThreatImage = await pdfDoc.embedPng(ServerThreatImage);
        const pngServerThreatDims = await pngServerThreatImage.scale(1);
        finishedServerThreatPage.drawImage(pngServerThreatImage, {
            x: 50,
            y: 450,
            width: pngServerThreatDims.width,
            height: pngServerThreatDims.height
        });
        finishedServerThreatPage.drawText(`${getCard("ServerThreat")?.name}`, {
            x: 200,
            y: frontPageHeight - 100,
            size: 62,
            font: helveticaFont,
            color: rgb(1, 1, 1)
        });
        // //ServerThreat Comments
        // var ServerThreatComments = GetPageComments(allComments!, "ServerThreat");
        // numberOfExtraNewLines = 0;
        // ServerThreatComments.forEach((comment, index) => {
        //     finishedServerThreatPage.drawText(comment.comment, {
        //         x: frontPageWidth - 600,
        //         y: 620 - (index * 50) - (numberOfExtraNewLines * 20),
        //         size: 24,
        //         font: helveticaFont,
        //         color: rgb(1, 1, 1)
        //     });
        //     numberOfExtraNewLines = comment.comment.split(/\r\n|\r|\n/).length - 1;
        // });
        // ServerThreat Comments Image
        const serverThreatCommentsImage = await getScreenshot("serverAVComments");
        const pngServerThreatCommentsImage = await pdfDoc.embedPng(serverThreatCommentsImage);
        const pngServerThreatCommentsDims = await pngServerThreatCommentsImage.scale(1);
        finishedServerThreatPage.drawImage(pngServerThreatCommentsImage, {
            x: 575,
            y: 200,
            width: pngServerThreatCommentsDims.width,
            height: pngServerThreatCommentsDims.height
        });
        // ServerThreat Popout
        const antiThreatPopoutImage = await getScreenshot("antiThreatTable");
        const pngAntiThreatPopoutImage = await pdfDoc.embedPng(antiThreatPopoutImage);
        const pngAntiThreatPopoutDims = await pngAntiThreatPopoutImage.scale(1);
        finishedServerThreatPage.drawImage(pngAntiThreatPopoutImage, {
            x: 50,
            y: -310,
            width: pngAntiThreatPopoutDims.width,
            height: pngAntiThreatPopoutDims.height
        });
        pdfDoc.addPage(finishedServerThreatPage);
        //CSERemoteAgent
        const [finishedCSERemoteAgentPage] = await pdfDoc.copyPages(blankPagePDFDoc, [0]);
        const CSERemoteAgentImage = await getScreenshot("CSERemoteAgent");
        const pngCSERemoteAgentImage = await pdfDoc.embedPng(CSERemoteAgentImage);
        const pngCSERemoteAgentDims = await pngCSERemoteAgentImage.scale(1);
        finishedCSERemoteAgentPage.drawImage(pngCSERemoteAgentImage, {
            x: 50,
            y: 450,
            width: pngCSERemoteAgentDims.width,
            height: pngCSERemoteAgentDims.height
        });
        finishedCSERemoteAgentPage.drawText(`${getCard("CSERemoteAgent")?.name}`, {
            x: 200,
            y: frontPageHeight - 100,
            size: 62,
            font: helveticaFont,
            color: rgb(1, 1, 1)
        });
        // //CSERemoteAgent Comments
        // var CSERemoteAgentComments = GetPageComments(allComments!, "CSERemoteAgent");
        // numberOfExtraNewLines = 0;
        // CSERemoteAgentComments.forEach((comment, index) => {
        //     finishedCSERemoteAgentPage.drawText(comment.comment, {
        //         x: frontPageWidth - 600,
        //         y: 620 - (index * 50) - (numberOfExtraNewLines * 20),
        //         size: 24,
        //         font: helveticaFont,
        //         color: rgb(1, 1, 1)
        //     });
        //     numberOfExtraNewLines = comment.comment.split(/\r\n|\r|\n/).length - 1;
        // });
        // CSERemoteAgent Comments Image
        const CSERemoteAgentCommentsImage = await getScreenshot("cseRemoteAgentComments");
        const pngCSERemoteAgentCommentsImage = await pdfDoc.embedPng(CSERemoteAgentCommentsImage);
        const pngCSERemoteAgentCommentsDims = await pngCSERemoteAgentCommentsImage.scale(1);
        finishedCSERemoteAgentPage.drawImage(pngCSERemoteAgentCommentsImage, {
            x: 575,
            y: 200,
            width: pngCSERemoteAgentCommentsDims.width,
            height: pngCSERemoteAgentCommentsDims.height
        });
        pdfDoc.addPage(finishedCSERemoteAgentPage);
        //CSERemoteAgent Last Month page
        const [finishedCSERemoteAgentLMonthPage] = await pdfDoc.copyPages(blankPagePDFDoc, [0]);
        const lMonthCSERemoteAgentImage = await getScreenshot("LastMonthCSERemoteAgent");
        const lMonthCSERemoteAgentPNGImage = await pdfDoc.embedPng(lMonthCSERemoteAgentImage);
        const lMonthCSERemoteAgentImageDims = await lMonthCSERemoteAgentPNGImage.scale(1);
        finishedCSERemoteAgentLMonthPage.drawImage(lMonthCSERemoteAgentPNGImage, {
            x: 50,
            y: 450,
            width: lMonthCSERemoteAgentImageDims.width,
            height: lMonthCSERemoteAgentImageDims.height
        })
        finishedCSERemoteAgentLMonthPage.drawText("Compared to Last Month", {
            x: 200,
            y: frontPageHeight - 100,
            size: 62,
            font: helveticaFont,
            color: rgb(1, 1, 1)
        });
        pdfDoc.addPage(finishedCSERemoteAgentLMonthPage);
        //NetworkSwitchDeviceStatus
        //NetworkWAPDeviceStatus (combine with above)
        const [finishedNetworkSwitchDeviceStatusPage] = await pdfDoc.copyPages(blankPagePDFDoc, [0]);
        const NetworkSwitchDeviceStatusImage = await getScreenshot("NetworkSwitchDeviceStatus");
        const pngNetworkSwitchDeviceStatusImage = await pdfDoc.embedPng(NetworkSwitchDeviceStatusImage);
        const pngNetworkSwitchDeviceStatusDims = await pngNetworkSwitchDeviceStatusImage.scale(1);
        finishedNetworkSwitchDeviceStatusPage.drawImage(pngNetworkSwitchDeviceStatusImage, {
            x: 50,
            y: 480,
            width: pngNetworkSwitchDeviceStatusDims.width,
            height: pngNetworkSwitchDeviceStatusDims.height
        });
        const NetworkWAPDeviceStatusImage = await getScreenshot("NetworkWAPDeviceStatus");
        const pngNetworkWAPDeviceStatusImage = await pdfDoc.embedPng(NetworkWAPDeviceStatusImage);
        const pngNetworkWAPDeviceStatusDims = await pngNetworkWAPDeviceStatusImage.scale(1);
        finishedNetworkSwitchDeviceStatusPage.drawImage(pngNetworkWAPDeviceStatusImage, {
            x: 50,
            y: 250,
            width: pngNetworkWAPDeviceStatusDims.width,
            height: pngNetworkWAPDeviceStatusDims.height
        });
        finishedNetworkSwitchDeviceStatusPage.drawText("Network Status", {
            x: 200,
            y: frontPageHeight - 100,
            size: 62,
            font: helveticaFont,
            color: rgb(1, 1, 1)
        });
        // //NetworkSwitchDeviceStatus Comments
        // var NetworkSwitchDeviceStatusComments = GetPageComments(allComments!, "NetworkSwitchDeviceStatus");
        // numberOfExtraNewLines = 0;
        // var startingNetworkSwitchY = 0;
        // NetworkSwitchDeviceStatusComments.forEach((comment, index) => {
        //     finishedNetworkSwitchDeviceStatusPage.drawText(comment.comment, {
        //         x: frontPageWidth - 600,
        //         y: 620 - (index * 50) - (numberOfExtraNewLines * 20),
        //         size: 24,
        //         font: helveticaFont,
        //         color: rgb(1, 1, 1)
        //     });
        //     numberOfExtraNewLines = comment.comment.split(/\r\n|\r|\n/).length - 1;
        //     if (index === NetworkSwitchDeviceStatusComments.length - 1) {
        //         startingNetworkSwitchY = 620 - (index * 50) - (numberOfExtraNewLines * 20) - 50;
        //     }
        // });
        // NetworkSwitchDeviceStatus Comments Image
        const networkSwitchDeviceStatusCommentsImage = await getScreenshot("networkStatusComments");
        const pngNetworkSwitchDeviceStatusCommentsImage = await pdfDoc.embedPng(networkSwitchDeviceStatusCommentsImage);
        const pngNetworkSwitchDeviceStatusCommentsDims = await pngNetworkSwitchDeviceStatusCommentsImage.scale(1);
        finishedNetworkSwitchDeviceStatusPage.drawImage(pngNetworkSwitchDeviceStatusCommentsImage, {
            x: 575,
            y: 200,
            width: pngNetworkSwitchDeviceStatusCommentsDims.width,
            height: pngNetworkSwitchDeviceStatusCommentsDims.height
        });
        // //NetworkWAPDeviceStatus Comments
        // var NetworkWAPDeviceStatusComments = GetPageComments(allComments!, "NetworkWAPDeviceStatus");
        // NetworkWAPDeviceStatusComments.forEach((comment, index) => {
        //     finishedNetworkSwitchDeviceStatusPage.drawText(comment.comment, {
        //         x: frontPageWidth - 600,
        //         y: startingNetworkSwitchY - (index * 50) - (numberOfExtraNewLines * 20),
        //         size: 24,
        //         font: helveticaFont,
        //         color: rgb(1, 1, 1)
        //     });
        //     numberOfExtraNewLines = comment.comment.split(/\r\n|\r|\n/).length - 1;
        // });
        pdfDoc.addPage(finishedNetworkSwitchDeviceStatusPage);
        //ServerWinUpdates
        const [finishedServerWinUpdatesPage] = await pdfDoc.copyPages(blankPagePDFDoc, [0]);
        const ServerWinUpdatesImage = await getScreenshot("ServerWinUpdates");
        const pngServerWinUpdatesImage = await pdfDoc.embedPng(ServerWinUpdatesImage);
        const pngServerWinUpdatesDims = await pngServerWinUpdatesImage.scale(1);
        finishedServerWinUpdatesPage.drawImage(pngServerWinUpdatesImage, {
            x: 50,
            y: 450,
            width: pngServerWinUpdatesDims.width,
            height: pngServerWinUpdatesDims.height
        });
        finishedServerWinUpdatesPage.drawText(`${getCard("ServerWinUpdates")?.name}`, {
            x: 200,
            y: frontPageHeight - 100,
            size: 62,
            font: helveticaFont,
            color: rgb(1, 1, 1)
        });
        // //ServerWinUpdates Comments
        // var ServerWinUpdatesComments = GetPageComments(allComments!, "ServerWinUpdates");
        // numberOfExtraNewLines = 0;
        // ServerWinUpdatesComments.forEach((comment, index) => {
        //     finishedServerWinUpdatesPage.drawText(comment.comment, {
        //         x: frontPageWidth - 600,
        //         y: 620 - (index * 50) - (numberOfExtraNewLines * 20),
        //         size: 24,
        //         font: helveticaFont,
        //         color: rgb(1, 1, 1)
        //     });
        //     numberOfExtraNewLines = comment.comment.split(/\r\n|\r|\n/).length - 1;
        // });
        // ServerWinUpdates Comments Image
        const ServerWinUpdatesCommentsImage = await getScreenshot("serverPendingWinUpdatesComments");
        const pngServerWinUpdatesCommentsImage = await pdfDoc.embedPng(ServerWinUpdatesCommentsImage);
        const pngServerWinUpdatesCommentsDims = await pngServerWinUpdatesCommentsImage.scale(1);
        finishedServerWinUpdatesPage.drawImage(pngServerWinUpdatesCommentsImage, {
            x: 575,
            y: 200,
            width: pngServerWinUpdatesCommentsDims.width,
            height: pngServerWinUpdatesCommentsDims.height
        });
        // ServerWinUpdates Popout
        const serverWinUpdatesPopoutImage = await getScreenshot("serverUpdateTable");
        const pngServerWinUpdatesPopoutImage = await pdfDoc.embedPng(serverWinUpdatesPopoutImage);
        const pngServerWinUpdatesPopoutDims = await pngServerWinUpdatesPopoutImage.scale(1);
        finishedServerWinUpdatesPage.drawImage(pngServerWinUpdatesPopoutImage, {
            x: 50,
            y: 100,
            width: pngServerWinUpdatesPopoutDims.width,
            height: pngServerWinUpdatesPopoutDims.height
        });
        pdfDoc.addPage(finishedServerWinUpdatesPage);
        const endPagePDFDoc = await PDFDocument.load(endPage);
        const [finalEndPage] = await pdfDoc.copyPages(endPagePDFDoc, [0]);
        pdfDoc.addPage(finalEndPage);
        // //Dashboard as page
        // const fullDashPage = pdfDoc.addPage([841.89, 595.28]);
        // const { width: dashWidth, height: dashHeight } = fullDashPage.getSize();
        // const fullDashImage = await getScreenshot("content");
        // const pngDashImage = await pdfDoc.embedPng(fullDashImage);
        // const pngDashDims = pngDashImage.scale(0.25);
        // fullDashPage.drawImage(pngDashImage, {
        //     x: 0,
        //     y: dashHeight - pngDashDims.height,
        //     width: pngDashDims.width,
        //     height: pngDashDims.height
        // });
        // const cardImage = await getScreenshot("ServerStatus");
        // const pngCardImage = await pdfDoc.embedPng(cardImage);
        // const cardImageDims = pngCardImage.scale(0.75);
        // fullDashPage.drawImage(pngCardImage, {
        //     x: 0,
        //     y: 0,
        //     width: cardImageDims.width,
        //     height: cardImageDims.height
        // });
        const newBytes = await pdfDoc.saveAsBase64({ dataUri: true });

        // Create a new link
        const anchor = document.createElement("a");
        anchor.href = newBytes;
        anchor.download = "output.pdf";

        // Append to the DOM
        document.body.appendChild(anchor);

        // Trigger `click` event
        anchor.click();

        // Remove element from DOM
        document.body.removeChild(anchor);
    }
    return <div style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", marginBottom: "80vh" }}>
        <h2>Generate Report</h2>
        <Button primary={!isGeneratingPDF} disabled={isGeneratingPDF} onClick={GenAndDownloadStandardPDF}>{!isGeneratingPDF ? "Download Report" : "Please Wait"}</Button>
    </div>
};

type LastMonthCardsProps = {
    custID: string,
    startDate: Date,
    endDate: Date,
    onLoaded: () => void,
}
const LastMonthsCards = (props: LastMonthCardsProps) => {
    const startDateClone = new Date(JSON.parse(JSON.stringify(props.startDate)));
    const endDateClone = new Date(JSON.parse(JSON.stringify(props.endDate)));
    const lastMonthStartDate = new Date(startDateClone.setMonth(props.startDate.getMonth() - 1));
    const lastMonthEndDate = new Date(endDateClone.setMonth(props.endDate.getMonth() - 1));
    return (
        <div id="lastMonth" style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }}>
            <RenderCard
                customID={"LastMonthServiceDeskStats"}
                cardName={"ServiceDeskStats"}
                cardType={0}
                custID={props.custID}
                startDate={lastMonthStartDate}
                endDate={lastMonthEndDate}
                cardRendered={props.onLoaded}
                disableReRender={true}
                isCSE={false}
                localPreview={false}
                OpenHelpPopup={() => { }}
            />
            <RenderCard
                customID={"LastMonthServiceDeskSitePerf"}
                cardName={"ServiceDeskPerformance"}
                cardType={0}
                custID={props.custID}
                startDate={lastMonthStartDate}
                endDate={lastMonthEndDate}
                cardRendered={props.onLoaded}
                disableReRender={true}
                isCSE={false}
                localPreview={false}
                OpenHelpPopup={() => { }}
            />
            <RenderCard
                customID={"LastMonthActivityPerUser"}
                cardName={"ActivityPerUser"}
                cardType={0}
                custID={props.custID}
                startDate={lastMonthStartDate}
                endDate={lastMonthEndDate}
                cardRendered={props.onLoaded}
                disableReRender={true}
                isCSE={false}
                localPreview={false}
                OpenHelpPopup={() => { }}
            />
            {/* <RenderCard
                customID={"LastMonthServerThreat"}
                cardName={"ServerThreat"}
                cardType={1}
                custID={props.custID}
                startDate={lastMonthStartDate}
                endDate={lastMonthEndDate}
                cardRendered={props.onLoaded}
                disableReRender={true}
                isCSE={false}
                localPreview={false}
                OpenHelpPopup={() => { }}
            /> */}
            <RenderCard
                customID={"LastMonthCSERemoteAgent"}
                cardName={"CSERemoteAgent"}
                cardType={1}
                custID={props.custID}
                animate={false}
                startDate={lastMonthStartDate}
                endDate={lastMonthEndDate}
                cardRendered={props.onLoaded}
                disableReRender={true}
                isCSE={false}
                localPreview={false}
                OpenHelpPopup={() => { }}
            />
        </div>
    )
}

const Comments = (props: LastMonthCardsProps) => {
    const [allComments, setAllComments] = useState<Comment[]>();
    const [isLoading, setIsLoading] = useState(true);
    const GetRemoteComments = async (custID: string, startDate: Date, endDate: Date) => {
        const timeZoneOffset = startDate.getTimezoneOffset() * 60;
        const newStartDate = Math.floor(startDate.getTime() / 1000) - timeZoneOffset;
        const newEndDate = Math.floor(endDate.getTime() / 1000) - timeZoneOffset;
        var res = await send({
            method: "GET",
            url: `/api/reporting/getallcomments?custid=${custID}&startdate=${newStartDate}&enddate=${newEndDate}`,
            data: "",
            params: "",
            token: await getAccessToken()
        });
        return res.data as Comment[];
    }
    useEffect(() => {
        GetRemoteComments(props.custID, props.startDate, props.endDate).then((res) => {
            setAllComments(res);
            setIsLoading(false);
            props.onLoaded();
        }).catch((error) => {
            console.log(error);
        })
    }, [])
    const GetPageCommentsString = (cardType: string) => {
        var returnArr: Comment[] = [];
        allComments?.forEach((comment) => {
            if (comment.card_type === cardType) {
                returnArr.push(comment);
            }
        })
        return returnArr;
    }
    if (isLoading) {
        return <></>
    }
    return (
        <div id="comments" style={{ whiteSpace: "pre-wrap" }}>
            <div className={styles.commentBlock} id="serviceDeskStatsComments">{GetPageCommentsString("ServiceDeskStats").map((comment, index) => { return <p key={"serviceDeskStatsCommentsKey" + index}>{comment.comment}</p> })}</div>
            <div className={styles.commentBlock} id="sdSitePerfComments">{GetPageCommentsString("ServiceDeskPerformance").map((comment, index) => { return <p key={"sdSitePerfCommentsKey" + index}>{comment.comment}</p> })}</div>
            <div className={styles.commentBlock} id="userActivityComments">{GetPageCommentsString("ActivityPerUser").map((comment, index) => { return <p key={"userActivityCommentsKey" + index}>{comment.comment}</p> })}</div>
            <div className={styles.commentBlock} id="serverAVComments">{GetPageCommentsString("ServerThreat").map((comment, index) => { return <p key={"serverAVCommentsKey" + index}>{comment.comment}</p> })}</div>
            <div className={styles.commentBlock} id="cseRemoteAgentComments">{GetPageCommentsString("CSERemoteAgent").map((comment, index) => { return <p key={"cseRemoteAgentCommentsKey" + index}>{comment.comment}</p> })}</div>
            <div className={styles.commentBlock} id="networkStatusComments">
                {GetPageCommentsString("NetworkSwitchDeviceStatus").map((comment, index) => { return <p key={"NetworkSwitchDeviceStatusCommentsKey" + index}>{comment.comment}</p> })}
                {GetPageCommentsString("NetworkWAPDeviceStatus").map((comment, index) => { return <p key={"NetworkWAPDeviceStatusCommentsKey" + index}>{comment.comment}</p> })}
            </div>
            <div className={styles.commentBlock} id="serverPendingWinUpdatesComments">{GetPageCommentsString("ServerWinUpdates").map((comment, index) => { return <p key={"serverPendingWinUpdatesCommentsKey" + index}>{comment.comment}</p> })}</div>
        </div>
    )
}

const PopoutViewer = (props: LastMonthCardsProps) => {
    return (
        <div id="popoutViewer">
            <ServiceDeskPerformancePopout
                theme={GetTheme()}
                startDate={props.startDate}
                endDate={props.endDate}
                popoutRendered={props.onLoaded}
                custID={props.custID}
            />
            <ServiceRequestsPopout
                theme={GetTheme()}
                startDate={props.startDate}
                endDate={props.endDate}
                popoutRendered={props.onLoaded}
                customerID={props.custID}
            />
            <ActivityPerUser
                theme={GetTheme()}
                startDate={props.startDate}
                endDate={props.endDate}
                popoutRendered={props.onLoaded}
                customerID={props.custID}
            />
            <AntiThreatPopout theme={GetTheme()} serversBool={true} popoutRendered={props.onLoaded} customerID={props.custID} />
            <ServerUpdatePopout
                theme={GetTheme()}
                renderBar={false}
                popoutRendered={props.onLoaded}
                customerID={props.custID}
            />
        </div>
    )
}

export default Reports;
