import React, { useState } from "react";
import LoginMenu from "./authorization/LoginMenu";
import "./NavMenu.css";
import useDarkMode from "../../hooks/useDarkMode";
import { tr } from "../../helpers/languages";

import NoteIcon from "../../assets/icons/changelog.svg";
import SunIcon from "../../assets/icons/sun.svg";
import MoonIcon from "../../assets/icons/moon.svg";
import HandleURLParams from "./URLParams";
import { LogEvent } from "../analytics/eventTracker";

const GetTheme = () => {
    let theme = localStorage.getItem("theme");
    if (theme === null) {
        theme = "light-theme";
    }
    return theme;
};

const ThemeIcon = () => {
    const [theme, setTheme] = useDarkMode();
    var themeImage = theme === "light-theme" ? SunIcon : MoonIcon;
    const handleMode = () => {
        LogEvent(6, { type: "nav-menu" });
        if (theme === "light-theme") {
            setTheme("dark-theme");
            themeImage = MoonIcon;
        } else {
            setTheme("light-theme");
        }
    };
    const [itemHovered, setItemHovered] = useState("");
    return (
        <div className="icon-hide-mobile cse-nav-menu-icon-container">
            <img
                alt=""
                aria-label="Theme Toggle"
                onMouseEnter={() => setItemHovered("item5")}
                onMouseLeave={() => setItemHovered("")}
                className="cse-nav-menu-icon"
                src={themeImage}
                onClick={handleMode}
            />
            <span className={itemHovered === "item5" ? "cse-nav-menu-tooltip-hovered cse-nav-menu-tooltip" : "cse-nav-menu-tooltip"}>
                Toggle Theme
            </span>
        </div>
    );
};

type NavMenuProps = {};
type NavMenuState = {
    username: string,
    theme: string,
    itemHovered: string,
    themeKey: number,
    marquee: string
};

export default class NavMenu extends React.PureComponent<NavMenuProps, NavMenuState> {
    state = {
        username: "",
        theme: GetTheme(),
        itemHovered: "",
        themeKey: 0,
        marquee: ""
    };

    componentDidMount() {
        window.addEventListener("themeToggled", this.UpdateTheme);
        this.getMarqueeMode();
    }
    componentWillUnmount() {
        window.removeEventListener("themeToggled", this.UpdateTheme);
    }
    getMarqueeMode = () => {
        const marqueeVal = window.localStorage.getItem("marquee");
        if (marqueeVal) {
            this.setState({ marquee: marqueeVal });
        }
    };
    UpdateTheme = () => {
        var curTheme = GetTheme();
        if (curTheme !== this.state.theme) {
            this.setState({
                theme: GetTheme(),
                themeKey: this.state.themeKey + 1
            });
        }
    };
    receiveUserName = (username: string) => {
        if (username !== this.state.username) {
            this.setState({ username });
        }
    };
    toggleItemHover = (hoverItem: string) => {
        this.setState({ itemHovered: hoverItem });
    };

    /**            <ClickOutside onClickOutside={() => { this.setState({ expanded: false }) }}>
                <SideNav
                    onSelect={this.onSelect}
                    expanded={this.state.expanded}
                    onToggle={this.onToggle}
                >
                    <Toggle />
                    {this.state.expanded &&
                        <div className="NavHeader">
                            <div className="NavTitle">CSE.ServiceManager.Reporting</div>
                            {this.state.username !== "" && <div className="NavSubtitle"><a href={this.state.profilePath}>Hello {this.state.username}</a></div>}
                        </div>
                    }
                    <Nav defaultSelected={this.state.selected}>
                        <LoginMenu onSelect={this.onSelect} expanded={this.state.expanded} selected={this.state.selected} receiveUserName={this.receiveUserName}>
                        </LoginMenu>
                        <ThemeIcon />
                        <NavItem eventKey="popup" selected={false} onClick={() => {this.setState({ popupStatus: !this.state.popupStatus, expanded: false })}}>
                            <NavIcon><img alt="Change Log" aria-label="Change Log" src={Rocket}/></NavIcon>
                            <NavText>{tr('WHATS_NEW')}</NavText>
                        </NavItem>
                    </Nav>
                </SideNav>
                {this.state.popupStatus && <Popup content={<Changelog endpoint="changelog.json" />} close={this.closePopup} />}
            </ClickOutside> */

    render() {
        const urlParams = HandleURLParams();
        if (this.state.marquee !== "" || urlParams.isServiceDesk === "true") {
            return <></>;
        }
        return (
            <nav className="cse-nav">
                <div className="cse-navbar">
                    <LoginMenu receiveUserName={this.receiveUserName}>
                        <div className="icon-hide-mobile cse-nav-menu-icon-container cse-nav-menu-last-item">
                            <img
                                alt=""
                                onMouseEnter={() => this.toggleItemHover("item4")}
                                onMouseLeave={() => this.toggleItemHover("")}
                                aria-label={tr("MENU_CHANGELOG")}
                                className="cse-nav-menu-icon"
                                src={NoteIcon}
                                onClick={() => {
                                    window.open("https://updates.cse-net.co.uk/Home/Reporting", "_blank");
                                }}
                            />
                            <span
                                className={
                                    this.state.itemHovered === "item4" ? "cse-nav-menu-tooltip-hovered cse-nav-menu-tooltip" : "cse-nav-menu-tooltip"
                                }
                            >
                                {tr("MENU_CHANGELOG")}
                            </span>
                        </div>
                    </LoginMenu>
                    <ThemeIcon key={this.state.themeKey} />
                </div>
            </nav>
        );
    }
}
