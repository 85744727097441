import * as React from "react";
import userManager from "../../../util/userManager";
import { send } from "../../../helpers/requests";
import DataTable from "react-data-table-component";
import { tr } from "../../../helpers/languages";
import { Input, Label } from "reactstrap";
import dayjs from "dayjs";
import plugin from "dayjs/plugin/advancedFormat";
import { CSVLink } from "react-csv";
import { Spinner } from "../../common/ui";

import styles from "./popout.module.css";

dayjs.extend(plugin);

const columns = [
    {
        name: "Application",
        selector: (row) => row.friendlyName,
        sortable: true,
        reorder: true
    },
    {
        name: "Process",
        selector: (row) => row.exeName,
        sortable: true,
        reorder: true
    },
    {
        name: "Publisher",
        selector: (row) => row.company,
        sortable: true,
        reorder: true
    },
    {
        name: "Number of Times Used",
        selector: (row) => row.count,
        sortable: true,
        reorder: true
    },
    {
        name: "Number of Stations Used On",
        selector: (row) => row.devicesCount,
        sortable: true,
        reorder: true
    }
];

export default class ApplicationUsagePopout extends React.PureComponent {
    _isMounted = false;

    constructor(props) {
        super(props);
        this.state = {
            getDone: false,
            isServer: true,
            sites: [],
            applications: []
        };
    }
    componentDidMount() {
        this._isMounted = true;
        this.getSites(this.props.customerID);
        this.getApplications(this.props.customerID, this.props.startDate, this.props.endDate);
    }
    componentWillUnmount() {
        this._isMounted = false;
    }
    //API Calls
    getAccessToken = async () => {
        let token = (await userManager.getUser()).access_token;
        return token;
    };
    getSites = async (custID) => {
        send({
            method: "GET",
            url: `/api/reporting/getsites?custid=${custID}`,
            token: await this.getAccessToken()
        }).then((response) => {
            if (this._isMounted) {
                this.props.popoutRendered();
                this.setState({ sites: response.data });
            }
        });
    };
    getApplications = async (custID, startDate, endDate, site = "All") => {
        const timeZoneOffset = startDate.getTimezoneOffset() * 60;
        const newStartDate = Math.floor(startDate.getTime() / 1000) - timeZoneOffset;
        const newEndDate = Math.floor(endDate.getTime() / 1000) - timeZoneOffset;
        var url;
        if (this.state.currentSite !== "All") {
            url = `/api/reporting/getusedapplications?custid=${custID}&startdate=${newStartDate}&enddate=${newEndDate}&siteid=${site}`;
        } else {
            url = `/api/reporting/getusedapplications?custid=${custID}&startdate=${newStartDate}&enddate=${newEndDate}`;
        }
        send({
            method: "GET",
            url: url,
            token: await this.getAccessToken()
        }).then((response) => {
            if (this._isMounted) {
                this.setState({
                    getDone: true,
                    applications: response.data.applications
                });
            }
        });
    };
    //Other
    ChangeSite = (newSite) => {
        this.getApplications(this.props.customerID, newSite.target.value);
    };
    render() {
        if (!this.state.getDone) {
            return (
                <div
                    style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        marginTop: "10rem"
                    }}
                >
                    <Spinner size="250" />
                </div>
            );
        }
        return (
            <div className={styles.popoutContainer}>
                <div>
                    <h2 className={styles.popoutHeader}>{tr("DASHBOARD_APP_USAGE_POPOUT_TITLE")}</h2>
                </div>
                <div className={styles.popoutExport}>
                    <div className="perm-floating-label">
                        <Input className={styles.popoutInput} type="select" id="backupSelect" onChange={this.ChangeSite}>
                            <option value="All">{tr("ALL")}</option>
                            {this.state.sites.map((site, key) => {
                                return (
                                    <option key={key} value={site.site_id}>
                                        {site.site_name}
                                    </option>
                                );
                            })}
                        </Input>
                        <Label for="backupSelect">{tr("DASHBOARD_SITE_SELECT")}</Label>
                    </div>
                    <CSVLink
                        className={styles.exportCSVButton}
                        data={this.state.applications}
                        filename={"export " + dayjs().format("DD-MM-YYYY h-mm-ss") + ".csv"}
                    >
                        {tr("EXPORT_CSV")}
                    </CSVLink>
                </div>
                <div className={styles.popoutDataTable}>
                    <DataTable columns={columns} data={this.state.applications} theme={this.props.theme} pagination />
                </div>
            </div>
        );
    }
}
