import { tr } from "../../../helpers/languages";
import * as React from "react";
import ClickOutside from "../Nav/ClickOutside";
import styles from "./popout.module.css";
import { LogError } from "../../analytics/eventTracker";

type PopupProps = {
    close: () => void,
    content: JSX.Element | JSX.Element[],
    disableOverflow?: boolean,
    isNestedPopup?: boolean,
    PopupName: string
};
type PopupState = {
    hasError: boolean
};

class Popup extends React.PureComponent<PopupProps, PopupState> {
    state: PopupState = {
        hasError: false
    };
    isNestedPopup = document.getElementById("popupBlackout") !== null;

    static getDerivedStateFromError() {
        return { hasError: true };
    }

    componentDidCatch() {
        this.setState({ hasError: true });
    }
    render(): React.ReactNode {
        if (this.state.hasError) {
            var custID, startDate, endDate;
            try {
                custID = sessionStorage.getItem("custID");
                startDate = sessionStorage.getItem("startDate");
                endDate = sessionStorage.getItem("endDate");
            } catch (error) {
                console.warn("Unable to find customer ID and timeframe. Skipping.");
            }
            LogError("Card Details Popout Render Error", {
                custID,
                startDate,
                endDate,
                PopupName: this.props.PopupName
            });
            return (
                <div className={styles.popupBlackout}>
                    <ClickOutside onClickOutside={this.props.close}>
                        <div className={styles.popupBox}>
                            <span id="spanCloseButton" className={styles.closeIcon} onClick={this.props.close}>
                                X
                            </span>
                            <div>
                                <div style={{ display: "flex", flexDirection: "column" }}>
                                    <h2 className={styles.popoutHeader} style={{ color: "var(--cse-neg)", fontSize: "48px" }}>
                                        {tr("DASHBOARD_ERROR_GENERIC")}
                                    </h2>
                                    <p style={{ marginTop: "2rem", fontSize: "28px", lineHeight: "28px" }}>{tr("DASHBOARD_ERROR_COMPONENT")}</p>
                                </div>
                            </div>
                        </div>
                    </ClickOutside>
                </div>
            );
        }
        return (
            <PopupContents
                close={this.props.close}
                isNestedPopup={this.isNestedPopup}
                content={this.props.content}
                disableOverflow={this.props.disableOverflow}
                PopupName={this.props.PopupName}
            />
        );
    }
}

const PopupContents: React.FC<PopupProps> = ({ close, content, disableOverflow, isNestedPopup, PopupName }) => {
    return (
        <div id={`popupBlackout-${PopupName}`} className={isNestedPopup ? `${styles.popup}` : `${styles.popup} ${styles.popupBlackout}`}>
            <ClickOutside onClickOutside={close}>
                <div className={disableOverflow ? `${styles.popupBox} ${styles.popupOverflowDisable}` : styles.popupBox}>
                    <span id="spanCloseButton" className={styles.closeIcon} onClick={close}>
                        X
                    </span>
                    {content}
                </div>
            </ClickOutside>
        </div>
    );
};

export default Popup;
