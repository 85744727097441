import * as React from 'react';
import { tr } from '../../../../helpers/languages';

import styles from './skip-nav.module.css';

export default class SkipNav extends React.PureComponent {
    render(){
        //ToDo: Change /dashboard to fill the current page
        return(
            <a href="/dashboard#content" className={styles['skip-nav']}>{tr('SKIP_NAVIGATION')}</a>
        );
    }
}