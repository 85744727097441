import * as React from "react";
import { Spinner } from "../../../common/ui";
import { send } from "../../../../helpers/requests";
import { tr } from "../../../../helpers/languages";
import userManager from "../../../../util/userManager";
import { GetTheme } from "../SharedFunctions";

import Popup from "../../../common/popup/popout";
import VolumePopout from "../../popout/StorageVolume";

import CardClassic from "../../ui/TextCard";
import CardGauge from "../../ui/GaugeCard";
import CardPie from "../../ui/PieCard";

export default class DashCardStationVolStorage extends React.PureComponent {
    _isMounted = false; //If true then component is mounted. If false data returned from API GET should be dropped.

    constructor(props) {
        super(props);
        this.state = {
            stationVolStorage: {
                total: 0,
                under90: 0,
                over90: 0,
                over90Details: [],
                autoResolved: 0
            },
            getDone: false,
            popupStatus: false
        };
    }
    /**
     * componentDidMount
     * @description API calls for rebuilding base dashboard
     */
    componentDidMount() {
        this._isMounted = true;
        if (this.props.localPreview) {
            this.getDiskInformationPreview();
        } else {
            if (!this.props.raOnly) {
                this.getDiskInformation(this.props.custID, this.props.startDate, this.props.endDate);
            }
        }
    }
    componentWillUnmount() {
        this._isMounted = false;
    }
    componentDidUpdate(prevProps) {
        if (!this.props.disableReRender) {
            if (prevProps.custID !== this.props.custID || prevProps.startDate !== this.props.startDate || prevProps.endDate !== this.props.endDate) {
                this.setState({
                    getDone: false,
                    stationVolStorage: {
                        total: 0,
                        under90: 0,
                        over90: 0,
                        over90Details: [],
                        autoResolved: 0
                    }
                });
                this.getDiskInformation(this.props.custID, this.props.startDate, this.props.endDate);
            }
        }
    }
    /**
     * OpenPopup
     * @description Calls OpenPopup inside Dashboard.js
     */
    OpenPopup = () => {
        this.setState({ popupStatus: true });
    };
    ///
    /// API Calls
    ///
    /**
     * getAccessToken
     * @description Gets the access token of the authenticated user
     */
    getAccessToken = async () => {
        let token = (await userManager.getUser()).access_token;
        return token;
    };
    /**
     * getDiskInformation
     * @description Gets Disk Information and sets this.state.serverVolStorage or this.state.stationVolStorage
     * @param {String} custID Customer ID
     * @param {Date} startDate Start date as JS Date
     * @param {Date} endDate End date as JS Date
     * @param {Boolean} servers if (servers === true) => serverVolStorage else if (servers === false) => stationVolStorage
     */
    getDiskInformation = async (custID, startDate, endDate) => {
        const timeZoneOffset = startDate.getTimezoneOffset() * 60;
        const newStartDate = Math.floor(startDate.getTime() / 1000) - timeZoneOffset;
        const newEndDate = Math.floor(endDate.getTime() / 1000) - timeZoneOffset;
        send({
            method: "GET",
            url: `/api/reporting/diskdriveinformation?custid=${custID}&startdate=${newStartDate}&enddate=${newEndDate}&servers=false`,
            token: await this.getAccessToken()
        })
            .then((response) => {
                //if (servers) needed to match API. Moved stateVar to inside this if
                if (this._isMounted) {
                    this.setState({
                        getDone: true,
                        stationVolStorage: {
                            total: response.data.diskTotals,
                            under90: response.data.diskUnder90,
                            over90: response.data.diskOver90,
                            over90Details: response.data.Over90Details,
                            autoResolved: response.data.autoResolved
                        }
                    });
                    this.props.cardRendered();
                }
            })
            .catch((error) => {
                console.log(error);
            });
    };
    getDiskInformationPreview = () => {
        this.setState({
            getDone: true,
            stationVolStorage: {
                total: 5000,
                under90: 3500,
                over90: 100,
                autoResolved: 640
            }
        });
    };
    render() {
        var CardSpinner,
            ReturnCard = <></>;
        if (this.props.raOnly) {
            return <></>;
        }
        if (!this.state.getDone) {
            CardSpinner = (
                <div id="loading-spinner" className="spinner-parent">
                    <Spinner size={40} className="spinner-position" />
                </div>
            );
        }
        var percent = 0;
        switch (this.props.cardType) {
            case 0:
                ReturnCard = (
                    <CardClassic
                        title={tr("DASHBOARD_VOL_STOR_STATION_TITLE")}
                        cardID={this.props.cardID}
                        data={[
                            this.state.stationVolStorage.total,
                            this.state.stationVolStorage.under90,
                            this.state.stationVolStorage.over90,
                            this.state.stationVolStorage.autoResolved || 0
                        ]}
                        type={["dash-neut", "dash-neut", "dash-neg", "dash-neut"]}
                        altText={[
                            tr("DASHBOARD_VOL_STOR_TOTAL"),
                            tr("DASHBOARD_VOL_STOR_UNDER90"),
                            tr("DASHBOARD_VOL_STOR_OVER90"),
                            tr("DASHBOARD_VOL_STOR_AUTORES")
                        ]}
                        activatePopup={() => {
                            !this.props.localPreview ? this.OpenPopup() : console.log("Card Details");
                        }}
                        openHelpPopup={() => {
                            this.props.openHelpPopup("StationVolStorageCard");
                        }}
                        custID={this.props.custID}
                        startDate={this.props.startDate}
                        endDate={this.props.endDate}
                        isCSE={this.props.isCSE}
                        customID={this.props.customID}
                    >
                        {CardSpinner}
                    </CardClassic>
                );
                break;
            case 1:
                ReturnCard = (
                    <CardPie
                        graphType="doughnut"
                        cardID={this.props.cardID}
                        data={[this.state.stationVolStorage.under90, this.state.stationVolStorage.over90]}
                        labels={[tr("DASHBOARD_VOL_STOR_UNDER90"), tr("DASHBOARD_VOL_STOR_OVER90")]}
                        pieColours={["#8AB785", "#f12a2a"]}
                        title={tr("DASHBOARD_VOL_STOR_STATION_TITLE")}
                        getDone={this.state.getDone}
                        otherData={[this.state.stationVolStorage.total]}
                        type={[""]}
                        otherDataTitle={[tr("DASHBOARD_VOL_STOR_TOTAL")]}
                        activatePopup={() => {
                            !this.props.localPreview ? this.OpenPopup() : console.log("Card Details");
                        }}
                        openHelpPopup={() => {
                            this.props.openHelpPopup("StationVolStorageCard");
                        }}
                        custID={this.props.custID}
                        startDate={this.props.startDate}
                        endDate={this.props.endDate}
                        isCSE={this.props.isCSE}
                        customID={this.props.customID}
                    >
                        {CardSpinner}
                    </CardPie>
                );
                break;
            case 2:
            default:
                if (this.state.getDone) {
                    percent = (this.state.stationVolStorage.under90 / this.state.stationVolStorage.total) * 100;
                    if (percent >= 99 && percent < 100) {
                        percent = 0.99;
                    } else {
                        percent = +(percent / 100).toFixed(2);
                    }
                }
                ReturnCard = (
                    <CardGauge
                        title={tr("DASHBOARD_VOL_STOR_STATION_TITLE")}
                        cardID={this.props.cardID}
                        textData={[this.state.stationVolStorage.total, this.state.stationVolStorage.under90, this.state.stationVolStorage.over90]}
                        type={["dash-small-text dash-neut", "dash-small-text dash-neut", "dash-small-text dash-neg"]}
                        gaugeText={tr("DASHBOARD_VOL_STOR_GAUGE")}
                        gaugeColours={["#DC2323", "#35A048"]}
                        percent={percent}
                        altText={[tr("DASHBOARD_VOL_STOR_TOTAL"), tr("DASHBOARD_VOL_STOR_UNDER90"), tr("DASHBOARD_VOL_STOR_OVER90")]}
                        getDone={this.state.getDone}
                        activatePopup={() => {
                            !this.props.localPreview ? this.OpenPopup() : console.log("Card Details");
                        }}
                        openHelpPopup={() => {
                            this.props.openHelpPopup("StationVolStorageCard");
                        }}
                        custID={this.props.custID}
                        startDate={this.props.startDate}
                        endDate={this.props.endDate}
                        isCSE={this.props.isCSE}
                        customID={this.props.customID}
                    >
                        {CardSpinner}
                    </CardGauge>
                );
                break;
        }
        return (
            <>
                {ReturnCard}
                {this.state.popupStatus && (
                    <Popup
                        content={<VolumePopout theme={GetTheme()} popoutRendered={() => {}} serversBool={false} customerID={this.props.custID} />}
                        close={() => this.setState({ popupStatus: false })}
                        PopupName="StationVolumeCardDetails"
                    />
                )}
            </>
        );
    }
}
