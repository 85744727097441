import * as React from "react";
import userManager from "../../../util/userManager";
import { send } from "../../../helpers/requests";
import DataTable from "react-data-table-component";
import { tr } from "../../../helpers/languages";
import { Input, Label } from "reactstrap";
import dayjs from "dayjs";
import plugin from "dayjs/plugin/advancedFormat";
import { CSVLink } from "react-csv";
import { Spinner } from "../../common/ui";

import styles from "./popout.module.css";

dayjs.extend(plugin);

const defaultSort = (a, b) => {
    var checkValA, checkValB;
    if (a.result_status.includes("%")) {
        checkValA = a.result_status.replaceAll("%", "");
    } else {
        //Should be "Unable to get battery information". Fail-safe in-case new value is returned
        checkValA = "0";
    }
    if (b.result_status.includes("%")) {
        checkValB = b.result_status.replaceAll("%", "");
    } else {
        //Should be "Unable to get battery information". Fail-safe in-case new value is returned
        checkValB = "0";
    }
    return checkValB - checkValA;
};

export default class LaptopBatteryHealthPopout extends React.PureComponent {
    _isMounted = false;
    constructor(props) {
        super(props);
        this.state = {
            getDone: false,
            LaptopBatterHealth: [],
            search: "",
            curSite: "All"
        };
    }
    componentDidMount() {
        this._isMounted = true;
        this.getLaptopBatteryHealth(this.props.customerID);
    }
    componentWillUnmount() {
        this._isMounted = false;
    }
    //API Calls
    getAccessToken = async () => {
        let token = (await userManager.getUser()).access_token;
        return token;
    };
    getLaptopBatteryHealth = async (custID) => {
        send({
            method: "GET",
            url: `/api/reporting/getlaptopbatteryhealth?custid=${custID}`,
            token: await this.getAccessToken()
        }).then((response) => {
            if (this._isMounted) {
                this.props.popoutRendered();
                this.setState({
                    getDone: true,
                    LaptopBatterHealth: response.data
                });
            }
        });
    };
    //Other
    columns = [
        {
            name: "Host Name",
            selector: (row) => row.host_name,
            sortable: true,
            reorder: true
        },
        {
            name: "Result Status",
            selector: (row) => {
                if (row.result_status === "%") {
                    return "0%";
                }
                if (parseFloat(row.result_status) >= 100) {
                    return "100%";
                }
                return row.result_status;
            },
            conditionalCellStyles: [
                {
                    when: (row) => Math.round(parseInt(row.result_status)) >= 85,
                    style: {
                        fontColor: "#9fff7a"
                    }
                },
                {
                    when: (row) => Math.round(parseInt(row.result_status)) >= 85,
                    style: {
                        fontColor: "#ff8989"
                    }
                }
            ],
            sortable: true,
            sortFunction: (a, b) => defaultSort(a, b),
            reorder: true
        },
        {
            name: "Time Stamp",
            selector: (row) => dayjs(row.time_stamp).format("MMMM Do YYYY, h:mm:ss a"),
            sortable: true,
            reorder: true
        },
        {
            name: "Site Name",
            selector: (row) => row.site_name,
            sortable: true,
            reorder: true
        }
    ];
    ChangeSite = (newSite) => {
        this.setState({ curSite: newSite.target.value });
    };
    CreateSiteList = () => {
        var uniqueSites = [];
        this.state.LaptopBatterHealth.forEach((laptop) => {
            if (!uniqueSites.includes(laptop.site_name)) {
                uniqueSites.push(laptop.site_name);
            }
        });
        return uniqueSites.sort();
    };
    CreateFinalLaptopList = () => {
        var finalLapList = [];
        if (this.state.search === "") {
            if (this.state.curSite !== "All") {
                //If site filter used
                this.state.LaptopBatterHealth.forEach((laptop) => {
                    if (laptop.site_name === this.state.curSite) {
                        finalLapList.push(laptop);
                    }
                });
            } else {
                finalLapList = JSON.parse(JSON.stringify(this.state.LaptopBatterHealth));
            }
        } else {
            if (this.state.curSite !== "All") {
                //If site filter used
                this.state.LaptopBatterHealth.forEach((laptop) => {
                    if (laptop.site_name === this.state.curSite) {
                        if (laptop.host_name.toLowerCase().includes(this.state.search)) {
                            finalLapList.push(laptop);
                        }
                    }
                });
            } else {
                this.state.LaptopBatterHealth.forEach((laptop) => {
                    if (laptop.host_name.toLowerCase().includes(this.state.search)) {
                        finalLapList.push(laptop);
                    }
                });
            }
        }
        return finalLapList.sort((a, b) => defaultSort(a, b));
    };
    render() {
        var uniqueSites = this.CreateSiteList();
        var finalLaptopList = this.CreateFinalLaptopList();
        if (!this.state.getDone) {
            return (
                <div
                    style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        marginTop: "10rem"
                    }}
                >
                    <Spinner size="250" />
                </div>
            );
        }

        return (
            <div className={styles.popoutContainer}>
                <div>
                    <h2 className={styles.popoutHeader}>{tr("DASHBOARD_LAPTOP_BATTERY_HEALTH_POPOUT")}</h2>
                </div>
                <div className={styles.popoutSearch}>
                    <div className="form-floating-labels">
                        <Input
                            id="Search"
                            placeholder=" "
                            value={this.state.search || ""}
                            onChange={(v) => {
                                this.setState({
                                    search: v.target.value.toLowerCase()
                                });
                            }}
                        />
                        <Label for="Search">{tr("DASHBOARD_SEARCH_POPOUT_HOSTNAMES")}</Label>
                    </div>
                </div>
                <div className={styles.popoutExport}>
                    <div className="perm-floating-label">
                        <Input className={styles.popoutInput} type="select" id="volumeSiteSelect" onChange={this.ChangeSite}>
                            <option value="All">{tr("ALL")}</option>
                            {uniqueSites.map((site, key) => {
                                return (
                                    <option key={key} value={site}>
                                        {site}
                                    </option>
                                );
                            })}
                        </Input>
                        <Label for="lapSiteSelect">{tr("DASHBOARD_SITE_SELECT")}</Label>
                    </div>
                    <CSVLink
                        className={styles.exportCSVButton}
                        data={finalLaptopList}
                        filename={"export " + dayjs().format("DD-MM-YYYY h-mm-ss") + ".csv"}
                    >
                        {tr("EXPORT_CSV")}
                    </CSVLink>
                </div>
                <div className={styles.popoutDataTable}>
                    <DataTable columns={this.columns} data={finalLaptopList} theme={this.props.theme} pagination />
                </div>
            </div>
        );
    }
}
