import React from "react";
import { Route } from "react-router";
import AuthorizeRoute from "./common/authorization/AuthorizeRoute";
import Dashboard from "./dashboard/Dashboard";
import CallbackPage from "./common/authorization/CallbackPage";
import LoginPage from "./common/authorization/LoginPage";
import LogoutPage from "./common/authorization/LogoutPage";
import SilentRenewPage from "./common/authorization/SilentRenewPage";
import AccessDeniedPage from "./common/authorization/AccessDeniedPage";
import { ApplicationPaths, LoginActions } from "./common/authorization/AuthorizationConstants";
import { CacheCleanup } from "./common/cachecleanup/CacheCleanup";
import InternalPage from "./dashboard/internal/InternalPage";
class Routes extends React.Component {
    render() {
        return (
            <>
                <Route exact path="/" component={LoginPage} />
                <Route path={ApplicationPaths.AccessDenied} component={AccessDeniedPage} />
                <Route path={ApplicationPaths.Login} component={LoginPage} />
                <Route path={ApplicationPaths.LoginCallback} component={CallbackPage} />
                <Route path={ApplicationPaths.Profile} render={() => loginAction(LoginActions.Profile)} />
                <Route path={ApplicationPaths.LogOut} component={LogoutPage} />
                <Route path={ApplicationPaths.SilentRenew} component={SilentRenewPage} />
                <Route path="/forcerefresh" component={CacheCleanup} />
                <AuthorizeRoute path="/dashboard" component={Dashboard} />
                <AuthorizeRoute path="/internal" component={InternalPage} />
            </>
        );
    }
}
function loginAction(name: string) {
    window.location.replace(ApplicationPaths.IdentityManagePath);
    return <></>;
}

export default Routes;
