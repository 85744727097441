import { Options } from "../../types/options";

const defaultOptions: Options = {
    optionsVersion: 1,
    graphTextSize: 12
};

export const SetDefaultLocalStorage = () => {
    //lastVersion
    //Set inside index.html

    //lastCustomer
    //Set after changing customer

    //options
    if (window.localStorage.getItem("options") === null) {
        window.localStorage.setItem("options", JSON.stringify(defaultOptions));
    }
};
