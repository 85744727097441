import * as React from "react";
import userManager from "../../../../util/userManager";
import { send } from "../../../../helpers/requests";
import { Spinner } from "../../../common/ui";
import Popup from "../../../common/popup";
import CardLineGraph from "../../ui/LineGraphCard";
import { tr } from "../../../../helpers/languages";
import ServiceDeskMultiTypePopout from "../../popout/ServiceDeskMultiTypeRequests";

type ServiceDeskRequestPerDay = {
  date: string,
  value: number
}

type DashCardServiceDeskClosedRequestsProps = {
  animate?: boolean,
  custID?: string,
  startDate?: Date,
  endDate?: Date,
  disableReRender?: boolean,
  localPreview: boolean,
  cardRendered?: () => void,
  cardType: number,
  cardID: string,
  isCSE?: boolean,
  customID?: string,
  raOnly?: boolean,
  openHelpPopup: (type: string) => void
};
type DashCardServiceDeskClosedRequestsState = {
  popupStatus: boolean,
  getDone: boolean,
  ServiceDeskClosedRequestsDates: string[],
  ServiceDeskClosedRequestsValues: number[]
};

class DashCardServiceDeskClosedRequests extends React.PureComponent<DashCardServiceDeskClosedRequestsProps, DashCardServiceDeskClosedRequestsState> {
  _isMounted = false; //If true then component is mounted. If false data returned from API GET should be dropped.
  state = {
    popupStatus: false,
    getDone: false,
    ServiceDeskClosedRequestsDates: [],
    ServiceDeskClosedRequestsValues: []
  };
  /**
   * componentDidMount
   * @description API calls for rebuilding base dashboard
   */
  componentDidMount() {
    this._isMounted = true;
    if (this.props.localPreview) {
      this.getServiceDeskClosedRequestsPreview();
    } else {
      this.getServiceDeskClosedRequests(this.props.custID!, this.props.startDate!, this.props.endDate!);
    }
  }
  componentWillUnmount() {
    this._isMounted = false;
  }
  componentDidUpdate(prevProps: DashCardServiceDeskClosedRequestsProps) {
    if (!this.props.disableReRender) {
      if (prevProps.custID !== this.props.custID || prevProps.startDate !== this.props.startDate || prevProps.endDate !== this.props.endDate) {
        this.setState({ getDone: false, ServiceDeskClosedRequestsDates: [], ServiceDeskClosedRequestsValues: [] });
        this.getServiceDeskClosedRequests(this.props.custID!, this.props.startDate!, this.props.endDate!);
      }
    }
  }
  /**
   * OpenPopup
   * @description Calls OpenPopup inside Dashboard.js
   */
  OpenPopup = () => {
    this.setState({ popupStatus: true });
  };
  ///
  /// API Calls
  ///
  /**
   * getAccessToken
   * @description Gets the access token of the authenticated user
   */
  getAccessToken = async () => {
    let token = (await userManager.getUser())!.access_token;
    return token;
  };
  getServiceDeskClosedRequests = async (custID: string, startDate: Date, endDate: Date) => {
    const timeZoneOffset = startDate.getTimezoneOffset() * 60;
    const newStartDate = Math.floor(startDate.getTime() / 1000) - timeZoneOffset;
    const newEndDate = Math.floor(endDate.getTime() / 1000) - timeZoneOffset;
    send({
      method: "GET",
      url: `/api/reporting/sdclosedrequests?custid=${custID}&startdate=${newStartDate}&enddate=${newEndDate}`,
      token: await this.getAccessToken(),
      data: "",
      params: ""
    }).then((response: any) => {
      if (this._isMounted) {
        let dateArr: string[] = [];
        let valueArr: number[] = [];
        response.data.forEach((item: ServiceDeskRequestPerDay) => {
          const fixedDate = new Date(item.date);
          dateArr.push((new Date(fixedDate).getDate()) + '/' + (new Date(fixedDate).getMonth() + 1) + '/' + new Date(fixedDate).getFullYear());
          valueArr.push(item.value);
        })
        this.setState({
          getDone: true,
          ServiceDeskClosedRequestsDates: dateArr,
          ServiceDeskClosedRequestsValues: valueArr
        });
        this.props.cardRendered!();
      }
    }).catch((error) => {
      console.log(error);
    })
  }
  getServiceDeskClosedRequestsPreview = () => {
    this.setState({
      getDone: true,
      ServiceDeskClosedRequestsDates: ["2023-04-20T00:00:00", "2023-04-21T00:00:00", "2023-04-22T00:00:00", "2023-04-23T00:00:00", "2023-04-24T00:00:00", "2023-04-25T00:00:00", "2023-04-26T00:00:00"],
      ServiceDeskClosedRequestsValues: [100, 89, 5, 10, 0, 15, 46]
    })
  }
  render() {
    var CardSpinner,
      ReturnCard = <></>;
    if (!this.state.getDone) {
      CardSpinner = (
        <div id="loading-spinner" className="spinner-parent">
          <Spinner size={40} className="spinner-position" />
        </div>
      );
    }
    switch (this.props.cardType) {
      case 0:
      default:
        ReturnCard = (
          <CardLineGraph
            title={tr("DASHBOARD_SERVICEDESK_CLOSED_REQ_TITLE")}
            cardID={this.props.cardID}
            displayLegend={false}
            data={[this.state.ServiceDeskClosedRequestsValues]}
            chartColours={["#8AB785", "#f12a2a"]}
            labels={this.state.ServiceDeskClosedRequestsDates}
            datasetLabel={[tr("DASHBOARD_SERVICEDESK_CLOSED_REQ_LABEL")]}
            getDone={this.state.getDone}
            activatePopup={() => {
              !this.props.localPreview ? this.OpenPopup() : console.log("Card Details");
            }}
            openHelpPopup={() => {
              this.props.openHelpPopup("ServiceDeskClosedRequestsCard");
            }}
            custID={this.props.custID}
            startDate={this.props.startDate}
            endDate={this.props.endDate}
            isCSE={this.props.isCSE}
            customID={this.props.customID}
          >
            {CardSpinner}
          </CardLineGraph>
        );
        break;
    }
    return (
      <>
        {ReturnCard}
        {this.state.popupStatus && (
          <Popup
            content={
              <ServiceDeskMultiTypePopout
                type="Close"
                startDate={this.props.startDate!}
                endDate={this.props.endDate!}
                popoutRendered={() => { }}
                customerID={this.props.custID!}
              />
            }
            close={() => this.setState({ popupStatus: false })}
            PopupName="ServiceDeskClosedRequestsCardDetails"
          />
        )}
      </>
    );
  }
}

export default DashCardServiceDeskClosedRequests;
