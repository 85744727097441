import * as React from "react";
import userManager from "../../../util/userManager";
import { send } from "../../../helpers/requests";
import DataTable, { TableColumn, TableRow } from "react-data-table-component";
import { tr } from "../../../helpers/languages";
import { Input, Label } from "reactstrap";
import dayjs from "dayjs";
import plugin from "dayjs/plugin/advancedFormat";
import { CSVLink } from "react-csv";
import { Spinner } from "../../common/ui";

import styles from "./popout.module.css";
import type { ServiceDeskTopCategoriesAPI } from "../cards/ServiceDeskTopCategories/ServiceDeskTopCategories";

dayjs.extend(plugin);

type Props = {
    customerID: string,
    startDate: Date,
    endDate: Date,
    theme: string,
    popoutRendered: () => void
}
type State = {
    getDone: boolean,
    search: string,
    TopCategories: ServiceDeskTopCategoriesAPI[],
}

export default class ServiceDeskTopCategories extends React.PureComponent<Props, State> {
    _isMounted = false;
    state = {
        getDone: false,
        search: "",
        TopCategories: [],
    };
    componentDidMount() {
        this._isMounted = true;
        this.getTopCategories(this.props.customerID, this.props.startDate, this.props.endDate);
    }
    componentWillUnmount() {
        this._isMounted = false;
    }
    //API Calls
    /**
     * getAccessToken
     * @description Gets the access token of the authenticated user
     */
    getAccessToken = async () => {
        let token = (await userManager.getUser())?.access_token;
        return token;
    };
    getTopCategories = async (custID: string, startDate: Date, endDate: Date) => {
        const timeZoneOffset = startDate.getTimezoneOffset() * 60;
        const newStartDate = Math.floor(startDate.getTime() / 1000) - timeZoneOffset;
        const newEndDate = Math.floor(endDate.getTime() / 1000) - timeZoneOffset;
        send({
            method: "GET",
            url: `/api/reporting/getsdtopcategories?custid=${custID}&startdate=${newStartDate}&enddate=${newEndDate}`,
            token: await this.getAccessToken(),
            params: "",
            data: ""
        }).then((response: any) => {
            if (this._isMounted) {
                this.props.popoutRendered();
                this.setState({
                    getDone: true,
                    TopCategories: response.data
                });
            }
        });
    };
    columns: TableColumn<ServiceDeskTopCategoriesAPI>[] = [
        {
            name: "Category Name",
            selector: (row) => row._id,
            style: {
                textTransform: "capitalize"
            },
            sortable: true,
            reorder: true
        },
        {
            name: "Category Count",
            selector: (row) => row.count,
            sortable: true,
            reorder: true
        },
    ];
    CreateFinalKeywordList = () => {
        var finalKeywordList = [];
        if (this.state.search === "") {
            finalKeywordList = JSON.parse(JSON.stringify(this.state.TopCategories));
        } else {
            this.state.TopCategories.forEach((device: ServiceDeskTopCategoriesAPI) => {
                if (device._id.toLowerCase().includes(this.state.search)) {
                    finalKeywordList.push(device);
                }
            });
        }
        return finalKeywordList;
    }
    render() {
        var finalKeywordList = this.CreateFinalKeywordList();
        if (!this.state.getDone) {
            return (
                <div
                    style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        marginTop: "10rem"
                    }}
                >
                    <Spinner size={250} />
                </div>
            );
        }
        return (
            <div className={styles.popoutContainer}>
                <div>
                    <h2 className={styles.popoutHeader}>{tr("DASHBOARD_SERVICEDESK_CATEGORIES_TITLE")}</h2>
                </div>
                <div className={styles.popoutSearch}>
                    <div className="form-floating-labels">
                        <Input
                            id="Search"
                            placeholder=" "
                            value={this.state.search || ""}
                            onChange={(v: React.ChangeEvent<HTMLInputElement>) => {
                                this.setState({
                                    search: v.target.value.toLowerCase()
                                });
                            }}
                        />
                        <Label for="Search">{tr("DASHBOARD_SEARCH_POPOUT_CATEGORIES")}</Label>
                    </div>
                </div>
                <div className={styles.popoutExport}>
                    <CSVLink
                        className={styles.exportCSVButton}
                        data={finalKeywordList}
                        filename={"export " + dayjs().format("DD-MM-YYYY h-mm-ss") + ".csv"}
                    >
                        {tr("EXPORT_CSV")}
                    </CSVLink>
                </div>
                <DataTable columns={this.columns} data={finalKeywordList} theme={this.props.theme} pagination />
            </div>
        );
    }
}