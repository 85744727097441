import * as React from "react";
import { Spinner } from "../../../common/ui";
import { send } from "../../../../helpers/requests";
import { tr } from "../../../../helpers/languages";
import userManager from "../../../../util/userManager";
import { GetTheme } from "../SharedFunctions";

import Popup from "../../../common/popup/popout";
import AlertServiceRequestsPopout from "../../popout/AlertServiceRequests";

import CardClassic from "../../ui/TextCard";
import CardGauge from "../../ui/GaugeCard";

export default class DashCardCSERemoteAgent extends React.PureComponent {
    _isMounted = false; //If true then component is mounted. If false data returned from API GET should be dropped.

    constructor(props) {
        super(props);
        this.state = {
            cseRemoteAgentAlerts: {
                total: 0,
                resolved: 0,
                unresolved: 0,
                unresolvedDetails: []
            },
            getDone: false,
            popupStatus: false
        };
    }
    /**
     * componentDidMount
     * @description API calls for rebuilding base dashboard
     */
    componentDidMount() {
        this._isMounted = true;
        if (this.props.localPreview) {
            this.getCSERemoteAgentAlertsPreview();
        } else {
            if (!this.props.raOnly) {
                this.getCSERemoteAgentAlerts(this.props.custID, this.props.startDate, this.props.endDate);
            }
        }
    }
    componentWillUnmount() {
        this._isMounted = false;
    }
    componentDidUpdate(prevProps) {
        if (!this.props.disableReRender) {
            if (prevProps.custID !== this.props.custID || prevProps.startDate !== this.props.startDate || prevProps.endDate !== this.props.endDate) {
                this.setState({
                    getDone: false,
                    cseRemoteAgentAlerts: {
                        total: 0,
                        resolved: 0,
                        unresolved: 0,
                        unresolvedDetails: []
                    }
                });
                this.getCSERemoteAgentAlerts(this.props.custID, this.props.startDate, this.props.endDate);
            }
        }
    }
    /**
     * OpenPopup
     * @description Calls OpenPopup inside Dashboard.js
     */
    OpenPopup = () => {
        this.setState({ popupStatus: true });
    };
    ///
    /// API Calls
    ///
    /**
     * getAccessToken
     * @description Gets the access token of the authenticated user
     */
    getAccessToken = async () => {
        let token = (await userManager.getUser()).access_token;
        return token;
    };
    /**
     * getCSERemoteAgentAlerts
     * @description Gets CSE Remote Agent Alerts and Sets this.state.cseRemoteAgentAlerts
     * @param {String} custID Customer ID
     * @param {Date} startDate Start date as JS Date
     * @param {Date} endDate End date as JS Date
     */
    getCSERemoteAgentAlerts = async (custID, startDate, endDate) => {
        const timeZoneOffset = startDate.getTimezoneOffset() * 60;
        const newStartDate = Math.floor(startDate.getTime() / 1000) - timeZoneOffset;
        const newEndDate = Math.floor(endDate.getTime() / 1000) - timeZoneOffset;
        send({
            method: "GET",
            url: `/api/reporting/cseremoteagentalerts?custid=${custID}&startdate=${newStartDate}&enddate=${newEndDate}`,
            token: await this.getAccessToken()
        })
            .then((response) => {
                if (this._isMounted) {
                    this.setState({
                        getDone: true,
                        cseRemoteAgentAlerts: {
                            total: response.data.totalAlerts,
                            resolved: response.data.resolved,
                            unresolved: response.data.unresolved,
                            unresolvedDetails: response.data.unresolvedDetails0
                        }
                    });
                    this.props.cardRendered();
                }
            })
            .catch((error) => {
                console.log(error);
            });
    };
    getCSERemoteAgentAlertsPreview = () => {
        this.setState({
            getDone: true,
            cseRemoteAgentAlerts: {
                total: 1000,
                resolved: 900,
                unresolved: 100
            }
        });
    };
    render() {
        var CardSpinner,
            ReturnCard = <></>;
        if (this.props.raOnly) {
            return <></>;
        }
        if (!this.state.getDone) {
            CardSpinner = (
                <div id="loading-spinner" className="spinner-parent">
                    <Spinner size={40} className="spinner-position" />
                </div>
            );
        }
        switch (this.props.cardType) {
            case 0:
                ReturnCard = (
                    <CardClassic
                        title={tr("DASHBOARD_CSE_REMAGENT_TITLE")}
                        cardID={this.props.cardID}
                        data={[
                            this.state.cseRemoteAgentAlerts.total,
                            this.state.cseRemoteAgentAlerts.resolved,
                            this.state.cseRemoteAgentAlerts.unresolved
                        ]}
                        type={["dash-neut", "dash-neut", "dash-neg"]}
                        altText={[tr("DASHBOARD_CSE_REMAGENT_TOTAL"), tr("DASHBOARD_CSE_REMAGENT_RESOLVED"), tr("DASHBOARD_CSE_REMAGENT_UNRESOLVED")]}
                        activatePopup={() => {
                            !this.props.localPreview ? this.OpenPopup() : console.log("Card Details");
                        }}
                        openHelpPopup={() => {
                            this.props.openHelpPopup("CSERemoteAgentCard");
                        }}
                        custID={this.props.custID}
                        isCSE={this.props.isCSE}
                        startDate={this.props.startDate}
                        endDate={this.props.endDate}
                        customID={this.props.customID}
                    >
                        {CardSpinner}
                    </CardClassic>
                );
                break;
            case 1:
            default:
                ReturnCard = (
                    <CardGauge
                        title={tr("DASHBOARD_CSE_REMAGENT_TITLE")}
                        cardID={this.props.cardID}
                        textData={[
                            this.state.cseRemoteAgentAlerts.total,
                            this.state.cseRemoteAgentAlerts.resolved,
                            this.state.cseRemoteAgentAlerts.unresolved
                        ]}
                        altText={[tr("DASHBOARD_CSE_REMAGENT_TOTAL"), tr("DASHBOARD_CSE_REMAGENT_RESOLVED"), tr("DASHBOARD_CSE_REMAGENT_UNRESOLVED")]}
                        type={["dash-neut", "dash-neut", "dash-neg"]}
                        gaugeText="Alerts Resolved"
                        percent={Math.round((this.state.cseRemoteAgentAlerts.resolved / this.state.cseRemoteAgentAlerts.total) * 100) / 100}
                        gaugeColours={["#DC2323", "#35A048"]}
                        getDone={this.state.getDone}
                        activatePopup={() => {
                            !this.props.localPreview ? this.OpenPopup() : console.log("Card Details");
                        }}
                        openHelpPopup={() => {
                            this.props.openHelpPopup("CSERemoteAgentCard");
                        }}
                        custID={this.props.custID}
                        isCSE={this.props.isCSE}
                        startDate={this.props.startDate}
                        endDate={this.props.endDate}
                        customID={this.props.customID}
                    >
                        {CardSpinner}
                    </CardGauge>
                );
                break;
        }
        return (
            <>
                {ReturnCard}
                {this.state.popupStatus && (
                    <Popup
                        content={
                            <AlertServiceRequestsPopout
                                getDone={this.state.getDone}
                                theme={GetTheme()}
                                startDate={this.props.startDate}
                                endDate={this.props.endDate}
                                popoutRendered={() => {}}
                                customerID={this.props.custID}
                            />
                        }
                        close={() => this.setState({ popupStatus: false })}
                        PopupName="ServiceRequestsCardDetails"
                    />
                )}
            </>
        );
    }
}
