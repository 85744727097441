import * as React from "react";
import styles from "./Layout.module.css";

type ContainerProps = {
    children: JSX.Element[] | JSX.Element,
    className?: string,
    style?: React.CSSProperties,
    center?: boolean,
    id?: string
};

const Container: React.FC<ContainerProps> = ({ center, children, className, style }) => {
    var centerValue = "";
    if (center) centerValue = styles.center;
    return (
        <div id="container" style={style} className={`${className} ${centerValue} ${styles.container}`}>
            {children}
        </div>
    );
};

export default Container;
