import React, { useEffect, useState } from "react";
import { tr } from "../../../../helpers/languages";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { ErrorMessage } from "@hookform/error-message";
import { send } from "../../../../helpers/requests";
import userManager from "../../../../util/userManager";
import EmailIcon from "../../../../assets/icons/email.svg";
import SendIcon from "../../../../assets/icons/send.svg";
import { toPng } from "html-to-image";
import Popup from "../../popup";
import { z } from "zod";
import styles from "./SendEmail.module.css";
import { Button, Col, Row, Spinner } from "../../ui";
import { format } from "date-fns";
import ReactQuill from "react-quill";
import "./quill.css";
import { AlwaysLogEvent, LogError } from "../../../analytics/eventTracker";

const sleep = (ms: number) => new Promise(
    resolve => setTimeout(resolve, ms)
);

const SendEmailSchema = z.object({
    outgoingemailid: z.number().or(z.string()),
    customerid: z.string(),
    to: z.string().min(2),
    cc: z.string().optional(),
    subject: z.string().min(2),
    attachmentName: z.string(),
    attachment: z.string() //email png as blob
});

type SendEmailType = z.infer<typeof SendEmailSchema>;

const getScreenshot = async () => {
    let image = "";
    try {
        const el = document.getElementById("content");
        if (el == null) {
            throw new Error();
        }
        image = await toPng(el);
    } catch (e) {
        LogError("Error taking screenshot", { type: "SendEmail", e });
        console.error(e);
    }
    return image;
};

const SendEmail = () => {
    const [hover, setHover] = useState(false);
    const [popupStatus, setPopupStatus] = useState(false);

    return (
        <>
            <div className="icon-hide-mobile cse-nav-menu-icon-container">
                <img
                    alt=""
                    onMouseEnter={() => setHover(true)}
                    onMouseLeave={() => setHover(false)}
                    aria-label={tr("DASHBOARD_SEND_EMAIL")}
                    className="cse-nav-menu-icon"
                    src={EmailIcon}
                    onClick={() => {
                        setPopupStatus(true);
                        AlwaysLogEvent("Email Sent", {});
                    }}
                />
                <span className={hover ? "cse-nav-menu-tooltip-hovered cse-nav-menu-tooltip" : "cse-nav-menu-tooltip"}>
                    {tr("DASHBOARD_SEND_EMAIL")}
                </span>
            </div>
            {popupStatus && (
                <Popup
                    content={<SendEmailForm />}
                    close={() => {
                        setPopupStatus(false);
                    }}
                    PopupName="SendEmail"
                />
            )}
        </>
    );
};

const ScreenshotPreview: React.FC<{ attachmentFileName: string, image: string }> = ({ attachmentFileName, image }) => {
    const [showImage, setShowImage] = useState<boolean>(false);
    const ToggleImage = () => {
        setShowImage(!showImage);
    };
    return (
        <>
            <p style={{ marginBottom: "0px" }}>
                {tr("EMAIL_ATTACHMENT")}: {attachmentFileName}
                <Button
                    type={"button"}
                    onClick={() => {
                        ToggleImage();
                    }}
                    style={{ marginLeft: "10px" }}
                >
                    {tr("EMAIL_PREVIEW")}
                </Button>
            </p>
            <img
                alt={tr("MENU_SCREENSHOT")}
                src={image}
                className={`${showImage ? styles.screenshotShow : styles.screenshotHide} ${styles.screenshotPreview}`}
            />
        </>
    );
};

const getCustomerID = async () => {
    let customerid = (await userManager.getUser())?.profile["http://schemas.cse-net.co.uk/ws/2014/02/identity/claims/customerid"];
    return customerid;
};
const getAccessToken = async () => {
    let token = (await userManager.getUser())?.access_token;
    return token;
};
export const getCustomerName = async () => {
    let CustID = await getCustomerID();
    let lastCustomer = localStorage.getItem("lastCustomer");
    if (lastCustomer !== null) {
        const lastCustomerObj = JSON.parse(lastCustomer);
        return {
            Name: lastCustomerObj.organisation_name,
            CustID: lastCustomerObj.customer_id
        };
    }
    var res: any = await send({
        method: "GET",
        url: `/api/reporting/customername`,
        token: await getAccessToken(),
        data: "",
        params: ""
    });
    return {
        Name: res.data.customerName,
        CustID: CustID
    }
};
export type SitesType = {
    id: number,
    from: string,
    name: string
}
const SendEmailForm = () => {
    const [emailSent, setEmailSent] = useState(false);
    const [image, setImage] = useState("");
    const [body, setBody] = useState(
        `<p>Hello,</p><p><br></p><p>Please find attached your report summary page.</p><p></p><p>If you would like to view the full report please use this link.</p><p></p><p><a href=\"https://reporting.cseservicemanager.co.uk \" rel=\"noopener noreferrer\" target=\"_blank\">https://reporting.cseservicemanager.co.uk </a></p><p></p><p>You will be asked to log into this system, please use your schools email address and password to login and view this report.</p><p><br></p><p>Kind Regards,</p><p>CSE Education Systems</p>`//eslint-disable-line no-useless-escape
    );
    const [customer, setCustomer] = useState<{ Name: string, CustID: string }>();
    useEffect(() => { //useEffect to only run once on first render, function because async/await
        getCustomerName().then((res) => {
            setCustomer(res);
        });
    }, [])
    const [sites, setSites] = useState<SitesType[]>();
    const [curSiteID, setCurSiteID] = useState<number>(0);
    const [curSite, setCurSite] = useState<number>(0);
    useEffect(() => { //useEffect to only run once on first render, function because async/await
        const getSites = async () => {
            send({
                method: "GET",
                url: `/api/reporting/getcustomeroutemails/${customer?.CustID}`,
                data: "",
                params: "",
                token: await getAccessToken()
            }).then((res) => {
                setSites(res.data as SitesType[]);
            })
        }
        if (customer?.CustID !== undefined) {
            getSites();
        }
    }, [customer]);
    const dateString = format(Date.now(), "dd-MM-yyyy HH-mm-ss");
    var attachmentFileName =
        customer?.Name === "" ? "dashboard " + dateString + ".png" : customer?.Name.split(" ").join("").slice(0, 5).toUpperCase() + " " + dateString + ".png";
    const {
        register,
        watch,
        formState: { errors },
        handleSubmit
    } = useForm({ resolver: zodResolver(SendEmailSchema) });
    if (customer === undefined) {
        return (
            <div
                style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    marginTop: "10rem"
                }}
            >
                <Spinner size={250} />
            </div>
        );
    }
    if (image === "") {
        getScreenshot().then((res) => {
            setImage(res);
        });
        return (
            <div
                style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    marginTop: "10rem"
                }}
            >
                <Spinner size={250} />
            </div>
        );
    }
    //curSite == Index of current site
    //curSiteID == ID of current site (not the same as curSite)
    if (parseInt(watch("outgoingemailid", sites?.[0].id)) !== curSiteID) {
        sites?.forEach((site, index) => {
            if (site.id === parseInt(watch("outgoingemailid"))) {
                setCurSite(index);
                setCurSiteID(site.id);
            }
        })
    }
    const postEmail = async (jsonData: SendEmailType) => {
        send({
            method: "POST",
            url: `/api/reporting/sendemail`,
            data: Object.assign(jsonData, { body: body }),
            params: "",
            token: await getAccessToken()
        }).then((response) => {
            setEmailSent(true);
            sleep(5000).then(() => {
                setEmailSent(false);
            });
        });
    };
    return (
        <>
            <form
                onSubmit={handleSubmit((data) => {
                    postEmail(data as SendEmailType);
                })}
                className={styles.formContainer}
            >
                <div className={styles.flexCol}>
                    <div className={`${styles.flexRow} ${styles.topItems}`}>
                        <Button type="submit" className={styles.sendButton} primary>
                            <img
                                src={SendIcon}
                                className={styles.sendImage}
                                aria-label={tr("DASHBOARD_SEND_EMAIL")}
                                alt={tr("DASHBOARD_SEND_EMAIL")}
                            />
                            <p style={{ marginBottom: "0" }}>{emailSent ? tr("EMAIL_SENT") : tr("EMAIL_SEND")}</p>
                        </Button>
                        <div style={{ maxWidth: "100%" }}>
                            <Row className={styles.headerRow}>
                                <Col>
                                    <span className={styles.spanHeading}>{tr("EMAIL_FROM")}: </span>
                                </Col>
                                <Col>
                                    <select {...register("outgoingemailid", {
                                        required: {
                                            value: true,
                                            message: "From field required"
                                        }
                                    })}
                                        placeholder="From *"
                                        className={styles.formTextInput}
                                        defaultValue={sites?.[0].id}
                                    >
                                        {sites && sites?.map((site) => {
                                            return <option value={site.id} key={site.id}>{site.name}</option>
                                        })}
                                    </select>
                                    <input
                                        className={styles.formTextInput}
                                        value={sites?.[curSite].from || ""}
                                        disabled
                                    />
                                    <ErrorMessage errors={errors} name="outgoingemailid" as="p" />
                                </Col>
                            </Row>
                            <Row>
                                <Col><p className={styles.emailAddrHeading}>{tr('EMAIL_ADDITIONAL_WARNING')}</p></Col>
                            </Row>
                            <Row className={styles.headerRow}>
                                <Col>
                                    <span className={styles.spanHeading}>{tr("EMAIL_TO")}: </span>
                                </Col>
                                <Col>
                                    <input
                                        {...register("to", {
                                            required: {
                                                value: true,
                                                message: "To field required"
                                            }
                                        })}
                                        placeholder="To *"
                                        autoComplete="off"
                                        className={styles.formTextInput}
                                    />
                                    <ErrorMessage errors={errors} name="to" as="p" />
                                </Col>
                            </Row>
                            <Row className={styles.headerRow}>
                                <Col>
                                    <span className={styles.spanHeading}>{tr("EMAIL_CC")}: </span>
                                </Col>
                                <Col>
                                    <input {...register("cc")} placeholder="Cc" autoComplete="off" className={styles.formTextInput} />
                                    <ErrorMessage errors={errors} name="cc" as="p" />
                                </Col>
                            </Row>
                            <Row className={styles.headerRow}>
                                <Col>
                                    <span className={styles.spanHeading}>{tr("EMAIL_SUBJECT")}: </span>
                                </Col>
                                <Col>
                                    <input
                                        {...register("subject", {
                                            required: {
                                                value: true,
                                                message: "Subject field required"
                                            }
                                        })}
                                        placeholder="Subject *"
                                        className={styles.formTextInput}
                                        defaultValue={`${customer?.Name} Weekly Report`}
                                        autoComplete="off"
                                    />
                                    <ErrorMessage errors={errors} name="subject" as="p" />
                                </Col>
                            </Row>
                        </div>
                    </div>
                    <Row className={`${styles.headerRow} ${styles.screenshotContainer}`}>
                        <ScreenshotPreview attachmentFileName={attachmentFileName} image={image} />
                    </Row>
                    <div id="quillDiv"><ReactQuill theme={"snow"} value={body} onChange={setBody} /></div>
                    <input {...register("customerid")} defaultValue={customer.CustID} style={{ display: "none" }} />
                    <input {...register("attachment")} defaultValue={image} style={{ display: "none" }} />
                    <input {...register("attachmentName")} defaultValue={attachmentFileName} style={{ display: "none" }} />
                </div>
            </form>
        </>
    );
};

export default SendEmail;
