import React, { Component } from "react";
import { connect } from "react-redux";
import { CallbackComponent } from "redux-oidc";
import userManager from "../../../util/userManager";
import PropTypes from "prop-types";
import { push } from "react-router-redux";
import { send } from "../../../helpers/requests";
import { ApplicationPaths } from '../authorization/AuthorizationConstants';
import { LogError } from "../../analytics/eventTracker";
class CallbackPage extends Component {

    render() {
        return (
            <CallbackComponent
                userManager={userManager}
                successCallback={async () => {
                    let token = (await userManager.getUser()).access_token;
                    send({
                        method: "GET",
                        url: '/api/servicemanager/getmysmaccess',
                        token: token
                    }).then((response) => {

                        console.log(response.data);
                        if (response.data.isAdmin || response.data.isTech) {
                            this.props.dispatch(push("/dashboard"));
                        } else {
                            this.props.dispatch(push(ApplicationPaths.AccessDenied));
                        }

                    }).catch((error) => {
                        console.log("Error :" + error);
                    });
                }}
                errorCallback={error => {
                    this.props.dispatch(push("/"));
                    LogError("Error Callback", error);
                    console.error(error);
                }}
            >
                <div style={{ textAlign: 'center' }}><h4>Processing login callback...</h4></div>
            </CallbackComponent>
        );
    }
}

CallbackPage.propTypes = {
    dispatch: PropTypes.func.isRequired
};

export default connect()(CallbackPage);