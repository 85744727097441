import React, { useEffect, useState } from "react";
import DataTable, { TableColumn } from "react-data-table-component";
import { tr } from "../../../helpers/languages";
import { send } from "../../../helpers/requests";
import dayjs from "dayjs";
import userManager from "../../../util/userManager";
import { Button, Row, Spinner } from "../../common/ui";
import { format } from "date-fns";
import { GetTheme } from "../cards/SharedFunctions";
import { CSVLink } from "react-csv";

import styles from "../popout/popout.module.css";

type ServiceDeskFeedback = {
    _id: string,
    customer_id: string,
    site_id: string,
    database_link_id: string,
    feedback_user: string,
    feedback_message: string,
    feedback_rating: number | string,
    created: Date,
    updated: Date,
    updated_by: string,
    organisation_id: string
};
type Site = {
    site_id: string,
    site_name: string
}
type AllFeedbackProps = {
    ExitPage: () => void
}

const AllFeedback = (props: AllFeedbackProps) => {
    const [feedback, setFeedback] = useState<ServiceDeskFeedback[]>([]);
    const [sites, setSites] = useState<Site[]>();
    const [getDone, setGetDone] = useState(false);
    const getAccessToken = async () => {
        let token = (await userManager.getUser())?.access_token;
        return token;
    };
    const getFeedback = async (timeFrame: boolean, startDate?: Date, endDate?: Date) => {
        let timeZoneOffset, newStartDate, newEndDate;
        if (timeFrame) {
            timeZoneOffset = startDate!.getTimezoneOffset() * 60;
            newStartDate = Math.floor(startDate!.getTime() / 1000) - timeZoneOffset;
            newEndDate = Math.floor(endDate!.getTime() / 1000) - timeZoneOffset;
        }

        send({
            method: "GET",
            url: timeFrame ? `/api/reporting/getalluserfeedback?startdate=${newStartDate}&enddate=${newEndDate}` : `/api/reporting/getalluserfeedback`,
            token: await getAccessToken(),
            data: "",
            params: ""
        })
            .then((response: any) => {
                setGetDone(true);
                setFeedback(response.data);
            })
            .catch((error) => {
                console.log(error);
            });
    };
    const getSites = async () => {
        send({
            method: "GET",
            url: `/api/reporting/getallsites`,
            token: await getAccessToken(),
            data: "",
            params: ""
        })
            .then((response: any) => {
                setSites(response.data);
            })
            .catch((error) => {
                console.log(error);
            })
    };
    const setDays = (days: Number) => {
        const curDate = new Date();
        var curStartDate = curDate;
        var curEndDate = new Date(new Date().setHours(0, 0, 0, 0));
        switch (days) {
            case 0: //Today
                break;
            case 1: //Yesterday
                curEndDate = new Date(curEndDate.setDate(curDate.getDate() - 1));
                break;
            case 2: //Last Week
                curEndDate = new Date(curEndDate.setDate(new Date().getDate() - 7));
                break;
            case 3: //Last 14 Days
                curEndDate = new Date(curEndDate.setDate(curDate.getDate() - 14));
                break;
            case 4: //Last Month
                curEndDate = new Date(curEndDate.setDate(curDate.getDate() - 30));
                break;
            case 5: //Last 2 Months
                curEndDate = new Date(curEndDate.setDate(curDate.getDate() - 60));
                break;
            case 6: //Last 3 Months
                curEndDate = new Date(curEndDate.setDate(curDate.getDate() - 90));
                break;
            case 7: //Last 6 Months
                curEndDate = new Date(curEndDate.setDate(curDate.getDate() - 180));
                break;
            case 8: //Last Year
                curEndDate = new Date(curEndDate.setDate(curDate.getDate() - 365));
                break;
            case 9: //All Time
                setGetDone(false);
                getFeedback(false);
                return;
            default:
                break;
        }
        setGetDone(false);
        getFeedback(true, curStartDate, curEndDate);
    };
    useEffect(() => {
        getFeedback(false);
        getSites();
    }, [])
    const CreateFinalFeedbackList = () => {
        var finalFeedbackList: ServiceDeskFeedback[] = [];
        finalFeedbackList = JSON.parse(JSON.stringify(feedback));
        finalFeedbackList.forEach((feedback: ServiceDeskFeedback, index) => {
            switch (feedback.feedback_rating) {
                case 0:
                    finalFeedbackList[index].feedback_rating = "Happy Feedback";
                    break;
                case 1:
                    finalFeedbackList[index].feedback_rating = "Fine Feedback";
                    break;
                default:
                    finalFeedbackList[index].feedback_rating = "Unhappy Feedback";
                    break;
            }
        })
        return finalFeedbackList;
    }
    const columns: TableColumn<ServiceDeskFeedback>[] = [
        {
            name: "Service Request ID",
            selector: (row) => row.database_link_id,
            sortable: true,
            reorder: true
        },
        {
            name: "Service Request Link",
            cell: (row) => {
                return (<a target="_blank" style={{ color: "var(--cse-text)", textDecoration: "underline" }} href={`https://cloud.cseservicemanager.co.uk/ServiceManager/ViewEditServiceRequestV2?id=${row.database_link_id}&orgId=${row.organisation_id}&isArchived=0`}>Click here</a>)
            },
            sortable: true,
            reorder: true
        },
        {
            name: "Site",
            selector: (row) => {
                var returnVal = row.site_id;
                sites?.every((site) => {
                    if (site.site_id === row.site_id) {
                        returnVal = site.site_name;
                        return false;
                    }
                    return true;
                })
                return returnVal;
            },
            sortable: true,
            reorder: true
        },
        {
            name: "Feedback Left By",
            selector: (row) => row.feedback_user,
            sortable: true,
            reorder: true
        },
        {
            name: "Feedback Rating",
            selector: (row) => row.feedback_rating,
            // selector: (row) => {
            //     switch (row.feedback_rating) {
            //         case 0:
            //             return "Happy Feedback"
            //         case 1:
            //             return "Fine Feedback"
            //         default:
            //             return "Unhappy Feedback"
            //     }
            // },
            sortable: true,
            reorder: true
        },
        {
            name: "Feedback Message",
            selector: (row) => row.feedback_message,
            sortable: true,
            reorder: true,
            wrap: true
        },
        {
            name: "Created Date",
            selector: (row) => row.created.toLocaleString(),
            sortable: true,
            reorder: true
        }
    ]
    if (!getDone) {
        return (
            <div
                style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    marginTop: "10rem"
                }}
            >
                <Spinner size={250} />
            </div>
        );
    }
    var finalFeedbackList = CreateFinalFeedbackList();
    return (
        <div className={styles.popoutContainer}>
            <div>
                <div style={{ flex: 1 }}><Button onClick={props.ExitPage}>X</Button></div>
                <h2 className={styles.popoutHeader}>{tr("CSE_INTERNAL_ALL_FEEDBACK")}</h2>
            </div>
            <Row center>
                <div style={{ display: "flex", flexDirection: "row" }}>
                    <Button
                        primary
                        onClick={() => {
                            setDays(0);
                        }}
                        style={{ marginRight: "5px" }}
                    >
                        {tr("DASHBOARD_FILTER_TODAY")}
                    </Button>
                    <Button
                        primary
                        onClick={() => {
                            setDays(1);
                        }}
                        style={{ marginRight: "5px" }}
                    >
                        {tr("DASHBOARD_FILTER_YESTERDAY")}
                    </Button>
                    <Button
                        primary
                        onClick={() => {
                            setDays(2);
                        }}
                        style={{ marginRight: "5px" }}
                    >
                        {tr("DASHBOARD_FILTER_LAST_WEEK")}
                    </Button>
                    <Button
                        primary
                        onClick={() => {
                            setDays(3);
                        }}
                        style={{ marginRight: "5px" }}
                    >
                        {tr("DASHBOARD_FILTER_LAST_FORTNIGHT")}
                    </Button>
                    <Button
                        primary
                        onClick={() => {
                            setDays(4);
                        }}
                        style={{ marginRight: "5px" }}
                    >
                        {tr("DASHBOARD_FILTER_LAST_MONTH")}
                    </Button>
                    <Button
                        primary
                        onClick={() => {
                            setDays(5);
                        }}
                        style={{ marginRight: "5px" }}
                    >
                        {tr("DASHBOARD_FILTER_LAST_TWO_MONTHS")}
                    </Button>
                    <Button
                        primary
                        onClick={() => {
                            setDays(6);
                        }}
                        style={{ marginRight: "5px" }}
                    >
                        {tr("DASHBOARD_FILTER_LAST_THREE_MONTHS")}
                    </Button>
                    <Button
                        primary
                        onClick={() => {
                            setDays(7);
                        }}
                        style={{ marginRight: "5px" }}
                    >
                        {tr("DASHBOARD_FILTER_LAST_SIX_MONTHS")}
                    </Button>
                    <Button
                        primary
                        onClick={() => {
                            setDays(8);
                        }}
                        style={{ marginRight: "5px" }}
                    >
                        {tr("DASHBOARD_FILTER_LAST_YEAR")}
                    </Button>
                    <Button
                        primary
                        onClick={() => {
                            setDays(9);
                        }}
                    >
                        {tr("DASHBOARD_FILTER_ALL_TIME")}
                    </Button>
                </div>
            </Row>
            <div className={styles.popoutExport}>
                <CSVLink
                    className={styles.exportCSVButton}
                    data={finalFeedbackList}
                    filename={`export ${format(Date.now(), "dd-MM-yyyy HH-mm-ss")}.csv`}
                >
                    {tr("EXPORT_CSV")}
                </CSVLink>
            </div>
            <div id="feedbackPerCustomerTable">
                <DataTable
                    columns={columns}
                    data={finalFeedbackList}
                    theme={"dark"}
                    pagination
                />
            </div>
        </div>
    )
}

export { AllFeedback };