import React, { useState } from "react";
import { Collapse, Input, Label } from "reactstrap";
import { Button } from "../../common/ui";
import ChevronDown from "../../../assets/icons/chevronDown.svg";
import { tr } from "../../../helpers/languages";
import { Cards } from "../Cards";
import { DashboardCard, DefaultDashboard } from "../DefaultDashboardLayouts";
import RenderCard from "../RenderCards";
import { send } from "../../../helpers/requests";
import userManager from "../../../util/userManager";
import { Convert } from "../../common/ValidateDashboardLayout";
import { LogError } from "../../analytics/eventTracker";

const getDashboard = (layout: DashboardCard[]) => {
    try {
        if (layout !== null) {
            Convert.toDashboardLayout(JSON.stringify(layout)); //validate JSON
        }
    } catch {
        LogError("Error Invalid Dashboard", { type: "CustomCards", dashLayout: JSON.stringify(layout) });
        console.warn("Invalid dashboard detected. Using default");
        layout = DefaultDashboard;
    }
    return layout;
};

type CardPreviewProps = {
    cardID: string,
    cardItem: string,
    cardType: number
};
const CardPreview: React.FC<CardPreviewProps> = (props) => {
    return (
        <div>
            <h5>{tr("DASHBOARD_SETTINGS_CARDS_PREVIEW")}</h5>
            <div style={{ maxHeight: "550px" }}>
                <RenderCard
                    cardName={props.cardItem}
                    cardType={props.cardType}
                    OpenHelpPopup={() => console.log("Open Help Popup")}
                    localPreview={true}
                />
            </div>
        </div>
    );
};

type CustomCardsProps = {
    layout: DashboardCard[],
    UpdateLayout: (layout: DashboardCard[], type: Boolean) => void,
    custID: string
};
const CustomCards: React.FC<CustomCardsProps> = (props) => {
    const [cards, setCards] = useState(() => {
        return getDashboard(props.layout);
    });
    const [activePreview, setActivePreview] = useState(999);
    const [saveButtonText, setSaveButtonText] = useState("");
    const activeList = Array.from(
        cards.map((item) => {
            return item.cardItem;
        })
    );
    const UpdateDashboard = (newValue: string, index: number) => {
        var tempCards = JSON.parse(JSON.stringify(cards));
        tempCards[index].cardType = parseInt(newValue);
        setCards(tempCards);
    };
    const getAccessToken = async () => {
        let token = (await userManager.getUser())?.access_token;
        return token;
    };
    const pushChanges = async () => {
        send({
            method: "POST",
            url: `/api/reporting/updatelayout`,
            data: cards,
            params: "",
            token: await getAccessToken()
        });
        props.UpdateLayout(cards, false);
        setSaveButtonText("Saved");
    };
    const togglePreview = (rowIndex: number) => {
        if (rowIndex === activePreview) {
            setActivePreview(999);
        } else {
            setActivePreview(rowIndex);
        }
    };
    return (
        <div style={{ display: "grid", justifyContent: "center" }}>
            {activeList.map((card, index) => {
                const cardIndex = Cards.findIndex((curItem) => {
                    return curItem.cardItem === card;
                });
                if (cardIndex === -1) {
                    return <div key={"errorCardContainer " + index}></div>;
                }
                return (
                    <React.Fragment key={"cardContainer" + index}>
                        <div style={{ display: "flex", justifyContent: "center" }}>
                            <div style={{ width: "250px", marginTop: "5px", marginBottom: "5px" }} className="perm-floating-label">
                                <Input
                                    style={{ minHeight: "50px" }}
                                    type="select"
                                    id={"cardItem" + index}
                                    onChange={(v: any) => {
                                        UpdateDashboard(v.target.value, index);
                                    }}
                                    value={Cards[cardIndex].availableUI.indexOf(Cards[cardIndex].availableUI[cards[index].cardType])}
                                >
                                    {Cards[cardIndex].availableUI.map((cardType, cardTypeIndex) => {
                                        return (
                                            <option key={"cardItem" + index + "option" + cardTypeIndex} value={cardTypeIndex}>
                                                {cardType}
                                            </option>
                                        );
                                    })}
                                </Input>
                                <Label style={{ textAlign: "left" }} for={"cardItem" + index}>
                                    {Cards[cardIndex].name}
                                </Label>
                            </div>
                            <img
                                style={{ cursor: "pointer", width: "60px", height: "60px" }}
                                src={ChevronDown}
                                alt=""
                                aria-label={tr("DASHBOARD_SETTINGS_CARDS_PREVIEW")}
                                onClick={() => togglePreview(index)}
                            />
                        </div>
                        <Collapse isOpen={activePreview === index}>
                            <CardPreview cardID={props.custID} cardItem={Cards[cardIndex].cardItem} cardType={cards[index].cardType} />
                        </Collapse>
                    </React.Fragment>
                );
            })}
            <Button style={{ marginBottom: "5px", marginLeft: "auto", marginRight: "auto" }} primary onClick={pushChanges}>
                {saveButtonText === "" ? tr("DASHBOARD_SETTINGS_SAVE_CARDS") : saveButtonText}
            </Button>
        </div>
    );
};

export default CustomCards;
