import React, { useEffect, useState } from "react";
import { version } from "../../../package.json";

const ConsoleDebug = () => {
    const [loading, setLoading] = useState(true);
    useEffect(() => {
        setLoading(false);
    }, []);
    if (loading) {
        console.log(`%cCSE Service Manager Reporting \nVersion: ${version}`, "font-family:system-ui;font-size:2rem;");
        console.log(`%c© CSE Education Systems ${new Date(Date.now()).getFullYear()}`, "font-family:system-ui;font-size:1rem;");
        console.log("%cStop!", "color:red;font-family:system-ui;font-size:4rem;-webkit-text-stroke: 1px black;font-weight:bold");
        console.log(
            "%cThis is a browser feature intended for developers. If someone told you to copy and paste something here you could give them access to your Microsoft account.",
            "font-family:system-ui;font-size:1.25rem;"
        );
    }
    return <></>;
};

export default ConsoleDebug;
