import React from "react";
import { Col, Row } from "../../common/ui";
import HelpDashIcon from "../../common/help/HelpDashIcon";
import BlankCard from "./BlankCard";

type CardBoldProps = {
    cardID: string,
    activatePopup: () => void,
    title: string,
    openHelpPopup: () => void,
    children: JSX.Element,
    text: number,
    textType: string,
    textTitle: string,
    additionalData?: {
        hostName: string,
        rebootRequired: boolean,
        siteName: string
    }[],
    additionalDataTitle?: string,
    custID: string,
    isCSE: boolean,
    customID?: string
    startDate: Date,
    endDate: Date
};

const CardBold = (props: CardBoldProps) => {
    const BaseReturn = (
        <BlankCard activatePopup={props.activatePopup} title={props.title} cardID={props.cardID} openHelpPopup={props.openHelpPopup} isCSE={props.isCSE} custID={props.custID} startDate={props.startDate} endDate={props.endDate}>
            <Row style={{ flexWrap: "nowrap" }} className="dash-content-row">
                <Col>
                    <div className={`main ${props.textType}`}>
                        {props.text}
                        <br />
                    </div>
                    <p>{props.textTitle}</p>
                </Col>
                {props.children}
            </Row>
        </BlankCard>
    );
    var AdditionalData = <></>;
    if (props.additionalData !== undefined) {
        if (props.additionalData.length !== 0) {
            AdditionalData = (
                <Col className="dash-content-col" center>
                    <h3
                        style={{
                            fontSize: "16px",
                            fontWeight: "bold"
                        }}
                    >
                        {props.additionalDataTitle}
                    </h3>
                    <ul style={{ paddingLeft: "0px" }}>
                        {props.additionalData.map((data, index) => {
                            if (index < 3) {
                                return (
                                    <li
                                        style={{
                                            listStyleType: "none",
                                            fontSize: "20px",
                                            paddingTop: "2px",
                                            paddingBottom: "2px"
                                        }}
                                        key={`ad ${index}`}
                                    >
                                        {data.hostName}
                                    </li>
                                );
                            } else {
                                return null;
                            }
                        })}
                    </ul>
                </Col>
            );
            return (
                <BlankCard
                    activatePopup={props.activatePopup}
                    customID={props.customID}
                    title={props.title}
                    openHelpPopup={props.openHelpPopup}
                    cardID={props.cardID}
                    custID={props.custID}
                    isCSE={props.isCSE}
                    startDate={props.startDate}
                    endDate={props.endDate}
                >
                    {props.children}
                    <Row style={{ flexWrap: "nowrap" }} className="dash-content-row">
                        <Col className="dash-content-col" center>
                            <div className={`main ${props.textType}`}>
                                {props.text}
                                <br />
                            </div>
                            <p>{props.textTitle}</p>
                        </Col>
                        {AdditionalData}
                    </Row>
                </BlankCard>
            );
        }
    }
    return BaseReturn;
};

export default CardBold;
