import * as React from "react";
import { Spinner } from "../../../common/ui";
import { send } from "../../../../helpers/requests";
import { tr } from "../../../../helpers/languages";
import userManager from "../../../../util/userManager";
import { GetTheme } from "../SharedFunctions";

import Popup from "../../../common/popup/popout";
import ServerShutdownPopout from "../../popout/ServerShutdown";

import CardHalfBarGraph from "../../ui/HalfBarGraphCard";

export default class DashCardUnexpectedServerShutdown extends React.PureComponent {
    _isMounted = false; //If true then component is mounted. If false data returned from API GET should be dropped.

    constructor(props) {
        super(props);
        this.state = {
            serverShutdowns: {
                totalShutdowns: 0,
                topDevices: [
                    { name: "", count: 0 },
                    { name: "", count: 0 },
                    { name: "", count: 0 }
                ]
            },
            getDone: false,
            popupStatus: false
        };
    }
    /**
     * componentDidMount
     * @description API calls for rebuilding base dashboard
     */
    componentDidMount() {
        this._isMounted = true;
        if (this.props.localPreview) {
            this.getServerShutdownsPreview();
        } else {
            if (!this.props.raOnly) {
                this.getServerShutdowns(this.props.custID, this.props.startDate, this.props.endDate);
            }
        }
    }
    componentWillUnmount() {
        this._isMounted = false;
    }
    componentDidUpdate(prevProps) {
        if (!this.props.disableReRender) {
            if (prevProps.custID !== this.props.custID || prevProps.startDate !== this.props.startDate || prevProps.endDate !== this.props.endDate) {
                this.setState({
                    getDone: false,
                    serverShutdowns: {
                        totalShutdowns: 0,
                        topDevices: [
                            { name: "", count: 0 },
                            { name: "", count: 0 },
                            { name: "", count: 0 }
                        ]
                    }
                });
                this.getServerShutdowns(this.props.custID, this.props.startDate, this.props.endDate);
            }
        }
    }
    /**
     * OpenPopup
     * @description Calls OpenPopup inside Dashboard.js
     */
    OpenPopup = () => {
        this.setState({ popupStatus: true });
    };
    ///
    /// API Calls
    ///
    /**
     * getAccessToken
     * @description Gets the access token of the authenticated user
     */
    getAccessToken = async () => {
        let token = (await userManager.getUser()).access_token;
        return token;
    };
    /**
     * getServerShutdowns
     * @description Gets the total number of server shutdowns given the customer ID and number of days entered
     * @param {String} custID Customer ID
     * @param {Date} startDate Start date as JS Date
     * @param {Date} endDate End date as JS Date
     */
    getServerShutdowns = async (custID, startDate, endDate) => {
        const timeZoneOffset = startDate.getTimezoneOffset() * 60;
        const newStartDate = Math.floor(startDate.getTime() / 1000) - timeZoneOffset;
        const newEndDate = Math.floor(endDate.getTime() / 1000) - timeZoneOffset;
        send({
            method: "GET",
            url: `/api/reporting/unexpectedservershutdowns?custid=${custID}&startdate=${newStartDate}&enddate=${newEndDate}`,
            token: await this.getAccessToken()
        })
            .then((response) => {
                if (this._isMounted) {
                    var serverShutdowns = JSON.parse(JSON.stringify(this.state.serverShutdowns));
                    serverShutdowns.totalShutdowns = response.data.totalShutdowns;
                    for (let index = 0; index < response.data.topDevices.length; index++) {
                        serverShutdowns.topDevices[index] = {
                            name: response.data.topDevices[index].key,
                            count: response.data.topDevices[index].value
                        };
                    }
                    this.setState({ getDone: true, serverShutdowns });
                    this.props.cardRendered();
                }
            })
            .catch((error) => {
                console.log(error);
            });
    };
    getServerShutdownsPreview = () => {
        this.setState({
            getDone: true,
            serverShutdowns: {
                totalShutdowns: 10,
                topDevices: [
                    { name: "Server-1", count: 5 },
                    { name: "Server-3", count: 3 },
                    { name: "Server-2", count: 1 }
                ]
            }
        });
    };
    render() {
        var CardSpinner,
            ReturnCard = <></>;
        if (this.props.raOnly) {
            return <></>;
        }
        if (!this.state.getDone) {
            CardSpinner = (
                <div id="loading-spinner" className="spinner-parent">
                    <Spinner size={40} className="spinner-position" />
                </div>
            );
        }
        switch (this.props.cardType) {
            case 0:
            default:
                ReturnCard = (
                    <CardHalfBarGraph
                        title={tr("DASHBOARD_UNEXP_SHUTDOWN_TITLE")}
                        cardID={this.props.cardID}
                        barData={this.state.serverShutdowns.topDevices}
                        barDataTitle={tr("DASHBOARD_UNEXP_SHUTDOWN_SHUTDOWNS")}
                        otherData={this.state.serverShutdowns.totalShutdowns}
                        otherDataTitle={tr("DASHBOARD_UNEXP_SHUTDOWN_NUMBER")}
                        type={"dash-neut"}
                        dataReturned={this.state.getDone}
                        displayLegend={false}
                        fontSize={"18px"}
                        activatePopup={() => {
                            !this.props.localPreview ? this.OpenPopup() : console.log("Card Details");
                        }}
                        openHelpPopup={() => {
                            this.props.openHelpPopup("UnexpectedShutdownCard");
                        }}
                        custID={this.props.custID}
                        startDate={this.props.startDate}
                        endDate={this.props.endDate}
                        isCSE={this.props.isCSE}
                        customID={this.props.customID}
                    >
                        {CardSpinner}
                    </CardHalfBarGraph>
                );
                break;
        }
        return (
            <>
                {ReturnCard}
                {this.state.popupStatus && (
                    <Popup
                        content={
                            <ServerShutdownPopout
                                theme={GetTheme()}
                                startDate={this.props.startDate}
                                endDate={this.props.endDate}
                                popoutRendered={() => {}}
                                customerID={this.props.custID}
                            />
                        }
                        close={() => this.setState({ popupStatus: false })}
                        PopupName="ServerUnexpectedShutdownCardDetails"
                    />
                )}
            </>
        );
    }
}
