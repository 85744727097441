import * as React from "react";
import userManager from "../../../util/userManager";
import { send } from "../../../helpers/requests";
import DataTable, { TableColumn, TableRow } from "react-data-table-component";
import { tr } from "../../../helpers/languages";
import { Input, Label } from "reactstrap";
import dayjs from "dayjs";
import plugin from "dayjs/plugin/advancedFormat";
import { CSVLink } from "react-csv";
import { Spinner } from "../../common/ui";

import styles from "./popout.module.css";
import { SamiConversationCountAPI } from "../cards/SamiConversationCount/SamiConversationCount";

type Props = {
    customerID: string,
    startDate: Date,
    endDate: Date,
    theme: string,
    popoutRendered: () => void
}
type State = {
    getDone: boolean,
    search: string,
    Conversations: SamiConversationCountAPI[],
}

export default class SamiConversationCount extends React.PureComponent<Props, State> {
    _isMounted = false;
    state = {
        getDone: false,
        search: "",
        Conversations: []
    }
    componentDidMount() {
        this._isMounted = true;
        this.getSamiConversationCount(this.props.customerID, this.props.startDate, this.props.endDate);
    }
    componentWillUnmount() {
        this._isMounted = false;
    }
    //API Calls
    /**
     * getAccessToken
     * @description Gets the access token of the authenticated user
     */
    getAccessToken = async () => {
        let token = (await userManager.getUser())?.access_token;
        return token;
    };
    getSamiConversationCount = async (custID: string, startDate: Date, endDate: Date) => {
        const timeZoneOffset = startDate.getTimezoneOffset() * 60;
        const newStartDate = Math.floor(startDate.getTime() / 1000) - timeZoneOffset;
        const newEndDate = Math.floor(endDate.getTime() / 1000) - timeZoneOffset;
        send({
            method: "GET",
            url: `/api/reporting/getsamiconversationcount?custid=${custID}&startdate=${newStartDate}&enddate=${newEndDate}`,
            token: await this.getAccessToken(),
            params: "",
            data: ""
        }).then((response: any) => {
            if (this._isMounted) {
                this.props.popoutRendered();
                this.setState({
                    getDone: true,
                    Conversations: response.data
                });
            }
        });
    };
    columns: TableColumn<SamiConversationCountAPI>[] = [
        {
            name: "User",
            selector: (row) => row._id,
            style: {
                textTransform: "capitalize"
            },
            sortable: true,
            reorder: true
        },
        {
            name: "Conversation Count",
            selector: (row) => row.count,
            sortable: true,
            reorder: true
        },
    ];
    CreateFinalConversationList = () => {
        var finalConversationList = [];
        if (this.state.search === "") {
            finalConversationList = JSON.parse(JSON.stringify(this.state.Conversations));
        } else {
            this.state.Conversations.forEach((user: SamiConversationCountAPI) => {
                if (user._id.toLowerCase().includes(this.state.search)) {
                    finalConversationList.push(user);
                }
            });
        }
        return finalConversationList;
    }
    render() {
        var finalConversationList = this.CreateFinalConversationList();
        if (!this.state.getDone) {
            return (
                <div
                    style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        marginTop: "10rem"
                    }}
                >
                    <Spinner size={250} />
                </div>
            );
        }
        return (
            <div className={styles.popoutContainer}>
                <div>
                    <h2 className={styles.popoutHeader}>{tr("DASHBOARD_SAMI_CONVERSATION_COUNT_TITLE")}</h2>
                </div>
                <div className={styles.popoutSearch}>
                    <div className="form-floating-labels">
                        <Input
                            id="Search"
                            placeholder=" "
                            value={this.state.search || ""}
                            onChange={(v: React.ChangeEvent<HTMLInputElement>) => {
                                this.setState({
                                    search: v.target.value.toLowerCase()
                                });
                            }}
                        />
                        <Label for="Search">{tr("DASHBOARD_SEARCH_POPOUT_USER")}</Label>
                    </div>
                </div>
                <div className={styles.popoutExport}>
                    <CSVLink
                        className={styles.exportCSVButton}
                        data={finalConversationList}
                        filename={"export " + dayjs().format("DD-MM-YYYY h-mm-ss") + ".csv"}
                    >
                        {tr("EXPORT_CSV")}
                    </CSVLink>
                </div>
                <DataTable columns={this.columns} data={finalConversationList} theme={this.props.theme} pagination />
            </div>
        )
    }
}