import { createUserManager } from "redux-oidc";

const clientId = "urn_cloudcsereporting"; //GUID Client Application ID
const authority = process.env.REACT_APP_MAG_IDP_HOST_URL+ "/core";

const userManagerConfig = {
    authority: authority,
    client_id: clientId,
    redirect_uri: `${document.location.origin}/authentication/login-callback`,
    silent_redirect_uri: `${document.location.origin}/authentication/silent-renew`,
   // post_logout_redirect_uri: `${window.location.protocol}//${window.location.hostname}${window.location.port ? `:${window.location.port}` : ""}`,
    automaticSilentRenew: true,
    accessTokenExpiringNotificationTime: 4,
    monitorSession:false,
    filterProtocolClaims: true,
    loadUserInfo: false,
    includeIdTokenInSilentRenew:true,
    scope: "openid profile cloudcsesm offline_access trinidadapi smapi",
    response_type: "id_token token",
    extraQueryParams: { domain_hint: '', acr_values: '' },
    extraTokenParams :{ domain_hint: '', acr_values: '' },
    signingKeys: [
    ]
};

const userManager = createUserManager(userManagerConfig);

userManager.getCustomerIDAsync = async () => {
    let customerid = (await userManager.getUser()).profile['http://schemas.cse-net.co.uk/ws/2014/02/identity/claims/customerid'];
    return customerid;
}

export default userManager;