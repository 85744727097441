import * as React from "react";
import { Button } from "../ui";
import styles from "./Footer.module.css";

const Footer = () => {
    const marquee = window.localStorage.getItem("marquee");
    return (
        <footer className={styles.footer}>
            {marquee && (
                <h6 className={styles.marqueeText}>
                    Marquee Mode Enabled{" "}
                    <Button
                        onClick={() => {
                            window.localStorage.removeItem("marquee");
                            window.location.reload();
                        }}
                        style={{ padding: "5px", fontSize: "14px" }}
                        primary
                    >
                        Disable
                    </Button>
                </h6>
            )}
            <h6 className={styles.copyrightText}>© CSE Education Systems {new Date(Date.now()).getFullYear()}</h6>
        </footer>
    );
};

export default Footer;
