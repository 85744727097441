import * as React from "react";
import { Spinner } from "../../../common/ui";
import { send } from "../../../../helpers/requests";
import { tr } from "../../../../helpers/languages";
import userManager from "../../../../util/userManager";
import { GetTheme } from "../SharedFunctions";

import Popup from "../../../common/popup/popout";
import DeviceList from "../../popout/DeviceList";

import CardClassic from "../../ui/TextCard";
import CardHalfBarGraph from "../../ui/HalfBarGraphCard";

export default class DashCardStationsLastBooted extends React.PureComponent {
    _isMounted = false; //If true then component is mounted. If false data returned from API GET should be dropped.

    constructor(props) {
        super(props);
        this.state = {
            stationsLastBooted: {
                total: 0,
                days90: 0,
                days30: 0,
                days14: 0,
                days7: 0
            },
            getDone: false,
            popupStatus: false
        };
    }
    /**
     * componentDidMount
     * @description API calls for rebuilding base dashboard
     */
    componentDidMount() {
        this._isMounted = true;
        if (this.props.localPreview) {
            this.getStationLastSeenPreview();
        } else {
            if (!this.props.raOnly) {
                this.getStationLastSeen(this.props.custID);
            }
        }
    }
    componentWillUnmount() {
        this._isMounted = false;
    }
    componentDidUpdate(prevProps) {
        if (!this.props.disableReRender) {
            if (prevProps.custID !== this.props.custID) {
                this.setState({
                    getDone: false,
                    stationsLastBooted: {
                        total: 0,
                        days90: 0,
                        days30: 0,
                        days14: 0,
                        days7: 0
                    }
                });
                this.getStationLastSeen(this.props.custID);
            }
        }
    }
    /**
     * OpenPopup
     * @description Calls OpenPopup inside Dashboard.js
     */
    OpenPopup = () => {
        this.setState({ popupStatus: true });
    };
    ///
    /// API Calls
    ///
    /**
     * getAccessToken
     * @description Gets the access token of the authenticated user
     */
    getAccessToken = async () => {
        let token = (await userManager.getUser()).access_token;
        return token;
    };
    /**
     * getStationLastSeen
     * @description Gets Stations Last Seen (Stations Last Booted) and Sets this.state.stationsLastBooted
     * @param {String} custID Customer ID
     */
    getStationLastSeen = async (custID) => {
        send({
            method: "GET",
            url: `/api/reporting/stationslastseen${custID !== "" ? "?custid=" + custID : ""}`,
            token: await this.getAccessToken()
        })
            .then((response) => {
                if (this._isMounted) {
                    this.setState({
                        getDone: true,
                        stationsLastBooted: {
                            total: response.data.totalStations,
                            days90: response.data.last90Days,
                            days30: response.data.last30Days,
                            days14: response.data.last14Days,
                            days7: response.data.last7Days
                        }
                    });
                    this.props.cardRendered();
                }
            })
            .catch((error) => {
                console.log(error);
            });
    };
    getStationLastSeenPreview = () => {
        this.setState({
            getDone: true,
            stationsLastBooted: {
                total: 2500,
                days90: 2400,
                days30: 2200,
                days14: 2000,
                days7: 1500
            }
        });
    };
    render() {
        var CardSpinner,
            ReturnCard = <></>;
        if (this.props.raOnly) {
            return <></>;
        }
        if (!this.state.getDone) {
            CardSpinner = (
                <div id="loading-spinner" className="spinner-parent">
                    <Spinner size={40} className="spinner-position" />
                </div>
            );
        }
        switch (this.props.cardType) {
            case 0:
                ReturnCard = (
                    <CardClassic
                        title={tr("DASHBOARD_STATIONLASTSEEN_TITLE")}
                        cardID={this.props.cardID}
                        data={[
                            this.state.stationsLastBooted.total,
                            this.state.stationsLastBooted.days90,
                            this.state.stationsLastBooted.days30,
                            this.state.stationsLastBooted.days14,
                            this.state.stationsLastBooted.days7
                        ]}
                        type={["dash-neut", "dash-neut", "dash-neut", "dash-neut", "dash-neut"]}
                        altText={[
                            tr("DASHBOARD_STATIONLASTSEEN_TOTAL"),
                            tr("DASHBOARD_STATIONLASTSEEN_90"),
                            tr("DASHBOARD_STATIONLASTSEEN_30"),
                            tr("DASHBOARD_STATIONLASTSEEN_14"),
                            tr("DASHBOARD_STATIONLASTSEEN_7")
                        ]}
                        activatePopup={() => {
                            !this.props.localPreview ? this.OpenPopup() : console.log("Card Details");
                        }}
                        openHelpPopup={() => {
                            this.props.openHelpPopup("StationLastBootedCard");
                        }}
                        custID={this.props.custID}
                        startDate={this.props.startDate}
                        endDate={this.props.endDate}
                        isCSE={this.props.isCSE}
                        customID={this.props.customID}
                    >
                        {CardSpinner}
                    </CardClassic>
                );
                break;
            case 1:
            default:
                ReturnCard = (
                    <CardHalfBarGraph
                        title={tr("DASHBOARD_STATIONLASTSEEN_TITLE")}
                        cardID={this.props.cardID}
                        barData={[
                            {
                                name: tr("DASHBOARD_STATIONLASTSEEN_90"),
                                count: this.state.stationsLastBooted.days90
                            },
                            {
                                name: tr("DASHBOARD_STATIONLASTSEEN_30"),
                                count: this.state.stationsLastBooted.days30
                            },
                            {
                                name: tr("DASHBOARD_STATIONLASTSEEN_14"),
                                count: this.state.stationsLastBooted.days14
                            },
                            {
                                name: tr("DASHBOARD_STATIONLASTSEEN_7"),
                                count: this.state.stationsLastBooted.days7
                            }
                        ]}
                        barTitle={""}
                        barDataTitle={tr("DASHBOARD_STATIONLASTSEEN_NUM")}
                        otherData={this.state.stationsLastBooted.total}
                        otherDataTitle={tr("DASHBOARD_STATIONLASTSEEN_TOTAL")}
                        type={"dash-neut"}
                        dataReturned={this.state.getDone}
                        displayLegend={false}
                        legendTitle=""
                        activatePopup={() => {
                            !this.props.localPreview ? this.OpenPopup() : console.log("Card Details");
                        }}
                        openHelpPopup={() => {
                            this.props.openHelpPopup("StationLastBootedCard");
                        }}
                        custID={this.props.custID}
                        startDate={this.props.startDate}
                        endDate={this.props.endDate}
                        isCSE={this.props.isCSE}
                        customID={this.props.customID}
                    >
                        {CardSpinner}
                    </CardHalfBarGraph>
                );
                break;
        }
        return (
            <>
                {ReturnCard}
                {this.state.popupStatus && (
                    <Popup
                        content={<DeviceList theme={GetTheme()} popoutRendered={() => {}} customerID={this.props.custID} />}
                        close={() => this.setState({ popupStatus: false })}
                        PopupName="StationsLastBootedCardDetails"
                    />
                )}
            </>
        );
    }
}
