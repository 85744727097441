import React, { Ref } from "react";

type ClickOutsideProps = {
    onClickOutside: (param: any) => void,
    children: JSX.Element
};
type ClickOutsideState = {};

export default class ClickOutside extends React.PureComponent<ClickOutsideProps, ClickOutsideState> {
    isTouch = false;
    container: HTMLDivElement | undefined;

    getContainer = (ref: HTMLDivElement) => {
        this.container = ref;
    };

    componentDidMount() {
        document.addEventListener("touchend", this.handle, true);
        document.addEventListener("click", this.handle, true);
    }

    componentWillUnmount() {
        document.removeEventListener("touchend", this.handle, true);
        document.removeEventListener("click", this.handle, true);
    }

    handle = (e: { type: string, target: any }) => {
        if (e.type === "touchend") this.isTouch = true;
        if (e.type === "click" && this.isTouch) return;
        const { onClickOutside } = this.props;
        const el = this.container;
        if (el && !el.contains(e.target)) onClickOutside(e);
    };
    render() {
        const { children, onClickOutside, ...props } = this.props;
        return (
            <div className="main-nav" {...props} ref={this.getContainer}>
                {children}
            </div>
        );
    }
}
