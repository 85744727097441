import * as React from "react";
import { useState, useEffect } from "react";
import { Spinner } from "../../../common/ui";
import { send } from "../../../../helpers/requests";
import { tr } from "../../../../helpers/languages";
import userManager from "../../../../util/userManager";
import { GetTheme, RandomGUID } from "../SharedFunctions";

import Popup from "../../../common/popup/popout";
import CardClassic from "../../ui/TextCard";
import ServiceDeskTopCategories from "../../popout/ServiceDeskTopCategories";
import { LogError } from "../../../analytics/eventTracker";

export type ServiceDeskTopCategoriesAPI = {
    _id: string,
    count: number
}

interface DashCardServiceDeskTopCategoriesProps {
    animate?: boolean;
    custID?: string;
    startDate?: Date;
    endDate?: Date;
    disableReRender?: boolean;
    localPreview: boolean;
    cardRendered?: () => void;
    cardType: number;
    cardID: string;
    isCSE?: boolean;
    customID?: string;
    raOnly?: boolean;
    openHelpPopup: (type: string) => void;
};

//API Request
const getAccessToken = async () => {
    let token = (await userManager.getUser())!.access_token;
    return token;
};

const DashCardServiceDeskTopCategories = (props: DashCardServiceDeskTopCategoriesProps) => {
    const [getDone, setGetDone] = useState(false);
    const [popupStatus, setPopupStatus] = useState(false);
    const [sdTopCategories, setSDTopCategories] = useState<ServiceDeskTopCategoriesAPI[]>([]);
    useEffect(() => {
        const getSDTopCategories = async (custID: string, startDate: Date, endDate: Date) => {
            const timeZoneOffset = startDate.getTimezoneOffset() * 60;
            const newStartDate = Math.floor(startDate.getTime() / 1000) - timeZoneOffset;
            const newEndDate = Math.floor(endDate.getTime() / 1000) - timeZoneOffset;
            const response = await send({
                method: "GET",
                url: `/api/reporting/sdtopcategories?custid=${custID}&startdate=${newStartDate}&enddate=${newEndDate}`,
                token: await getAccessToken(),
                data: "",
                params: ""
            })
            setSDTopCategories(response.data);
        }
        setGetDone(false);
        //Get Data on component creation
        if (props.localPreview) {
            setSDTopCategories([{ _id: "Laptop", count: 50 }, { _id: "Desktop", count: 26 }, { _id: "Printer", count: 14 }, { _id: "Keyboard", count: 6 }]);
        }
        else {
            getSDTopCategories(props.custID!, props.startDate!, props.endDate!).catch((err) => {
                LogError("Error sdtopcategories card", { err, custID: props.custID });
                console.error(err);
            })
        }
        setGetDone(true);
    }, [props.startDate, props.endDate, props.custID]);
    if (props.raOnly) return <></>;
    const openPopup = () => {
        setPopupStatus(true);
    }
    var CardSpinner = <></>;
    if (!getDone) {
        CardSpinner = <div id="loading-spinner" className="spinner-parent">
            <Spinner size={40} className="spinner-position" />
        </div>;
    }
    var ReturnCard = <></>;
    const CalClassicCardData = () => {
        var returnArr: string[] = [];
        sdTopCategories.forEach((keyword) => {
            returnArr.push(keyword.count.toString());
        })
        return returnArr;
    }
    const CalClassicCardAltText = () => {
        var returnArr: string[] = [];
        sdTopCategories.forEach((keyword) => {
            returnArr.push(keyword._id);
        })
        return returnArr;
    }
    switch (props.cardType) {
        case 0:
        default:
            ReturnCard = (
                <CardClassic
                    title={tr("DASHBOARD_SERVICEDESK_CATEGORIES_TITLE")}
                    cardID={props.cardID!}
                    activatePopup={() => {
                        !props.localPreview ? openPopup() : console.log("Card Details");
                    }}
                    openHelpPopup={() => {
                        props.openHelpPopup("ServiceDeskTopCategories");
                    }}
                    data={CalClassicCardData()}
                    type={["dash-neut", "dash-neut", "dash-neut", "dash-neut", "dash-neut", "dash-neut"]}
                    altText={CalClassicCardAltText()}
                    altTextType={["dash-xsmall-text dash-no-overflow", "dash-xsmall-text dash-no-overflow", "dash-xsmall-text dash-no-overflow", "dash-xsmall-text dash-no-overflow"]}
                    getDone={getDone}
                    custID={props.custID!}
                    startDate={props.startDate!}
                    endDate={props.endDate!}
                    isCSE={props.isCSE!}
                    customID={props.customID!}
                >
                    {CardSpinner}
                </CardClassic>
            )
    }
    return (
        <>
            {ReturnCard}
            {popupStatus && (
                <Popup
                    content={<ServiceDeskTopCategories theme={GetTheme()} startDate={props.startDate!} endDate={props.endDate!} customerID={props.custID!} popoutRendered={() => { }} />}
                    close={() => setPopupStatus(false)}
                    PopupName="ServiceDeskTopCategoriesCardDetails"
                />
            )}
        </>
    );
}

export { DashCardServiceDeskTopCategories }