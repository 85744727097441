import * as React from "react";
import { Spinner } from "../../../common/ui";
import { send } from "../../../../helpers/requests";
import { tr } from "../../../../helpers/languages";
import userManager from "../../../../util/userManager";
import { GetTheme } from "../SharedFunctions";

import Popup from "../../../common/popup/popout";

import CardClassic from "../../ui/TextCard";
import CardPie from "../../ui/PieCard";
import CardGauge from "../../ui/GaugeCard";
import LaptopBatteryHealthPopout from "../../popout/LaptopBatteryHealth";

export default class DashCardLaptopBatteryHealth extends React.PureComponent {
    _isMounted = false; //If true then component is mounted. If false data returned from API GET should be dropped.

    constructor(props) {
        super(props);
        this.state = {
            laptopBatteryHealth: {
                total: 0,
                goodHealth: 0,
                badHealth: 0,
                noBattery: 0
            },
            getDone: false,
            popupStatus: false
        };
    }
    /**
     * componentDidMount
     * @description API calls for rebuilding base dashboard
     */
    componentDidMount() {
        this._isMounted = true;
        if (this.props.localPreview) {
            this.getLaptopBatteryHealthPreview();
        } else {
            if (!this.props.raOnly) {
                this.getLaptopBatteryHealth(this.props.custID);
            }
        }
    }
    componentWillUnmount() {
        this._isMounted = false;
    }
    componentDidUpdate(prevProps) {
        if (!this.props.disableReRender) {
            if (prevProps.custID !== this.props.custID) {
                this.setState({
                    getDone: false,
                    laptopBatteryHealth: { total: 0, goodHealth: 0, badHealth: 0, noBattery: 0 }
                });
                this.getLaptopBatteryHealth(this.props.custID);
            }
        }
    }
    /**
     * OpenPopup
     * @description Calls OpenPopup inside Dashboard.js
     */
    OpenPopup = () => {
        this.setState({ popupStatus: true });
    };
    ///
    /// API Calls
    ///
    /**
     * getAccessToken
     * @description Gets the access token of the authenticated user
     */
    getAccessToken = async () => {
        let token = (await userManager.getUser()).access_token;
        return token;
    };
    getLaptopBatteryHealth = async (custID) => {
        send({
            method: "GET",
            url: `/api/reporting/batteryhealth?custid=${custID}&goodpercent=85`,
            token: await this.getAccessToken()
        })
            .then((response) => {
                if (this._isMounted) {
                    this.setState({
                        getDone: true,
                        laptopBatteryHealth: {
                            total: response.data.goodBattery + response.data.poorBattery + response.data.noBattery,
                            goodHealth: response.data.goodBattery,
                            badHealth: response.data.poorBattery,
                            noBattery: response.data.noBattery
                        }
                    });
                    this.props.cardRendered();
                }
            })
            .catch((error) => {
                console.log(error);
            });
    };
    getLaptopBatteryHealthPreview = () => {
        this.setState({
            getDone: true,
            laptopBatteryHealth: { total: 20, goodHealth: 18, badHealth: 2, noBattery: 0 }
        });
    };
    render() {
        var DashSpin,
            ReturnCard = <></>;
        if (this.props.raOnly) {
            return <></>;
        }
        if (!this.state.getDone) {
            DashSpin = (
                <div id="loading-spinner" className="spinner-parent">
                    <Spinner size={40} className="spinner-position" />
                </div>
            );
        }
        switch (this.props.cardType) {
            case 0:
                ReturnCard = (
                    <CardClassic
                        title={tr("DASHBOARD_LAPTOP_BATTERY_HEALTH_TITLE")}
                        cardID={this.props.cardID}
                        data={[
                            this.state.laptopBatteryHealth.total,
                            this.state.laptopBatteryHealth.goodHealth,
                            this.state.laptopBatteryHealth.badHealth,
                            this.state.laptopBatteryHealth.noBattery
                        ]}
                        type={["dash-neut", "dash-neut", "dash-neg", "dash-neut"]}
                        altText={[
                            tr("DASHBOARD_LAPTOP_BATTERY_HEALTH_TOTAL"),
                            tr("DASHBOARD_LAPTOP_BATTERY_HEALTH_GOOD"),
                            tr("DASHBOARD_LAPTOP_BATTERY_HEALTH_POOR"),
                            tr("DASHBOARD_LAPTOP_BATTERY_HEALTH_NO")
                        ]}
                        altTextType={["dash-xsmall-text", "dash-xsmall-text", "dash-xsmall-text", "dash-xsmall-text"]}
                        getDone={this.state.getDone}
                        activatePopup={() => {
                            !this.props.localPreview ? this.OpenPopup() : console.log("Card Details");
                        }}
                        openHelpPopup={() => {
                            this.props.openHelpPopup("NotebookBatteryHealthCard");
                        }}
                        custID={this.props.custID}
                        startDate={this.props.startDate}
                        endDate={this.props.endDate}
                        isCSE={this.props.isCSE}
                        customID={this.props.customID}
                    >
                        {DashSpin}
                    </CardClassic>
                );
                break;
            case 1:
                ReturnCard = (
                    <CardPie
                        title={tr("DASHBOARD_LAPTOP_BATTERY_HEALTH_TITLE")}
                        cardID={this.props.cardID}
                        graphType="pie"
                        data={[
                            this.state.laptopBatteryHealth.goodHealth,
                            this.state.laptopBatteryHealth.badHealth,
                            this.state.laptopBatteryHealth.noBattery
                        ]}
                        labels={[
                            tr("DASHBOARD_LAPTOP_BATTERY_HEALTH_GOOD"),
                            tr("DASHBOARD_LAPTOP_BATTERY_HEALTH_POOR"),
                            tr("DASHBOARD_LAPTOP_BATTERY_HEALTH_NO")
                        ]}
                        pieColours={["#8AB785", "#f12a2a"]}
                        getDone={this.state.getDone}
                        otherData={[this.state.laptopBatteryHealth.total]}
                        type={[""]}
                        otherDataTitle={[tr("DASHBOARD_LAPTOP_BATTERY_HEALTH_TOTAL")]}
                        activatePopup={() => {
                            !this.props.localPreview ? this.OpenPopup() : console.log("Card Details");
                        }}
                        openHelpPopup={() => {
                            this.props.openHelpPopup("NotebookBatteryHealthCard");
                        }}
                        custID={this.props.custID}
                        startDate={this.props.startDate}
                        endDate={this.props.endDate}
                        isCSE={this.props.isCSE}
                        customID={this.props.customID}
                    >
                        {DashSpin}
                    </CardPie>
                );
                break;
            case 2:
            default:
                ReturnCard = (
                    <CardGauge
                        title={tr("DASHBOARD_LAPTOP_BATTERY_HEALTH_TITLE")}
                        cardID={this.props.cardID}
                        textData={[
                            this.state.laptopBatteryHealth.total,
                            this.state.laptopBatteryHealth.goodHealth,
                            this.state.laptopBatteryHealth.badHealth,
                            this.state.laptopBatteryHealth.noBattery
                        ]}
                        gaugeText={tr("DASHBOARD_LAPTOP_BATTERY_HEALTH_GAUGE")}
                        gaugeColours={["#DC2323", "#35A048"]}
                        percent={Math.round((this.state.laptopBatteryHealth.goodHealth / this.state.laptopBatteryHealth.total) * 100) / 100}
                        type={["dash-small-text dash-neut", "dash-small-text dash-neut", "dash-small-text dash-neg"]}
                        altText={[
                            tr("DASHBOARD_LAPTOP_BATTERY_HEALTH_TOTAL"),
                            tr("DASHBOARD_LAPTOP_BATTERY_HEALTH_GOOD"),
                            tr("DASHBOARD_LAPTOP_BATTERY_HEALTH_POOR"),
                            tr("DASHBOARD_LAPTOP_BATTERY_HEALTH_NO")
                        ]}
                        getDone={this.state.getDone}
                        activatePopup={() => {
                            !this.props.localPreview ? this.OpenPopup() : console.log("Card Details");
                        }}
                        openHelpPopup={() => {
                            this.props.openHelpPopup("NotebookBatteryHealthCard");
                        }}
                        custID={this.props.custID}
                        startDate={this.props.startDate}
                        endDate={this.props.endDate}
                        isCSE={this.props.isCSE}
                        customID={this.props.customID}
                    >
                        {DashSpin}
                    </CardGauge>
                );
                break;
        }
        return (
            <>
                {ReturnCard}
                {this.state.popupStatus && (
                    <Popup
                        content={<LaptopBatteryHealthPopout theme={GetTheme()} popoutRendered={() => {}} customerID={this.props.custID} />}
                        close={() => this.setState({ popupStatus: false })}
                        PopupName="LaptopBatteryHealthCardDetails"
                    />
                )}
            </>
        );
    }
}
