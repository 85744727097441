import React, { useState } from "react";
import { Button } from "../../common/ui";
import { send } from "../../../helpers/requests";
import userManager from "../../../util/userManager";
import { tr } from "../../../helpers/languages";
import styles from "./Settings.module.css";
import SettingsCollapse from "./SettingsCollapse";
import { Spinner } from "../../common/ui/";
import LayoutDnD from "./LayoutDnD";
import CustomCards from "./CustomCards";
import { DashboardCard } from "../DefaultDashboardLayouts";
import useDarkMode from "../../../hooks/useDarkMode";
import ImportExportLayout from "./ImportExportLayout";
import { ForceCacheCleanup } from "../../common/cachecleanup/ForceCacheCleanup";
import { ApplicationPaths } from "../../common/authorization/AuthorizationConstants";
import { Link } from "react-router-dom";
import Reports from "../reports/Reports";
import { version } from "../../../../package.json";
import { Options } from "../../../types/options";
import { Convert } from "./ValidateOptions";
import { AlwaysLogEvent, LogEvent } from "../../analytics/eventTracker";

export type CustomerName = {
    customer_id: string,
    organisation_name: string,
    allowRemoteAgent: boolean
};

//For whatever reason I decided to change the localStorage layout of this object.
//ToDo: Only use CustomerName object key names.
type CustomerNameStorage = {
    Name: string,
    CustID: string,
    allowRemoteAgent: boolean
}

type DashSettingsProps = {
    UpdateCustomerName: (customer: CustomerName) => void,
    dash: DashboardCard[],
    Customer: CustomerNameStorage,
    initialCustID: any,
    pushDashType: (dash: DashboardCard[]) => void
};

function DashSettings(props: DashSettingsProps) {
    const [layout, setLayout] = useState<DashboardCard[]>(props.dash);
    const [isLoading, setLoading] = useState(false);
    const [cardKey, setCardKey] = useState(0);
    const [AllCustomerNames, setCustomerNames] = useState<CustomerName[]>([]);
    //const [options, setOptions] = useState<Options>({optionsVersion: 1, graphTextSize: 12});
    const [options, setOptions] = useState<Options>(Convert.toOptions(window.localStorage.getItem("options") || ""));
    const [marquee, setMarquee] = useState(window.localStorage.getItem("marquee"));
    const [marqueeModeToggled, setMarqueeModeToggled] = useState(false);
    const [showEmptyCustomer, setShowEmptyCustomer] = useState(true);
    const logoutPath = { pathname: `${ApplicationPaths.LogOut}`, state: { local: true } };
    ;
    /**
     * getAccessToken
     * @description Gets the access token of the authenticated user
     */
    const getAccessToken = async () => {
        let token = (await userManager.getUser())?.access_token;
        return token;
    };
    /**
     * getOtherCustomerNames
     * @description Gets all available CustomerName(s) and sets this.state.AllCustomerNames
     */
    const getOtherCustomerNames = async () => {
        send({
            method: "GET",
            url: "/api/reporting/getothercustomers?raonly=false",
            token: await getAccessToken(),
            data: "",
            params: ""
        })
            .then((response: any) => {
                setCustomerNames(response.data.customers);
            })
            .catch((error) => {
                console.log(error);
            });
    };
    const getRemoteLayout = async () => {
        var res = await send({
            method: "GET",
            url: "/api/reporting/getlayout",
            token: await getAccessToken(),
            data: "",
            params: ""
        });
        return res.data as DashboardCard[];
    };
    const setRemoteLayout = async (layout: DashboardCard[]) => {
        send({
            method: "POST",
            url: `/api/reporting/updatelayout`,
            data: layout,
            params: "",
            token: await getAccessToken()
        });
    };
    if (props.initialCustID === "CSE-NET" && AllCustomerNames.length === 0) {
        //If user === "CSE-NET"
        getOtherCustomerNames();
    }
    const ChangeTextSize = (newTextSize: number) => {
        LogEvent(9, { newSize: newTextSize });
        var optionsCopy: Options = JSON.parse(JSON.stringify(options));
        optionsCopy.graphTextSize = newTextSize
        setOptions(optionsCopy);
        window.localStorage.setItem("options", JSON.stringify(optionsCopy));
    }
    const ChangeDashType = async (newLayout: number) => {
        LogEvent(10, { newLayout: newLayout });
        if (newLayout === layout[0].dashLayout) return;
        var newDashType = [];
        switch (newLayout) {
            case 0:
                setLoading(true);
                newDashType = await getRemoteLayout();
                newDashType[0].dashLayout = 0;
                await setRemoteLayout(newDashType);
                setLoading(false);
                break;
            case 1:
                setLoading(true);
                newDashType = await getRemoteLayout();
                newDashType[0].dashLayout = 1;
                await setRemoteLayout(newDashType);
                setLoading(false);
                break;
            case 2:
                setLoading(true);
                newDashType = await getRemoteLayout();
                newDashType[0].dashLayout = 2;
                await setRemoteLayout(newDashType);
                setLoading(false);
                break;
            case 99: //Custom
            default: //Will return default layout or existing custom layout
                setLoading(true);
                newDashType = await getRemoteLayout();
                newDashType[0].dashLayout = 99;
                await setRemoteLayout(newDashType);
                setLoading(false);
                break;
        }
        setLayout(newDashType);
        props.pushDashType(newDashType);
    };
    /**
     * UpdateLayout
     * @param layout
     * @param type
     */
    const UpdateLayout = (layout: DashboardCard[], type: Boolean) => {
        setLayout(layout);
        if (type) {
            setCardKey(cardKey + 1);
        }
        props.pushDashType(layout);
    };
    const [theme, setTheme] = useDarkMode();
    const toggleTheme = () => {
        LogEvent(6, { type: "settings-menu" });
        if (theme === "light-theme") {
            setTheme("dark-theme");
        } else {
            setTheme("light-theme");
        }
    };
    const ToggleMarqueeMode = () => {

        if (marquee) {
            window.localStorage.removeItem("marquee");
            setMarquee(null);
            LogEvent(0, { enabled: false });
        } else {
            window.localStorage.setItem("marquee", "5");
            setMarquee("5");
            LogEvent(0, { enabled: true });
        }
        setMarqueeModeToggled(!marqueeModeToggled);
    }
    const SetMarqueeAutoRefresh = (newValue: string) => {
        window.localStorage.setItem("marquee", newValue);
        setMarquee(newValue);
    }
    return (
        <>
            <div className={styles.popoutContainer}>
                <h2 className={styles.popoutMain}>{tr("DASHBOARD_SETTINGS_MAIN")}</h2>
                <h3 className={styles.popoutDivider}>{tr("DASHBOARD_SETTINGS_GENERAL")}</h3>
                <div className={styles.popoutItem}>
                    <Button primary onClick={() => { window.open("https://updates.cse-net.co.uk/Home/Reporting", "_blank"); }}>
                        Open {tr("MENU_RELEASENOTES")}
                    </Button>
                </div>
                <div className={styles.popoutItem}>
                    <Button primary onClick={toggleTheme}>
                        {tr("MENU_TOGGLE_THEME")}
                    </Button>
                </div>
                <div className={styles.popoutItem}>
                    <Link to={logoutPath}>
                        <Button primary>
                            {tr("SIGNOUT")}
                        </Button>
                    </Link>
                </div>
                <div className={styles.popoutItem}>
                    <h4 className={styles.popoutHeader}>{tr("DASHBOARD_SETTINGS_GENERAL_LAYOUT")}</h4>
                    <select
                        className={styles.selectDropDown}
                        id="dashLayout"
                        value={layout[0].dashLayout || 0}
                        onChange={(e) => {
                            ChangeDashType(parseInt(e.target.value));
                        }}
                    >
                        <option value={0}>Default</option>
                        <option value={1}>Classic</option>
                        <option value={2}>Preview</option>
                        <option value={99}>Custom</option>
                    </select>
                </div>
                <div className={styles.popoutItem}>
                    <h4 className={styles.popoutHeader}>{tr("DASHBOARD_SETTINGS_GRAPH_TEXT_SIZE")}</h4>
                    <select className={styles.selectDropDown} id="graphTextSizeSelect" value={options.graphTextSize} onChange={(e) => { ChangeTextSize(parseInt(e.target.value)) }}>
                        <option value={12}>12</option>
                        <option value={14}>14</option>
                        <option value={16}>16</option>
                        <option value={20}>20</option>
                        <option value={22}>22</option>
                        <option value={24}>24</option>
                    </select>
                </div>
                {isLoading && (
                    <div className={styles.popoutItem}>
                        <div style={{ marginBottom: "4px" }}>
                            <Spinner size={45} />
                        </div>
                    </div>
                )}
                <h3 className={styles.popoutDivider}>{tr("DASHBOARD_SETTINGS_MARQUEE")}</h3>
                <div className={styles.popoutItem}>
                    <Button primary onClick={ToggleMarqueeMode}>{marquee ? tr("DASHBOARD_SETTINGS_MARQUEE_DISABLE") : tr("DASHBOARD_SETTINGS_MARQUEE_ENABLE")}</Button>
                </div>
                <div className={styles.popoutItem}>
                    {marquee && <><h4 className={styles.popoutHeader}>{tr("DASHBOARD_SETTINGS_AUTO_REFRESH")}</h4>
                        <select className={styles.selectDropDown} value={marquee} onChange={(newValue) => { SetMarqueeAutoRefresh(newValue.target.value) }} id="marqueeDropDown">
                            <option value="no-refresh">No Refresh</option>
                            <option value="1">Every Minute</option>
                            <option value="5">Every 5 Minutes</option>
                            <option value="10">Every 10 Minutes</option>
                            <option value="15">Every 15 Minutes</option>
                            <option value="30">Every 30 Minutes</option>
                            <option value="60">Every 1 Hour</option>
                        </select></>}
                </div>
                <div className={styles.popoutItem}>
                    {marqueeModeToggled && <h4 className={styles.errorText}>{tr("DASHBOARD_SETTINGS_MARQUEE_WARNING")}</h4>}
                </div>
                <h3 className={styles.popoutDivider}>{tr("DASHBOARD_SETTINGS_DEBUG")}</h3>
                <div className={styles.popoutItem}>
                    <Button primary onClick={ForceCacheCleanup}>{tr('DASHBOARD_SETTINGS_DEBUG_REFRESH')}</Button>
                </div>
                {Number(layout[0].dashLayout) === 99 && (
                    <>
                        <div className={styles.popoutDivider} />
                        <ImportExportLayout UpdateLayout={UpdateLayout} />
                        <div className={styles.popoutDivider} />
                        <SettingsCollapse buttonLabel="Edit Layout">
                            <LayoutDnD key={cardKey} layout={layout} UpdateLayout={UpdateLayout} />
                        </SettingsCollapse>
                        <div className={styles.popoutDivider} />
                        <SettingsCollapse buttonLabel="Edit Cards">
                            <CustomCards key={cardKey} layout={layout} UpdateLayout={UpdateLayout} custID={props.Customer.CustID} />
                        </SettingsCollapse>
                    </>
                )}
                {/* If user === CSE-NET */}
                {props.initialCustID === "CSE-NET" && (
                    <>
                        <h3 className={styles.popoutDivider}>{tr("DASHBOARD_SETTINGS_CSE")}</h3>
                        <div className={styles.popoutItem}>
                            <Link to={"/internal"}><Button primary>{tr('DASHBOARD_SETTINGS_CSE_INTERNAL')}</Button></Link>
                        </div>
                        <div className={styles.popoutItem}>
                            <Reports />
                        </div>
                        <div className={styles.popoutItem}>
                            <h4 className={styles.popoutHeader}>{tr("DASHBOARD_SETTINGS_CSE_SITE")}</h4>
                            <select
                                className={styles.selectDropDown}
                                id="dashSite"
                                onChange={(e) => {
                                    setShowEmptyCustomer(false);
                                    if (e.target.value !== "" && e.target.value !== undefined) {
                                        AlwaysLogEvent("CSE-NET - Change Customer", { userCustomerID: props.initialCustID, prevSite: props.Customer.CustID, newSite: AllCustomerNames[parseInt(e.target.value)].customer_id });
                                        props.UpdateCustomerName(AllCustomerNames[parseInt(e.target.value)]);
                                    }
                                }}
                            >
                                {showEmptyCustomer && <option value=""></option>}
                                {AllCustomerNames.map((customerName, index) => {
                                    return (
                                        <option key={index} id={customerName.customer_id} value={index}>
                                            {customerName.organisation_name}
                                        </option>
                                    );
                                })}
                            </select>
                        </div>
                    </>
                )}
                <div className={styles.popoutDividerNoText} style={{ marginTop: "20px" }}></div>
                <div className={styles.popoutItem}>
                    <h3>{`${tr("DASHBOARD_SETTINGS_CURRENT_VERSION")}: ${version}`}</h3>
                </div>
            </div>
        </>
    );
}

export default DashSettings;
