import * as React from "react";
import styles from "./Layout.module.css";

type Props = {
    className?: string,
    children: JSX.Element[] | JSX.Element,
    style?: React.CSSProperties,
    center?: boolean,
    id?: string
};

const Col: React.FC<Props> = ({ className, children, style, center }) => {
    var centerValue = "";
    if (center) centerValue = styles.center;
    return (
        <div id="col" className={`${className} ${centerValue} ${styles.col}`} style={style}>
            {children}
        </div>
    );
};

export default Col;
