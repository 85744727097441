import * as React from "react";
import { useState, useEffect } from "react";
import { Spinner } from "../../../common/ui";
import { send } from "../../../../helpers/requests";
import { tr } from "../../../../helpers/languages";
import userManager from "../../../../util/userManager";
import { GetTheme } from "../SharedFunctions";

import Popup from "../../../common/popup/popout";
import CardClassic from "../../ui/TextCard";
import { LogError } from "../../../analytics/eventTracker";
import AssetChanges from "../../popout/AssetChanges";

type AssetChangesAPI = number;

interface DashCardAssetChangesProps {
    animate?: boolean;
    custID?: string;
    startDate?: Date;
    endDate?: Date;
    disableReRender?: boolean;
    localPreview: boolean;
    cardRendered?: () => void;
    cardType: number;
    cardID: string;
    isCSE?: boolean;
    customID?: string;
    raOnly?: boolean;
    openHelpPopup: (type: string) => void;
};

//API Request
const getAccessToken = async () => {
    let token = (await userManager.getUser())!.access_token;
    return token;
};

const DashCardAssetChanges = (props: DashCardAssetChangesProps) => {
    const [getDone, setGetDone] = useState(false);
    const [popupStatus, setPopupStatus] = useState(false);
    const [assetChanges, setAssetChanges] = useState<AssetChangesAPI>();
    useEffect(() => {
        const getAssetChanges = async (custID: string, startDate: Date, endDate: Date) => {
            const timeZoneOffset = startDate.getTimezoneOffset() * 60;
            const newStartDate = Math.floor(startDate.getTime() / 1000) - timeZoneOffset;
            const newEndDate = Math.floor(endDate.getTime() / 1000) - timeZoneOffset;
            const response = await send({
                method: "GET",
                url: `/api/reporting/assetchanges?custid=${custID}&startdate=${newStartDate}&enddate=${newEndDate}`,
                token: await getAccessToken(),
                data: "",
                params: ""
            })
            setAssetChanges(response.data);
        }
        setGetDone(false);
        //Get Data on component creation
        if (props.localPreview) {
            setAssetChanges(5);
        }
        else {
            getAssetChanges(props.custID!, props.startDate!, props.endDate!).catch((err) => {
                LogError("Error assetchanges card", { err, custID: props.custID });
                console.error(err);
            })
        }
        setGetDone(true);
    }, [props.startDate, props.endDate, props.custID]);
    if (props.raOnly) return <></>;
    const openPopup = () => {
        setPopupStatus(true);
    }
    var CardSpinner = getDone ? <></> : (
        <div id="loading-spinner" className="spinner-parent">
            <Spinner size={40} className="spinner-position" />
        </div>
    );
    var ReturnCard = <></>;
    switch (props.cardType) {
        case 0:
        default:
            ReturnCard = (
                <CardClassic
                    title={tr("DASHBOARD_ASSET_CHANGES_TITLE")}
                    cardID={props.cardID!}
                    activatePopup={() => {
                        !props.localPreview ? openPopup() : console.log("Card Details");
                    }}
                    openHelpPopup={() => {
                        props.openHelpPopup("SamiTokenUsage");
                    }}
                    data={[assetChanges!]}
                    type={["dash-neut"]}
                    altText={[assetChanges! !== 0 ? tr("DASHBOARD_ASSET_CHANGES_ALT") : tr("DASHBOARD_ASSET_CHANGES_NO_ADDED")]}
                    altTextType={[""]}
                    getDone={getDone}
                    custID={props.custID!}
                    startDate={props.startDate!}
                    endDate={props.endDate!}
                    isCSE={props.isCSE!}
                    customID={props.customID!}
                >
                    {CardSpinner}
                </CardClassic>
            )
    }
    return (
        <>
            {ReturnCard}
            {popupStatus && (
                <Popup
                    content={<AssetChanges theme={GetTheme()} startDate={props.startDate!} endDate={props.endDate!} customerID={props.custID!} popoutRendered={() => { }} />}
                    close={() => setPopupStatus(false)}
                    PopupName="AssetChangesCardDetails"
                />
            )}
        </>
    )
}

export { DashCardAssetChanges }