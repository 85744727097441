import { isAfter } from "date-fns";

function addLeadingZeros(n: number) {
    if (n <= 9) {
        return "0" + n;
    }
    return n;
}

const DatesToString = (startDateStr: string, endDateStr: string) => {
    var startDate: Date = new Date(startDateStr);
    var endDate: Date = new Date(endDateStr);
    if (isAfter(startDate, endDate)) {
        return [
            `${addLeadingZeros(endDate.getDate())}-${addLeadingZeros(endDate.getMonth() + 1)}-${endDate.getFullYear()}`,
            `${addLeadingZeros(startDate.getDate())}-${addLeadingZeros(startDate.getMonth() + 1)}-${startDate.getFullYear()}`
        ];
    } else {
        return [
            `${addLeadingZeros(startDate.getDate())}-${addLeadingZeros(startDate.getMonth())}-${startDate.getFullYear()}`,
            `${addLeadingZeros(endDate.getDate())}-${addLeadingZeros(endDate.getMonth())}-${endDate.getFullYear()}`
        ];
    }
};

export { DatesToString };
