import * as React from "react";
import { Spinner } from "../../../common/ui";
import { send } from "../../../../helpers/requests";
import { tr } from "../../../../helpers/languages";
import userManager from "../../../../util/userManager";
import { GetTheme } from "../SharedFunctions";

import Popup from "../../../common/popup/popout";
import AntiThreatPopout from "../../popout/AntiThreat";

import CardClassic from "../../ui/TextCard";
import CardGauge from "../../ui/GaugeCard";
import CardPie from "../../ui/PieCard";

export default class DashCardStationThreatStatus extends React.PureComponent {
    _isMounted = false; //If true then component is mounted. If false data returned from API GET should be dropped.

    constructor(props) {
        super(props);
        this.state = {
            stationAVStatus: {
                upToDate: 0,
                outOfDate: 0,
                notRunning: 0,
                noAV: 0,
                autoResolved: 0,
                badDevices: []
            },
            getDone: false,
            popupStatus: false
        };
    }
    /**
     * componentDidMount
     * @description API calls for rebuilding base dashboard
     */
    componentDidMount() {
        this._isMounted = true;
        if (this.props.localPreview) {
            this.getAVStatusPreview();
        } else {
            if (!this.props.raOnly) {
                this.getAVStatus(this.props.custID, this.props.startDate, this.props.endDate);
            }
        }
    }
    componentWillUnmount() {
        this._isMounted = false;
    }
    componentDidUpdate(prevProps) {
        if (!this.props.disableReRender) {
            if (prevProps.custID !== this.props.custID || prevProps.startDate !== this.props.startDate || prevProps.endDate !== this.props.endDate) {
                this.setState({
                    getDone: false,
                    stationAVStatus: {
                        upToDate: 0,
                        outOfDate: 0,
                        notRunning: 0,
                        noAV: 0,
                        autoResolved: 0,
                        badDevices: []
                    }
                });
                this.getAVStatus(this.props.custID, this.props.startDate, this.props.endDate);
            }
        }
    }
    /**
     * OpenPopup
     * @description Calls OpenPopup inside Dashboard.js
     */
    OpenPopup = () => {
        this.setState({ popupStatus: true });
    };
    ///
    /// API Calls
    ///
    /**
     * getAccessToken
     * @description Gets the access token of the authenticated user
     */
    getAccessToken = async () => {
        let token = (await userManager.getUser()).access_token;
        return token;
    };
    /**
     * getAVStatus
     * @description Gets Anti-Virus Status and Sets this.state.serverAVStatus or this.state.stationAVStatus
     * @param {String} custID Customer ID
     * @param {Date} startDate Start date as JS Date
     * @param {Date} endDate End date as JS Date
     */
    getAVStatus = async (custID, startDate, endDate) => {
        const timeZoneOffset = startDate.getTimezoneOffset() * 60;
        const newStartDate = Math.floor(startDate.getTime() / 1000) - timeZoneOffset;
        const newEndDate = Math.floor(endDate.getTime() / 1000) - timeZoneOffset;
        send({
            method: "GET",
            url: `/api/reporting/antivirusstatus?custid=${custID}&startdate=${newStartDate}&enddate=${newEndDate}&servers=false`,
            token: await this.getAccessToken()
        })
            .then((response) => {
                if (this._isMounted) {
                    this.setState({
                        getDone: true,
                        stationAVStatus: {
                            upToDate: response.data.avUptodate,
                            outOfDate: response.data.avOutOfDate,
                            notRunning: response.data.avNotRunning,
                            noAV: response.data.avNotDetected,
                            autoResolved: response.data.avAutoResolved,
                            badDevices: response.data.sophosBadStatusDevices
                        }
                    });
                    this.props.cardRendered();
                }
            })
            .catch((error) => {
                console.log(error);
            });
    };
    getAVStatusPreview = () => {
        this.setState({
            getDone: true,
            stationAVStatus: {
                upToDate: 2000,
                outOfDate: 25,
                notRunning: 12,
                noAV: 5,
                autoResolved: 155,
                badDevices: [
                    { hostname: "Desktop-2", status: "Out of Date" },
                    { hostname: "Desktop-1", status: "Out of Date" },
                    { hostname: "Desktop-3", status: "Not Running" }
                ]
            }
        });
    };
    render() {
        var CardSpinner,
            ReturnCard = <></>;
        if (this.props.raOnly) {
            return <></>;
        }
        if (!this.state.getDone) {
            CardSpinner = (
                <div id="loading-spinner" className="spinner-parent">
                    <Spinner size={40} className="spinner-position" />
                </div>
            );
        }
        var percent = 0;
        switch (this.props.cardType) {
            case 0:
                ReturnCard = (
                    <CardClassic
                        title={tr("DASHBOARD_AV_STATION_TITLE")}
                        cardID={this.props.cardID}
                        data={[
                            this.state.stationAVStatus.upToDate,
                            this.state.stationAVStatus.outOfDate + this.state.stationAVStatus.notRunning,
                            this.state.stationAVStatus.notRunning,
                            this.state.stationAVStatus.noAV,
                            this.state.stationAVStatus.autoResolved || 0
                        ]}
                        type={["dash-neut", "dash-warn", "dash-neg", "dash-neg", "dash-neut"]}
                        altText={[
                            tr("DASHBOARD_AV_UPTODATE"),
                            tr("DASHBOARD_AV_OUTOFDATE"),
                            tr("DASHBOARD_AV_NOTRUN"),
                            tr("DASHBOARD_AV_NOAV"),
                            tr("DASHBOARD_AV_AUTORES")
                        ]}
                        activatePopup={() => {
                            !this.props.localPreview ? this.OpenPopup() : console.log("Card Details");
                        }}
                        openHelpPopup={() => {
                            this.props.openHelpPopup("StationThreatCard");
                        }}
                        custID={this.props.custID}
                        startDate={this.props.startDate}
                        endDate={this.props.endDate}
                        isCSE={this.props.isCSE}
                        customID={this.props.customID}
                    >
                        {CardSpinner}
                    </CardClassic>
                );
                break;
            case 1:
                ReturnCard = (
                    <CardPie
                        graphType="pie"
                        cardID={this.props.cardID}
                        data={[
                            this.state.stationAVStatus.upToDate,
                            this.state.stationAVStatus.outOfDate,
                            this.state.stationAVStatus.notRunning,
                            this.state.stationAVStatus.noAV
                        ]}
                        labels={[tr("DASHBOARD_AV_UPTODATE"), tr("DASHBOARD_AV_OUTOFDATE"), tr("DASHBOARD_AV_NOTRUN"), tr("DASHBOARD_AV_NOAV")]}
                        pieColours={["#8AB785", "#FDB515", "#f12a2a", "#7c0c10"]}
                        title={tr("DASHBOARD_AV_STATION_TITLE")}
                        getDone={this.state.getDone}
                        otherData={[tr("DASHBOARD_AV_OUTOFDATE"), tr("DASHBOARD_AV_NOTRUN"), tr("DASHBOARD_AV_NOAV"), tr("DASHBOARD_AV_AUTORES")]}
                        otherDataTitle={[
                            this.state.stationAVStatus.outOfDate,
                            this.state.stationAVStatus.notRunning + this.state.stationAVStatus.notRunning,
                            this.state.stationAVStatus.noAV,
                            this.state.stationAVStatus.autoResolved || 0
                        ]}
                        type={["dash-warn", "dash-neg", "dash-neg", "dash-neut"]}
                        activatePopup={() => {
                            !this.props.localPreview ? this.OpenPopup() : console.log("Card Details");
                        }}
                        openHelpPopup={() => {
                            this.props.openHelpPopup("StationThreatCard");
                        }}
                        custID={this.props.custID}
                        startDate={this.props.startDate}
                        endDate={this.props.endDate}
                        isCSE={this.props.isCSE}
                        customID={this.props.customID}
                    >
                        {CardSpinner}
                    </CardPie>
                );
                break;
            case 2:
            default:
                if (this.state.getDone) {
                    var totalAV =
                        this.state.stationAVStatus.outOfDate +
                        this.state.stationAVStatus.notRunning +
                        this.state.stationAVStatus.noAV +
                        this.state.stationAVStatus.upToDate;
                    var totalMinusBadAV =
                        totalAV - (this.state.stationAVStatus.notRunning + this.state.stationAVStatus.noAV + this.state.stationAVStatus.outOfDate);
                    percent = (totalMinusBadAV / totalAV) * 100;
                    if ((percent >= 99) & (percent < 100)) {
                        percent = 0.99;
                    } else {
                        percent = +(percent / 100).toFixed(2);
                    }
                }
                ReturnCard = (
                    <CardGauge
                        title={tr("DASHBOARD_AV_STATION_TITLE")}
                        cardID={this.props.cardID}
                        textData={[
                            this.state.stationAVStatus.upToDate,
                            this.state.stationAVStatus.outOfDate + this.state.stationAVStatus.notRunning,
                            this.state.stationAVStatus.notRunning,
                            this.state.stationAVStatus.noAV
                        ]}
                        type={["dash-pos-opt80", "dash-warn", "dash-neg", "dash-neg"]}
                        gaugeText={tr("DASHBOARD_AV_STATION_GAUGE")}
                        gaugeColours={["#DC2323", "#35A048"]}
                        percent={percent}
                        altText={[tr("DASHBOARD_AV_UPTODATE"), tr("DASHBOARD_AV_OUTOFDATE"), tr("DASHBOARD_AV_NOTRUN"), tr("DASHBOARD_AV_NOAV")]}
                        getDone={this.state.getDone}
                        activatePopup={() => {
                            !this.props.localPreview ? this.OpenPopup() : console.log("Card Details");
                        }}
                        openHelpPopup={() => {
                            this.props.openHelpPopup("StationThreatCard");
                        }}
                        custID={this.props.custID}
                        startDate={this.props.startDate}
                        endDate={this.props.endDate}
                        isCSE={this.props.isCSE}
                        customID={this.props.customID}
                    >
                        {CardSpinner}
                    </CardGauge>
                );
                break;
        }
        return (
            <>
                {ReturnCard}
                {this.state.popupStatus && (
                    <Popup
                        content={<AntiThreatPopout theme={GetTheme()} popoutRendered={() => {}} serversBool={false} customerID={this.props.custID} />}
                        close={() => this.setState({ popupStatus: false })}
                        PopupName="StationAntiThreadCardDetails"
                    />
                )}
            </>
        );
    }
}
